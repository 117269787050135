import authService from "@auth/AuthorizeService"
import React, { useEffect, useState } from "react"
import { RegisterAdminUser } from "./RegisterUserModal"
import { MDBTable, MDBTableHead, MDBTableBody, MDBIcon } from "mdb-react-ui-kit"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"



export function AdminUsersView(){

    const [users, setUsers] = useState([])
    async function fetchUsers(){
        const token = await authService.getAccessToken()
        const res = await fetch('/api/admin/userscount',{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if(res.ok){
            setUsers(await res.json())
        }
    }
    useEffect(()=>{
        fetchUsers()
    },[])
    const navigate = useNavigate()
    async function loginToAccount(userId){
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/admin/LoginToUser/${userId}`, {
            method: 'POST',
            headers: {
                "Authorization" : `Bearer ${token}`
            }
        })
        if(res.ok){
            authService.setUser(await res.json())
            navigate('/profile/')
        } else {
            toast.error('Ошибка входа')
        }
    }
    return(
        <>
        <h4>Пользователи</h4>
        <RegisterAdminUser/>
        <p>Всего {users.length} шт.</p>
        <MDBTable>
      <MDBTableHead>
        <tr>
          <th scope='col'>#</th>
          <th scope='col'>Имя</th>
          <th scope='col'>Username</th>
          <th scope='col'>TelegramId</th>
          <th scope='col'>Пароль</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {users.map((it, i)=>
            <tr key={i}>
                <th scope='row'>{i+1}</th>
                <td>{it.firstName}</td>
                <td>{it.userName}</td>
                <td>{it.telegramId}</td>
                <td>{it.pwdIsSet ? <MDBIcon fas color="success" icon="check" /> : <MDBIcon color="danger" fas icon="times" />}</td>
                <td><button className="btn btn-danger" onClick={()=>{loginToAccount(it.id)}}><MDBIcon fas icon="user-alt" /></button></td>
            </tr>
        )}
       
      </MDBTableBody>
    </MDBTable>
        </>
    )
}