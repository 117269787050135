




// Modal.js
import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCardTitle, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from "@auth/AuthorizeService";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { changeAmount, changeAmountMinus } from '../../state/WarehouseTalbeSlice';
import { useParams } from 'react-router-dom';
import { UnloadPage } from './UnloadPage';
import { LogsPage } from './LogsPage';

const TakeoutModal = ({ item, show, setShow }) => {

   

    
    const [justifyActive, setJustifyActive] = useState('tab1');

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };
  


  return (
    <MDBModal show={show} setShow={setShow}>
        <MDBModalDialog scrollable> 
          <MDBModalContent  >
            <MDBModalHeader className='d-block pb-0'>
              <div className='d-flex justify-content-between'>
              <MDBModalTitle>Выгрузка {item?.range?.name}</MDBModalTitle>
              
              </div>
              <div>
              <MDBTabs pills  fill className='mb-3'>
              
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                  Выгрузка & Перемещение
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                  Продажа
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
              </div>
              
            </MDBModalHeader>
            <MDBModalBody>

            

            <MDBTabsContent >
              
              <MDBTabsPane show={justifyActive === 'tab1'}>
              <UnloadPage item={item}/>
              </MDBTabsPane>
              <MDBTabsPane show={justifyActive === 'tab2'}>
                <LogsPage item={item}/>

              </MDBTabsPane>
            </MDBTabsContent>
              
                
            </MDBModalBody>

            <MDBModalFooter className='d-flex justify-content-between'>
                <button className='btn btn-primary' onClick={()=> setShow()}>Закрыть</button>
                
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
  );
};

export default TakeoutModal;