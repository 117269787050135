import React, { useEffect, useState } from "react";
import { OrdersLayoutStore } from "./OrdersLayout";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchOrders } from "./OrdersState/OrdersStateSlice";
// TODO add redux
// TODO add pagination
// TODO add categories Создан, принят, отменён, выполнен, в пути, доставлен


export function StoreOrders(){
    //const orders = useSelector(state => state.orders.orders)
    
    return (
        <>
        
            
            <OrdersLayoutStore/>
        </>
    );
}