import React, { createContext, useState } from 'react';
import Modal from './AddMetroLineModal';

const AddMetroLineModalContext = createContext(null);

export const AddMetroLineModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [id, setid] = useState(1);

  const showAddMLModal = (cityId) => {
    console.log(cityId)
    setid(cityId);
    
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setid(null);
  };

  return (
    <AddMetroLineModalContext.Provider value={{ showAddMLModal, hideModal }}>
      {children}
      
      {<Modal show={modal} cityId={id} setShow={hideModal}/>}
    </AddMetroLineModalContext.Provider>
  );
};

export default AddMetroLineModalContext;