import { MDBBtn, MDBCol, MDBInput, MDBInputGroup } from 'mdb-react-ui-kit';
import React from 'react';

export default function CreateShopForm({callback}) {
    function handleSubmit(e) {
        e.preventDefault();
        
        const formData = new FormData(e.target)
        console.log(formData.get('name'));
        callback(JSON.stringify(Object.fromEntries(formData.entries())));
        console.log('imhere')
    }
    
    return(
        <>
        <p>Позже ты сможешь поменять все эти данные</p>
        <form onSubmit={handleSubmit}>
            <MDBInput required id="name" name='Name' wrapperClass='mb-4' label='Название магазина'/>
            <MDBInputGroup textBefore="@">
            <MDBInput name="username" wrapperClass='mb-4' label='Username' placeholder='bestVape'/>
            </MDBInputGroup>
            <button type='submit' className="btn btn-primary">Создать магазин</button>
            {/* <MDBBtn type="button" >Создать магазин</MDBBtn> */}
          </form>
        </>
        
    )
}

