import authService from "@auth/AuthorizeService";
import { MDBBadge, MDBCollapse, MDBIcon, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";



export function AdminSidebar(){
    
    
    const roles = authService.getRoles();
    
  const [collapse1, setCollapse1] = useState(true);
    return <>
        <div  
    className="d-block sidebar"
  >
    <div className="position-sticky">
        <NavLink to="/"><img src="/logo.png"  className="px-3 w-100"/></NavLink>
    
      <div className="list-group mt-2 mx-3">
      
        
        <NavLink
          to="/admin/"
          className="list-group-item list-group-item-action py-2"
          aria-current="true"
        >
          <i className="fas fa-tachometer-alt fa-fw me-3" />
          <span>Показатели</span>
        </NavLink>
        <NavLink
          to="/admin/orders"
          className="list-group-item list-group-item-action py-2 d-flex align-items-center"
        >
          
         
          <div>
          <i className="fas fa-chart-bar fa-fw me-3" />
          <span>Заказы</span>
          </div>
          {99 > 0 && <MDBBadge color={'info'} className="ms-auto">{99}</MDBBadge>}
          
        </NavLink>
        
        <NavLink
          to={`/admin/pricelist/`}
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-book fa-fw me-3" />
          <span>Позиции</span>
        </NavLink>
        
          <NavLink
          to="/admin/stores/"
          className="list-group-item list-group-item-action py-2"
            >
          <i className="fas fa-store fa-fw me-3" />
          
          <span>Магазины</span>
        </NavLink>
        
        
           
              
            
              
        
       
        
        <NavLink
          to="/admin/users"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-users fa-fw me-3" />
          <span>Пользователи</span>
        </NavLink>
        <NavLink
          to="/admin/locations"
          className="list-group-item list-group-item-action py-2"
        >
          <MDBIcon fas icon="map-marked-alt me-3" />
          <span>Локации</span>
        </NavLink>
        
        
        <NavLink
          to="/admin/123"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-chart-line fa-fw me-3" />
          <span>Доходы</span>
        </NavLink>
        
        <NavLink
          to="/admin/3214"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-chart-line fa-fw me-3" />
          <span>Аналитика</span>
        </NavLink>
        
        
        
        
        
        
        
        
      </div>
    </div>
  </div>
    </>
}