import { MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useRef, useState } from "react";
import authService from "../../../api-authorization/AuthorizeService";
import { BrandCard } from "./BrandCard";

import DeleteModalContext from "./DeleteModal/DeleteModalContext";
import { toast } from "react-toastify";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useDispatch, useSelector } from "react-redux";
import { AddBrandAdmin, AddCategoryAdmin, changePageAdminPriceList, changeSearchTextPL, fetchCategoriesAdmin, fetchCategoryTableAdmin, selectCategoryAdmin, switchAddCategoryModal } from "./TableStateSlice";
import AddBrandModalContext from "../addBrand/AddBrandRangeModalContext";



export function CategoriesTabs(){
    const categories = useSelector(state => state.admin.priceList.categories)
    const [observer, setObserver] = useState(null);
    const currentCategory = useSelector(state => state.admin.priceList.currentCategory)
    const page = useSelector(state => state.admin.priceList.page)
    const isLastPage = useSelector(state => state.admin.priceList.isLastPage)
    const search = useSelector(state => state.admin.priceList.search)
    const dispatch = useDispatch()
    const {showDeleteModal} = useContext(DeleteModalContext);
    const {showAddBrandModal} = useContext(AddBrandModalContext);
    const lastItemRef = useRef(null);
    const isLoading = useSelector(state => state.admin.priceList.isLoadingCategory)
    const loadMore = ()=>dispatch(fetchCategoryTableAdmin({categoryId: currentCategory, page, search}))
    useEffect(()=>{
        if(!isLastPage && currentCategory || search){
            loadMore()
        }
        
    }, [currentCategory, search, page])
    useEffect(() => {
        console.log(lastItemRef.current, 'load api setup')
        if (!lastItemRef.current) return;
    
        const options = {
          root: null, // viewport
          rootMargin: '0px',
          threshold: 0.5, // 0.5 means when 50% of the element is visible
        };
    
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            console.log(entry)
            if (entry.isIntersecting) {
              dispatch(changePageAdminPriceList())
            }
          });
        }, options);
    
        observer.observe(lastItemRef.current);
    
        //setObserver(observer);
    
        // Clean up
        return () => observer.disconnect();
      }, [categories]);
    
    async function fetchSearch(text){
        console.log('Search text: ' + text)
        dispatch(changeSearchTextPL({search: text}))
        //dispatch(fetchCategoryTableAdmin({categoryId: currentCategory, search: text, page: 0}))
    }


    const timeout = useRef(0)
    function onSearchChange(value){
        if(timeout.current < new Date().getTime() + 300000){
            clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(()=> fetchSearch(value), 300)
    }
    return (
        <>
            <MDBInput onChange={(e)=>{onSearchChange(e.currentTarget.value)}} label="Поиск по всем категориям" id="typeText" type="text" />
            <MDBTabs pills>
                {categories.map((it, index)=>{
                    return (
                        <MDBTabsItem key={index}>
                            <MDBTabsLink onClick={() => dispatch(selectCategoryAdmin(it.id))} active={currentCategory == it.id}>
                                {it.name}
                                {currentCategory == it.id &&  <button className="" style={{margin: 0, padding: 0, backgroundColor: '#ffff0000', border: 'none'}} onClick={()=> {showDeleteModal(it, 'category')}}><MDBIcon style={{marginLeft: '10px'}} color="white" size="1x" fas icon="pen" /></button>}
                               
                            </MDBTabsLink>
                        </MDBTabsItem>
                    );
                })}
                <AddCategory />
            </MDBTabs>
            <MDBTabsContent>
                {categories?.map((it, index) =>{
                    return (
                    <>
                        <MDBTabsPane style={{marginBottom: '20px'}} show={currentCategory === it.id}>
                            <MDBRow>
                                <MDBCol className="mb-3">
                                <button className="btn btn-primary" onClick={()=>{showAddBrandModal({type: 'Brand', parentId: currentCategory})}}>Добавить бренд</button>
                                    {/* <AddBrand categoryId={it.id}/> */}
                                    {/* <button onClick={()=>{fetchTable()}} style={{marginLeft: '10px'}} className="btn btn-warning">
                                        <MDBIcon fas icon="sync-alt"/>
                                    </button> */}
                                </MDBCol>
                            </MDBRow>
                            
                            <MDBRow className="gy-3">
                                    
                                    {it?.brands?.map((brand, i) =>{
                                    return (
                                        <>
                                        <MDBCol  size={12}>
                                            <BrandCard brand={brand} key={i} setTable={null}/>
                                        </MDBCol>
                                        {i == it.brands.length-1 && <div ref={lastItemRef}>Конец</div>}
                                        </>
                                    );
                                })}
                                
                            </MDBRow>
                            
                        </MDBTabsPane>
                    </>
                    )
                })}
                
            </MDBTabsContent>
            {isLoading && <div className="spinner-border text-primary" role="status"/>}
        </>
    );
}

function AddCategory(){
    const dispatch = useDispatch()
    
    
    const staticModal = useSelector(state => state.admin.priceList.addCategoryModal)
    return (
    <>
        <MDBTabsItem>
                    <MDBTabsLink onClick={()=> dispatch(switchAddCategoryModal({to: true}))}>
                        Добавить категорию
                    </MDBTabsLink>
        </MDBTabsItem>
        
        <MDBModal staticBackdrop tabIndex='-1' show={staticModal} onHide={()=>dispatch(switchAddCategoryModal({to: false}))}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Добавить категорию</MDBModalTitle>
            </MDBModalHeader>
            <form onSubmit={e => {e.preventDefault();dispatch(AddCategoryAdmin(new FormData(e.target)))}}>
            <MDBModalBody>
                <MDBInput name="Name" label='Название' onChange={(e) => {}}/>
                <MDBInput className="mt-3" name="itemsName" label='Подпись' placeholder="Вкусы, Цвет, Ом" onChange={(e) => {}}/>
            </MDBModalBody>
            <MDBModalFooter className="d-flex justify-content-between">
              <button type="button" className="btn btn-danger" onClick={()=>dispatch(switchAddCategoryModal({to: false}))}>
                Отмена
              </button>
              <button className="btn btn-success" type="submit">
                Добавить
              </button>
            </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      
        </MDBModal>
    </>
    );
}