
import { MDBBadge, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import React from "react";


export function InfoCard({number, underText, previosNumber = null}){

    const styleNumber = {
        fontSize: "1.5rem",
        fontWeight: '400'
      }
      const styleTextUnderNumber ={
        fontSize: "1rem",
        fontWeight: '300'
      }
      
    return(
        <>
            <MDBCard className="mt-3 h-100">
                <MDBCardBody className="h-100">
                  <div className="d-flex justify-content-between h-100">
                  <div className="d-flex flex-column justify-content-between">
                      <p className="mb-1" style={styleNumber}>{number}</p>
                      <p className="mb-0" style={styleTextUnderNumber}>{underText}</p>
                  </div>
                  
                  {previosNumber !== null && 
                      <div className="d-flex flex-column justify-content-between pb-2">
                        {previosNumber >= 0 ?
                        <>
                          <MDBBadge color="success">+ {previosNumber}%</MDBBadge>
                          <img src="/img/chart_up.png"/>
                        </>
                        :
                        <>
                          <MDBBadge color="warning">{previosNumber}%</MDBBadge>
                          <img src="/img/chart_down.png"/>
                        </>}
                        
                        </div>
                        }
                  </div>
                  
                </MDBCardBody>
                </MDBCard>
        </>
    )
}