import authService from "@auth/AuthorizeService";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TLoginButton, TLoginButtonSize } from "react-telegram-auth";
import { toast } from "react-toastify";

export function TelegramLoginButton({className=''}){
    const navigate = useNavigate();
    return <>
    
    <TLoginButton
                        botName={process.env.REACT_APP_BOT_USERNAME}
                        buttonSize={TLoginButtonSize.Large}
                        lang="en"
                        additionalClassNames={className}
                        usePic={true}
                        cornerRadius={20}
                        onAuthCallback={async (user) => {
                        console.log('Hello, user!', user);
                        const res = await fetch('/api/authentication/telegram', {
                            method: 'POST',
                            body: JSON.stringify(user),
                            headers: {
                            'Content-Type': 'application/json'
                            }
                        })
                        if(res.ok){
                            authService.setUser(await res.json())
                            // navigate to profile
                            navigate('/profile')
                        } else{
                            
                            toast.error('Ошибка авторизации')
                        }
                        }}
                        requestAccess={'write'}
                        
                    />
    </>
}