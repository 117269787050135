import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { LoginPage } from "./LoginPage";
import { RegisterPage } from "./RegisterPage";
import { MDBCol, MDBCollapse, MDBContainer, MDBIcon, MDBNavbar, MDBNavbarItem, MDBNavbarLink, MDBNavbarNav, MDBNavbarToggler, MDBRow } from "mdb-react-ui-kit";


export function LoginLayout(){


    return(
        <>
        <header className="mb-5">
      <MDBNavbar expand='lg' light bgColor='dark'>
        <MDBContainer fluid>
          <MDBNavbarToggler
            aria-controls='navbarExample01'
            aria-expanded='true'
            aria-label='Toggle navigation'
          >
            <MDBIcon fas icon='bars' />
          </MDBNavbarToggler>
          <MDBCollapse show>
            <MDBNavbarNav right className='mb-2 mb-lg-0'>
              <MDBNavbarItem>
                
                <NavLink to='/' className='nav-link' style={{fontSize: '1.5rem'}}>
                  <img src="/logo.png" height={35}/>
                </NavLink>
                
              </MDBNavbarItem>
              
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>

      
    </header>
    
            <MDBContainer className="">
            
            <Routes>
                
                <Route path="login" element={<LoginPage/>}/>
                {/* <Route path="register" element={<RegisterPage/>}/> */}
                {/* <Route path="profile/*" element={<ProfilePage/>}/> */}
                
                
            </Routes>
            </MDBContainer>
            
        </>
    );
}