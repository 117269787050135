import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");


const LocationsPageStateSlice = createSlice({
    name: "locationsPage",
    initialState:{
        cities: [],
        isLoadingCities: false
        
    },
    reducers:{
        
        addCity: (state, action)=>{
            state.cities.push(action.payload)
        },
        addDistrict: (state, action)=>{
            const {cityId, district} = action.payload;
            state.cities.forEach((it)=>{
                if(it.id == cityId){
                    if(it.districts?.length >= 0){
                        it.districts.push(district)
                    } else {
                        it.districts = [district]
                    }
                }
            })
        },
        addMetroStationAdmin: (state, action)=>{
            const {metroLineId, station} = action.payload;
            state.cities.map((city)=>{
                
                city?.metro?.map(metroLine =>{
                    if (metroLine.id == metroLineId){
                        if(metroLine.metroStations.length >= 0){
                            metroLine.metroStations.push(station)
                        } else{
                            metroLine.metroStations = [station]
                        }
                       
                    }
                    return metroLine
                })
                return city
            })
        },
        deleteCityAdmin: (state, action)=>{
            const {id} = action.payload;
            state.cities = state.cities.filter(x => x.id != id)
        },
        deleteDistrictAdmin: (state, action)=>{
            const {id} = action.payload;
            state.cities = state.cities.map(x => {
                x.districts = x.districts?.filter(x => x.id != id)
                return x
            }) 
            return state
        },
        deleteMetroStationAdmin: (state, action)=>{
            const {id} = action.payload;
            state.cities = state.cities.map(city => {
                city?.metro?.forEach(line => {
                    line.metroStations = line.metroStations.filter(x => x.id != id)
                })
                return city
            }) 
        },
        deleteMetroLineAdmin: (state, action)=>{
            const {id} = action.payload;
            state.cities.forEach(city => {
                city.metro = city.metro?.filter(x => x.id != id)
            }) 
        }
        
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchCities.pending, (state, action) => {
            state.isLoadingCities = true;
        });
        builder.addCase(fetchCities.fulfilled, (state, action) => {
            
            state.isLoadingCities = false;
            state.cities = action.payload;
        });
        builder.addCase(fetchCities.rejected, (state, action) => {
            state.isLoadingCities = false;
        });

        builder.addCase(fetchDistricts.pending, (state, action) => {
            state.isLoadingCities = true;
        });
        builder.addCase(fetchDistricts.fulfilled, (state, action) => {
            const { data, cityId } = action.payload;
            state.isLoadingCities = false;
            state.cities.forEach((it)=>{
                if(it.id == cityId){
                    it.districts = data.districts
                    it.metro = data.metro
                }
            })
        });
        builder.addCase(fetchDistricts.rejected, (state, action) => {
            state.isLoadingCities = false;
        });


        
       
    }
    
    // async thunk fetch chats, fetch dialog, but 

})

export const fetchCities = createAsyncThunk('locationsPage/fetchCities', async ()=>{
    const token = await authService.getAccessToken()
    const res = await fetch('/api/admin/location/cities',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})

export const fetchDistricts = createAsyncThunk('locationsPage/fetchDistricts', async ({cityId})=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/admin/location/cities/${cityId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return {data: await res.json(), cityId: cityId}
    }
    throw Error("Ошибка")
})



export const { addCity, addDistrict, addMetroStationAdmin, deleteCityAdmin, deleteDistrictAdmin, deleteMetroLineAdmin, deleteMetroStationAdmin } = LocationsPageStateSlice.actions;
export default LocationsPageStateSlice.reducer;