import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useContext } from "react";
import FlavourStoresModalContext from "./Modal/FlavourStoresModalContext";
import { NavLink } from "react-router-dom";



export function RangeBoxItem({range}){
    const {showFlavourModal} = useContext(FlavourStoresModalContext)
    return (
        <>
        
        
        <MDBCard className="mt-2">
            <MDBCardBody>

            
        <MDBRow className="gy-3 gx-3"> 
            
            <MDBCol size={12} md={4}>
                <h5 className="text-white">{range.name}</h5>
                <img style={{width: '100%', maxWidth: '250px', borderRadius:'12px'}} src={`/photos/ranges/${range.id}.jpg/0`}/>
            </MDBCol>
            <MDBCol size={12} md={8}>
                {/* <h6 className="text-white">text...)))</h6>     */}
                {range.flavours?.map((it, index)=>{
                    return (
                        <>
                        
                            <div className="d-flex w-100 justify-content-between">
                                
                                <p className="m-0 text-start">{index+1}. {it.name}</p>
                                
                                <NavLink to={`/product/${it.id}/${it.link}`} className="btn btn-primary m-1 "><MDBIcon fas icon="store-alt" /></NavLink>
                            </div>
                        </>
                    );
                })}
            </MDBCol>
            
        </MDBRow>
        </MDBCardBody>
        </MDBCard>
        
        
        </>
    )
}