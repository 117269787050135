import React, { createContext, useEffect, useState } from 'react';
import Modal from './TakeoutModal';

const TakeoutModalContext = createContext();

export const TakeoutModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const showTakeoutModal = (item, whid) => {
    setItem({flavour: item, whid});
    
    setModal(true);
  };

  
  useEffect(()=>{
      if(modal == false){
          setItem(null);
      }
  },[modal])

  return (
    <TakeoutModalContext.Provider value={{ showTakeoutModal }}>
      {children}
      {modal && item &&<Modal show={modal} item={item} setShow={setModal}></Modal>}
    </TakeoutModalContext.Provider>
  );
};

export default TakeoutModalContext;
