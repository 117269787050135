import authService from '@auth/AuthorizeService';
import {
    JsonHubProtocol,
    HubConnectionState,
    HubConnectionBuilder,
    LogLevel,
    HttpTransportType
} from '@microsoft/signalr';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteSignalRData } from './signalRHubRouter';
import { markAsReadFromStore, messageFromStore, setClientUnreadChats, storeWriting } from 'components/Global/Chat/Client/ClientChatStateSlice';
import { markAsReadFromUser, messageFromUser, setMyStoreUnreadChats, userWriting } from 'components/Global/Chat/MyStore/MyStoreChatStateSlice';
import { toast } from 'react-toastify';
import { fillInfo, newOrderToStore } from './MyStoreInfoStateSlice';
import { addOrder } from '../Orders/OrdersState/OrdersStateSlice';
import { fillUserInfo } from './UserInfoStateSlice';
import { setClientCartItemsCount } from 'components/store/Cart/MyCartState/CartStateSlice';
import { setClientOrdersItemsCount } from 'components/store/Profile/Orders/UserOrdersPageStateSlice';

const isDev = process.env.NODE_ENV === 'development';
export let SignalRConnectionInstance = null;

export function SignalRConnection() {

    const dispatch = useDispatch();

    useEffect(() => {

        const startSignalR = async () => {
            const token = await authService.getAccessToken();
            if (token) {
                SignalRConnectionInstance = new HubConnectionBuilder()
                    .withUrl("/hub", { transport: HttpTransportType.WebSockets, logMessageContent: true, logger: LogLevel.Error, accessTokenFactory: () => token }).build();

                try {
                    await SignalRConnectionInstance.start();
                    const roles = authService.getRoles();

                    if (roles.includes("StoreAdmin")) {
                        SignalRConnectionInstance.on('messageFromUser', (data) => {
                            dispatch(messageFromUser({ dto:data }))
                        })
                        SignalRConnectionInstance.on('userWriting', (data) => {
                            dispatch(userWriting({ userId: data.userId, bool: true }))
                            setTimeout(() => {
                                dispatch(userWriting({ userId: data.userId, bool: false }))
                            },
                                3000)
                        })
                        SignalRConnectionInstance.on('newOrder', (data) => {
                            dispatch(newOrderToStore({ data }))
                            dispatch(addOrder({ order: data }))
                        })
                        SignalRConnectionInstance.on('storeinfo', (data) => {
                            dispatch(fillInfo({data}))
                            dispatch(setMyStoreUnreadChats({ unreadChats: data.unreadChats }))
                        })
                        SignalRConnectionInstance.on('userRead', data => dispatch(markAsReadFromUser(data)))

                    }
                    SignalRConnectionInstance.on('storeRead', data => dispatch(markAsReadFromStore(data)))
                    SignalRConnectionInstance.on('storeWriting', (data) => {
                        dispatch(storeWriting({ storeId: data.storeId, bool: true }))
                        setTimeout(() => {
                            dispatch(storeWriting({ storeId: data.storeId, bool: false }))
                        },
                            3000)
                    })

                    SignalRConnectionInstance.on('userInfo', (data) => {
                        if(data.unreadChats != undefined){
                            dispatch(setClientUnreadChats({ unreadChats: data.unreadChats }))
                        }

                        if(data.cartItems != undefined){
                            dispatch(setClientCartItemsCount({ count: data.cartItems }))
                        }
                        if(data.orders != undefined){
                            dispatch(setClientOrdersItemsCount({ count: data.orders }))
                        }
                        
                        
                    })

                    SignalRConnectionInstance.on('messageFromStore', (data) => {
                        dispatch(messageFromStore({ dto: data }))
                    })

                    SignalRConnectionInstance.on('OrderStatusChanged', (data) => {
                        toast.success(data)
                    })

                    console.log('SignalR connection started');
                } catch (error) {
                    console.error('Error starting SignalR connection:', error);
                }
            }
        };

        const stopSignalR = () => {
            if (SignalRConnectionInstance) {
                SignalRConnectionInstance.stop();
                SignalRConnectionInstance = null;

                console.log('SignalR connection stopped');
            }
        };

        const handleUserChange = async (type) => {
            console.log('im here', type)
            
            if (type === 'login') {
                stopSignalR()
                await startSignalR()
            } else {
                stopSignalR();
            }
        };

        let subscriptionId = null;
        startSignalR().then(async ()=>{
            subscriptionId = authService.subscribe(handleUserChange);
        })
        
        return () => {
            authService.unsubscribe(subscriptionId);
            stopSignalR();
        };

    }, []);

    return <></>;
};      