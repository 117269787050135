import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBCard, MDBCardBody, MDBCardTitle, MDBBadge } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import authService from "@auth/AuthorizeService";
import { getColorByOrderStatus } from "../Orders/OrderPage/OrderPage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export function ActiveOrdersModal({to, type}){

    const [activeOrders, setActiveOrders] = useState([]);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();
    async function fetchActiveOrders(){
        const token = await authService.getAccessToken()
        console.log(type)
        const res = await fetch(`api/${type == 'Store' ? 'mystore/' : ''}orders/activeOrders/${to}`, {
          headers:{
            'Authorization': `Bearer ${token}`,
          }
        })
        if(res.ok){
            const data = await res.json();
            setActiveOrders(data)
        } else{
            toast.error('Ошибка при загрузке заказов')
        }
        
      }
    useEffect(()=>{
        if(modal == true){
            fetchActiveOrders()
        }
    }, [modal, to]);
    
    return(
        <>
          <button className="btn btn-primary btn-sm ms-auto me-2" onClick={()=> setModal(true)}>Активные заказы</button>
          <MDBModal show={modal} setShow={setModal}>
            <MDBModalDialog>
                <MDBModalContent>
                <MDBModalHeader>Активные заказы</MDBModalHeader>
                <MDBModalBody>
                
                    {activeOrders?.map((order, index) => {
                    console.log(order)
                    return(
                        <>
                        
                        <MDBCard style={{cursor: 'pointer'}} className="mt-2" onClick={()=>{navigate(`/${type == 'User' ? 'profile' : 'mystore'}/orders/${type == 'Store' ? '?order=' : ''}${order?.id}`)}}>
                                            <MDBCardBody className="">
                                                <div className="d-flex">
                                                <MDBCardTitle className="flex-grow-1">№ {order?.fId}</MDBCardTitle>
                                                <MDBBadge color={getColorByOrderStatus(order.status)} className="text-center" style={{fontSize: '1rem', paddingTop: 10}}>
                                                    {order.status}
                                                </MDBBadge>
                                                </div>
                                                <div className="d-flex mt-2">
                                                    <span className="flex-grow-1" style={{fontSize: '0.9rem'}}>{order?.date}</span>
                                                    <MDBBadge style={{fontSize: '0.9rem', paddingTop: 6}}>
                                                        {order.sum} руб.
                                                    </MDBBadge>
                                                </div>
                                                
                                                
                                            </MDBCardBody>
                                            
                        </MDBCard>
                        </>
                    )
                    })}
                    
                </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
            </MDBModal>
        </>
    )
}