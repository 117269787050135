import { MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import { useState } from "react";
import authService from "@auth/AuthorizeService";
import React from "react";
import { toast } from "react-toastify";


export function AddReferralModal({onNewAdd}){
    const [showModal, setShowModal] = useState(false);
    async function addReferral(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const token = await authService.getAccessToken();
        const res = await fetch('/api/mystore/workers/AddReferral',{
            method: "PUT",
            headers:{
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        if(res.ok){
            toast.success('Успешно')
            const data = await res.json();
            onNewAdd(data);
            setShowModal(false)
            return;
        } else{
            const err = await res.text()
            toast.error(`Ошибка\n${err.substring(1,35)}`)
        }
        

    }
    return(
        <>
        <button className="btn btn-primary" onClick={()=>setShowModal(true)}>Добавить менеджера</button>
        <MDBModal show={showModal}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Добавить менеджера</MDBModalTitle>
                    </MDBModalHeader>
                    <form onSubmit={addReferral}>
                    <MDBModalBody>
                        
                        <MDBInput label="Ник" placeholder="Ник" name="nickname" autoComplete="off"/>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn btn-success" type="submit">Добавить</button>
                        <button className="btn btn-danger" onClick={()=>setShowModal(false)}>Отмена</button>
                    </MDBModalFooter>
                    </form>
                </MDBModalContent>
            </MDBModalDialog>

        </MDBModal>
        </>
    );
}