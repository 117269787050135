import { MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import React, { useState } from "react";
import authService from "../../../api-authorization/AuthorizeService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AddBrandAdmin, AddRangeAdmin } from "../table/TableStateSlice";


export default function AddBrand({show, item, setShow}){

    //const currentCategory = useSelector(state => state.admin.priceList.currentCategory)
    const dispatch = useDispatch()

    
    async function submitForm(e){
        // collect form and send to server
        e.preventDefault();
        const fd = new FormData(e.target);
        if(item.type == 'Brand'){
          fd.append("CategoryId", item.parentId);
        } else {
          fd.append("BrandId", item.parentId);
        }
        
        const token = await authService.getAccessToken();
        const res = await fetch('/api/admin/pricelist/Add'+item.type, {
            method: "PUT",
            headers:{
                'Authorization': `Bearer ${token}`
            },
            body: fd
        });
        if(res.ok){
          toast.success('Добавлено')
          
          const brandrange = await res.json();
          if(item.type == 'Brand'){
            dispatch(AddBrandAdmin({brand: brandrange, categoryId: item.parentId}))
          } else {
            dispatch(AddRangeAdmin({range: brandrange, brandId: item.parentId}))
          }
          
          setShow(false);
        } else{
          toast.error('Ошибка\n'+ await res.text() +'\n' + res.status)
        }

    }
    
    return (
    <>
        
        
        <MDBModal staticBackdrop show={show} setShow={setShow}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Добавить {item?.type == 'Brand' ? 'бренд' : 'линейку'}</MDBModalTitle>
            </MDBModalHeader>
            <form onSubmit={submitForm}>
            <MDBModalBody>
                <MDBInput name="Name" label='Название'/>
                
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn btn-danger" onClick={()=>setShow(false)}>
                Отмена
              </button>
              <button className="btn btn-success" type="submit">
                Добавить
              </button>
            </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      
        </MDBModal>
    </>
    );
}