import MyStoreInfoSignalR from "components/Notifications/NotificationMyStore";
import { toast } from "react-toastify";
import React from "react";
import { NavLink } from "react-router-dom";
import authService from "@auth/AuthorizeService";
import { isAfter, isBefore, parse, parseISO, } from 'date-fns'
import ruLocale from "date-fns/locale/ru";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");




const MyStoreExpensesStateSlice = createSlice({
    name: "expenses",
    initialState:{
        expenses: [],
        sum: 0,
        isLoading: true,
        
    },
    reducers:{
       deleteExpenseItemR: (state, action)=>{
        state.expenses = state.expenses.filter(x => x.id != action.payload.id)
        state.sum = state.expenses.reduce((prev, cur)=> prev+cur.amount,0)
       },
       editExpenseItemR: (state, action)=>{
        console.log('im edit!!!', state.expenses)
        const hz = state.expenses.findIndex(x => x.id == action.payload.id)
        console.log(hz, action.payload)
        state.expenses[hz] = action.payload
        state.sum = state.expenses.reduce((prev, cur)=> prev+cur.amount,0)
        
       },
       
       addExpenseItemR: (state, action)=>{
        const {item, startDate, endDate} = action.payload;
        console.log()
        const dt = parse(item.date, 'dd MMMM', new Date(), {locale: ruLocale})
        
        //console.log(isAfter(dt, startDate), isBefore(dt, endDate))
        console.log(isAfter(dt, parseISO(startDate)), isBefore(dt, parseISO(endDate)), dt)
        if(isAfter(dt, parseISO(startDate)) && isBefore(dt, parseISO(endDate))){
            state.expenses = [item, ...state.expenses]

        }
        state.sum = state.expenses.reduce((prev, cur)=> prev+cur.amount,0)
        
       }
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchExpenses.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchExpenses.fulfilled, (state, action) => {
            state.expenses = action.payload.items;
            state.sum = action.payload.sum;
            state.isLoading = false;
        });
        builder.addCase(fetchExpenses.rejected, (state, action) => {
            state.isLoading = false;
        });
    }
})
export const fetchExpenses = createAsyncThunk('expenses/fetchExpenses', async ({start,end}) => {
    
    const token = await authService.getAccessToken()
    console.log(start, end)
    const res = await fetch(`/api/mystore/analytics/expenses?end=${end}&start=${start}&hx=21`,{
        headers: {
            "Authorization" : `Bearer ${token}`
        }
    })
    if(res.ok){
        return await res.json()
    }
});





export const { deleteExpenseItemR, editExpenseItemR,addExpenseItemR } = MyStoreExpensesStateSlice.actions;
export default MyStoreExpensesStateSlice.reducer;