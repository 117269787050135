import authService from "@auth/AuthorizeService";
import { RatingStars } from "components/store/ProductPage/StoresTab";
import { useTitle } from "hooks/useTitle";
import { MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ItemsForReview } from "../ProfileMain/ItemsForReview";
import { ProductReviewModalProvider } from "../ProfileMain/ReviewModal/ProductReviewModalContext";



export function MyReviewsPage(){
    const [reviews, setReviews] = useState([])
    const [loading, setLoading] = useState(true)
    async function getReviews(){
        setLoading(true)
        const token = await authService.getAccessToken()
        const res = await fetch('/api/profile/MyReviews', {
            headers: {
                "Authorization":`Bearer ${token}`
            }
        })
        if(res.ok){
            setReviews(await res.json())
        }
        setLoading(false)
    }
    useEffect(()=>{
        getReviews()
    },[])
    useTitle('Мои отзывы')
    return <>
        <h5>Мои отзывы</h5>
        <ProductReviewModalProvider>
                <ItemsForReview />
            </ProductReviewModalProvider>
        {loading ? <div className="spinner-border text-primary" role="status"/> : (reviews.length > 0 ?
        <MDBRow className="gy-3 mb-2">
            {reviews?.map((it, i)=>{
                return <MDBCol size={12}> <ReviewCard review={it} key={i}/></MDBCol>
            })}
        </MDBRow>: <p className="fw-bold">У вас ещё нет отзывов</p>)}
        
    </>
}

function ReviewCard({review}){

    return<>
        <MDBCard>
            <MDBCardBody>
                
                    <div className="d-flex align-items-start">
                        <MDBCardImage className="rounded-4" src={`/photos/ranges/${review?.rangeId}.jpg`} width='80px'/>
                            <div className="ms-2 w-100">
                                <div  className="d-flex justify-content-between">
                                <RatingStars rating={review?.rating}/> <p className="text-muted ms-2">{review?.date}</p>

                                </div>
                                <NavLink to={`/product/${review?.productId}`}><h4 className="text-white">{review?.productName}</h4></NavLink> 
                            
                            </div>
                    
                    </div>
                <div style={{lineHeight: '2rem'}}>
                                    {review?.benefits && <div className="d-flex align-items-center">
                                        <MDBIcon color="muted" fas icon="plus-circle" /> <p className="ms-2"> {review?.benefits}</p>
                                    </div>}
                                    {review?.disadvantages && <div className="d-flex align-items-center">
                                        <MDBIcon color="muted" fas icon="minus-circle" /> <p className="ms-2"> {review?.disadvantages}</p>
                                    </div>}
                                    {review?.commnet && <div className="d-flex align-items-center">
                                        <MDBIcon color="muted" fas icon="comment-dots" /> <p className="ms-2"> {review?.commnet}</p>
                                    </div>}
                    
                </div>
                <button className="btn btn-primary">Редактировать</button>

            </MDBCardBody>
        </MDBCard>
    </>
}