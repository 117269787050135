import authService from "@auth/AuthorizeService";
import { NavbarItem } from "components/layouts/Header/MobileHeader";
import { InlineLinkItem } from "components/store/Profile/ProfileMain/ProfileMainMobile";
import { MDBNavbar, MDBContainer, MDBRow } from "mdb-react-ui-kit";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";



export function MyStoreMobileHeader(){
    const unreadChats = useSelector(state => state.mystore.chat.unreadChats)
    const newOrders = useSelector(state => state.mystore.info.newOrders)
    const storeId = useSelector(state => state.mystore.info.storeId)
    const warehouses = useSelector(state => state.mystore.info.warehouses)
    const roles = authService.getRoles();
    const [menu, setMenu] = useState(false)
    return <>
        {menu && <>

            <div className="bg-dark p-3 pb-0" style={{height: '100%', position: 'fixed', top: 0, width: '100%', zIndex: 22, overflowY: 'scroll'}}>
                <div className="d-flex justify-content-between align-items-start mb-2">
                <NavLink to="/"><img src="/logo.png"  className=" w-50"/></NavLink>
                
                </div>
                
                <MDBRow className=" px-3 gy-2" style={{paddingBottom: '200px'}} onClick={()=> {if(menu) setMenu(false)}}>
                    {roles.includes('StoreAdmin') && <>
                    <InlineLinkItem url={'/mystore/'} name={'Мой магазин'}/>
                    <InlineLinkItem url={'/mystore/edit/'} name={'Редактировать'}/>
                    <InlineLinkItem url={'/mystore/orders/'} name={'Заказы'}/>
                    <InlineLinkItem url={'/mystore/chat/'} name={'Чат'}/>
                    <InlineLinkItem url={'/mystore/warehouse/'} name={'Мой склад'}/>
                    <InlineLinkItem url={'/mystore/managers/'} name={'Менеджеры'}/>
                    <InlineLinkItem url={'/mystore/couriers'} name={'Курьеры'}/>
                    <InlineLinkItem url={'/mystore/payments'} name={'Выплаты'}/>
                    <InlineLinkItem url={'/mystore/income'} name={'Доходы'}/>
                    <InlineLinkItem url={'/mystore/expenses'} name={'Расходы'}/>
                    <InlineLinkItem url={'/mystore/analytics'} name={'Аналитика'}/>
                    <InlineLinkItem url={`/store/${storeId}`} name={'Страница магазина'}/>
                    </>}
                    {roles?.includes('StoreManager') && <InlineLinkItem url={'/mystore/myManager'} name={'Я менеджер!'}/>}
                    {roles?.includes('StoreCourier') && <InlineLinkItem url={'/mystore/myCourier'} name={'Я курьер'}/>}
                    
                    
                    </MDBRow>
            </div>
        </>}
        <MDBNavbar fixed="bottom" bgColor="dark" id="bottomBar">
            <MDBContainer fluid>
                <div className="d-flex w-100 justify-content-around">
                    <NavbarItem onClick={()=>{if(menu) setMenu(false)}} name="Заказы" badgeColor="danger" indicator={newOrders} icon={'chart-bar'} link="/mystore/orders"/>
                    <NavbarItem onClick={()=>{if(menu) setMenu(false)}} name="Чат" badgeColor="danger" indicator={unreadChats} icon={'comment'} link="/mystore/chat"/>
                    <NavbarItem onClick={()=>{if(menu) setMenu(false)}} name="Склад" badgeColor="primary" indicator={warehouses?.length} icon={'globe'} link="/mystore/warehouse/"/>
                    <NavbarItem onClick={()=>{if(menu) setMenu(false)}} name="Аналитика" badgeColor="success" indicator={'+32'} icon={'chart-line'} link="/mystore/income"/>
                    <div 
                            onClick={()=> setMenu(prev => !prev)}
                            className="me-1  rounded py-1  nav-link d-flex flex-column align-items-center"
                            >
                            <div>
                                <i style={{color: 'gray'}} className={`fas fa-bars m-1 me-md-2`} />
                                
                            </div>
                            
                            
                            <p style={{fontSize: '13px'}} className="d-md-block mb-0 text-muted">Меню</p>
                            </div>
                    
                </div>
            </MDBContainer>
        </MDBNavbar>
    </>
}