import React, { Component } from 'react';
import authService from '@auth/AuthorizeService';
import { MDBCol } from 'mdb-react-ui-kit';
import { NavLink, Outlet, Route, Router, Routes } from 'react-router-dom';
import { MyStoreEdit } from './Edit/Edit';
import AuthorizeRoute from '@auth/AuthorizeRoute'
import { MyStoreIndex } from './Index/Index';
import './myStore.css'
import { ManagersPage } from './ReferralPage/ManagersPage';
import { Warehouse } from './warehouse/Warehouse';
import { StoreOrders } from './Orders/Orders';
import { NewWarehouse } from './warehouse/NewWarehouse';
import { Provider } from 'react-redux';
//import WhStore from './warehouse/state/WarehouseStateStore'
import { IncomesPage } from './Analytics/AnalitycsPage';
import { CouriersPage } from './CourierPage/CouriersPage';
import { StoreChat } from 'components/store/Chat/Store/StoreChat';
import { CourierMainView } from './CourierPage/CourierView/CourierMainView';
import { CourierView } from './CourierPage/CourierView';
import { AdminCourierView } from './CourierPage/AdminCourierView';
import { AdminManagerView } from './ReferralPage/ManagerView/AdminManagerView';
import { ManagerMainView } from './ReferralPage/ManagerView/ManagerMainView';
import { TopSales } from './Analytics/TopSales/TopSales';
import { ExpensesPage } from './Analytics/Expenses/ExpensesPage';
import { MyStoreLayout } from './Layout/MyStoreLayout';
import { WarehousesView } from './warehouse/WarehousesView';

export class MyStore extends Component {
  static displayName = MyStore.name;

  constructor(props) {
    super(props);
    this.state = { store: null, loading: true };
  }
  componentDidMount(){
    
  }
  
  
  
  
  render() {
    
    return (
      <>
        
      
          {/* <MyStoreNavMenu/> */}
          
            
                  
                    
                      
                <Routes>
                  
                  <Route path='/' element={<MyStoreLayout/>}>
                  <Route index element={<MyStoreIndex/>}/>
                    
                  <Route path='edit' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<MyStoreEdit/>}/>}/>
                  
                  <Route path='managers'>
                    <Route path='' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<ManagersPage/>}/>}/>
                    <Route path=':id' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<AdminManagerView/>}/>}/>
                  </Route>
                  <Route path='couriers'>
                    <Route path='' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<CouriersPage/>}/>}/>
                    <Route path=':id' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<AdminCourierView/>}/>}/>
                  </Route>
                  <Route path='warehouse' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<WarehousesView/>}/>} />
                  <Route path='warehouse/:id' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<Warehouse/>}/>} />
                  <Route path='warehouse/add' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<NewWarehouse/>}/>} />
                  <Route path='chat' element={<AuthorizeRoute children={<StoreChat/>} requierRoles={['StoreAdmin', 'StoreCourier', 'StoreManager']}/>}/>
                  <Route path='orders' element={<AuthorizeRoute children={<StoreOrders/>} requierRoles={['StoreAdmin']}/>}/>
                  <Route path='income' element={<AuthorizeRoute children={<IncomesPage/>} requierRoles={['StoreAdmin']}/>}/>
                  <Route path='analytics' element={<AuthorizeRoute children={<TopSales/>} requierRoles={['StoreAdmin']}/>}/>
                  <Route path='myCourier' element={<AuthorizeRoute children={<CourierView/>} requierRoles={['StoreCourier']} />}/>
                  <Route path='myManager' element={<AuthorizeRoute children={<ManagerMainView/>} requierRoles={['StoreManager', 'StoreAdmin']} />}/>
                  <Route path='expenses' element={<AuthorizeRoute children={<ExpensesPage/>} requierRoles={['StoreManager', 'StoreAdmin']} />}/>
                  <Route path='payments' element={<AuthorizeRoute requierRoles={['StoreAdmin']} children={<h3>В разработке</h3>}/>}/>
                  </Route>
                </Routes>
                    
                  
              
              
          
                
          
        
      </>
    );
  }
}
