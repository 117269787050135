import React, { useEffect, useRef, useState } from 'react'
import { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

export default function AuthorizeRoute({children, requierRoles = []}) {
  const [ready, setReady] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const _subscription = useRef()
  function checkRoles(requireRoles, userRoles){
    if(!requireRoles){
      return true
    }
    if(requireRoles.length == 0) return true;
    return userRoles.some(value => requireRoles.includes(value))
  }
  async function populateAuthenticationState() {
    const authenticated = authService.isAuthenticated();
    const roles = await authService.getRoles();
    
    if(checkRoles(requierRoles, roles)){
      setReady(true)
      setAuthenticated(authenticated)
    }
  }

  async function authenticationChanged(type) {
    console.log(type)
    if(type == 'logout'){
      setReady(true)
      setAuthenticated(false)
    } else if(type == 'login'){
      setReady(true)
      setAuthenticated(true)
    }
    
    //await this.populateAuthenticationState();
  }
  useEffect(()=>{
    _subscription.current = authService.subscribe(authenticationChanged);
    populateAuthenticationState();
    return ()=> {
      authService.unsubscribe(_subscription.current);
    }
  },[])
  

  
  
    
    
    // var link = document.createElement("a");
    // link.href = this.props.path;
    // const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const currentUrl = window.location.pathname + window.location.search + window.location.hash;
    const returnUrl = encodeURIComponent(currentUrl);
    
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    
    if (!ready) {
      return <div className="spinner-border text-primary" role="status"/>;
    } else {
      
      return authenticated ? children  : <Navigate replace to={redirectUrl} />;
    }

  
}
