


import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk, current, isRejected } = require("@reduxjs/toolkit")

const ordersHz = {
    category: 'active',
    orders: []
}

const MyCourierStateSlice = createSlice({
    name: 'myCourierState',
    initialState: {
        orders: [],
        completedOrders: [],
        isLoading: false,
        completeError: null,
        activeTab: 0,
        page: 0,
        lastPage: false,
        courierId: null
    },
    reducers: {
        
        setCourierId: (state, action) =>{
            const {courierId} = action.payload;
            state.courierId = courierId;
            console.log(state.courierId, 'cur id', state)
        },
        setCourierActiveTab: (state, action)=>{
            const {status} = action.payload;
            state.page = 0;
            state.lastPage = false;
            state.activeTab = status;
            state.orders = []
            return state
        },
        cleanCompleteError: (state, action)=>{
            
            state.completeError = null;
            
            return state
        },
        changeCourierPage: (state, action) =>{
            if(state.lastPage == false){
                state.page += 1;
            }
        }
        

    },
    extraReducers: (builder) => {
        builder.addCase(fetchMyCourierOrders.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMyCourierOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            if(!action.payload){
                return state
            }
            const {orders, status} = action.payload
            if(!orders){
                return state
            }
            if(orders.length === 0){
                state.lastPage = true
            }
            state.orders = [...state.orders, ...orders];
            
        });
        builder.addCase(fetchMyCourierOrders.rejected, (state, action) => {
            state.isLoading = false;
            
        });

        // categories
        builder.addCase(myCourierCompleteOrder.pending, (state, action) => {
           state.completeError = null
        });
        builder.addCase(myCourierCompleteOrder.fulfilled, (state, action) => {
            
            console.log(action.payload)
            const {orderId} = action.payload;
            state.orders = state.orders.filter((it)=> it.id !== orderId)
            
        });
        builder.addCase(myCourierCompleteOrder.rejected, (state, action) => {
            state.completeError = action.payload
        });
      }
})
export const myCourierCompleteOrder = createAsyncThunk('myCourierState/completeOrder', async ({orderId}, {getState, rejectWithValue}) => {
    
    const state = getState()
    const token = await authService.getAccessToken()
    
        const res = await fetch(`/api/mystore/mycourier/orders/${orderId}/completeOrder?courierId=${state?.mystore.myCourier?.courierId}`,{
          headers:{
            "Authorization": `Bearer ${token}`
          },
          method: 'POST'
        })
        if(res.ok){
        return {orderId};
        } else{
          
          return rejectWithValue(await res.text())
        }
});
export const fetchMyCourierOrders = createAsyncThunk('myCourierState/fetchOrders', async ({status, page}, {getState, rejectWithValue}) => {
    
    if(status == undefined) return;
    console.log('fetching orders...')
    const state = getState()
    console.log('curState',state)
    const token = await authService.getAccessToken()
      const res = await fetch(`/api/mystore/mycourier/orders?courierId=${state?.mystore.myCourier?.courierId}&status=${status}&page=${page}`,{
        headers:{
          "Authorization": `Bearer ${token}`
        }
      })
      if(res.ok){
        return {orders: await res.json(), status: status}
      }
      return rejectWithValue(null)
});

export const { setCourierId, setCourierActiveTab, cleanCompleteError, changeCourierPage } = MyCourierStateSlice.actions
export default MyCourierStateSlice.reducer