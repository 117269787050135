import React, { createContext, useState } from 'react';
import Modal from './FlavourStoresModal'
const FlavourStoresModalContext = createContext();

export const FlavourStoresModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const showFlavourModal = (flavour) => {
    setItem(flavour);
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setItem(null);
  };

  return (
    <FlavourStoresModalContext.Provider value={{ showFlavourModal, hideModal }}>
      {children}
      {<Modal show={modal} flavour={item} setShow={hideModal}></Modal>}
    </FlavourStoresModalContext.Provider>
  );
};

export default FlavourStoresModalContext;
