import authService from "@auth/AuthorizeService";

const { createSlice, createAsyncThunk, current } = require("@reduxjs/toolkit")



export const OrdersStateSlice = createSlice({
    name: 'ordersState',
    initialState: {
        orders: [],
        isLoading: false,
        error: null,
        categories: [],
        isLoadingCategories: false,
        activeCategory: 0,
        page: 0,
        lastPage: false
    },
    reducers: {
        updateStatus: (state, action) => {
            const {orderId, status} = action.payload;
            state.orders.forEach((order)=>{
                if(order.id === orderId){
                    order.status = status;
                }
            })
        },
        updateSum: (state, action) => {
            const {orderId, sum} = action.payload;
            state.orders.forEach((order)=>{
                if(order.id === orderId){
                    order.sum = sum;
                }
            })
        },
        changePage: (state, action) =>{
            if(state.lastPage == false){
                state.page += 1;
            }
        },
        removeOrderFromCat: (state, action) => {
            const {orderId, orderStatus} = action.payload;
            state.orders = state.orders.filter((order)=>order.id !== orderId);
        },
        setActiveCategory: (state, action) => {
            console.log('Устанавливаю активную категорию')
            state.activeCategory = action.payload;
            state.page = 0;
            state.lastPage = false;
            state.orders = []
        },
        addOrder: (state, action) =>{
            const {order} = action.payload;
            state.orders = [order, ...state.orders]
        },
        cleanOrders: (state, action)=>{
            state.orders = []
        }

    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrders.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            const {orders, status} = action.payload
            if(orders.orders.length == 0){
                state.lastPage = true
            }
            
                state.orders = [...state.orders, ...orders.orders];
            
            
            
        });
        builder.addCase(fetchOrders.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });

        // categories
        builder.addCase(fetchCategories.pending, (state, action) => {
            state.isLoadingCategories = true;
        });
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.isLoadingCategories = false;
            console.log(action.payload)
            state.categories = action.payload;
        });
        builder.addCase(fetchCategories.rejected, (state, action) => {
            state.isLoadingCategories = false;
            state.error = action.error.message;
        });
      }
})
export const fetchCategories = createAsyncThunk('ordersState/fetchCategories', async (id) => {
    
    
    const token = await authService.getAccessToken();
    const res = await fetch('api/mystore/orders/OrderStatuses', {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    if(!res.ok){
        // error
        console.log(await res.text())
        return await Promise.reject(await res.text());
    }
    const data = await res.json();
    return data;
});
export const fetchOrders = createAsyncThunk('ordersState/fetchOrders', async ({status, page, pageSize}) => {
    
    if(status == undefined) return;
    const token = await authService.getAccessToken();
    const res = await fetch(`api/mystore/orders/orders?status=${status}&page=${page}&pageSize=${pageSize}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    if(!res.ok){
        // error
        console.log(await res.text())
        return;
    }
    const data = await res.json();
    return {orders: data, status: status};
});
export const { updateStatus, setActiveCategory, addOrder, changePage, cleanOrders } = OrdersStateSlice.actions
export default OrdersStateSlice.reducer