import authService from "@auth/AuthorizeService";
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ItemForReview, ItemsForReview } from "./ItemsForReview";
import { ProductReviewModalProvider } from "./ReviewModal/ProductReviewModalContext";


export function ProfileMain(){
    
    return (
        <>
            <h4>Привет!</h4>
            
            

            
            
            <MDBRow className="gx-2">
                
                
                <MDBCol size={4}>
                    <NavLink to='favorites' className='topProfileLink flex-fill'>
                        <MDBCard className="topProfileCard">
                            <MDBCardBody className="topProfileCardBody">
                            <MDBIcon fas icon="heart" color="danger"/>
                                <p className="title">Избранное</p>
                                <p className="text-muted desc">32 товара</p>
                            </MDBCardBody>
                        </MDBCard>
                    </NavLink>
                </MDBCol>
                <MDBCol size={4}>
                    <NavLink to='/profile/points/' className='topProfileLink flex-fill'>
                        <MDBCard className="topProfileCard">
                            <MDBCardBody className="topProfileCardBody">
                            <MDBIcon far icon="money-bill-alt" color="success"/>
                                <p className="title">Баллы</p>
                                <p className="text-muted desc">231</p>
                            </MDBCardBody>
                        </MDBCard>
                    </NavLink>
                </MDBCol>
                <MDBCol size={4}>
                    <NavLink to='/profile/points/' className='topProfileLink flex-fill'>
                        <MDBCard className="topProfileCard">
                            <MDBCardBody className="topProfileCardBody">
                                <MDBIcon color="warning" fas icon="star" />
                                <p className="title">Ждут отзыва</p>
                                <p className="text-muted desc">32 товара</p>
                            </MDBCardBody>
                        </MDBCard>
                    </NavLink>
                </MDBCol>
                
                
                
                
            </MDBRow>
                
            <h4 className="my-3">Оцени недавние заказы</h4>
            <ProductReviewModalProvider>
                <ItemsForReview />
            </ProductReviewModalProvider>
            
            
            <h4 className="my-3">Используйте промокод</h4>
            <div className="d-flex">
                <MDBInput label="Промокод" placeholder="SDF32S" wrapperStyle={{maxWidth: '200px'}}/>
                <button className="btn btn-success ms-2">Применить</button>
            </div>
        </>
    );
}