import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCity, fetchCities, fetchDistricts, fetchMetroAdmin } from "./LocationsStateStore";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";
import AddDistrictProviderModalContext, { AddDistrictProvider } from "./AddDistrictModal/AddDistrictModalContext";
import AddMetroLineModalContext, { AddMetroLineModalProvider } from "./AddMetroLine/AddMetroLineModalContext";
import AddMetroStationModalContext, { AddMetroStationModalProvider } from "./AddMetroStation/AddMetroStationModalContext";
import DeleteLocationModalContext, { DeleteLocationModalProvider } from "./DeleteModal/DeleteModal";


export function LocationPage(){

    const dispatch = useDispatch()
    const cities = useSelector(state => state.admin.locations.cities)
    const isLoadingCities = useSelector(state => state.admin.locations.isLoadingCities)
    const [isEdit, setIsEdit] = useState(false)
    useEffect(()=>{
        dispatch(fetchCities())
    },[])

    return(<>
        <h4>Редактирование станций, метро, районов</h4>
        <div className="mb-4 d-flex justify-content-between">
        <AddCityModal onAdd={data => dispatch(addCity(data))}/>
        <button className="btn btn-warning" onClick={()=> setIsEdit(prev => !prev)}>{isEdit ? <MDBIcon fas icon="check" /> : <MDBIcon far icon="trash-alt" />}</button>
        </div>
        
        {isLoadingCities ? <div className="spinner-border text-primary" role="status"/> : (!cities ? <p>Пока что не городов</p> : "")}
        <AddDistrictProvider>
            <AddMetroLineModalProvider>
                <AddMetroStationModalProvider>
                    <DeleteLocationModalProvider>
                        {cities.map((it, index)=> <CityCard isEdit={isEdit} city={it} key={index}/>)}
                    </DeleteLocationModalProvider>
                    
                </AddMetroStationModalProvider>
            </AddMetroLineModalProvider>
            
        </AddDistrictProvider>
        
        
    </>)
}
function CityCard({city, isEdit}){
    const { showAddModal } = useContext(AddDistrictProviderModalContext);
    const {showAddMLModal} = useContext(AddMetroLineModalContext)
    const {showAddMSTModal} = useContext(AddMetroStationModalContext)
    const { showDeleteModal } = useContext(DeleteLocationModalContext)
    const [showMore, setShowMore] = useState(false)
    const dispatch = useDispatch()
    function toogleShow(){
        setShowMore(prev => {
            if(!prev){
                // load districts
                dispatch(fetchDistricts({cityId: city.id}))
                
            }
            return !prev
        })
    }
    return(
        <>
        <MDBCard className="mb-3">
            <MDBCardHeader className="d-flex justify-content-between align-items-center flex-wrap">
                <MDBCardTitle className="pb-0 mb-2">{city.name}</MDBCardTitle>
                <MDBRow style={{maxWidth: '500px'}} className="d-flex flex-wrap gx-2 gy-3 w-100 text-nowrap">
                    {isEdit && <MDBCol size={6} sm={2}><button className="btn btn-danger w-100" onClick={()=> {showDeleteModal(city, 'Cities')}}><MDBIcon fas icon="minus" /></button></MDBCol> }
                    <MDBCol size={6} sm={3} ><button className="btn btn-info w-100" onClick={()=> showAddModal(city.id)}>район <MDBIcon fas icon="plus" /></button></MDBCol>
                    <MDBCol size={6} sm={5} ><button className="btn btn-info w-100" onClick={()=> showAddMLModal(city.id)}>линия метро <MDBIcon fas icon="plus" /></button></MDBCol>
                    <MDBCol size={6} sm={2} ><button className="btn btn-info w-100" onClick={toogleShow}>{showMore  ? <MDBIcon fas icon="angle-double-up" /> :<MDBIcon fas icon="angle-double-down" />}</button></MDBCol>
                </MDBRow>
                
            </MDBCardHeader>
            {showMore && 
                <MDBCardBody>
                    {city?.districts?.map((it, index)=>{
                        return<div className="d-flex mt-1">
                            <p>{index+1}. {it.name}</p> {isEdit && <button className="btn btn-danger p-0 px-2 ms-2"  onClick={()=> {showDeleteModal(it, 'disctricts')}}><MDBIcon fas icon="minus" /></button>}
                        </div>
                    })}
                    {city?.metro?.length > 0 && <><hr/> <h6>Метро</h6></>}
                    {city?.metro?.map((it, index)=>{
                        return<>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div style={{width: '30px', height: '30px', backgroundColor: `#${it.color}`}}></div>
                                    <p style={{fontWeight: '600', fontSize: '30px'}} className="p-0 mb-0 ms-3">{it.name}</p>
                                </div>
                                <div>
                                    {isEdit && <button className="btn btn-danger" onClick={()=> {showDeleteModal(it, 'MetroLines')}}><MDBIcon fas icon="minus" /></button>}
                                    <button className="btn btn-info ms-2" onClick={()=> showAddMSTModal(it.id)}><MDBIcon fas icon="plus" /></button>
                                </div>
                                
                            </div>
                            
                            {it?.metroStations?.map((station, i)=>{
                                return<div className="d-flex mt-1">
                                    <p>{i+1}. {station.name}</p> {isEdit && <button className="btn btn-danger p-0 px-2 ms-2"  onClick={()=> {showDeleteModal(station, 'MetroStations')}}><MDBIcon fas icon="minus" /></button>}
                                </div>
                            })}
                        </>
                    })}
                </MDBCardBody>
}
        </MDBCard>
            
        </>
    )
}
function AddCityModal({onAdd}){

    const [modal, setModal] = useState(false)
    async function applyChanges(e){
        e.preventDefault();
        const fd = new FormData(e.target)
        
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/admin/location/cities`,{
            method: 'PUT',
            headers:{
                "Authorization": `Bearer ${token}`
            },
            body: fd
        })
        if(res.ok){
            onAdd(await res.json())
            setModal(false)
        } else{
            toast.error('Ошибка!')
        }
    }
    return(<>
        <button className="btn btn-primary" onClick={()=> setModal(true)}>Добавить город</button>
        <MDBModal show={modal} setShow={setModal}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader><MDBModalTitle>Добавить город</MDBModalTitle></MDBModalHeader>
                    <form onSubmit={applyChanges}>
                        <MDBModalBody>
                            <MDBInput label={'Имя'} placeholder="Имя" name="name"></MDBInput>
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={()=> setModal(false)}>Закрыть</button>
                            <button className="btn btn-success">Добавить</button>
                        </MDBModalFooter>
                    </form>
                    
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </>)
}
