import React, { useEffect, useState } from "react";
import authService from "@auth/AuthorizeService";
import { CategoriesW } from "./Category";
import { useLocation, useParams } from "react-router-dom";
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBInput, MDBModalFooter, MDBBadge, MDBIcon, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBListGroupItem, MDBBtn } from "mdb-react-ui-kit";


import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchTableData } from "./state/WarehouseTalbeSlice";
import { getWhBadgeColor, getWhBadgeText } from "../WarehouseTypes";
import { TakeoutModalProvider } from "./Modals/TakeoutModalContext";
import { BigTakeoutModalProvider } from "./Modals/Takeout/BigTakoutModalContext";


export function Warehouse() {
    
    
    const location = useLocation();
    const { state } = location;
    const table = useSelector(state=>state.mystore.warehouseTable.categories)
    const loading = useSelector(state=>state.mystore.warehouseTable.isLoading)
    const dispatch = useDispatch()
    const warehouses = useSelector(state => state.mystore.info.warehouses)
    const {id} = useParams();
    
    const totalBalance = useSelector(state=>state.mystore.warehouseTable.totalBalance)
    
      useEffect(()=>{
        dispatch(fetchCategories(id))
      }, [id])
    
    return (
        <>
        <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
                <h2>Мой склад</h2>
                <p className="ms-4 pb-0 mb-0">Баланс: {totalBalance} руб.</p>
            </div>
            
            <div>
                <EditWarehouse warehouse={warehouses.find(x => x.id == id)}/>
                
                
            </div>
            
            
        </div>
        <TakeoutModalProvider >
            <BigTakeoutModalProvider>
                <CategoriesW table={table}/>
            </BigTakeoutModalProvider>
        
        </TakeoutModalProvider>
        {loading && <div className="spinner-border text-primary" role="status"/>}
        </>
    );
}

function EditWarehouse({warehouse}){

    const [name, setText] = useState(warehouse?.name)
    async function editWarehouse(){
        
        const fd = new FormData();
        fd.append('type', type.toString());
        fd.append('id', warehouse.id);
        fd.append('name', name)
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/warehouse/edit`, {
            method: 'PATCH',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: fd
        });
        if(res.ok){
            setModal(false);
            window.location.reload();
        }
    }
    const [modal, setModal] = useState(false);
    const [type, setType] = useState(0);
    async function DeleteWarehoues(){
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/warehouse/${warehouse.id}`, {
            method: 'DELETE',
            headers: {
                "Authorization" : `Bearer ${token}`
            }
        })
        if(res.ok){
            toast.success('Удалён')
        } else {
            toast.error('Ошибка удаления. Склад должен быть пустым', {autoClose: false})
        }
    }
    useEffect(()=>{
        setType(warehouse?.type)
        setText(warehouse?.name)
    }, [warehouse])
    return(
        <>
            
            <button onClick={()=>setModal(true)} className="btn btn-primary me-2"><MDBIcon fas icon="pen" /></button>
            <MDBModal show={modal} setShow={setModal}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Редактировать склад</MDBModalTitle>
                        </MDBModalHeader>
                        
                        <MDBModalBody>
                        
                        {warehouse?.name && <MDBInput label="Название склада" defaultValue={warehouse?.name} value={name} onChange={(e)=> {setText(e.target.value)}} name="name" type="text" />}
                            

                            <MDBDropdown className='btn-group my-2'>
                                {/* <button type="button" className={`btn btn-${getBadgeColor(type)}`}>Тип: {getBadgeText(type)}</button> */}
                                <MDBDropdownToggle type="button" className={`btn-${getWhBadgeColor(type)}`}>Тип: {getWhBadgeText(type)}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    
                                    
                                    <MDBDropdownItem type="button" link onClick={()=> setType(0)} childTag='button'>
                                    {getWhBadgeText(0)}
                                    </MDBDropdownItem>
                                    <MDBDropdownItem type="button" link onClick={()=> setType(1)} childTag='button'>
                                    {getWhBadgeText(1)}
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            
  
                            
                            <MDBBtn className="d-block" color="danger" onClick={DeleteWarehoues}>Удалить склад</MDBBtn>
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={()=>setModal(false)}>Закрыть</button>
                            <button onClick={()=> editWarehouse()} className="btn btn-success">Применить</button>
                        </MDBModalFooter>
                        
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}


export function AddWarehouse(){

    async function addWarehouse(e){
        e.preventDefault();
        const fd = new FormData(e.target);
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/warehouse/create`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: fd
        });
        if(res.ok){
            setModal(false);
            window.location.reload();
        } else{
            toast.error("Ошибка при добавлении склада");
        }
    }
    const [modal, setModal] = useState(false);
    return(
        <>
            <button onClick={()=>setModal(true)} className="btn btn-primary">Добавить склад</button>
            
            <MDBModal show={modal} setShow={setModal} >
                <MDBModalDialog style={{zIndex: 23}}>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Добавить склад</MDBModalTitle>
                        </MDBModalHeader>
                        <form onSubmit={addWarehouse}>
                        <MDBModalBody>
                        
                            <MDBInput label="Название склада" placeholder="Основной" name="name" type="text" />
                            <label className="select mt-2" htmlFor="slct"> Тип склада
                                    <select name="whType" id="slct">
                                        <option value='0'>{getWhBadgeText(0)}</option>
                                        <option value='1'>{getWhBadgeText(1)}</option>
                                        
                                    </select>
                                    <svg>
                                        <use xlinkHref="#select-arrow-down"></use>
                                    </svg>
                                </label>
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={()=>setModal(false)}>Закрыть</button>
                            <button type="submit" className="btn btn-success">Добавить</button>
                        </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
