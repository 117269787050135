import MyStoreInfoSignalR from "components/Notifications/NotificationMyStore";
import { toast } from "react-toastify";
import React from "react";
import authService from "@auth/AuthorizeService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");




const UserOrdersPageStateSlice = createSlice({
    name: "userOrdersPage",
    initialState:{
        orders: [],
        isLoadingOrders: true,
        currentOrder: [],
        isLoadingPage: true,
        count: 0
    },
    reducers:{
        setClientOrdersItemsCount: (state, action)=>{
            state.count = action.payload.count;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserOrders.pending, (state, action) => {
            state.isLoadingOrders = true
        });
        builder.addCase(fetchUserOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
            state.isLoadingOrders = false
            
        });
        builder.addCase(fetchUserOrders.rejected, (state, action) => {
            state.isLoadingOrders = false
        });

        builder.addCase(fetchUserOrderById.pending, (state, action) => {
            state.isLoadingPage = true
        });
        builder.addCase(fetchUserOrderById.fulfilled, (state, action) => {
            state.currentOrder = action.payload;
            state.isLoadingPage = false
            
        });
        builder.addCase(fetchUserOrderById.rejected, (state, action) => {
            state.isLoadingPage = false
        });
    }
})

export const fetchUserOrders = createAsyncThunk('userOrdersPage/fetchOrders', async ()=>{
    
    const token = await authService.getAccessToken();
    const res = await fetch('api/orders', {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})

export const fetchUserOrderById = createAsyncThunk('userOrdersPage/fetchOrderById', async ({id})=>{
    
    const token = await authService.getAccessToken();
    const res = await fetch(`/api/orders/${id}`,{
        headers:{
            "Authorization": `Bearer ${token}`
        }
    });
    if(res.ok){
        return await res.json()
    }
    throw Error()
})
export const { setClientOrdersItemsCount } = UserOrdersPageStateSlice.actions;
export default UserOrdersPageStateSlice.reducer;