import React, { createContext, useEffect, useState } from 'react';
import Modal from './AddBrand';

const AddBrandModalContext = createContext();

export const AddBrandRangeModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const [item, setItem] = useState(null);
  const showAddBrandModal = (item) => {
    setItem(item)
    setModal(true);
  };
  
  

  return (
    <AddBrandModalContext.Provider value={{ showAddBrandModal }}>
      {children}
      {<Modal show={modal} item={item} setShow={setModal}></Modal>}
    </AddBrandModalContext.Provider>
  );
};

export default AddBrandModalContext;
