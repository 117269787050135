import authService from "@auth/AuthorizeService";
import { MDBCard, MDBCardBody, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { toast } from "react-toastify";
import * as locales from 'react-date-range/dist/locale';
import ExpenseEditModalContext, { ExpenseEditModalProvider } from "./EditExpneseModal/EditExpneseModalContext";
import { useDispatch, useSelector } from "react-redux";
import { addExpenseItemR, fetchExpenses } from "./ExpensesStateSlice";

export function ExpensesPage(){
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.mystore.expenses.isLoading)
    const expenses = useSelector(state => state.mystore.expenses.expenses)
    const sum = useSelector(state => state.mystore.expenses.sum)
    useEffect(()=>{
        console.log(expenses)
    },[expenses])
    const [rangePicker, setRangePicker] = useState([{
        startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ),
        endDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth()+1,
            0
          ),
        key: 'selection'
      }])
    
      const [showCalendar, setShowCalendar] = useState(false);
    
    useEffect(()=>{
        console.log(rangePicker)
        dispatch(fetchExpenses({start: rangePicker[0].startDate.toISOString().split('T')[0], end: rangePicker[0].endDate.toISOString().split('T')[0]}))
    },[rangePicker])
    
      function handleDateRangePicker(range){
        console.log(range)
        setRangePicker([range])
      }
      function handlePrevMonth() {
        const newStartDate = new Date(
          rangePicker[0].startDate.getFullYear(),
          rangePicker[0].startDate.getMonth() - 1,
          1
        );
        const newEndDate = new Date(
          rangePicker[0].startDate.getFullYear(),
          rangePicker[0].startDate.getMonth(),
          0
        );
        setRangePicker([{ startDate: newStartDate, endDate: newEndDate, key: 'selection' }])
      }
      
      function handleNextMonth() {
        const newStartDate = new Date(
          rangePicker[0].endDate.getFullYear(),
          rangePicker[0].endDate.getMonth() + 1,
          1
        );
        const newEndDate = new Date(
          rangePicker[0].endDate.getFullYear(),
          rangePicker[0].endDate.getMonth() + 2,
          0
        );
        setRangePicker([{ startDate: newStartDate, endDate: newEndDate, key: 'selection' }])
      }
    return <>
        <div className="d-flex justify-content-between align-items-center mt-2">
            <h4>Расходы</h4>
            <AddExpenseModal onAdd={(r)=>dispatch(addExpenseItemR({item: r, startDate: rangePicker[0].startDate.toISOString(),endDate: rangePicker[0].endDate.toISOString()}))}/>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
            <div>
            <button className="btn btn-primary" onClick={()=> handlePrevMonth()}><MDBIcon fas icon="arrow-left" /></button>
        <button
            data-mdb-ripple-duration="0"
            
            className={'btn btn-primary mx-2'}
            onClick={() => setShowCalendar((prev) => !prev)}
        >
        {showCalendar
            ? `${rangePicker[0].startDate.toLocaleDateString('ru-ru')} - ${rangePicker[0].endDate.toLocaleDateString('ru-ru')}`
            : (rangePicker[0].startDate.getDate() === 1 &&
                rangePicker[0].endDate.getDate() ===
                new Date(
                    rangePicker[0].startDate.getFullYear(),
                    rangePicker[0].startDate.getMonth() + 1,
                    0
                ).getDate()) ||
            (rangePicker[0].startDate.getDate() < 2 &&
                rangePicker[0].endDate.getDate() >
                new Date(
                    rangePicker[0].startDate.getFullYear(),
                    rangePicker[0].startDate.getMonth() + 1,
                    0
                ).getDate())
            ? rangePicker[0].startDate.toLocaleDateString('ru-ru', {
                month: 'long',
            })
            : `${rangePicker[0].startDate.toLocaleDateString('ru-ru')} - ${rangePicker[0].endDate.toLocaleDateString('ru-ru')}`}{' '}
        <MDBIcon fas icon="calendar" />
        </button>
        <button className="btn btn-primary" onClick={()=> handleNextMonth()}><MDBIcon fas icon="arrow-right" /></button>
        {showCalendar && <div className="float d-flex flex-column mt-2" style={{zIndex: 1, position: 'absolute'}}>
                  {/* <DateRangePicker /> */}
                  <DateRange maxDate={new Date()} editableDateInputs onChange={item => handleDateRangePicker(item.selection)}  locale={locales.ru}  ranges={rangePicker}/>
                  <button className="btn btn-primary" onClick={()=>{
                     
                      setShowCalendar(false);
                      
                  }}>Применить</button>
                  </div>}
        </div>
        <p className="text-muted">Расходы за этот период: <span className="text-white"><b>{sum} руб.</b></span></p>
            </div>
        
        
        <div className="mt-2">
            {isLoading && <>
                <div className="spinner-border text-primary" role="status"/>
            </>}
            {!isLoading && expenses?.items?.length == 0 && <>
                <p>Расходов нет</p>
            </>}
            <ExpenseEditModalProvider>
                {expenses?.map((it, index)=>{
                    return <>
                        <ExpenseItem item={it} key={index}/>
                    </>
                })}
            </ExpenseEditModalProvider>
            
        </div>
        
    </>
}
function ExpenseItem({item}){
    
    const {showEditModal} = useContext(ExpenseEditModalContext)
    return <>
        <MDBCard onClick={()=>{showEditModal(item)}} style={{backgroundColor: '#363636', cursor:'pointer'}} className="mt-2">
            <MDBCardBody>
                <div className="">
                    
                            
                    <div  className="d-flex justify-content-between align-items-start">
                        <p className="fw-bold fs-5">{item.title}</p>
                        <div>
                            <p className="text-muted ms-2">{item.date}</p>
                            
                        </div>
                        

                    </div>
                    <div className="d-flex justify-content-between">
                        <div className='mt-2'>
                            <p className="text-muted">Описание</p>
                            
                                <p className='text-wrap text-break'>{item?.description}</p>
                            
                        </div>
                        <p className="text-nowrap ms-2 d-block">{item.amount} руб.</p>
                    </div>  
                   
                        
                    
               </div>
                    
               

            </MDBCardBody>
        </MDBCard>
    </>
}
function AddExpenseModal({onAdd}){
    const [modal, setModal] = useState(false)
    async function AddExpense(e){
        e.preventDefault()
        const fd = new FormData(e.target)
        const tmp = fd.get('amount')
        fd.delete('amount')
        fd.append('amount', String(Number.parseFloat(tmp)).replace('.',','))
        const token = await authService.getAccessToken();
        const res = await fetch('/api/mystore/analytics/expenses',{
            headers:{
                "Authorization": `Bearer ${token}`
            },
            method: 'PUT',
            body: fd
        })
        if(res.ok){
            onAdd(await res.json())
            setModal(false)
            return
        }
        toast.error('Ошибка добавления расхода')
    }
    return<>
        <button className="btn btn-primary" onClick={()=>{setModal(true)}}>Добавить расход</button>
        <MDBModal show={modal} setShow={setModal}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader><MDBModalTitle>Добавить расход</MDBModalTitle></MDBModalHeader>
                    <form onSubmit={AddExpense}>
                        <MDBModalBody>
                            <MDBInput name="title" className="mt-2" label="Название" placeholder="Название"/>
                            <MDBInput name="description" className="mt-2" label="Описание" placeholder="Описание"/>
                            <MDBInput name="amount" className="mt-2" label="Сумма" type="number" step={0.01} placeholder="Сумма"/>
                            <MDBInput defaultValue={new Date().toISOString().split('T')[0]} name="date" className="mt-2" color="white" label="Дата" lang="ru" type="date"/>
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button className="btn btn-danger" type="button" onClick={()=> setModal(false)}>Отмена</button>
                            <button className="btn btn-success" type="submit">Добавить</button>
                        </MDBModalFooter>
                    </form>
                    
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </>
}


