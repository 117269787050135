import React, { createContext, useState } from 'react';
import Modal from './ProductReviewModal';

const ProductReviewModalContext = createContext(null);

export const ProductReviewModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState(null);

  const showReviewModal = (prod) => {
    setProduct(prod);
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setProduct(null)
  };

  return (
    <ProductReviewModalContext.Provider value={{ showReviewModal, hideModal }}>
      {children}
      {<Modal show={modal} product={product} setShow={hideModal}></Modal>}
    </ProductReviewModalContext.Provider>
  );
};

export default ProductReviewModalContext;