import authService from "@auth/AuthorizeService";
import { MDBBadge, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBIcon } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";


export function TopSales({period, CustomPeriod}){

    const [items, setItems] = useState([])

    async function getTopSales(periodIn, CustomPeriod = null){
        
        if(periodIn === 'Custom' && CustomPeriod == null){
          return
        }
        const token = await authService.getAccessToken()
        console.log(periodIn)
        const res = await fetch('/api/mystore/analytics/getTopSales',{
            headers:{
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            },
            method:'POST',
            body: JSON.stringify({
                PeriodType: periodIn,
                CustomDatePeriod: CustomPeriod
              })
        })
        if(res.ok){
            setItems(await res.json())
        }
        
    }
    useEffect(()=>{
        
        getTopSales(period, CustomPeriod)

    },[period, CustomPeriod])
    return(<>
        <h3 className="mt-3">Топ продаж</h3>
        {items.map((it, index)=>{
            return(
                <>
                <MDBCard className="mb-3">
                    <MDBCardHeader className="d-flex justify-content-between align-items-center">
                        <MDBCardTitle className="mb-0">
                            {it.brandRangeName}
                        </MDBCardTitle>
                        <div className="d-flex flex-wrap justify-content-end align-items-start">
                            <MDBBadge color="success" className="mb-1">
                            + {it.averageMargin} руб/шт.
                            </MDBBadge>
                            <MDBBadge color="success" className="ms-2">
                                
                                {it.totalQty} шт.
                            </MDBBadge>
                        </div>
                    </MDBCardHeader>
            <MDBCardBody>
                {it.flavours.slice(0, it?.showMore ? 9999 : 5).map(flavour =>{
                    return(
                        <>
                            <div className="d-flex justify-content-between">
                                <p>{flavour.name}</p>
                                <p>{flavour.quantity} шт.</p>
                            </div>
                        </>
                    )
                })}
                {it.flavours.length > 5 && 
                <button className="btn btn-success" onClick={()=>{
                    setItems(prevItems => {
                        const updatedItems = [...prevItems];
                        updatedItems[index] = {
                          ...updatedItems[index],
                          showMore: !updatedItems[index]?.showMore ? true : false, // Set showMore to true for the specific object
                        };
                        return updatedItems;
                      });
                }}><MDBIcon fas icon={`chevron-circle-${it.showMore == true ? "up" : "down"}`} /></button>}
            </MDBCardBody>
        </MDBCard>
                
                </>
            )
        })}
        
    </>)
}
function TopSalesBrandRange(){

}

function TopSalesFlavour(){

}

