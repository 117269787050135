import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBListGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRange, MDBRow } from "mdb-react-ui-kit";
import React, { useContext, useRef, useState } from "react";
import authService from "../../../api-authorization/AuthorizeService";
import { FlavourItem } from "./FlavourItem";



import AvatarEditor from "react-avatar-editor";
import DeleteModalContext from "./DeleteModal/DeleteModalContext";
import { toast } from "react-toastify";
import { StyledDropzone } from "components/MyStore/Dropzone";
import { useDispatch } from "react-redux";
import { AddBrandAdmin, AddProductsAdmin } from "./TableStateSlice";



export function RangeItem({range, setTable}){

    const {showDeleteModal} = useContext(DeleteModalContext);
    const [showImage, setShowImage] = useState(false);
    function renderFlavours(){
        if(range.flavours.length == 0){
            return <span>Вкусы ещё не добавлены</span>
        }
        return range.flavours?.map((it, index)=>{
            return <FlavourItem flavour={it} key={index}/>
        })
    }
    return(
        <>
            <div style={{maxWidth: '350px'}} className="p-2">
                <MDBRow className="g-0">
                    
                    <MDBCol size={5} md={6}>
                    <MDBCardImage loading="lazy" style={{borderRadius: '10px'}} width={'100%'} src={`/photos/ranges/${range.id}.jpg`}/>
                    </MDBCol>
                    <MDBCol size={7} md={6}>
                        <MDBCardBody className="ms-2 p-0">
                            
                                <div className="">
                                    
                                        <h5>Линейка: {range.name}</h5>
                                    
                                    <MDBRow between className="gy-2">
                                        <MDBCol size={6}><button className="btn btn-primary" onClick={() => setShowImage(true)}><MDBIcon far icon="image" size="1x" /></button></MDBCol>
                                        <MDBCol size={6}><button className="btn btn-primary" onClick={()=> {showDeleteModal(range, 'range')}}><MDBIcon  color="white" size="1x" fas icon="pen" /></button>
                                        </MDBCol>
                                        <MDBCol size={12}><AddFlavour rangeName={range.name} setTable={setTable} rangeId={range.id}/></MDBCol>
                                    </MDBRow>
                                    
                                </div>
                                
                            

                            
                        </MDBCardBody>
                    </MDBCol>
                    <MDBCardBody className="p-0 mt-2">
                    <MDBCardText>
                                <h5>{}</h5>
                                <MDBListGroup>
                                {renderFlavours()}
                                </MDBListGroup>
                                
                                
                                
                            </MDBCardText>
                        </MDBCardBody>
                </MDBRow>
                
            </div>
            <SetPicture show={showImage} setShow={setShowImage} rangeId={range.id}/>
        </>
    );
}


function AddFlavour({rangeId, rangeName}){
    const toggleShow = () =>{
        setFlavours([''])
        setStaticModal(!staticModal);
    }
    const dispatch = useDispatch()
    const [staticModal, setStaticModal] = useState(false);
    async function send(){
        // collect state to json and send to server
        const data = {
            rangeId: rangeId,
            flavours: flavours.filter((it)=>it.trim() != '')
        }

        const token = await authService.getAccessToken();
        const res = await fetch('/api/admin/pricelist/AddFlavour', {
            method: "PUT",
            headers:{
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        if(res.ok){
            toast.success('Вкус добавлен')
            
            
            const flavours = await res.json();
            
            // set table table from response but ranges contains in brands and categories. You need to deep search. Flavour not contains category and brand ids
            dispatch(AddProductsAdmin({products: flavours, rangeId: rangeId}))
            toggleShow();

        } else{
            toast.error('Ошибка при добавлении вкуса')
        }
        

    }
    const [flavours, setFlavours] = useState(['']);
    function onPaste(event){
        // multiline text to array
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text');
        const pastedLines = pastedText.split('\n').map(line => line.trim()).filter(line => line !== '');
        
        console.log(pastedLines);
        // and add to flavours
        setFlavours((prev)=>{
            return [...prev, ...pastedLines];
        });
    }
    function onChange(text, index){
        setFlavours((prev)=>{
            const newArr = [...prev];
            newArr[index] = text;
            return newArr;
        })
    }
    function deleteFlavour(index){
        if(flavours.length == 1){
            return;
        }
        // by filter delete flavour
        setFlavours((prev)=>{
            return prev.filter((it, i)=>{
                return i != index;
            })
        });
    }
    function AddFlavour(){
        setFlavours((prev)=>{
            return [...prev, ''];
        })
    }

    return (
    <>
        <button className="btn btn-primary w-100" style={{fontSize: '13px'}} onClick={()=>{setStaticModal(true)}}>Добавить вкус</button>
        
        <MDBModal staticBackdrop tabIndex='-1' show={staticModal}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Добавить вкус {rangeName}</MDBModalTitle>
            </MDBModalHeader>
            
            <MDBModalBody>
                {flavours.map((it, index)=>{
                    return(
                        <>
                        <div className="d-flex mb-2">
                            <MDBInput name="Name" onPaste={onPaste} value={it} label='Название' onInput={(e) => {onChange(e.currentTarget.value, index)}}/>
                            <button onClick={()=>{deleteFlavour(index)}} className="ms-2 btn btn-danger">-</button>
                        </div>
                            
                        </>
                    )
                })}
                <button onClick={()=>{AddFlavour()}} className="ms-2 btn btn-success">+</button>
                
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn btn-danger" onClick={toggleShow}>
                Отмена
              </button>
              <button className="btn btn-success" onClick={()=> send()}>
                Добавить
              </button>
            </MDBModalFooter>
            
          </MDBModalContent>
        </MDBModalDialog>
      
        </MDBModal>
    </>
    );
    return(
        <>
            

        </>
    );
}
function SetPicture({rangeId, show, setShow}){

    const dipatch = useDispatch()
    async function uploadPicture(){
        if(!editor) return;
        const canvas = editor.current.getImage();
            const dataUrl = canvas.toDataURL('image/jpeg', 0.8)
            console.log(dataUrl)
            const dataURItoBlob = (dataURI)=> {
              var binary = atob(dataURI.split(',')[1]);
              var array = [];
              for(var i = 0; i < binary.length; i++) {
                  array.push(binary.charCodeAt(i));
              }
              return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
            }
            var blob = dataURItoBlob(dataUrl);
            var fd = new FormData();
            const file = new File( [blob], 'canvasImage.jpg', { type: 'image/jpeg' } );
            fd.append("file", file);
            fd.append("rangeId", rangeId)

            const token = await authService.getAccessToken();
            const res = await fetch('/api/admin/pricelist/uploadImage', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: fd
            })
            if(res.ok){
                const data = await res.json()
                const imageId = data.imageId

                toast.success('Изображение загружено')
                setShow(false)
            } else{
                toast.error('Ошибка при загрузке изображения')
            }
    }
    const editor = useRef(null);
    
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(1);
    function onDrop(files){
        setImage(files[0])
    }
    return(
        <>
            <MDBModal show={show} setShow={setShow}>
                <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        Изображение
                    </MDBModalHeader>
                    <MDBModalBody className="d-flex flex-column align-items-stretch">
                        <StyledDropzone onDrop1={onDrop} />
                        <AvatarEditor className='mt-1 self-align-center' ref={editor} border={0} width={250} height={250}  image={image} scale={scale}/>
                        <MDBRange min={0.5} max={7} step={0.1} value={scale} onChange={(e) => setScale(e.target.value)}/>
                        
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBRow>
                            <MDBCol size={6}><button className="btn btn-danger" onClick={() => setShow(false)}>Отмена</button></MDBCol>
                            <MDBCol onClick={() => uploadPicture()} size={6}><button className="btn btn-success">Добавить</button></MDBCol>
                        </MDBRow>
                        
                        
                    </MDBModalFooter>
                </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}