export function getWhBadgeColor(type){
    switch(type){
      case 0:
        return 'success';
      case 1:
        return 'info';
      case 2:
        return 'warning';
      default:
        return 'info';
    }
  }
export function getWhBadgeText(type){
    switch(type){
      case 0:
        return 'Точка самовывоза';
      case 1:
        return 'Вторичный';
      case 2:
        return 'Курьер';
      default:
        return '-_-';
    }
  }