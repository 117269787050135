import authService from "@auth/AuthorizeService";
import { CourierItem } from "components/MyStore/CourierPage/CourierItem";
import { EditModalProvider } from "components/MyStore/ReferralPage/EditModal/EditModalContext";
import { MDBBadge, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTooltip } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";


export function OrderMarginDiv({order, setOrder, fetchOrder}){

    async function AddCourier(cId, type){
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/mystore/orders/${order.id}/Add${type}?${type}Id=${cId}`,{
            headers:{
                "Authorization": `Bearer ${token}`
            },
            method: 'PUT'
        })
        if(res.ok){
            //setCouriers(await res.json())
            const courier = await res.json()
            setOrder(prev => {return {...prev, about: {...prev.about, courier}}})
            
            fetchOrder()
            return true;
        }
    }
    

    // На кнопку добавить курьера автоматически открывается модальное окно с поиском + выбор
    // 
    
    console.log(order)
    return(
        <>
            <p style={{fontWeight: 'bold', fontSize: '20px'}} className="m-0">О заказе</p>
            <p>Склад: <span className="text-warning">{order?.about?.warehouse?.name}</span>
            
            <MDBTooltip   tag='a' wrapperProps={{ href: '#' }} title="Склад, с которго списываются товары. При добавлении курьера автоматически указывается его склад">
                    <MDBIcon className='ms-2' fas icon="info-circle" />
                </MDBTooltip>
            </p>
            
            <p>Тип: {order?.about?.type}</p>
            {order?.about?.address && <p>Адрес: {order?.about.address}</p>}
            {order?.timeToDelivery && <p>Время доставки: {order?.timeToDelivery}</p>}
            <p>Комментарий: {order.about?.comment ? order.about?.comment : 'Отсутсвует'}</p>
            <p>Грязный доход: {order?.about.grossProfit} руб.<MDBTooltip   tag='a' wrapperProps={{ href: '#' }} title="Разница между стоимостью услуг и товаров, переданных клиентом и себестоимостью этих товаров и услуг">
                    <MDBIcon className='ms-2' fas icon="info-circle" />
                </MDBTooltip></p>
                {order.orderType == 3 &&<div className="d-flex align-items-start">
                 <p>Курьер:</p>{order.about.courier == null ? <AddCourierModal type={'courier'} callback={AddCourier}/> :
                        <>
                            <NavLink to={`/mystore/couriers/${order.about.courier?.id}`} className="ms-2">@{order.about.courier?.username}</NavLink>
                            <MDBBadge className="ms-2" color="success">{order.about.courier?.reward} руб.</MDBBadge>
                            <RemoveCourierModal type={'courier'} orderId={order?.id} refetchOrder={()=> fetchOrder()}/>
                        </>
                    }
                    </div>}
                
            <div className="d-flex align-items-start my-2">
            <p>Менеджер:</p>{order.about.manager == null ? <AddCourierModal type={'manager'} callback={AddCourier}/> :
                <>
                    <NavLink to={`/mystore/couriers/${order.about.manager?.id}`} className="ms-2">@{order.about.manager?.username}</NavLink>
                    <MDBBadge className="ms-2" color="success">{order.about.manager?.reward} руб.</MDBBadge>
                    <RemoveCourierModal type={'manager'} orderId={order?.id} refetchOrder={()=> fetchOrder()}/>
                </>
            }</div>
            <p>Комиссия: {order?.about?.fee} руб.</p>
            <p>Чистый доход: {order?.about?.cleanIncome} руб.</p>
            
        </>
    )
}
function AddCourierModal({callback, type}){
    const [loading, setIsLoading] = useState(false);
    const [showAddCourierModal, setShowAddCourierModal] = useState(false);
    async function fetchAvialableCouriers(){
        setIsLoading(true)
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/mystore/workers/${type}s`,{
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        if(res.ok){
            setCouriers(await res.json())
        }
        setIsLoading(false)
    }
    useEffect(()=>{
        if(showAddCourierModal){
            fetchAvialableCouriers()
        }
        
    },[showAddCourierModal])
    const [couriers, setCouriers] = useState([])
    return(<>
    <button onClick={()=> setShowAddCourierModal(true)} className="btn btn-success p-1 ms-2">Добавить</button>
        <MDBModal show={showAddCourierModal} setShow={setShowAddCourierModal}>
            <MDBModalDialog>
                <MDBModalContent>
                    
                    <MDBModalHeader>
                    <MDBModalTitle>
                            Добавить {type == 'courier' ? 'курьера' : 'менеджера'} к заказу
                    </MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody>
                        {loading && <div className="spinner-border text-primary" role="status"/>}
                        {/* <EditModalProvider> */}
                        {couriers.map(it =>{
                                return<>
                                    <MDBCard style={{ maxWidth: '540px', borderRadius: '10px' }}  className='mt-1'> 
                                        <MDBRow className='g-0'>
                                            <MDBCol md='3' className="p-1">
                                            <MDBCardImage src='/img/avaDefault.png' alt='Карточка товара' fluid />
                                            </MDBCol>
                                            <MDBCol md='9'>
                                            <MDBCardBody>
                                                <div className="d-flex justify-content-between">
                                                <MDBCardTitle>{it.username}</MDBCardTitle>
                                                    <p style={{color: '#8babe0', cursor: 'pointer'}} onClick={()=>{}}>Активные заказы: {it.activeOrders}<MDBIcon className="ms-2" fas icon="truck" /></p>

                                                </div>
                                                
                                                
                                                <div className="d-flex justify-content-between">
                                                <p>{it.createdAt}</p>
                                                
                                                <button className="btn btn-success" onClick={async ()=>{const res = await callback(it.id, type); if(res === true) setShowAddCourierModal(false)}}>Выбрать</button>
                                                
                                                </div>
                                            </MDBCardBody>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCard>
                                </>
                            })}
                        {/* </EditModalProvider> */}
                            
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </>)
}
function RemoveCourierModal({orderId, refetchOrder, type}){
    const [modal, setModal] = useState(false)

    async function removeCourier(){
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/mystore/orders/${orderId}/Remove${type}`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            },
            method: "PATCH"
        })
        if(res.ok){

            setModal(false)
            refetchOrder()
        } else{
            toast.error('Ошибка!')
        }
    }
    return(
        <>
        <MDBBadge className="ms-2" style={{cursor: 'pointer'}} color="danger" onClick={()=>{setModal(true)}}><MDBIcon fas icon="minus" /></MDBBadge>
            <MDBModal show={modal} setShow={setModal}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader><MDBModalTitle>Ты точно хочешь удалить {type == 'courier' ? 'курьера' : 'менеджера'} из заказа?</MDBModalTitle></MDBModalHeader>
                        <MDBModalFooter>
                        <button className="btn btn-info" onClick={()=> setModal(false)}>Отмена</button>
                            <button className="btn btn-danger" onClick={removeCourier}>Да</button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}