import MyStoreInfoSignalR from "components/Notifications/NotificationMyStore";
import { toast } from "react-toastify";
import { addOrder } from "../Orders/OrdersState/OrdersStateSlice";
import React from "react";
import { NavLink } from "react-router-dom";

const { createSlice } = require("@reduxjs/toolkit");




const UserInfoStateSlice = createSlice({
    name: "userInfo",
    initialState:{
        unreadChats: 0,
    },
    reducers:{
       
       fillUserInfo: (state, action)=>{
        console.log('filling user info')
        state = {
            ...state,
            ...action.payload.data
        }
        console.log(state)
        return state
       },
       

    },
    extraReducers:{

    }
})
export const {fillUserInfo} = UserInfoStateSlice.actions;
export default UserInfoStateSlice.reducer;