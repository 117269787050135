import React, { Component } from "react";


export class Footer extends Component{
    render(){
        return (
            <footer className="text-center text-lg-start text-muted" style={{flex: '0 1 100px'}}>
            
            <section className="pt-3">
              <div className="container text-center text-md-start mt-5 mb-4">
                <div className="row mt-3">
                  <div className="col-12 col-lg-3 col-sm-12">
                   
                      <img
                        src="/logo.png"
                        height={35}
                      />
                    <p className="mt-3">© 2023 Все права защищены: Vape-Market</p>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-2">
                    <h6 className="text-uppercase text-dark fw-bold mb-2">Store</h6>
                    <ul className="list-unstyled mb-4">
                      <li>
                        <a className="text-muted" href="#">
                          О нас
                        </a>
                      </li>
                      <li>
                        <a className="text-muted" href="#">
                          Связаться
                        </a>
                      </li>
                      <li>
                        <a className="text-muted" href="#">
                          Стать продавцом
                        </a>
                      </li>
                      <li>
                        <a className="text-muted" href="#">
                          Новости
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-2">
                    <h6 className="text-uppercase text-dark fw-bold mb-2">Information</h6>
                    <ul className="list-unstyled mb-4">
                      <li>
                        <a className="text-muted" href="#">
                          Помощь
                        </a>
                      </li>
                      <li>
                        <a className="text-muted" href="#">
                          Как мы работаем?
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                  {/* <div className="col-6 col-sm-4 col-lg-2">
                    <h6 className="text-uppercase text-dark fw-bold mb-2">Support</h6>
                    <ul className="list-unstyled mb-4">
                      <li>
                        <a className="text-muted" href="#">
                          Help center
                        </a>
                      </li>
                      <li>
                        <a className="text-muted" href="#">
                          Documents
                        </a>
                      </li>
                      <li>
                        <a className="text-muted" href="#">
                          Account restore
                        </a>
                      </li>
                      <li>
                        <a className="text-muted" href="#">
                          My orders
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  
                </div>
              </div>
            </section>
            
            
          </footer>

            
        );
    }
}