// Modal.js
import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from "@auth/AuthorizeService";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { changeAmount, changeAmountMinus } from '../state/WarehouseTalbeSlice';
import { useParams } from 'react-router-dom';

const TakeoutModal = ({ item, show, setShow }) => {

   const [amount, setAmount] = useState('');
    const dispatch = useDispatch();
    const {id} = useParams();
    async function takeout(e){
      e.preventDefault();
        
        const fd = new FormData(e.target)
        fd.append("flavourId", item.flavour.id)
        fd.append("warehouseId", item.whid)
        const token = await authService.getAccessToken();
        const res = await fetch('/api/warehouse/QuickSell', {
            method: 'POST',
            headers:{
                'Authorization': `Bearer ${token}`
            },
            body: fd
        });
        if(res.ok){
            const resAmount = Number(await res.text());
            await setShow()
            toast.success('Успешно');
            dispatch(changeAmountMinus({flavourId: item.flavour.id, amount: resAmount}));
            
            // update table
        } else{
            toast.error(await res.text())
        }
    }
    const [logs, setLogs] = useState([])
    async function fetchLogs(){
      const token = await authService.getAccessToken();
      console.log(item)
      const res = await fetch(`/api/warehouse/Log?flavourId=${item.flavour.id}&whid=${item.whid}`,{
        headers: {
          "Authorization" : `Bearer ${token}`
        },
        method: 'GET'
      })

      if(res.ok){
        const data = await res.json()
        setLogs(data)
      }
    }
    useState(()=>{
      fetchLogs()
    },[item])
    const [justifyActive, setJustifyActive] = useState('tab1');

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };
  return (
    <MDBModal show={show} setShow={setShow}>
        <MDBModalDialog scrollable> 
          <MDBModalContent  >
            <MDBModalHeader className='d-block pb-0'>
              <div className='d-flex justify-content-between'>
              <MDBModalTitle>Выгрузка {item.flavour?.name}</MDBModalTitle>
              
              </div>
              <div>
              <MDBTabs pills  fill className='mb-3'>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                    Быстрая продажа
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                    Логи
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
              </div>
              
            </MDBModalHeader>
            <MDBModalBody>

            

            <MDBTabsContent >
              <MDBTabsPane show={justifyActive === 'tab2'}>
                {logs && logs.map((it)=>{
                        return(
                          <>
                            <MDBCard className='mb-2'>
                              <MDBCardBody className='d-flex flex-column justify-content-between'>
                                <div className='d-flex justify-content-between'>
                                  <p className='self-align-center'>Тип: <MDBBadge className='align-center' color={it.type == 'Загрузка' ? "success" : 'danger'}>{it.type}</MDBBadge></p>
                                  <p>{it.date}</p>
                                </div>
                                <div className='d-flex justify-content-between'>
                                  <p>{it.type == 'Выгрузка' ? '- ' : ''}{it.quantity} шт.</p>
                                  {it.purchasePrice && <p>{it.purchasePrice} руб.</p>}
                                  {it.comment && <p>{it.comment}</p>}
                                </div>
                                
                              </MDBCardBody>

                            </MDBCard>
                          </>
                        )
                      })}

              </MDBTabsPane>
              <MDBTabsPane show={justifyActive === 'tab1'}>
                <form onSubmit={takeout}>
                  <MDBInput label="Количество" min={1} placeholder='1' type="number" name='quantity'/>
                  <MDBInput className='mt-3' min={0} placeholder='12.3' label="Цена за шт." type="number" name='price'/>
                  <MDBInput className='mt-3' label="Комментарий" type='text' name='comment' maxLength={120}/>
                  <button className='mt-3 align-end btn btn-success' type='submit'>Отправить</button>
                </form>
              
              </MDBTabsPane>
              
            </MDBTabsContent>
              
                
            </MDBModalBody>

            <MDBModalFooter className='d-flex justify-content-between'>
                <button className='btn btn-danger' onClick={()=> setShow()}>Закрыть</button>
                
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
  );
};

export default TakeoutModal;