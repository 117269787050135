import authService from "@auth/AuthorizeService";
import React, { useEffect, useState } from "react";
import { AdminStoreItem } from "./AdminStoreItem";
import { MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";





export function AdminStoresView(){
    const [stores, setStores] = useState([])


    async function getStores(){
        const token = await authService.getAccessToken()
        const res = await fetch('/api/admin/stores',{
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        if(res.ok){
            const data = await res.json()
            setStores(data)
        }
    }

    useEffect(()=>{
        getStores()
    },[])
    const navigate = useNavigate()
    async function changeStoreChecked(toState, id){

        console.log(toState, id)
        const newState = [...stores];

        newState.forEach(it => {
            if (it.id === id) {
                it.isEnabled = toState;
            }
        });

        
        setStores(newState)
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/admin/changeStoreVisible?storeId=${id}&toState=${toState}`,{
            headers:{
                "Authorization": `Bearer ${token}`
            },
            method: 'PATCH'
        })
        if(res.ok){
            toast.success('ОК!')
        }
    }
    async function loginToStoreAccount(storeId){
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/admin/LoginToStore?storeId=${storeId}`, {
            method: 'POST',
            headers: {
                "Authorization" : `Bearer ${token}`
            }
        })
        if(res.ok){
            authService.setUser(await res.json())
            navigate('/mystore/')
        } else {
            toast.error('Ошибка входа')
        }
    }
    return(
        <>
        <CreateNewStore onNewStoreAdded={(data)=>{
            setStores(prev => {return[...prev, data]})
        }}/>
        <div className="mt-3">
            {stores?.map((it, index)=>{
                return(
                    <>
                        <AdminStoreItem onLogin={loginToStoreAccount} store={it} key={index} setStoreState={(toState) => changeStoreChecked(toState, it.id)}/>
                    </>
                )
            })}
        </div>
            
        </>
    )
}

function CreateNewStore({onNewStoreAdded}){

    const [show, setShow] = useState(false);

    async function createStore(e){
        e.preventDefault();

        const fd = new FormData(e.target)
        
        const token = await authService.getAccessToken()
        const res = await fetch('/api/admin/store',{
            headers:{
                "Authorization": `Bearer ${token}`
            },
            method: 'PUT',
            body: fd
        })
        if(res.ok){
            const data = await res.json()
            onNewStoreAdded(data)
        }
    }
    useEffect(()=>{
        
    },[])

    return(
        <>
            <button className="btn btn-success" onClick={()=> setShow(true)}>Создать магазин</button>
            <MDBModal show={show} setShow={setShow}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Создать магазин</MDBModalTitle>
                        </MDBModalHeader>
                        <form onSubmit={createStore}>
                        <MDBModalBody>
                            
                                <MDBInput name="storeName" placeholder="Название" label="Название"/>
                                <MDBInput className="mt-2" name="username" placeholder="Владелец username" label="Владелец username"/>
                            
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button type="button" onClick={()=> setShow(false)} className="btn btn-danger">Закрыть</button>
                            <button type="submit" className="btn btn-success">Создать</button>
                        </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
                
            </MDBModal>
        </>
    )
}