import { MDBIcon, MDBInput, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";
import { DisabledTransportError } from "@microsoft/signalr/dist/esm/Errors";
import { NavLink } from "react-router-dom";


export function ProfileEdit(){
    const [user, setUser] = useState(null);
    async function fetchUser(){
        const token = await authService.getAccessToken();
        const res = await fetch('/api/user',{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        });
        if(res.ok){
            setUser(await res.json());
        }
        return null;
    }

    async function saveUser(e){
        e.preventDefault();
        const fd = new FormData(e.target);
        const token = await authService.getAccessToken();
        const res = await fetch('/api/user',{
            method: 'PUT',
            headers:{
                "Authorization": `Bearer ${token}`,
            },
            body: fd
        });
        if(res.ok){
            toast.success('Успешно');
        }
        else{
            toast.error('Ошибка');
        }
    }
    async function savePassword(e){
        e.preventDefault();
        const fd = new FormData(e.target);
        if(fd.get('password') !== fd.get('rPassword')){
            toast.error('Пароли различются')
            return;
        }
        if(user.pwdIsSet == true){
            if(!!fd.get('currentPassword') == null){
                
            }
        }
        const token = await authService.getAccessToken();
        const res = await fetch('/api/user/pwd',{
            method: 'PUT',
            headers:{
                "Authorization": `Bearer ${token}`,
            },
            body: fd
        });
        if(res.ok){
            toast.success('Успешно');
        }
        else{
            toast.error('Ошибка');
        }
    }
    useEffect(()=>{
        fetchUser()
    }, [])
    const [validator, setValidator] = useState({
        notEmpty: false,
        min8: false,
        digit: false,
        bigLet: false,
        special: false,
        max32: false,
        small: false,
        pwd: '',
        Rpwd: ''
    });
    if(!user){
        return <p>Загрузка...</p>
    }
    
        return (
            <>
                <NavLink to={'/profile/'} className="collapse backToProfileBtn btn btn-info me-2" ><MDBIcon color="white" fas icon="arrow-left" /></NavLink>
                <h4 className="d-inline-block m-0">Настройки</h4>
                <h3 className="mt-2">Имя, Никнейм</h3>
                <form onSubmit={saveUser}>
                    <MDBInput name="firstname" label="Имя" type="text" defaultValue={user.firstName}/> 
                    <MDBInput name="username" className="mt-2" label="Username" type="text" defaultValue={user.userName}/>
                    <button type="submit" className="btn btn-warning mt-2">Сохранить</button>
                </form>
                <hr/>
                <h3>Телеграм</h3>
                {user.telegramId ? <>

                <p>Id: {user?.telegramId}</p> 
                <button className="btn btn-primary" disabled>Отвязать</button>
                </>: <>
                    <p>Не привязан</p>
                    <button className="btn btn-info" disabled>Привязать</button>
                </>}
                <hr/>
                <h3>Пароль</h3>
                <MDBTypography note noteColor='info' className="mb-3">
                    <strong>Заметка:</strong> Вы можете подключить вход по паролю и логину (Username)
                    <div
      className="alert px-4 py-3 mb-0 "
      role="alert"
      data-mdb-color="warning"
      id="password-alert"
      >
      <ul className="list-unstyled mb-0">
        <li className="requirements leng">
            {validator.min8 ? <i className="fas fa-check text-success me-2"></i> : <i className="fas fa-times text-danger me-3"></i>}
          
          
          Минимум 8 символов</li>
        <li className="requirements big-letter">
        {validator.bigLet ? <i className="fas fa-check text-success me-2"></i> : <i className="fas fa-times text-danger me-3"></i>}
          
          Минимум 1 заглавная буква</li>
        <li className="requirements num">
        {validator.digit ? <i className="fas fa-check text-success me-2"></i> : <i className="fas fa-times text-danger me-3"></i>}
          
          Минимум 1 цифра</li>
        <li className="requirements special-char">
        {validator.special ? <i className="fas fa-check text-success me-2"></i> : <i className="fas fa-times text-danger me-3"></i>}
          
          Минимум 1 спец. символ</li>
          {validator.min8 ? <li className="requirements pwd-repeat">
          {validator.Rpwd == validator.pwd ? <i className="fas fa-check text-success me-2"></i> : <i className="fas fa-times text-danger me-3"></i>}
          
          Совпадают</li> : <></>}
          
      </ul>
    </div>
                </MDBTypography>
                {/* <p>Пароль уже установлен 😎</p><p>Пароль не установлен</p> */}
                <form onSubmit={savePassword}>
                {user.pwdIsSet ? <MDBInput className="mb-2" name="currentPassword" label="Текущий пароль" type="password"/> : <></>}
                <MDBInput name="password" label="Пароль" type="password" onInput={(e) =>{
                        const text = e.currentTarget.value;
                        let specChars = false; // 33-47 58-64
                        let digits = false; // 48-57
                        let bigLets = false; //65-90
                        let smallLets = false; // 97-122
                        for (let i = 0; i < text.length; i++) {
                            const char = text[i];
                            const code = text.charCodeAt(i);
                            if(code >= 33 && code <= 47 || code >= 58 && code <= 64)
                            {
                                specChars = true;
                                continue;
                            }
                            if(code >= 48 && code <= 57){
                                digits = true;
                                continue;
                            }
                            if(code >= 65 && code <= 90){
                                bigLets = true;
                                continue;
                            }
                            if(code >= 97 && code <= 122){
                                smallLets = true;
                            }
                        }
                        const min8 = text.length >=8;
                        const max32 = text.length <=32;
                        setValidator({
                            notEmpty : text.length>0,
                            min8: min8,
                            digit: digits,
                            bigLet: bigLets,
                            special: specChars,
                            max32: max32,
                            pwd: text,
                            small: smallLets,
                            Rpwd: validator.Rpwd
                        })
                    }}/>
                <MDBInput name="rPassword" className="mt-2" label="Повторите пароль" type="password"
                onInput={(e)=>{
                    const text = e.currentTarget.value;
                    console.log(text)
                    setValidator({...validator, Rpwd: text})
                }}/>
                <button
                    type="submit"
                    disabled={!(validator.bigLet && validator.digit && validator.min8 && validator.special && validator.Rpwd === validator.pwd && validator.small)}
                    className="btn btn-warning mt-2"
                    >
                Сохранить
                </button>
                </form>
                
                
                
                
                
            </>
        );
}