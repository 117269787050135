import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBIcon, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import authService from "../../api-authorization/AuthorizeService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addToCartStorePage, removeFromCartStorePage } from "./ShopPageStateSlice";
import { it } from "react-date-range/dist/locale";
import useScreenSize from "hooks/ScreenSize/useScreenSize";
import { NavLink } from "react-router-dom";
import { decrementCartCount, incrementCartCount } from "../Cart/MyCartState/CartStateSlice";
import { AvailabilityStatusColor, AvailabilityStatusText } from "css/Utils";


export function BrandSp({brands, storeId, itemsName}){
    const screenSize = useScreenSize()
    if(!brands){
        return(<>null</>)
    }
    if(screenSize.width <= 768){
        return <>
            {brands?.map((it, index)=>{
                return (
                    <>
                        <div className="w-100" size={12}>
                                <RangesSpMobile itemsName={itemsName} ranges={it?.ranges} storeId={storeId} brandName={it.name}/>
                        </div>
                    </>
                );
                
            })}
        </>
    }
    return(
        <>
            
                
            <div className="d-table gx-3 w-100" style={{}}>
            {brands?.map((it, index)=>{
                return (
                    <>
                        <div className="w-100" size={12}>
                                <RangesSp itemsName={itemsName} ranges={it?.ranges} storeId={storeId} brandName={it.name}/>
                        </div>
                    </>
                );
                
            })}
            </div>
        </>
    );
}
function RangesSpMobile({ranges, storeId, brandName, itemsName}){
    const dispatch = useDispatch()
    return<>
        {ranges?.map((it, index)=>{
            return <>
                <div>
                <h5 className="text-white">{brandName+" "+it.name}</h5>
                <div  className="d-flex">
                    <img style={{width: '50%', maxWidth: '250px', borderRadius:'12px'}} className="me-4" src={`/photos/ranges/${it.id}.jpg/0`}/>
                    
                    <Pricing prices={it?.prices}/>
                </div>
                
                </div>
                <div className="mb-4 mt-3">
                    <FlavoursSp
                    itemsName={itemsName}
                                    flavours={it.flavours}
                                    onAddToCart={(flavourId) => {dispatch(addToCartStorePage({flavourId, storeId}))}}
                                    onRemoveFromCart={(flavourId) => { dispatch(removeFromCartStorePage({flavourId, storeId}))}}
                                />
                </div>
                
            </>
        })}
    </>
}
function RangesSp({ranges, storeId, brandName, itemsName}){

    const dispatch = useDispatch()
    return(
        <>
            {ranges?.map((it, index)=>{
                return (
                    <>
                        <div className="d-table-row">

                            <div className="d-table-cell">
                                <Title brandName={brandName} item={it}/>
                            </div>
                            
                            <div className="d-table-cell px-3 px-lg-5" style={{width: 'fit-content'}}>
                                    <Pricing prices={it?.prices}/>
                            </div>

                            <div className="d-table-cell w-100">
                                <FlavoursSp
                                itemsName = {itemsName}
                                    flavours={it.flavours}
                                    onAddToCart={(flavourId) => dispatch(addToCartStorePage({flavourId, storeId}))}
                                    onRemoveFromCart={(flavourId) => dispatch(removeFromCartStorePage({flavourId, storeId}))}
                                />
                            </div>

                        </div>
                        
                        <hr/>
                    
                    </>
                )
            })}
        </>
    );
}



function Title({brandName, item}){
    return<div>
        <h5 className="text-white">{brandName+" "+item.name}</h5>
        <img style={{width: '250px', borderRadius:'12px'}} src={`/photos/ranges/${item.id}.jpg/0`}/>
    </div>
}

function Pricing({prices}){
    return <div>
        <h6 className="text-white">Цены</h6>
            <div className="d-table">
                {prices?.map((item, index)=>{
                    return (
                        <>
                        <div className="d-table-row text-nowrap">
                            <p className="pe-md-4 pe-3 d-table-cell" style={{fontWeight: '600'}}>от {item.from}  шт.</p><p style={{fontWeight: '600'}} className="d-table-cell">{item.price} руб.</p>
                        </div>
                        
                    
                
                        </>
                    );
                })}
            </div>   
    </div>
}
function FlavoursSp({flavours, onAddToCart, onRemoveFromCart, itemsName}){

    
    
    return(
        <>
            <h6 className="text-white">{itemsName}</h6>
            {flavours?.map((it, index)=>{
                    return (
                        <>
                        
                            <div className={`d-flex align-items-center ${it.amount == 0 && 'text-muted'}`}>
                                
                                <p className="flex-grow-1 m-0">{index+1}. <NavLink to={`/product/${it.id}/${it.link}`} style={{color: 'inherit'}}>{it.name}</NavLink></p>
                                <div className="d-flex align-items-center text-nowrap">
                                    {it.amount < 6 ? <span className={`me-2 text-${AvailabilityStatusColor(it.amount)}`}>{AvailabilityStatusText(it.amount)}</span> : <span className="me-2 text-success">5 шт +</span>}
                                    {it.inCart ? 
                                    <button className="btn btn-info m-1" onClick={()=> onRemoveFromCart(it.id)}><MDBIcon color="white" fas icon="minus" /></button>
                                    : <button className="btn btn-primary m-1" disabled={it.amount == 0} onClick={() => onAddToCart(it.id)}><MDBIcon color="white" fas icon="cart-plus" /></button>}
                                </div>
                                
                            </div>
                                
                        </>
                    );
                })}
            
        </>
    );
}