import React, { createContext, useState } from 'react';
import Modal from './StoreReviewsModal';

const StoreReviewsModalContext = createContext(null);

export const StoreReviewsModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState(null);

  const showReviewsModal = (prod) => {
    setProduct(prod);
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setProduct(null)
  };

  return (
    <StoreReviewsModalContext.Provider value={{ showReviewsModal, hideModal }}>
      {children}
      {<Modal show={modal} store={product} setShow={hideModal}></Modal>}
    </StoreReviewsModalContext.Provider>
  );
};

export default StoreReviewsModalContext;