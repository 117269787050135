// Modal.js
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBProgress, MDBProgressBar, MDBRow, MDBTextArea } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from '@auth/AuthorizeService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import StarRating from 'components/StarRating/StarRating';
import { AutoGrowTextArea, AutoTextArea } from 'css/Utils';
import { RatingStars } from 'components/store/ProductPage/StoresTab';
import { AvatarBox } from 'components/Global/AvatarBox';

const Modal = ({ store, show, setShow }) => {

    const dispatch = useDispatch()
    const [reviews, setReviews] = useState([])
    let totalCount = 0;
    const [count, setCount] = useState([0,0,0,0,0])
    async function fetchStoreReviews(){
        const token = await authService.getAccessToken()
        const res1 = await fetch(`/api/store/${store?.id}/reviewsCount`)
        if(res1.ok){
            const arr = await res1.json()
            
            setCount(arr)
        }
        const res = await fetch(`/api/store/${store?.id}/reviews`)
        if(res.ok){
            setReviews(await res.json())
        }

    }

    useEffect(()=>{
        console.log(store)
       fetchStoreReviews()
    }, [store])
    
    
    
    
  return (
    <MDBModal show={show} onHide={()=> {setShow()}} >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
                
              <MDBModalTitle>Отзывы {store?.name}</MDBModalTitle>
              <button className='btn-close' color='none' onClick={()=>setShow()}></button>
            </MDBModalHeader>
            
            <MDBModalBody>
                <div className='d-flex justify-content-between mb-3'>
                <img className='rounded-5' src={`/photos/store/${store?.id}.jpg`} width='150px'/>
                    <div className='w-100 ms-2'>
                        <div className='d-flex'>
                            <RatingStars rating={store?.rating}/>
                            <p className='ms-2 mb-0 ' style={{fontWeight: '600'}}>{store?.rating} / 5</p>
                        </div>
                        
                        <div className='d-table'>
                            {function(){
                                const tlcnt =  count.reduce((pr, cur) => pr+=cur)
                                console.log(tlcnt, 'ttoal')
                                
                                return <>
                                    <RatingBar total={tlcnt} count={count[4]} text={'5 звезд'}/>
                                    <RatingBar total={tlcnt} count={count[3]} text={'4 звезды'}/>
                                    <RatingBar total={tlcnt} count={count[2]} text={'3 звезды'}/>
                                    <RatingBar total={tlcnt} count={count[1]} text={'2 звезды'}/>
                                    <RatingBar total={tlcnt} count={count[0]} text={'1 звезда'}/>
                                </>
                            }()}
                            
                        </div>
                    </div>
                    
                   
                </div>
                {reviews?.map((review, i)=>{
                    return <ReviewItem review={review} key={i}/>
                })}
                
                
            
            </MDBModalBody>
            
            
            
            
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
  );
};
function ReviewItem({review}){
    return <>
    
    <MDBCard style={{backgroundColor: '#363636'}} className='mt-3'>
            <MDBCardBody>
                <div className="d-flex justify-content-between align-items-start">
                    <div className="">
                    
                    <div className="d-flex">
                        <AvatarBox letter={review?.username[0]}/>
                        
                        <div>
                            <RatingStars rating={review?.rating}/>
                            <div  className="d-flex">
                                <p className='text-muted'>@{review?.username}</p>
                                <p className="text-muted ms-2">{review?.date}</p>

                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center mt-2'>
                        <MDBIcon color='muted' fas icon="comment-alt" />
                        
                            <p className='ms-2'>{review?.text}</p>
                        
                    </div>
                        
                    
                    </div>
               </div>
                    
               

            </MDBCardBody>
        </MDBCard>
    </>
}
function RatingBar({text, total, count}){

    console.log(total)
    return <div className='d-table-row align-items-center ratingBarWithText'>
        
            <p className='text-muted d-table-cell'>{text}</p>
        
            <div className='d-table-cell w-100' style={{verticalAlign: 'middle'}}>
                    <MDBProgress className='mx-2'>
                        <MDBProgressBar bgColor='warning'  width={count/total*100} />
                    </MDBProgress>
            </div>
            
       
            <p className='d-table-cell' style={{fontWeight: '500'}}>{count}</p>
        
                    
                    
                    
    </div>
}
export default Modal;