import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");


const AdminPricelistPageStateSlice = createSlice({
    name: "AdminPricelistPage",
    initialState:{
        categories: [],
        isLoadingCategory: false,
        currentCategory: null,
        page: 0,
        isLastPage: false,
        addCategoryModal: false,
        search: ''
        
    },
    reducers:{
        resetLastPageAdminPriceList: (state, action)=>{
            state.isLastPage = false
        },
        changePageAdminPriceList: (state, action) => {
            if(state.isLastPage == false){
                state.page += 1;
            }
            
          },
        changeSearchTextPL: (state, action)=>{
        state.search = action.payload.search
        state.isLastPage = false
        state.page = 0
        state.categories.forEach(it => {it.brands = []})
        },
        AddBrandAdmin: (state, action)=>{
            const {brand, categoryId} = action.payload;
            brand.ranges = [];
          
            
            const category = state.categories.find(x => x.id == categoryId);
            
            category.brands = [brand, ...category?.brands]
            
        },
        ChangeImageIdAdmin: (state, action)=>{
            const {imageId, rangeId} = action.payload;
            
          
            state.categories.forEach(category => {
                category.brands.forEach(brand => {
                    brand.ranges.forEach(range => {
                        if(range.id == rangeId){
                            
                        }
                    })
                })
            })
            
            
        },
        AddRangeAdmin: (state, action)=>{
            const {range, brandId} = action.payload;
            range.flavours = []
          
            
            state.categories.forEach(it => {
                const br = it.brands?.find(x => x.id == brandId)
                if(br){
                    console.log(br)
                    if(!br.ranges) br.ranges = []
                    br.ranges.push(range)
                }
                
            })
        },
        AddProductsAdmin: (state, action)=>{
            const {products, rangeId} = action.payload;
            
          
            
            state.categories.forEach(it => {
                it.brands?.forEach(brand => {
                    brand.ranges?.forEach(range => {
                        if(range.id == rangeId){
                            if(!range.flavours){
                                range.flavours = []
                            }
                            range.flavours.push(...products)
                        }
                    })
                })
            })
        },
        selectCategoryAdmin: (state, action) => {
            state.currentCategory = action.payload
            state.isLastPage = false
            state.categories.forEach(it => {
                it.brands = []
            })
            state.page = 0
        },
        switchAddCategoryModal: (state, action) =>{
            state.addCategoryModal = action.payload.to
        },
        deleteCategoryAdmin: (state, action) =>{
            state.categories = state.categories.filter(x => x.id != action.payload.id)
        },
        deleteBrandAdmin: (state, action) =>{
            state.categories.forEach(it => {
                it.brands = it.brands?.filter(x => x.id != action.payload.id)
            })
        },
        deleteRangeAdmin: (state, action) =>{
            state.categories.forEach(it => {
                it.brands?.forEach(brand => {
                    brand.ranges = brand.ranges?.filter(x => x.id != action.payload.id)
                })
            })
        },
        deleteProductAdmin: (state, action) =>{
            state.categories.forEach(it => {
                it.brands?.forEach(brand => {
                    brand.ranges?.forEach(range => {
                        range.flavours = range.flavours?.filter(x => x.id != action.payload.id)
                    })
                })
            })
        },
        
    },
    extraReducers: (builder)=>{
        
        builder.addCase(fetchCategoriesAdmin.fulfilled, (state, action) => {
            
           
            if(state.currentCategory != null){
                action.payload.forEach(it => {
                    it = {
                        ...it,
                        brands: state.categories.find(x => x.id == it.id)?.brands ?? []
                    }
                })
            } else {
                state.currentCategory = action.payload[0]?.id
            }
            state.categories = action.payload;
            
        });
        builder.addCase(fetchCategoryTableAdmin.pending, (state, action)=>{state.isLoadingCategory = true})
        builder.addCase(fetchCategoryTableAdmin.rejected, (state, action)=>{state.isLoadingCategory = false})
        builder.addCase(fetchCategoryTableAdmin.fulfilled, (state, action) => {
            const { data, categoryId, search } = action.payload;
            state.isLoadingCategory = false;
            
            if(data.length > 0){
                state.categories.forEach((it)=>{
                    if(it.id == categoryId){
                        
                            if(it.brands?.length >= 0){
                                it.brands = [...it.brands, ...data]
                            } else {
                                it.brands = data
                            }
                        
                        
                        
                    }
                })
            } else {
                state.isLastPage = true
            }
            
            
            
        });
        builder.addCase(AddCategoryAdmin.fulfilled, (state, action) => {
            state.categories.push(action.payload)
            state.addCategoryModal = false
        })

    }
    
    // async thunk fetch chats, fetch dialog, but 

})
export const AddCategoryAdmin = createAsyncThunk('pricelist/AddCategory', async (fd)=>{
    const token = await authService.getAccessToken()
    const res = await fetch('/api/admin/pricelist/AddCategory', {
        method: "PUT",
        headers:{
            'Authorization': `Bearer ${token}`
        },
        body: fd
    });
    if(res.ok){
        return await res.json()
    }
    toast.error('Ошибка добавления категории')

    throw Error("Ошибка")
})
const AddProducts = createAsyncThunk('pricelist/AddBrand', async ()=>{
    const token = await authService.getAccessToken()
    const res = await fetch('/api/admin/location/cities',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})

export const fetchCategoriesAdmin = createAsyncThunk('pricelist/fetchCategories', async ()=>{
    const token = await authService.getAccessToken();
        const res = await fetch('/api/admin/pricelist/Categories', {
            method: 'GET',
            headers:{
                'Authorization': `Bearer ${token}`
            }
        });
    if(res.ok){
        return await res.json()
    }
    return []
})

export const fetchCategoryTableAdmin = createAsyncThunk('pricelist/fetchCategoryTable', async ({categoryId, page, search})=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/admin/pricelist/Categories/${categoryId}?page=${page}&search=${search}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return {data: await res.json(), categoryId: categoryId, search: search}
    }
    throw Error("Ошибка")
})



export const { AddBrandAdmin,AddRangeAdmin,selectCategoryAdmin, switchAddCategoryModal, deleteBrandAdmin, deleteCategoryAdmin, deleteProductAdmin, deleteRangeAdmin, AddProductsAdmin, changeSearchTextPL, ChangeImageIdAdmin, resetLastPageAdminPriceList, changePageAdminPriceList} = AdminPricelistPageStateSlice.actions;
export default AdminPricelistPageStateSlice.reducer;