import React, { createContext, useState } from 'react';



// Modal.js
import { MDBBtn, MDBCol, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from 'mdb-react-ui-kit';

import authService from '@auth/AuthorizeService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addDistrict, deleteCityAdmin, deleteDistrictAdmin, deleteMetroLineAdmin, deleteMetroStationAdmin, fetchDistricts } from '../LocationsStateStore';
const DeleteLocationModalContext = createContext(null);
function Modal ({ loc, show, setShow }) {

    const dispatch = useDispatch()
    
    
    async function applyChanges(e){
        e.preventDefault();
        const fd = new FormData(e.target)
        
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/admin/location/${loc.type}/${loc.id}`,{
            method: 'DELETE',
            headers:{
                "Authorization": `Bearer ${token}`
            },
            body: fd
        })
        if(res.ok){
            switch (loc.type) {
                case 'Cities':
                    dispatch(deleteCityAdmin({id: loc.id}))
                    break;
                case 'disctricts':
                    dispatch(deleteDistrictAdmin({id: loc.id}))
                    break;
                case 'MetroLines':
                    dispatch(deleteMetroLineAdmin({id: loc.id}))
                    break;
                case 'MetroStations':
                    dispatch(deleteMetroStationAdmin({id: loc.id}))
                    break;
        
                default:
                    console.log('im here', loc)
                    break;
            }
            
          setShow(false)

        } else{
            toast.error('Ошибка! Возможно с этим объектом установлена свзяь у магазинов')
        }
    }
    return(<>
        
        <MDBModal show={show} onHide={setShow}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader><MDBModalTitle>Вы точно хотите удалить {loc.name}?</MDBModalTitle></MDBModalHeader>
                    <form onSubmit={applyChanges}>
                        
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button type='button' className="btn btn-success" onClick={()=> setShow(false)}>Закрыть</button>
                            <button type='submit' className="btn btn-danger">Удалить</button>
                        </MDBModalFooter>
                    </form>
                    
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </>)
};


export const DeleteLocationModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [loc, setLoc] = useState(1);

  const showDeleteModal = (loc, type) => {

    
    setLoc({
        ... loc,
        type
    });
    
    setModal(true);
  };


  return (
    <DeleteLocationModalContext.Provider value={{ showDeleteModal }}>
      {children}
      
      {<Modal show={modal} loc={loc} setShow={setModal}/>}
    </DeleteLocationModalContext.Provider>
  );
};

export default DeleteLocationModalContext;