import React, { createContext, useState } from 'react';
import Modal from './ChangeOrderStatusModal';

const CourierChangeOrderModalContext = createContext(null);

export const MyCourierChangeOrderStatusProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [type, setType] = useState(null);
  const [id, setid] = useState(null);

  const showEditModal = (orderId) => {
    setid(orderId);
    setType(type);
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setid(null);
    setType(null);
  };

  return (
    <CourierChangeOrderModalContext.Provider value={{ showEditModal, hideModal }}>
      {children}
      {<Modal show={modal} orderId={id} type={type} setShow={hideModal}></Modal>}
    </CourierChangeOrderModalContext.Provider>
  );
};

export default CourierChangeOrderModalContext;