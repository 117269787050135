import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export class AuthService{
    _callbacks = [];
    _nextSubscriptionId = 0;
    _accessTokenQueue = [];
    _renewingToken = false;
    setUser(user){
        // set user to local storage
        localStorage.setItem('user', JSON.stringify(user));
        this.notifySubscribers('login')
        // user = {
        //     access_token: user.access_token,
        //     expires_in: user.expires_in,
        //     token_type: user.token_type,
        //     userName: user.userName,
        //     role: user.role

        // }
    }
    isAuthenticated(){
        // return this.getUser() != null;
        return localStorage.getItem('user') != null;
    }
    getUser(){
        return JSON.parse(localStorage.getItem('user'));
    }
    getRoles(){
        
        return this.getUser()?.roles ?? [];
    }
    
    async getAccessToken() {
        const user = this.getUser();

        if (!user) {
            this.notifySubscribers('logout')
            return;
        }

        if (user.expiresAt < Date.now() / 1000) {
            if (!this._renewingToken) {
                // If not already renewing the token, start the renewal process
                this._renewingToken = true;
                await this.silentRenew(user);
                this._renewingToken = false;

                // Resolve all pending requests in the queue with the renewed token
                const renewedToken = user?.token;
                while (this._accessTokenQueue.length > 0) {
                    const resolveCallback = this._accessTokenQueue.shift();
                    resolveCallback(renewedToken);
                }

                return renewedToken;
            } else {
                // If already renewing the token, add the request to the queue
                return new Promise((resolve) => {
                    this._accessTokenQueue.push(resolve);
                });
            }
        }

        return user?.token;
    }
    redirectTo(path){
        this.notifySubscribers()
    }
    async silentRenew(){
        const user = this.getUser()
        // check if token expired
        const res = await fetch('/api/authentication/refresh-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": user.token
            },
            //body: JSON.stringify({AccessToken: user.token})
        });
        if(res.ok){
            const data = await res.json();
            this.setUser(data);
            //this.notifySubscribers('renew')
            return data;
        } else{
            // logout
            this.notifySubscribers('logout')
            return null;
        }
        

    }
    notifySubscribers(type) {
        for (let i = 0; i < this._callbacks.length; i++) {
          const callback = this._callbacks[i].callback;
          callback(type);
          
        }
      }
    async logout(){
        localStorage.removeItem('user')
        this.notifySubscribers('logout')
    }
    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
      }
    
      unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
          .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
          .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
          throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }
    
        this._callbacks.splice(subscriptionIndex[0].index, 1);
      }
}
const authService = new AuthService();
export default authService;