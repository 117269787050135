import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";


export function AdminStoreItem({store, setStoreState, onLogin}){

    async function changeStoreEnabled(toState){
        console.log(toState)


    }

    return(
        <>
            <MDBCard className="mt-3">
                <MDBCardHeader className="d-flex justify-content-between flex-wrap">
                    <MDBCardTitle>{store.name}</MDBCardTitle>
                    <div className="d-flex align-items-center flex-wrap">
                        <button onClick={()=>{onLogin(store.id)}} className="btn btn-danger me-3">Перейти в аккаунт магазина</button>
                        <div className="form-check form-switch">
                        <input className="form-check-input" onClick={(e) => setStoreState(e.currentTarget.checked)} checked={store.isEnabled} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <label className="form-check-label" for="flexSwitchCheckDefault">{store.isEnabled ? 'Показывает' : 'Скрыт'}</label>
                    </div>
                    </div>
                    
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBRow className="d-flex flex-wrap">
                        <MDBCol size={6} sm={6} md={3}>
                            <img src={`/photos/store/${store.id}.jpg`} width={'100%'}/>
                        </MDBCol>
                        
                        <MDBCol size={12} sm={6} md={8}>

                        
                        
                            <p>Всего в продаже: {store.totalQuantity} шт.</p>
                            <p>Баланс магазина: {store.totalBalance} руб.</p>
                            <p>Описание: {store.description}</p>
                            <p>Самовывоз: {store.selfPickup}</p>
                            <p>Доставка: {store.delivery}</p>
                        </MDBCol>
                    </MDBRow>
                    
                </MDBCardBody>
            </MDBCard>
        </>
    )
}