import { MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBCol, MDBRow, MDBModal, MDBInput, MDBBtn, MDBInputGroup } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";


import authService from "@auth/AuthorizeService";
import { BrandCardW } from "./BrandCardW";
import { AddProductToWarehouse } from "./AddProductToWarehouse";
import { TakeoutModalProvider } from "./Modals/TakeoutModalContext";
import { BrandCard } from "components/admin/PriceList/table/BrandCard";
import { useDispatch, useSelector } from "react-redux";
import { changePage, fetchTableData, findByText, setCurrentWhCategory, updateSearch } from "./state/WarehouseTalbeSlice";


export function CategoriesW({table}){
    
    const currentCategory = useSelector(state => state.mystore.warehouseTable.currentCategory);
    const currentWarehouse = useSelector(state => state.mystore.warehouseTable.curWarehouseId);

    const currentPage = useSelector(state => state.mystore.warehouseTable.page)
    const lastPage = useSelector(state => state.mystore.warehouseTable.lastPage)
    const dispatch = useDispatch()
    const searchText = useSelector(state => state.mystore.warehouseTable.searchText)
    
    useEffect(()=>{
        console.log('Диспатчу из таблицы, смена категории, текста, склада')
        if(currentCategory != null){
            dispatch(fetchTableData({currentCategory: currentCategory, search: searchText, whid: currentWarehouse, page: currentPage}))
        }
        
    }, [currentCategory, searchText, currentWarehouse])
    
    useEffect(()=>{
        const handleScroll = (e) =>{
        
            
            if (
                ((window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
              ) {
                console.log('dispatching...', currentPage+1, 'lastPage: ', lastPage)
                dispatch(changePage({page: currentPage+1}))
              }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll);
          };
    },[])
    

    useEffect(()=>{
        dispatch(fetchTableData({currentCategory: currentCategory, search: searchText, whid: currentWarehouse, page: currentPage}))
    }, [currentPage])

    return(
        <>
            <MDBTabs pills>
                {table?.map((it, index)=>{
                    return (
                        <div className="d-flex flex-column">
                            <MDBTabsItem key={index}>
                            <MDBTabsLink style={{marginBottom: '2px'}} onClick={() => dispatch(setCurrentWhCategory({id: it.id}))} active={it?.id == currentCategory}>
                                {it.name}
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <p className="ms-2 mt-0 pt-0" style={{color: 'gray', fontFamily: 'sans-serif'}}>{it.balance} руб.</p>
                        </div>
                        
                    );
                })}
                
            </MDBTabs>
            <MDBInputGroup tag="form" className='d-flex w-auto mb-3'>
                <input className='form-control' value={searchText} placeholder="Например: Rell" onChange={(e)=> dispatch(updateSearch({searchText: e.target.value}))} aria-label="Search" type='Search' />
                <MDBBtn outline>Поиск</MDBBtn>
            </MDBInputGroup>
            <AddProductToWarehouse warehouseId={currentWarehouse}/>
            <MDBTabsContent style={{marginBottom: '40px'}}>
                {table?.map((it, index) =>{
                    
                    return (
                    <>
                        <MDBTabsPane key={index+100} show={currentCategory === it.id} >
                            <MDBRow className="gy-5">
                                {it?.brands?.map((brand, i) =>{
                                    return (
                                        <>
                                        <MDBCol size={12}>
                                            
                                                <BrandCardW brand={brand} key={i}/>
                                            
                                            
                                        </MDBCol>
                                            
                                        </>
                                    );
                                })}
                            </MDBRow>
                            
                        </MDBTabsPane>
                    </>
                    )
                })}
                
            </MDBTabsContent>
        </>
    );
}