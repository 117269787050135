
import authService from "@auth/AuthorizeService";
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";


export default function Recomended({category = 'Жидкости'}) {
    const [items, setItems] = useState([]);

    async function fetchRecommenedItems(){
      const token = await authService.getAccessToken()

      const res = await fetch(`/api/home/popular`, {
        headers:{
          "Authorization": `Bearer ${token}`
        }
      })
      if(res.ok){
        const data = await res.json()
        setItems(data)
      }
    }

    useEffect(()=>{
      fetchRecommenedItems()
    },[])
    return(<>
    <h4>Популярное</h4>
    <MDBRow className="gy-3">
    {items?.length == 0 && [1,1,1,1,1,1,1,1].map((it, i)=> <RecommendedItem item={null} key={i}/>)}
    
    {items?.map((it, index)=><MDBCol xl={2} md={3} sm={4} size={6}><RecommendedItem item={it} key={index}/></MDBCol>)}
    
    
  </MDBRow></>);

    
}


export function RecommendedItem({item}){
  return (
    <>
    
      <NavLink to={`/product/${item?.id}/${item?.link}`} className="">
        <MDBCard className="">
          <MDBCardImage src={`/photos/ranges/${item?.rangeId}.jpg`} hidden={!item?.rangeId} position="top"/>
          {!item?.rangeId && <Skeleton height='200px' width='100%' containerClassName="" borderRadius={0}/>}
          
          <MDBCardBody className="py-3 px-2">
            <h5 className="mx-2 text-white m-0 recommendTextTitle">{item?.fullName || <Skeleton/>}</h5>
          </MDBCardBody>
        </MDBCard>
        </NavLink>
      
    </>
  )
}