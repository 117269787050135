import { NavLink, useParams, useSearchParams } from "react-router-dom";
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductInfo, fetchProductOtherRanges, fetchProductReviews, switchFavouriteProductPage } from "./ProductPageStateSlice";
import Skeleton from "react-loading-skeleton";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane, MDBTooltip } from "mdb-react-ui-kit";
import './product.css'
import { RatingStars, StoresTab } from "./StoresTab";
import { ReviewsTab } from "./ReviewsTab";
import { StoreReviewsModalProvider } from "../GeneralModals/StoreReviews/StoreReviewsModalContext";
import usePrevious from "hooks/usePrevious";
import { autoDeclensionReviews, autoDeclensionTimes } from "css/Utils";
export function ProductPage(){
    const dispatch = useDispatch();
    const { id } = useParams();
    
    const product = useSelector(state => state.store.productPage.product)
    const isLoadingProduct = useSelector(state => state.store.productPage.isLoadingProduct)
    useEffect(()=> {dispatch(fetchProductInfo({id: id}))}, [id])
    const [isLoadingImage, setIsLoadingImage] = useState(true)
    const [currentTab, setCurrentTab] = useState(1)
    const rangeId = usePrevious(product?.rangeId)
    useEffect(()=>{
        if(currentTab == 2){
            dispatch(fetchProductReviews({id: id}))
        }
    },[currentTab, product])
    useEffect(()=>{
        if(product != null && rangeId != product?.rangeId){
            dispatch(fetchProductOtherRanges({id: product?.rangeId}))
        }
    },[product])
    const otherRanges = useSelector(state => state.store.productPage.otherRanges)
    return(
        <>
            
                <MDBRow className=" mt-4">
                    <MDBCol lg={4}>
                    <div className="border rounded-4 mb-3">
                        
                        {(isLoadingImage) && <Skeleton height={'300px'} />}
                        {product && <img
                        onLoad={()=> setIsLoadingImage(false)}
                        
                        // hidden={isLoadingImage}
                        alt="Картинка товара"
                            style={{ width: "100%", margin: "auto", display: isLoadingImage ? "none":"initial"}}
                            className="rounded-4"
                            src={`/photos/ranges/${product?.rangeId}.jpg/`}
                        />}
                    </div>
                    {/* <div className="d-flex justify-content-center mb-3">
                        <a
                        data-fslightbox="mygalley"
                        className="border mx-1 rounded-2"
                        target="_blank"
                        data-type="image"
                        href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big1.webp"
                        >
                        <img
                            width={60}
                            height={60}
                            className="rounded-2"
                            src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big1.webp"
                        />
                        </a>
                        <a
                        data-fslightbox="mygalley"
                        className="border mx-1 rounded-2"
                        target="_blank"
                        data-type="image"
                        href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big2.webp"
                        >
                        <img
                            width={60}
                            height={60}
                            className="rounded-2"
                            src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big2.webp"
                        />
                        </a>
                        <a
                        data-fslightbox="mygalley"
                        className="border mx-1 rounded-2"
                        target="_blank"
                        data-type="image"
                        href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big3.webp"
                        >
                        <img
                            width={60}
                            height={60}
                            className="rounded-2"
                            src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big3.webp"
                        />
                        </a>
                        <a
                        data-fslightbox="mygalley"
                        className="border mx-1 rounded-2"
                        target="_blank"
                        data-type="image"
                        href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big4.webp"
                        >
                        <img
                            width={60}
                            height={60}
                            className="rounded-2"
                            src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big4.webp"
                        />
                        </a>
                        <a
                        data-fslightbox="mygalley"
                        className="border mx-1 rounded-2"
                        target="_blank"
                        data-type="image"
                        href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big.webp"
                        >
                        <img
                            width={60}
                            height={60}
                            className="rounded-2"
                            src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big.webp"
                        />
                        </a>
                    </div> */}
                    {/* thumbs-wrap.// */}
                    {/* gallery-wrap .end// */}
                    </MDBCol>
                    <MDBCol lg={8}>
                    <div className="ps-lg-3">
                        <div className="">
                        <h4 className="title">
                        {isLoadingProduct === true ? <Skeleton width={'150px'} /> : product?.name }
                        </h4>
                        <p className="addToFavorite text-primary" onClick={()=> dispatch(switchFavouriteProductPage({to: !product?.favourite, id: product.id}))}><MDBIcon className="me-1" color="warning" fas={product?.favourite} far={!product?.favourite} icon={"heart"} /> {product?.favourite ? 'В избранном' : 'В избранное'}</p>
                        

                        
                        </div>
                       
                        <NavLink className="d-flex flex-row mb-3 mt-2" to="#reviews" onClick={()=> {setCurrentTab(2); document.getElementById('tabsStoreReviews').scrollIntoView()}}>
                            <div className="d-flex me-2">
                                <p>{product?.rating?.toFixed(1)}</p>
                                <RatingStars rating={product?.rating} className="mx-2"/>
                                <p className="text-warning">{product?.reviewsCount || 0} {autoDeclensionReviews(product?.reviewsCount || 0)}</p>
                                
                            </div>
                        
                        <span className="text-muted">
                            <i className="fas fa-shopping-basket fa-sm mx-1" />
                            {product?.totalOrders} {autoDeclensionTimes(product?.totalOrders)} купили
                        </span>
                        {/* <span className="text-success ms-2">In stock</span> */}
                        </NavLink>
                        {/* <div className="mb-3">
                        <span className="h5">{product?.price} руб.</span>
                        <span className="text-muted">/шт.</span>
                        </div> */}
                        {/* <p>
                        {product?.description}
                        </p> */}
                        
                        <hr />
                        <div className="wrapper">
                            {product?.flavours?.map(flavour => {
                                return <>
                                {flavour.outOfStock ?

<MDBTooltip options={{ modifiers: [{ name: 'offset', options: { offset: [0, -5] }}]}} tag='span' wrapperClass='d-inline-block'  className="text-danger" title='Товар закончился'>
<div 
                                        className={ `item btn ${flavour.outOfStock && 'disabled'} btn-outline-${id == flavour.id ? "primary" : "secondary"}`}>
                                        {flavour.name}
                                    </div>
</MDBTooltip> :
                                    <NavLink to={`/product/${flavour.id}`} 
                                        className={ `item btn ${flavour.outOfStock && 'disabled'} btn-outline-${id == flavour.id ? "primary" : "secondary"}`}>
                                        {flavour.name}
                                    </NavLink>}
                                </>
                            })}
                        </div>
                        
                        
                    </div>
                    </MDBCol>
                </MDBRow>
                
            {/* content */}
        
                <div className="row gx-4 mb-4 mt-4">
                    <div className="col-lg-8 ">
                    <div className="border rounded-2 px-3 py-2 bg-dark">
                        {/* Pills navs */}
                        <MDBTabs pills justify className="mb-3" id="tabsStoreReviews">
                        <MDBTabsItem>
                        
                            <MDBTabsLink
                            onClick={()=> setCurrentTab(1)}
                            className=""
                            active={currentTab === 1}>
                            Магазины
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink
                            onClick={()=> setCurrentTab(2)}
                            className=""
                            active={currentTab === 2}>
                            Отзывы
                            </MDBTabsLink>
                        </MDBTabsItem>
                        
                        </MDBTabs>
                        {/* Pills navs */}
                        {/* Pills content */}
                        <MDBTabsContent>
                            <MDBTabsPane show={currentTab===1}>
                                <StoreReviewsModalProvider>
                                    <StoresTab productId={id}/>
                                </StoreReviewsModalProvider>
                                
                            </MDBTabsPane>
                            <MDBTabsPane show={currentTab===2}>
                                <ReviewsTab/>
                            </MDBTabsPane>
                        </MDBTabsContent>
                        
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="">
                        <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Другие линейки этого бренда</h5>
                            {otherRanges?.map((it, key)=>{
                                return  <NavLink to={`/product/${it.productId}`} className="d-flex mb-3">
                                <div  className="me-3">
                                    <img
                                    src={`/photos/ranges/${it?.rangeId}.jpg/`}
                                    style={{ minWidth: 96, height: 96 }}
                                    className="img-md"
                                    />
                                </div>
                                <div className="info w-100">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="text-white">{it?.name}</h5>
                                        <p className="text-muted">{it?.boughtTimes} {autoDeclensionTimes(it?.boughtTimes)} купили</p>
                                    </div>
                                    
                                    <strong className="text-white"> От {it?.firstPrice} руб.</strong>
                                    </div>
                                </NavLink>
                            })}
                           
                            
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
        </>


    );
}