import authService from "@auth/AuthorizeService";
import { IncomeGraph } from "components/MyStore/Analytics/Income/IncomeGraph";
import { InfoCard } from "components/MyStore/Analytics/Income/InfoCard";
import { MDBBadge, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBCol, MDBIcon, MDBProgress, MDBProgressBar, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as locales from 'react-date-range/dist/locale';
import { CourierOrderItem } from "./CourierOrderItem";
import { ChangeOrderStatus } from "components/MyStore/Orders/ChangeStatusModal";
import CourierChangeOrderModalContext, { MyCourierChangeOrderStatusProvider } from "./OrderStatusModal/ChangeOrderStatusContext";
import { useDispatch, useSelector } from "react-redux";
import { changeCourierPage, fetchMyCourierOrders, setCourierActiveTab, setCourierId } from "./CourierStateSlice";
import { WorkerPaymentInfo } from "./PaymentInfo";



export function CourierMainView({viewFrom='Courier', courierId = null}){

    //data = {
    //     
    //}
    // везде в запросе указывем courierId
    // и проверяем на наличие этого курьера в магазине и действительно ли делает запрос этот человек
    // также если смотрит админ проверяем
    // Как вариант сделать переиспользуемые компоненты
    // 
    const [activeTab, setActiveTab] = useState(0)
    const navigate = useNavigate()
    return(
        <>
            {viewFrom=='Admin' && <button className="btn btn-info mb-3" onClick={e => navigate(-1)}><MDBIcon color="white" fas icon="arrow-left" /></button>}<h2>Моя панель курьера</h2>
            
                    <MDBTabs pills fill>
                      <MDBTabsItem>
                        <MDBTabsLink onClick={()=> {setActiveTab(0)}} active={activeTab===0}>
                          Доходы
                        </MDBTabsLink>
                      </MDBTabsItem>
                      <MDBTabsItem>
                        <MDBTabsLink onClick={()=> {setActiveTab(1)}} active={activeTab===1}>
                          Заказы
                        </MDBTabsLink>
                      </MDBTabsItem>
                    </MDBTabs>
                    <MDBTabsContent>
                      <MDBTabsPane show={activeTab==0}><div><CourierCharts courierId={courierId}/><WorkerPaymentInfo type={'courier'} workerId={courierId}/></div></MDBTabsPane>
                      <MDBTabsPane show={activeTab==1}><CourierOrders/></MDBTabsPane>
                    </MDBTabsContent>
                    
                
        </>
    )
}
function CourierCharts({courierId}){
    const [selectedPeriod, setSelectedPeriod] = useState("Today")
    const [data, setData] = useState(null)
    function setPeriodToShow(preiod){
        setSelectedPeriod(preiod)
      }

      async function GetData(period, CustomPeriod = null){
        if(period === 'Custom' && CustomPeriod == null){
          return
        }
        setIsLoadins(true);
          const token = await authService.getAccessToken();
          const res = await fetch(`/api/mystore/mycourier/charts?courierId=${courierId}`,{
            headers:{
              "Authorization": `Bearer ${token}`,
              'Content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
              PeriodType: period,
              CustomDatePeriod: CustomPeriod
            })
          })
          if(res.ok){
              // setData
              applyData(await res.json())
              
          } else{
              toast.error('Произошла ошибка')
          }
          setIsLoadins(false);
      }
      function applyData(res){
  
    

        const temp = {
            labels: res.incomeCharts.map((it)=> (it.timeLabel)),
            datasets: [
                {
                  fill: false,
                  label: '',
  
                  data: res.incomeCharts.map((it)=> it.income),
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
              ],
        }
        console.log(temp)
        res.incomeCharts = temp;
        setData(res);
    }
    useEffect(()=>{
        GetData(selectedPeriod);
    },[selectedPeriod])
        const [isLoading, setIsLoadins] = useState(false);
        const [rangePicker, setRangePicker] = useState(
          [{
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }]);
        function handleDateRangePicker(range){
          console.log(range)
          setRangePicker([range])
        }
        useEffect(()=>{
          console.log(rangePicker)
          if(rangePicker[0]?.endDate !== null && rangePicker[0]?.startDate !== null){
            
          }
        },[rangePicker])
        const [showCalendar, setShowCalendar] = useState(false);
        if(data === null){
          return <><div className="spinner-border text-primary" role="status"/></>
        }
    return(
        <>
        <h3>Доходы</h3>
        <MDBCard>
          <MDBCardBody>
            <div className="d-flex flex-wrap">
              {/* buttons: Сегондя, Вчера, Неделя, Месяц, Квартал, Год, Пероид(каленьдарь) */}
              
              <button data-mdb-ripple-duration="0" className={`btn me-2 ${selectedPeriod == 'Today' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Today')}>Сегодня</button>
              <button data-mdb-ripple-duration="0" className={`btn me-2 ${selectedPeriod == 'Yesterday' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Yesterday')}>Вчера</button>
              <button data-mdb-ripple-duration="0" className={`btn me-2 ${selectedPeriod == 'Week' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Week')}>Неделя</button>
              <button data-mdb-ripple-duration="0" className={`btn me-2 ${selectedPeriod == 'Month' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Month')}>Месяц</button>
              <button data-mdb-ripple-duration="0" className={`btn me-2 ${selectedPeriod == 'Quarter' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Quarter')}>Квартал</button>
              <button data-mdb-ripple-duration="0" className={`btn me-2 ${selectedPeriod == 'Year' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Year')}>Год</button>
              <div>
                <button data-mdb-ripple-duration="0" className={`btn me-2 ${selectedPeriod == 'Custom' ? 'btn-info' : 'btn-primary'}`} onClick={()=> setShowCalendar(prev => !prev)}>{(selectedPeriod == 'Custom' && rangePicker[0]) ? `${rangePicker[0].startDate.toLocaleDateString('ru-ru')} - ${rangePicker[0].endDate.toLocaleDateString('ru-ru')}` : 'Период'} <MDBIcon fas icon="calendar" /></button>
                  
                {showCalendar && <div className="float d-flex flex-column" style={{zIndex: 1, position: 'absolute'}}>
                  {/* <DateRangePicker /> */}
                  <DateRange maxDate={new Date()}  editableDateInputs onChange={item => handleDateRangePicker(item.selection)}  locale={locales.ru} moveRangeOnFirstSelection ranges={rangePicker}/>
                  <button className="btn btn-primary" onClick={()=>{
                      setSelectedPeriod('Custom')
                      setShowCalendar(false);
                      GetData('Custom', {From: `${rangePicker[0].startDate.toLocaleDateString('ru-ru')}`, To: `${rangePicker[0].endDate.toLocaleDateString('ru-ru')}`})
                  }}>Применить</button>
                  </div>}
              </div>
              {isLoading && 
              <div className="spinner-border text-primary" role="status"/>}
              
            </div>
            
            <div className="mt-4">
              <MDBRow className="">
                <MDBCol size={12} md={4} className="">
                  <InfoCard number={data.income} underText={"Доход"} previosNumber={data.previousPeriodTrend}/>
                </MDBCol>
                
                <MDBCol size={12} md={4}>
                <InfoCard number={`${data.totalOrders} шт.`} underText={"Кол-во заказов"} previosNumber={null}/>
                
                </MDBCol>
              </MDBRow>
              {/* 3 cards */}
              
               
            </div>
            <div className="mt-5">
              <MDBRow>
                <MDBCol xl={8}>
                <IncomeGraph data={data?.incomeCharts}/>
                </MDBCol>
                <MDBCol xl={4}>
                  <MDBRow>
                    <MDBCol size={12}>
                    <MDBCard className="mt-3" style={{backgroundColor: '#3d3d3d',border: '1px solid green', borderColor: 'green'}}>
                    <MDBCardBody className="pt-3 pb-3">
                      <MDBRow>
                        <MDBCol size={12} md={6} lg={12} className="d-flex" >
                          
                          <b className="align-self-center" style={{flex: '0 0 25%'}}>{data.averageIncome} руб.</b>
                          <span className="align-self-center" style={{fontSize: '14px'}}>Средний чек</span>
                          
                          
                        </MDBCol>
                        <MDBCol size={12} md={6} lg={12} className="d-flex">
                        
                        <b className="align-self-center" style={{flex: '0 0 25%'}}>{data.previousPeriod.income} руб.</b>
                          <span className="align-self-center" style={{fontSize: '14px'}}>Принято в прошлом периоде<br/>({data.previousPeriod.displayString})</span>
                          
                        
                      
                        </MDBCol>
                      </MDBRow>
                      
                    </MDBCardBody>
                  </MDBCard>
                    </MDBCol>
                    {/* <MDBCol size={12}>
                    <MDBCard className="mt-3" style={{backgroundColor: '#3d3d3d',border: '1px solid green', borderColor: 'green'}}>
                    <MDBCardBody className="pt-3 pb-3">
                      <p className="mb-0" style={{color: '#F8F1F1'}}>Способ выдачи товара</p>

                      <div className="mt-2 d-flex justify-content-between">
                      <p className="mb-1">Самовывоз</p>
                      <p className="mb-1 pb-0" style={{fontSize: '14px', fontWeight: '200', color: '#F8F1F1'}}>49 %</p>
                      </div>
                      <MDBProgress>
                        <MDBProgressBar width={49} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <div className="mt-3 d-flex justify-content-between">
                      <p className="mb-1">Доставка</p>
                      <p className="mb-1 pb-0" style={{fontSize: '14px', fontWeight: '200', color: '#F8F1F1'}}>51 %</p>
                      </div>
                      <MDBProgress>
                        <MDBProgressBar width={51} valuemin={0} valuemax={100} />
                      </MDBProgress>
                    </MDBCardBody>
                  </MDBCard>
                    
                    </MDBCol> */}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {/* chart  and rigth способ доставки и средний оборот + прошлый период*/}
              
              <div className="d-flex flex-row">
                  
                  

              </div>
            </div>
          
          </MDBCardBody>
        </MDBCard>
        
        
        </>
    )
}
function CourierOrders(){
  const navigate = useNavigate()
  const dispatch = useDispatch()
    const selectedTab = useSelector(state => state.mystore.myCourier.activeTab)
    const orders = useSelector(state => state.mystore.myCourier.orders)
    const page = useSelector(state => state.mystore.myCourier.page)
    const isLastPage = useSelector(state => state.mystore.myCourier.lastPage)
    const isLoading = useSelector(state => state.mystore.myCourier.isLoading)
    function setSelectedTab(status){
      console.log('twice disp')
      dispatch(setCourierActiveTab({status}))
    }
    useEffect(()=>{
      console.log('selected tab: ',selectedTab)
      dispatch(fetchMyCourierOrders({status: selectedTab, page: page}))
    },[selectedTab, page])
    useEffect(()=>{
      const handleScroll = (e) =>{
      
          
          if (
              ((window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight) && !isLastPage
            ) {
              console.log('dispatching...', page+1, 'lastPage: ', isLastPage)
              dispatch(changeCourierPage())
              
            }
      }
      window.addEventListener('scroll', handleScroll)
      return () => {
          window.removeEventListener('scroll', handleScroll);
        };
  },[])
    return(
        <>
        <MDBTabs pills>
                    <MDBTabsItem>
                                <MDBTabsLink style={{padding: '10px'}} onClick={()=> {setSelectedTab(0)}} active={selectedTab===0}>
                                    Активные
                                </MDBTabsLink>
                                </MDBTabsItem>
                                <MDBTabsItem>
                                <MDBTabsLink style={{padding: '10px'}} onClick={()=> {setSelectedTab(1)}} active={selectedTab===1}>
                                    Завершённые
                                </MDBTabsLink>
                            </MDBTabsItem>
                    </MDBTabs>
                    <MyCourierChangeOrderStatusProvider>
                      {orders?.map((it, index)=>{
                        return(
                          <>
                            <CourierOrderItem order={it} key={index} openChat={()=> navigate(`/mystore/chat?id=${it.chatId}`)}/>
                          </>
                        )
                      })}
                      {isLoading && <div className="spinner-border text-primary" role="status"/>}
                    </MyCourierChangeOrderStatusProvider>
                    
                    {/* <CourierOrderItem order={null}/> */}
            
        </>
    )
}


