import React, { useEffect, useState } from "react";
import { Component } from "react";
import { render } from "react-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css'
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Stores(){

  const [stores, setStores] = useState([]);

    async function fetchProducts(){
    const res = await fetch('/api/store/stores',{
      method: 'GET'
    });
    if(res.ok){
     setStores(await res.json())
    }
    

    }
    useEffect(()=>{
      fetchProducts()
    }, [])
    // fetch from server products
 
    return(
        <>
            <section>
              <div className="my-5">
                <header className="mb-4">
                  <h3>Магазины</h3>
                </header>
                <MDBRow className="gy-3">
                  {stores?.length == 0 && [1,1,1,1,1,1,1,1].map((it, index)=> <StoreItemCard key={index} store={null}/>)}
                  
                  {stores?.map((it, index)=><StoreItemCard store={it} key={index}/>)}
                  
                  
                </MDBRow>
              </div>
            </section>

        </>
    );
}
function StoreItemCard({store}){
  return (
    <>
      <MDBCol lg={3} md={6} sm={6}>
      <NavLink to={`/store/@${store?.username}/`} className="">
        <MDBCard className="rounded">
          <MDBCardImage src={`/photos/store/${store?.id}.jpg`} fluid width='200px' height='200px' hidden={!store?.id} position="top" loading="lazy"/>
          {/* <iframe src="iframe" loading="lazy"></iframe> */}
          {!store?.id && <Skeleton height='200px' width='100%' containerClassName="rounded" />}
          
          <MDBCardBody className="py-3 px-2">
            <MDBCardTitle style={{marginBottom: '15px'}} className="mx-2 text-white m-0">{store?.name || <Skeleton/>}</MDBCardTitle>
          </MDBCardBody>
        </MDBCard>
        </NavLink>
      </MDBCol>
    </>
  );
}