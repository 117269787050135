
import { useEffect, useState } from "react";
import authService from "@auth/AuthorizeService";
import { CourierItem } from "./CourierItem";
import { AddCourierModal } from "./AddCourierModal";
import React from "react";
import { EditModalProvider } from "../ReferralPage/EditModal/EditModalContext";


export function CouriersPage(){
    const [couriers, setCouriers] = useState(null);
    async function fetchCouriers() {
        const token = await authService.getAccessToken();
        const res = await fetch('/api/mystore/workers/couriers',{
            method: 'GET',
            headers:{
                'Authorization': `Bearer ${token}`
            }
        });

        if(!res.ok){
            return;
        }
        const obj = await res.json();
        setCouriers(obj);
    }

    useEffect(()=>{
        fetchCouriers();
    }, [])
    function RenderCouriers(){
        if(!couriers){
            return (
                <>
                    <p>Курьеры ещй не добавлены</p>
                </>
            );
        }
        return(
            <>
                {couriers.map((item)=>{
                    return <CourierItem key={item.id} manager={item}/>
                })}
            </>
        );
    }

    return(<>
        <div className="d-flex justify-content-between align-items-center"><h1>Курьеры</h1>
        <AddCourierModal onNewAdded={(data)=>{
            setCouriers(prev => [...prev, data])
        }}/></div>
        
        <EditModalProvider>
        <RenderCouriers/>
        </EditModalProvider>
        

    </>);
}