import authService from "@auth/AuthorizeService";
import { MDBCard, MDBCardImage, MDBCardBody, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";



export function FavouriteProductsPage(){
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true)
    async function fetchItems(){
        setLoading(true)
        const token = await authService.getAccessToken()
        const res = await fetch('api/product/favourites',{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if(res.ok){
            setItems(await res.json())
        }
        setLoading(false)
    }
    useEffect(()=>{
        fetchItems()
    },[])
    return<>
        <h4>Избранные товары</h4>
        {loading ? <div className="spinner-border text-primary" role="status"/> : (items.length > 0 ? <MDBRow className="gy-2">
            {items?.map((item, i)=>
                <FavouriteItem item={item} key={i}/>
            )}
        </MDBRow> : <p className="fw-bold">У вас пока нет избранных товаров</p>)}
    </>
}

function FavouriteItem({item}){
    return (
      <>
      
        <NavLink to={`/product/${item?.productId}`} className="col-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3">
          <MDBCard className="">
            <MDBCardImage src={`/photos/ranges/${item?.rangeId}.jpg`} hidden={!item?.rangeId} position="top"/>
            {!item?.rangeId && <Skeleton height='200px' width='100%' containerClassName="" borderRadius={0}/>}
            
            <MDBCardBody className="py-3 px-2">
              <h5 className="mx-2 text-white m-0 recommendTextTitle">{item?.name || <Skeleton/>}</h5>
            </MDBCardBody>
          </MDBCard>
          </NavLink>
        
      </>
    )
  }