import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import authService from "../AuthorizeService";

import { toast } from "react-toastify";
import { TelegramLoginButton } from "./TelegramLoginButton";


export function LoginPage(){

    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [search,setSearch] = useSearchParams();
    async function login(e){
        e.preventDefault();
        
        const fd = new FormData(e.target)
        const res = await fetch('/api/authentication/login',{
            method: 'POST',
            body: fd,
            
        });
        
        if(res.ok){
            authService.setUser(await res.json())
            // navigate to profile
            const returnUrl = search.get('returnUrl');
            if(returnUrl !== null && returnUrl.length > 6){
                navigate(decodeURIComponent(returnUrl), {replace: true })
            } else{
                navigate('/profile/')

            }
            
            // if (window.PasswordCredential) {
            //     var c = await navigator.credentials.create({username : fd.get("username"),password: e.target.values[1]});
            //     return navigator.credentials.store(c);
            //   } else {
            //     return Promise.resolve();
            //   }
        } else{
            const text = await res.text();
            setError(text)
        }
        // get cookie
        
    }
    return (
        <>
        <MDBContainer style={{width: 'fit-content'}}>
            <h4>Вход</h4>
            {/* <NavLink to='/auth/register'>Регистрация</NavLink> */}
            <MDBCard style={{maxWidth: '540px'}}>
                
                <MDBCardBody>
                    <form onSubmit={login}>
                        <MDBRow className="gy-3">
                            <MDBCol size={12}>
                            <MDBInput name="username" label="Юзернейм" type="text" autoComplete="on"/>
                            </MDBCol>
                            <MDBCol size={12}>
                            <MDBInput name="password" label="Пароль" type="password" autoComplete="on"/>
                            {error != null && <>
                                <p className="text-danger m-0 p-0" style={{fontSize: '12px'}}>{error}</p>
                            </>}
                            </MDBCol>
                            <MDBCol size={12}>
                                <div className="d-flex justify-content-between align-items-center">
                                <button type="submit" className="btn btn-primary">Вход</button>
                                <TelegramLoginButton/>
                                </div>
                            
                            </MDBCol>
                        </MDBRow>
                    </form>
                    
                    
                </MDBCardBody>
            </MDBCard>
            </MDBContainer>
        </>
    );
}