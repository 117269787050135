
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ProfileMain } from "./ProfileMain/ProfileMain";
import { ProfileEdit } from "./Edit/ProfileEdit";
import AuthorizeRoute from "@auth/AuthorizeRoute";
import { ProfileSideBarV2 } from "./ProfileSidebarV2";
import { OrdersLayoutProfile } from "./Orders/OrdersLayout";
import { OrderPage } from "./Orders/OrderPage";
import { MyReviewsPage } from "./Reviews/MyReviewsPage";
import { FavouritesPage } from "./Favourites/FavouriesPage";
import { FavouriteStoresPage } from "./Favourites/FavouritesStores";
import { FavouriteProductsPage } from "./Favourites/FavouritesProducts";
import useScreenSize from "hooks/ScreenSize/useScreenSize";
import { ProfileMainMobile } from "./ProfileMain/ProfileMainMobile";
import { MDBContainer } from "mdb-react-ui-kit";
import { MyStoresReviewsPage } from "./Reviews/MyStoresReviewsPage";
import { useTitle } from "hooks/useTitle";



export function ProfilePage(){
    const screenSize = useScreenSize()
    useTitle('Профиль')
    if(screenSize.width <=768){
        return<>
        
        <AuthorizeRoute>
                    <Routes>
                        <Route index element={<ProfileMainMobile/>}/>
                        <Route path="orders" element={<OrdersLayoutProfile/>}/>
                        <Route path="orders/:id" element={<OrderPage/>}/>
                        <Route path="edit" element={<ProfileEdit/>}/>
                        <Route path="favoritesStores" element={<FavouriteStoresPage/>}/>
                        <Route path="favorites" element={<FavouriteProductsPage/>}/>
                        <Route path="reviews" element={<MyReviewsPage/>}/>
                        <Route path="storesReviews" element={<MyStoresReviewsPage/>}/>
                    </Routes>
                    </AuthorizeRoute>
                    <div className="mb-9"></div>
            
        </>
    }
    return (
        <div>
        
            
            <div className="d-flex gap-3">
                <div className="ms-4" style={{width: '250px', minWidth: '250px'}}>
                    <ProfileSideBarV2/>
                </div>
                <div className="flex-fill">
                    <AuthorizeRoute>
                    <Routes>
                        <Route index element={<ProfileMain/>}/>
                        <Route path="orders" element={<OrdersLayoutProfile/>}/>
                        <Route path="orders/:id" element={<OrderPage/>}/>
                        <Route path="edit" element={<ProfileEdit/>}/>
                        <Route path="favoritesStores" element={<FavouriteStoresPage/>}/>
                        <Route path="favorites" element={<FavouriteProductsPage/>}/>
                        <Route path="reviews" element={<MyReviewsPage/>}/>
                        <Route path="storesReviews" element={<MyStoresReviewsPage/>}/>
                    </Routes>
                    </AuthorizeRoute>
                    
                </div>
            </div>
            
        </div>
    );
}
