import React from "react";
import { IncomeCharts } from "./Income/IncomeCharts";
import { TopSales } from "./TopSales/TopSales";


export function IncomesPage(){


    return (
        <>
            <IncomeCharts/>
        </>
    )
}