import authService from "@auth/AuthorizeService";
import { MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { toast } from "react-toastify";



export function AdminCreateNewPaymentToWorker({type, workerId, unpaid, onAdd}){

    const [modal, setModal] = useState(false)
    async function submitPayment(e){
        e.preventDefault()
        const fd = new FormData(e.target)
        const obj = {};
        fd.forEach((value, key) => (obj[key] = value));
        obj.workerId = workerId;
        obj.isCourier = (type == 'courier')
        const token = await authService.getAccessToken()
        const res = await fetch('/api/mystore/workers/NewPayment',
        {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(obj)
        })
        if(res.ok){
            setModal(false)
            onAdd(await res.json())
            toast.success('Добавлено')
        } else{
            toast.error('Неизсвестная ошибка')
        }
    }
    return <>
        <button className="btn btn-primary" onClick={()=> setModal(true)}>Создать выплату</button>
        <MDBModal setShow={setModal} show={modal}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>
                            Создать новую выплату
                        </MDBModalTitle>
                    </MDBModalHeader>
                    <form onSubmit={submitPayment}>

                        {modal && <>
                        <MDBModalBody>
                            <p>Всего неоплачено (без долгов): <span className="fw-bold">{unpaid} руб.</span></p>
                            <MDBInputGroup className="my-2" textAfter="руб.">
                                <MDBInput required placeholder="23.5" defaultValue={unpaid} label="Сумма" name="amount"></MDBInput>
                                {/* <button className="btn btn-warning">Всю сумму</button> */}
                            </MDBInputGroup>
                            <MDBInput placeholder="Комментарий" label="Комментарий" type="text" name="comment"/>
                            
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button className="btn btn-danger" onClick={()=> setModal(false)} type="button">Отмена</button>
                            <button className="btn btn-success" type="submit">Добавить</button>
                        </MDBModalFooter></>}
                    </form>
                </MDBModalContent>
                
            </MDBModalDialog>
        </MDBModal>
    </>
}