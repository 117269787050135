// Modal.js
import { MDBBtn, MDBCol, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from '@auth/AuthorizeService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { cleanCompleteError, myCourierCompleteOrder } from '../CourierStateSlice';

const ChangeOrderStatusModal = ({ orderId, show, setShow }) => {

    const dispatch = useDispatch()
    
    const err = useSelector(state => state.mystore.myCourier.completeError)
  return (
    <MDBModal show={show} onHide={()=> {setShow(); dispatch(cleanCompleteError())}} >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Ты точно хочешь завершить заказ?</MDBModalTitle>
              <button className='btn btn-close' color='none' onClick={()=>setShow()}></button>
            </MDBModalHeader>
            
            {err && <MDBModalBody>
                    <p style={{whiteSpace: 'pre'}} className='text-danger'>{err}</p>
                </MDBModalBody>}
            <MDBModalFooter className='d-flex justify-content-between'>
              <button className='btn btn-danger' onClick={() => setShow()}>
                Закрыть
              </button>
              <button className='btn btn-success' onClick={() => dispatch(myCourierCompleteOrder({orderId: orderId}))}>
                Сохранить
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
  );
};

export default ChangeOrderStatusModal;