import { MDBTextArea } from 'mdb-react-ui-kit';
import React, { useState } from 'react';

export function AutoResizeTextarea  ({name, className = '', defaultValue, label, required})  {
  

  const calculateHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';
    // const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
    // const rows = Math.ceil(element.scrollHeight / lineHeight);
    // element.style.height = `${rows * lineHeight}px`;
  };

  const handleInput = (event) => {
    calculateHeight(event.target);
  };

  return (
    <MDBTextArea
      className={className}
      name={name}
      label={label}
      defaultValue={defaultValue}
      required={required}
      onInput={handleInput}
      style={{ resize: 'none', overflow: 'hidden' }}
    />
  );
};

export default AutoResizeTextarea;