import React from "react";
import { AddWarehouse } from "./Warehouse";

export function NewWarehouse(){
    return(
        <>
            <h1>Создание склада</h1>
            <p>У вас ещё нет склада</p>
            <AddWarehouse/>
        </>
    );
}