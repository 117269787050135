
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import React from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
  const options = {
    responsive: true,
    type: 'line',
    scaleShowVerticalLines: false,
    scales:{
        x:{
            grid:{
                display: false,
            },
            
        
        },
        y: {
          beginAtZero: true,

      }
    },
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },

      title: {
        display: false,
      
      },
    },
  };
export function convertDate(date){
    // convert date to ru like 13 авг. 23
    const temp = new Date(date);
    const day = temp.getDate();
    const month = temp.getMonth();
    const year = temp.getFullYear();
    const months = ['янв.','фев.','мар.','апр.','мая.','июн.','июл.','авг.','сен.','окт.','ноя.','дек.']
    return `${day} ${months[month]}`

  }

export function IncomeGraph({data}){
    

    
    
    return(
        <>
        {data && <Line  style={{width: '99%'}}  options={options} data={data}/>}
        </>
    )
}