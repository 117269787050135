import React, { useEffect, useRef, useState } from "react";
import './chat.css'
import { MDBBadge, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import * as signalR from "@microsoft/signalr";
import authService from "../../../api-authorization/AuthorizeService";
import { ChatGlobal } from './../../../Global/Chat/ChatGlobal';
import { useDispatch, useSelector } from "react-redux";
import { fetchChatsClient, fetchDialogClient, sendMessageClient, sendMessageReadEventClient, sendWritingEventClient, setSetlectedClientChat } from "components/Global/Chat/Client/ClientChatStateSlice";
import { sendMessageReadEventStore } from "components/Global/Chat/MyStore/MyStoreChatStateSlice";


export function ClientChat(){
  const selectedChat = useSelector(state => state.store.chat.selectedChat)
  const chats = useSelector(state => {
    return state.store.chat.chats
  })
  useEffect(()=>{
    console.log('selChat: ' + selectedChat)
  },[selectedChat])
  const dispatch = useDispatch();
  
  useEffect(()=>{
    
    dispatch(fetchChatsClient())
    
  },[])

  async function sendMessage(text, storeId){
    if(text.trim() === '') {
      return;
    }
    dispatch(sendMessageClient({to: storeId, text: text}))
  }
  
  async function fetchChat(chatId, fromCache = false){
    dispatch(fetchDialogClient({id: chatId}))
  }
  let isWritingTimeout;
  function isWriting(to){
    
    if(isWritingTimeout + 3000 > Date.now()) return;
    isWritingTimeout = Date.now();
    console.log('user writing-')
    // @ts-ignore
    dispatch(sendWritingEventClient({to}))
    
  }
  

  return(
    <>
      <ChatGlobal onMessageRead={(id, chatId) => dispatch(sendMessageReadEventClient({id, chatId}))} type={'User'} fetchChat={fetchChat} chats={chats} isWriting={isWriting} sendMessage={sendMessage} selectedChat={selectedChat} setSelectedChat={(id) => dispatch(setSetlectedClientChat({id: id}))}/>
    </>
  )
  
  }