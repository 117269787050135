import React, { useState } from 'react';
import './mycart.css'; // Import CSS for styling
import { MDBModal, MDBModalContent, MDBModalDialog } from 'mdb-react-ui-kit';

export const ImageGallery = ({ images, store }) => {
  const [zoomedImage, setZoomedImage] = useState(null);

  const openZoomedImage = (imageSrc) => {
    setZoomedImage(imageSrc);
  };

  const closeZoomedImage = () => {
    setZoomedImage(null);
  };

  return (
    <>
      {images.length > 0 && <button className='btn btn-info' onClick={()=> setZoomedImage(images[0])}>Посмотреть самовывоз</button>}
      
      <MDBModal   show={zoomedImage} onHide={()=> setZoomedImage(false)}>
        <MDBModalDialog size='xl'>
            <MDBModalContent>
            <img src={`/photos/pickup/${zoomedImage}.jpg`} alt="Zoomed" />
        <div className='mt-1'>
            {images.map((image) => (
        <img
          key={image}
          height='90px'
          className='p-2 imageInGallery'
          style={{cursor: 'pointer'}}
          src={`/photos/pickup/${image}.jpg`}
          alt="Фото самовывоза"
          onClick={() => openZoomedImage(image)}
        />
      ))}
        </div>
          <div className='p-3'>
            <h5>Самовывоз</h5>
            <p>{store.selfPickup}</p>
          </div>

            </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </>
  );
};