import React, { useEffect, useState } from "react";

import { MDBBadge, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { NavLink, Navigate, useNavigate, useParams } from "react-router-dom";
import authService from "@auth/AuthorizeService";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserOrderById } from "./UserOrdersPageStateSlice";
import Skeleton from "react-loading-skeleton";
import { useTitle } from "hooks/useTitle";



export function OrderPage(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();
    const order = useSelector(state => state.store.orders.currentOrder)
    const isLoading = useSelector(state => state.store.orders.isLoadingPage)
    
    useEffect(()=>{
        dispatch(fetchUserOrderById({id}))
        
    }, [id])
    
    async function goBack(){
        navigate('/profileorders')
    }
    async function openChat(){
        navigate(`/chat?id=${order?.store.id}`)
    }
    useTitle('Заказы')
    return(
        <>
        <div className="d-flex justify-content-between jusitfy-content-center flex-wrap">
        <NavLink to={'/profile/orders/'} className="btn btn-info mb-3" ><MDBIcon color="white" fas icon="arrow-left" /></NavLink>
        <div className="d-flex justify-content-between align-items-center mb-3">
        <button className="btn btn-secondary me-3" onClick={e => {openChat()}}>Открыть чат<MDBIcon className="ms-2" fas icon="comment-alt" /></button>
        {order?.statusCode < 4 && <button className="btn btn-info me-2 ">Информация о самовывозе</button>}
        {order?.status && order?.statusCode < 4 && <OrderCancelModal order={order}/>}
        {order?.status === 'Завершён' && <button className="btn btn-warning">Оцените заказ!</button>}
        </div>
        
        </div>
            
            <MDBCard>
                <MDBCardHeader>
                    <div className="d-flex align-items-center">
                        <MDBCardTitle className="flex-grow-1">{order?.store?.name || <Skeleton width={150}/>}</MDBCardTitle>
                        {!isLoading ? <MDBBadge style={{height: '20px'}}>{order?.status}</MDBBadge> : <Skeleton width={100}/>}
                    </div>
                    <div className="d-flex">
                        <span className="flex-grow-1">{order?.date || <Skeleton width={130}/>}</span>
                        {!isLoading ? <MDBBadge style={{height: '20px'}}>{order?.sum} руб.</MDBBadge> : <Skeleton width={70}/>}
                    </div>
                    
                    
                </MDBCardHeader>
                <MDBCardBody>
                    {isLoading && <><Skeleton width={100}/> <Skeleton className="mt-2" width="100%" height={300}/></> }
                    {order?.ranges?.map((range, index)=>{
                        return(
                            <>
                                <p className={`${index == 0 ? "mt-0" : "mt-4"} mb-0`}>{range.name}</p>
                                <MDBListGroup>
                                    
                                        {range?.flavours?.map((flavour)=>{
                                            return(
                                                <>
                                                    <MDBListGroupItem color="dark" className="d-flex">
                                                        
                                                        <p style={{fontSize: '1.1rem', margin: 'auto'}} className="flex-grow-1">{flavour.flavourName}</p>
                                                        <MDBRow className="">
                                                            <MDBCol>
                                                            <MDBBadge>
                                                            {flavour.amount} шт.
                                                        </MDBBadge>
                                                            </MDBCol>
                                                            <MDBCol>
                                                            <MDBBadge className="">
                                                            {flavour.sum} руб.
                                                        </MDBBadge>
                                                            </MDBCol>
                                                       
                                                        
                                                        </MDBRow>
                                                        
                                                    </MDBListGroupItem>
                                                </>
                                            )
                                        })}
                                </MDBListGroup>
                            </>
                        )
                    })}
                </MDBCardBody>
            </MDBCard>
        </>
    )
}

function OrderCancelModal({order}){
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch()

    async function cancelOrder(){
        if(order.status === 'Отменён' || order.status === 'Перенан курьеру'){
            toast.error("Заказ не может быть отменён. Напишите в магазин", {autoClose: 0})
            return;
        }
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/orders/${order?.id}/Cancel`,{
            headers: {
                "Authorization" : `Bearer ${token}`
            },
            method: "PATCH"
        })
        if(res.ok){
            setModal(false)
            dispatch(fetchUserOrderById({id: order?.id}))
        } else {
            // error
            toast.error("Ошибка отмены заказа. Напишите в магазин")
        }
    }
    return <>
        <button className="btn btn-danger" onClick={e => {setModal(true)}}>Отменить заказ</button>
        <MDBModal setShow={setModal} show={modal}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Вы точно хотите отменить заказ?</MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalFooter className="d-flex justify-content-between">
                        <button className="btn btn-primary" onClick={()=> setModal(false)}>Закрыть</button>
                        <button className="btn btn-danger" onClick={cancelOrder}>Отменить заказ</button>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </>


}