import { MDBCard, MDBRow, MDBCol, MDBCardImage, MDBCardBody, MDBCardTitle, MDBIcon, MDBCardText, MDBListGroup, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBContainer, MDBInput, MDBListGroupItem, MDBTable, MDBTableHead, MDBTableBody, MDBPopover, MDBModalTitle } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import authService from "@auth/AuthorizeService";
import './Flavours.css'
import { useDispatch, useSelector } from "react-redux";
import { changePrices } from "./state/WarehouseTalbeSlice";
import TakeoutModalContext from "./Modals/TakeoutModalContext";
import BigTakeoutModalContext from "./Modals/Takeout/BigTakoutModalContext";


export function RangeItemW({range}){
    const { showTakeoutModal } = useContext(TakeoutModalContext);
    const { showBigTakeoutModal } = useContext(BigTakeoutModalContext)
    const whid = useSelector(state => state.mystore.warehouseTable.curWarehouseId)
    function AddPricesToRange({oldPrices, setNewPrices}){

        const dispatch = useDispatch();
        const [modal, setModal] = useState(false);
        //...range?.flavours[0]?.prices
        
        const [prices, setPrices] = useState([]);
        function deletePrices(index){
            if(prices.length == 1) return
            console.log(index)
            setPrices((prevArray => prevArray.filter((_, i) => i !== index)))
           }
           function addPrices(e){
            if(prices.length >=8) return;
            if(prices.length == 0){
                setPrices([{from: 1, price: 10}])
                return
            }
            
            setPrices((prev) => [...prev, {from: prev[prev.length-1].from + 10, price: 1}])
           }
           function changeFrom(index, value){
    
            
            setPrices(prevArray => {
              const newArray = prevArray.map((item, i) => {
                if (i === index) {
                  return { ...item, from: value };
                }
                return item;
              });
              return newArray;
            });
           }
           function changePrice(index, value){
            
            setPrices(prevArray => {
              const newArray = prevArray.map((item, i) => {
                if (i === index) {
                  return { ...item, price: value };
                }
                return item;
              });
              return newArray;
            });
           }

           async function SavePricing(){
            
            const rangeId = range.id;
            const json = {
                rangeId,
                prices
            }
            const token = await authService.getAccessToken();
            const res = await fetch('/api/mystore/AddPrice', {
                method: 'PUT',
                headers:{
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(json)
            });
            if(res.ok){
                toast.success('Успешно')
                dispatch(changePrices({rangeId, prices}))
                setNewPrices(prices.sort((a,b)=> a.from - b.from))
                setModal(false)
            } else{
                toast.error('Ошибка')
            }

       }
       useEffect(()=>{
        setPrices(oldPrices)
       }, [oldPrices])

        return(
            <>
                <button  className="btn btn-primary" onClick={()=> setModal(true)}>Установить цены</button>
                {modal && 
                    <MDBModal show={modal} setShow={setModal}>
                        <MDBModalDialog>
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>
                                    Установка цен на всю линейку
                                    </MDBModalTitle>
                                    
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <p className="mb-3">Тут ты можешь установить цены сразу на всю линейку. Также ты можешь отдельно редактировать цену.</p>
                                    {/* <p>Обрати внимание! Если цены установлены, то они будут перезаписаны!</p> */}
                                    {/* <MDBContainer className="mt-3"> */}
                                        <MDBRow className="gy-3">
                                            {prices?.map((it, index)=>{
                                            return (
                                                <>
                                                <MDBCol size={12}>
                                                    <MDBRow>
                                                        <MDBCol size={3}>
                                                        <MDBInput type='text' required name='from' label='шт.' value={it.from} onChange={(e)=> changeFrom(index, e.target.value)}/>
                                                        </MDBCol>
                                                        <MDBCol size={7}>
                                                        <MDBInput type='text' required name='price' label='Цена' value={it.price} onChange={(e)=> changePrice(index, e.target.value)}/>
                                                        
                                                        </MDBCol>
                                                        <MDBCol size={2}><button className='btn btn-danger' onClick={() => deletePrices(index)}><i className="fas fa-minus"></i></button></MDBCol>
                                                    </MDBRow>
                                                </MDBCol> 
                                                </>
                                            );
                                            })}
                                        </MDBRow>
                                        <div className="d-flex justify-content-between mt-2">
                                            
                                            <button className="btn btn-success" onClick={() => SavePricing()}>Сохранить</button>
                                            
                                           
                                                {prices?.length <=7 && <button className="btn btn-primary" onClick={addPrices}><i className="fas fa-plus"></i></button>}
                                            
                                        </div>
                                        
                                        
                                    {/* </MDBContainer> */}
                                </MDBModalBody>
                                <MDBModalFooter>
                                    <button className="btn btn-danger" onClick={()=> setModal(false)}>Закрыть</button>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                }
            </>
        );
    }
    
    function renderFlavours(){
        if(range.flavours.length == 0){
            return <span>Вкусы ещё не добавлены</span>
        }
        
        return(
            <>
               <div className="d-flex flex-column w-100 ms-4" style={{maxWidth: '500px'}}>
                
                
                <MDBRow between className="">
                    <MDBCol size={7} className="ps-0"><h5>Название</h5></MDBCol>
                    <MDBCol size={2} className="text-center"><h5>Всего</h5></MDBCol>
                    <MDBCol size={3} className="text-center"><h5>В заказах</h5></MDBCol>
                </MDBRow>
                {range.flavours?.map((it, index)=>{
                    return (
                        <>
                            <MDBRow className={`${index != 0 ? 'mt-2' : ''}`}>
                                
                                <MDBCol className="fl-name" size={7} style={{cursor: 'pointer'}} onClick={()=>{showTakeoutModal(it, whid)}}><span className="ms-1 me-1">{it.name}</span></MDBCol>
                                <MDBCol size={2} className="text-center"><span className={`fl-amount ${it.total <=0 ? 'fl-amount-low' : ''}`}>{it.total} шт.</span></MDBCol>
                                <MDBCol size={3} className="text-center"><span className="fl-amount">{it.inOrders} шт.</span></MDBCol>
                                
                            </MDBRow>
                            {/* <hr/> */}
                        </>
                    );
                })}
                
               
                
                
                
                </div> 
            </>
        )
        
    }
    return(
        <>
            <MDBCard  className="">
                <MDBCardBody className="d-flex flex-wrap justify-content-between">
                        <div className="mb-2">
                            <div className="d-flex flex-row mb-1">
                                <h5>{range.name}</h5>
                                
                            </div>
                        
                            <MDBCardImage style={{borderRadius: '10px', width: '250px', height: '250px'}}  src={`/photos/ranges/${range.id}.jpg/0`}/>
                        </div>
                        
                        
                        <div className="d-flex flex-column">
                        <button className="btn btn-danger mb-2" onClick={()=>showBigTakeoutModal(range, whid)}>Выгрузить & Переместить</button>
                        
                        <AddPricesToRange oldPrices={range?.prices} setNewPrices={(it)=>{}}/>
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th>От</th>
                                        <th>Цена</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                {range?.prices && range.prices.map((it, index)=>{
                                return (
                                    <>
                                        <tr>
                                            <td>{it.from} шт.</td>
                                            <td>{it.price} руб.</td>
                                        </tr>
                                    </>
                                    
                                );
                            })}
                                </MDBTableBody>
                            </MDBTable>
                        
                            
                        </div>
                        
                    
                
                {renderFlavours()}
                </MDBCardBody>
            </MDBCard>
            
        </>
    );
}