import authService from "@auth/AuthorizeService";
import { MDBCard, MDBCardBody, MDBBadge } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";


export function LogsPage({item}){
    const [logs, setLogs] = useState([])
    async function fetchLogs(){
      const token = await authService.getAccessToken();
      
      const res = await fetch(`/api/warehouse/Log?flavourId=${item.range.id}&whid=${item.whid}`,{
        headers: {
          "Authorization" : `Bearer ${token}`
        },
        method: 'GET'
      })

      if(res.ok){
        const data = await res.json()
        setLogs(data)
      }
    }
    useEffect(()=>{
      fetchLogs()
      
    },[item])

    return <>
        {logs && logs.map((it)=>{
                        return(
                          <>
                            <MDBCard className='mb-2'>
                              <MDBCardBody className='d-flex flex-column justify-content-between'>
                                <div className='d-flex justify-content-between'>
                                  <p className='self-align-center'>Тип: <MDBBadge className='align-center' color={it.type == 'Загрузка' ? "success" : 'danger'}>{it.type}</MDBBadge></p>
                                  <p>{it.date}</p>
                                </div>
                                <div className='d-flex justify-content-between'>
                                  <p>{it.type == 'Выгрузка' ? '- ' : ''}{it.quantity} шт.</p>
                                  {it.purchasePrice && <p>{it.purchasePrice} руб.</p>}
                                  {it.comment && <p>{it.comment}</p>}
                                </div>
                                
                              </MDBCardBody>

                            </MDBCard>
                          </>
                        )
                      })}
    </>
}