import React, { createContext, useState } from 'react';
import Modal from './EditModal';

const EditRefModalContext = createContext(null);

export const EditModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [type, setType] = useState(null);
  const [id, setid] = useState(null);

  const showEditModal = (manager,type) => {
    setid(manager);
    setType(type);
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setid(null);
    setType(null);
  };

  return (
    <EditRefModalContext.Provider value={{ showEditModal, hideModal }}>
      {children}
      {<Modal show={modal} manager={id} type={type} setShow={hideModal}></Modal>}
    </EditRefModalContext.Provider>
  );
};

export default EditRefModalContext;