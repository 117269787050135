


export function getOrderColorByType(type){
    switch(type){
        case 1:
            return "success"
        case 2:
            return "info"
        case 3:
            return "warning"
        default:
            return "light"
    }
}
export function getOrderTypeStringByValue(type){
    switch(type){
        case 1:
            return "Самовывоз"
        case 2:
            return "Почта"
        case 3:
            return "Курьер"
        default:
            return ")"
    }
}