// Modal.js
import { MDBBtn, MDBCol, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from '@auth/AuthorizeService';
import { toast } from 'react-toastify';
import { FireWorkerModal } from './FireWorkerModal';

const EditModal = ({ manager, type, show, setShow }) => {

    const [workerFromServer, setWorkerFromServer] = useState(null)

    async function fetchWorker(){
        if(!manager) return
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/mystore/workers/${type}Fees?id=${manager?.id}`,{
            headers:{
                "Authorization": `Bearer ${token}`
            },
            method: 'GET'
        })
        if(res.ok){
            const data = await res.json();
            if(data.paymentType != 0){
                data.value = data.fees
                delete data.fees;
            }
            setWorkerFromServer(data)
        } else{
            toast.error('Ошибка при загрузке данных')
        }
    }
    useEffect(()=>{
        fetchWorker();
    }, [manager])
    function onInputFee(fee, categoryId, isOpt){
        fee = Number(fee)
        setWorkerFromServer(prev => {
            return prev?.fees?.map((it)=>{
                if(it.categoryId === categoryId && it.isOpt === isOpt){
                    return {...it, fee: Number(fee)}
                }
                return it
            })
        })
    }
    async function saveFees(){
        const token = await authService.getAccessToken();
        console.log(workerFromServer)
        const res = await fetch(`/api/mystore/workers/ChangeFees${type}?id=${manager.id}&value=${workerFromServer?.value}&paymentType=${workerFromServer?.paymentType}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            },
            method: 'PUT',
            body: JSON.stringify(workerFromServer?.fees?.map((it)=> {return {categoryId: it.categoryId, isOpt: it.isOpt, Fee: it.fee}}) ?? [])
        })
        if(res.ok){
            setShow()
            toast.success('Успешно')
        } else{
            toast.error('Ошибка применения')
        }
    }
    async function fetchCouriersPerItemFees(){
        const token = await authService.getAccessToken();
        const res = await fetch('/api/mystore/workers/PerItemListCategories',{
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        if(res.ok){
            const data = await res.json()
            setWorkerFromServer(prev => {
                return {
                    paymentType: 0,
                    fees: data
                }
            })
        } else{
            toast.error('Ошибка загрузки данных с сервера')
        }
    }
    function handleCourierPaymentTypeChange(paymentType){
        console.log(paymentType, 'ti[p')
        if(paymentType !== 0){
            setWorkerFromServer(prev => {return {
                value: 2,paymentType}})
        } else {
            fetchCouriersPerItemFees()
        }
    }
    function handlePaymentAmountInterestChange(val){
        setWorkerFromServer(prev => {return {
            ...prev,value: val}})
    }
    function RenderPayments(){
        switch(workerFromServer.paymentType){
            case 0:
                return <>
                <MDBRow>
                    {workerFromServer?.fees.map((it, index)=>{
                        return(
                            <>
                                <MDBCol size={12} sm={6}>
                                    <p className='mb-0'>{it.categoryName} {it.isOpt ? '(Опт)' : ''}</p>
                                    <MDBInputGroup className='mb-3' textAfter='руб/шт.'>
                                        <input className='form-control' type='number' step={0.1} value={it.fee} onChange={(e) => onInputFee(Number(e.currentTarget.value), it.categoryId, it.isOpt)}/>
                                    </MDBInputGroup>
                                    {/* <MDBInput type='number' step={0.1} value={it.fee} onChange={(e) => onInputFee(Number(e.currentTarget.value), it.categoryId, it.isOpt)}/> руб. */}
                                </MDBCol>
                            </>
                        )
                    })}
                </MDBRow>
                </>
            case 1:
                return <>
                <MDBInputGroup className='' textAfter='руб/заказ'>
                    <input className='form-control' value={isNaN(workerFromServer?.value) ? 3.5 : workerFromServer?.value} onChange={(e)=> {handlePaymentAmountInterestChange(e.currentTarget.value)}} name='value' type='number' max={100} min={0} step={0.1}/>
                </MDBInputGroup>
                </>
            case 2:
                return <>
                <MDBInputGroup className='' textAfter='%'>
                    <input className='form-control' value={isNaN(workerFromServer?.value) ? 2 : workerFromServer?.value} onChange={(e)=> {handlePaymentAmountInterestChange(e.currentTarget.value)}} name='value' type='number' max={100} min={0} step={0.1}/>
                </MDBInputGroup>
                </>
            default: return <>Непонятно</>
        }
    }
  return (
    <MDBModal show={show} onHide={()=> setShow()} >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Оплата за еденицу для {manager?.username}</MDBModalTitle>
              <button className='btn btn-close' color='none' onClick={()=>setShow()}></button>
            </MDBModalHeader>
            <MDBModalBody>
                <p style={{fontWeight: '600'}}>Тип оплаты сотруднику</p>
                <label className="select" htmlFor="slctType">
                    <select id='slctType' className='mb-2' onChange={(e)=> handleCourierPaymentTypeChange(e.currentTarget.selectedIndex)}>
                        <option value={0} selected={workerFromServer?.paymentType == 0}>За еденицу товара</option>
                        <option value={2} selected={workerFromServer?.paymentType == 1}>Фиксированная ставка за заказ</option>
                        <option value={1} selected={workerFromServer?.paymentType == 2}>Процент от суммы заказа</option>
                        
                    </select>
                </label>
                {workerFromServer && <RenderPayments/>}
                
                <FireWorkerModal worker={manager} setShowParent={setShow} type={type}/>
                
            </MDBModalBody>

            <MDBModalFooter className='d-flex justify-content-between'>
              <button type='button' className='btn btn-danger' onClick={() => setShow()}>
                Закрыть
              </button>
              <button className='btn btn-success' onClick={() => saveFees()}>
                Сохранить
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
  );
};

export default EditModal;