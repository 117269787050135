import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useContext } from "react";
import EditRefModalContext from "../ReferralPage/EditModal/EditModalContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";




export function CourierItem({manager}){
  const navigate = useNavigate()
  const {showEditModal} = useContext(EditRefModalContext);
    return(
        <>
          
          <MDBCard style={{ maxWidth: '540px', borderRadius: '10px' }}  className='mt-2'> 
                          <MDBRow className='g-0'>
                            <MDBCol sm='3' className="p-1">
                              <MDBCardImage src='/img/avaDefault.png' alt='Карточка товара' fluid />
                            </MDBCol>
                            <MDBCol sm='9'>
                              <MDBCardBody  className="h-100 d-flex flex-column justify-content-between">
                                <div className="d-flex justify-content-between">
                                  <MDBCardTitle>{manager.username}</MDBCardTitle>
                                  <p style={{color: '#8babe0', cursor: 'pointer'}} onClick={()=>{}}>Активные заказы: {manager.activeOrders}<MDBIcon className="ms-2" fas icon="truck" /></p>
                                </div>
                                
                                
                                <div className="d-flex justify-content-between align-items-end">
                                  <div>
                                    <p className="text-muted">Добавлен</p>
                                    <p>{manager.createdAt}</p>
                                  </div>
                                  
                                  <button className="btn btn-success" onClick={()=> {showEditModal(manager, 'Courier')}}>Ред.</button>
                                <button className="btn btn-info" onClick={()=>{
                                  navigate(`${manager.id}`)
                                }}>Инфо</button>
                                
                                </div>
                              </MDBCardBody>
                            </MDBCol>
                          </MDBRow>
                        </MDBCard>
        </>
    );
}