import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";
import { Exception } from "sass";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// {
//     "id": "465b36a5-462d-49e2-8a88-c724e72aa9af",
//     "chatName": "Облака Минск",
//     "to": "611b2e5c-62f0-41bb-bad0-b90c6d7584cf",
//     "lastMessage": "",
//     "unreadCount": 0,
//     "isWriting": false,
//     "messages": []
// },

// message:
// [
//     {
//         "id": "f2d5055a-307b-419f-8491-6d0a2e84cb10",
//         "text": "Привет!\nЯ хочу заказать:\nhuskyОблака МинскЯгодный микс",
//         "isFromStore": false,
//         "date": "14:32 | июл. 31"
//     },
// ]
const initialState={
    info: null,
    isLoadingStore: false,
    isLoadingTable: true,
    currentCategory: null,
    currentLocation: null,
    categories: [],
    page: 0,
    isLastPage: false,
    searchText: null
    
}
const StorePageStateSlice = createSlice({
    name: "storePage",
    initialState,
    reducers:{
        setCurrentCategoryShopPage: (state, action)=>{
            if(state.currentCategory === action.payload.id){
                return state
            }
            state.currentCategory = action.payload.id;
            state.page = 0;
            state.isLastPage = false

        },
        setPageShopPage: (state, action)=>{
           
                const category = state.categories.find(x => x.id == state.currentCategory)
                if(!category.isLastPage){
                    category.page = (category.page ?? 0) + 1
                }
                

                //state.page = state.page +1;
            
            
        },
        setSearchTextShopPage: (state, action)=>{
            state.searchText = action.payload.searchText;
            state.page = 0;
            state.categories.forEach(it => {
                it.brands = []
            })
        },
        clearShopPage: (state, action)=>{
            return initialState
        },
        setCurrentLocationsSP: (state, action) => {
            state.currentLocation = action.payload.id
            
        }
        
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchStorePage.pending, (state, action) => {
            state.isLoadingStore = true;
        });
        builder.addCase(fetchStorePage.fulfilled, (state, action) => {
            const { info } = action.payload;
            state.isLoadingStore = false;
            state.info = info.store;
            info.categories.forEach(it => {it.page = 0; it.isLastPage = false})
            state.categories = info.categories;
            state.currentCategory = info.categories[0]?.id
            state.currentLocation = info.store.pickupLocations[0]?.id
        });
        builder.addCase(fetchStorePage.rejected, (state, action) => {
            state.isLoadingStore = false;
        });

        builder.addCase(switchFavouriteStorePage.fulfilled, (state, action) => {
            const { to, id } = action.payload;
            state.info.favourite = to;
        });


        builder.addCase(fetchStorePageTable.pending, (state, action) => {
            state.isLoadingTable = true;
        });
        builder.addCase(fetchStorePageTable.fulfilled, (state, action, ) => {
            const { table, categoryId, page } = action.payload;
            state.isLoadingTable = false;
            
                state.categories.forEach((it)=>{
                    if(it.id == categoryId){
                        if(table.length > 0){
                            if(it.brands?.length >= 0){
                                it.brands = [...it.brands, ...table]
                            } else {
                                it.brands = table
                            }
                        } else {
                            it.isLastPage = true
                        }
                        
                        
                    }
                })
            
        });
        builder.addCase(fetchStorePageTable.rejected, (state, action) => {
            state.isLoadingTable = false;
        });

        builder.addCase(addToCartStorePage.pending, (state, action) => {
            state.isLoadingTable = true;
        });
        builder.addCase(addToCartStorePage.fulfilled, (state, action, ) => {
            const { flavourId } = action.payload;
            let find = false
            state.categories.forEach((category)=>{
                if(find == true){return}
                category.brands.forEach(brand => {
                    if(find == true){return}
                    brand.ranges.forEach(range => {
                        if(find == true){return}
                        range.flavours.forEach(flavour =>{
                            if(flavour.id == flavourId){
                                flavour.inCart = true
                                find = true
                                return
                            }
                        })
                        
                    })
                })
                
            })
        });
        builder.addCase(addToCartStorePage.rejected, (state, action) => {
            state.isLoadingTable = false;
        });

        builder.addCase(removeFromCartStorePage.pending, (state, action) => {
            //state.isLoadingTable = true;
        });
        builder.addCase(removeFromCartStorePage.fulfilled, (state, action, ) => {
            const { flavourId } = action.payload;
            let find = false
            state.categories.forEach((category)=>{
                if(find == true){return}
                category.brands.forEach(brand => {
                    if(find == true){return}
                    brand.ranges.forEach(range => {
                        if(find == true){return}
                        range.flavours.forEach(flavour =>{
                            if(flavour.id == flavourId){
                                flavour.inCart = false
                                find = true
                                return
                            }
                        })
                        
                    })
                })
                
            })
        });
        builder.addCase(removeFromCartStorePage.rejected, (state, action) => {
            //state.isLoadingTable = false;
        });
       
    }
    
    // async thunk fetch chats, fetch dialog, but 

})



export const fetchStorePage = createAsyncThunk('storePage/fetchStoreInfo', async ({id, ref}, {dispatch})=>{
    const token = await authService.getAccessToken();
    const res = await fetch(`/api/store/${id}?ref=${ref}`,{
        headers: {
            "Authorization":`Bearer ${token}`
        }
    });
    const info = await res.json();
    
    return {info, id}
})

export const switchFavouriteStorePage = createAsyncThunk('storePage/switchFavouriteStorePage', async ({id, to}, {dispatch})=>{
    console.log(to)
    const token = await authService.getAccessToken();
    const res = await fetch(`/api/store/${id}/favourite?to=${to}`,{
        headers: {
            "Authorization":`Bearer ${token}`
        },
        method: 'PUT'
    });
    if(res.ok){
        const data = await res.text();
    
        return {to: to, id}
    }
    
})

export const fetchStorePageTable = createAsyncThunk('storePage/fetchStorePageTable', async ({currentCategory, page, searchText, storeId, currentLocation})=>{
    if(page == undefined)
        throw new Error("");
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/store/GetTable?pageSize=5&storeid=${storeId}&page=${page}&location=${currentLocation}&categoryId=${currentCategory}&search=${searchText ? searchText : ''}`,{
        headers:{
            "Authorization":`Bearer ${token}`
        }
    });
    if(res.ok){
        const data = await res.json();
        return {
            table: data,
            categoryId: currentCategory,
            page
        }
    }
})
export const addToCartStorePage = createAsyncThunk('storePage/addToCartStorePage', async ({flavourId, storeId}, {dispatch})=>{
    const token = await authService.getAccessToken();
        if(!token){
            toast.error('Войдите в аккаунт')
        }
        const res = await fetch(`/api/cart?storeId=${storeId}&flavourId=${flavourId}`,{
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if(res.ok){
            return {
                flavourId
            }
            //toast.success('Добавлено в корзину')
        } else{
            return null
        }
    const data = await res.json();
    
    return {data}
})
export const removeFromCartStorePage = createAsyncThunk('storePage/removeFromCartStorePage', async ({flavourId, storeId}, {dispatch})=>{
    const token = await authService.getAccessToken()
        if(!token){
            return
        }
        const res = await fetch(`/api/cart/CartStorePage?storeId=${storeId}&flavourId=${flavourId}`,{
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if(res.ok){
            return {
                flavourId
            }
            //toast.success('Добавлено в корзину')
        } else{
            return null
        }
    const data = await res.json();
    
    return {data}
})


export const {setCurrentCategoryShopPage, setPageShopPage, setSearchTextShopPage, clearShopPage, setCurrentLocationsSP} = StorePageStateSlice.actions;
export default StorePageStateSlice.reducer;