import { MDBBadge, MDBContainer, MDBNavbar } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { SearchDiv } from "./SearchDiv";
import { SelectUserLocation } from "./SelectUserLocation";
import { NavLink, useLocation } from "react-router-dom";
import useScreenSize from "hooks/ScreenSize/useScreenSize";
import authService from "@auth/AuthorizeService";


export function MobileHeader(){
    const [user, setUser] = useState(authService.getUser())
    const screenSize = useScreenSize()
    const location = useLocation()
    return <>
        <MDBNavbar className="pb-0 pt-3" style={{flex: '0 1 auto'}}>
            <MDBContainer fluid>
                <div className="d-flex justify-content-between align-items-center w-100">
                {screenSize.width >=576 && <NavLink
                                to="/"
                                className='pe-2'
                                
                            >
                                
                                <img
                                className="logoImg"
                                src="/logon.png"
                                height={45}
                                />
                            </NavLink>}
                <SearchDiv/>
                
                </div>
                
            </MDBContainer>
           
        </MDBNavbar>
        <hr color="red" className="w-100 mb-0"/>
        {location.pathname == '/' &&  <MDBNavbar>
            <SelectUserLocation className="ms-2 me-0"/>
            
        </MDBNavbar>}
       
        

        <MDBNavbar fixed="bottom" bgColor="dark" id="bottomBar">
            <MDBContainer fluid>
                <div className="d-flex w-100 justify-content-around">
                <NavbarItem name="Главная" badgeColor="danger" indicator={1} icon={'home'} link="/"/>
                <NavbarItem name="Чат" badgeColor="danger" indicator={1} icon={'comment'} link="/chat"/>
                        <NavbarItem name="Корзина" badgeColor="success" indicator={2} icon={'shopping-cart'} link="/mycart"/>
                        <NavbarItem name="Избранное" badgeColor="success" indicator={11} icon={'heart'} link="/profile/favorites/"/>
                        <NavbarItem icon={'user-alt'} name={user ? user.username?.substring(0,12):"Вход"} link={user? "/profile/":"/auth/login"} indicator={null}/>
                </div>
            </MDBContainer>
        </MDBNavbar>
    </>
}

export function NavbarItem({name,icon, badgeColor = "info", link, indicator, onClick = null}){

   return <NavLink onClick={onClick}
                            to={link}
                            className="me-1  rounded py-1  nav-link d-flex flex-column align-items-center"
                            >
                            <div>
                                <i style={{color: 'gray'}} className={`fas fa-${icon} m-1 me-md-2`} />
                                {indicator > 0 ? <MDBBadge color={badgeColor} notification pill>
                                {indicator}
                                </MDBBadge> : ''}
                            </div>
                            
                            
                            <p style={{fontSize: '13px'}} className="d-md-block mb-0 text-muted">{name}</p>
                            </NavLink>
}