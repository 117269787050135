import authService from "@auth/AuthorizeService";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPickupPointsMyStore, setCityMyStore, setDistrictMyStore, setMetroStationMyStore } from "./MyStoreEditStateSlice";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon } from "mdb-react-ui-kit";



export function EditPickupInfo(){
    const points = useSelector(state => state.mystore.locations.points)
    const availableCities = useSelector(state => state.mystore.locations.cities)
    const dispatch = useDispatch()
    async function fetchPoints(){
        const token = await authService.getAccessToken()
        const res = await fetch('/api/mystore/edit/pickupPoints', {headers:{
            "Authorization" : `Bearer ${token}`
        }})
        console.log(await res.json())
    }
    useEffect(()=>{
        fetchPoints()
        dispatch(fetchPickupPointsMyStore())
    },[])

    return <>
        <h4 className="mt-5">Ваши точки самовывоза</h4>
        {points?.map((it, i)=>{
            return <MDBCard key={i} className="mb-3">
                <MDBCardBody>
                    <MDBCardTitle>{it.name}</MDBCardTitle>
                    <div className="d-flex">
                        <p>Город работы: {it.city ?
                        <span className="fw-bold">{it.city.name}</span> :
                        <span className="text-danger fw-bold">Не указан</span>}</p>
                        
                        <MDBDropdown dropright className='btn-group ms-2'>
                                {/* <button type="button" className={`btn btn-${getBadgeColor(type)}`}>Тип: {getBadgeText(type)}</button> */}
                                <MDBDropdownToggle type="button" className={`btn-sm`}><MDBIcon fas icon="pen" /></MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {availableCities.map((city, i)=> <MDBDropdownItem type="button" link onClick={()=> {dispatch(setCityMyStore({cityId: city.id, warehouseId: it.warehouseId}))}} childTag='button'>
                                    {city.name}
                                    </MDBDropdownItem>)}
                                    
                                    
                                </MDBDropdownMenu>
                            </MDBDropdown>
                    </div>
                    {it.city && <>
                        <div>
                        <p>Районы работы</p>
                        {it.districts?.map((district, key)=> <>
                            <MDBCheckbox checked={district.checked} onChange={(e)=> dispatch(setDistrictMyStore({districtId:district.id, warehouseId: it.warehouseId, to: e.currentTarget.checked}))}  label={district.name}/>
                        </>)}
                        </div>
                        {it.metro.length ? <>
                            <div>
                            <p>Станции метро работы</p>
                            {it.metro?.map((line, key)=> <>
                            <h5>{line.name}</h5>
                            {line.stations.map((station, i)=><>
                                <MDBCheckbox checked={station.checked} label={station.name} onChange={(e)=> dispatch(setMetroStationMyStore({stationId:station.id, warehouseId: it.warehouseId, to: e.currentTarget.checked}))}/>
                            </>)}
                        </>)}
                        </div>
                        </> : <></>}
                        
                    
                    </>}
                    
                </MDBCardBody>
            </MDBCard>
        })}
    </>
}