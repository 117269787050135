import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk, current } = require("@reduxjs/toolkit");

// {
//     "id": "465b36a5-462d-49e2-8a88-c724e72aa9af",
//     "chatName": "Облака Минск",
//     "to": "611b2e5c-62f0-41bb-bad0-b90c6d7584cf",
//     "lastMessage": "",
//     "unreadCount": 0,
//     "isWriting": false,
//     "messages": []
// },

// message:
// [
//     {
//         "id": "f2d5055a-307b-419f-8491-6d0a2e84cb10",
//         "text": "Привет!\nЯ хочу заказать:\nhuskyОблака МинскЯгодный микс",
//         "isFromStore": false,
//         "date": "14:32 | июл. 31"
//     },
// ]
function setLocationToLocalStorage(state){
    state = current(state)
    const curCity = state.cities.find(x => x.selected)

    console.log(curCity, state)
    const locObj = {
        cityId: curCity.id,
        cityName: curCity.name,
        districts: state.districts?.districts.filter(x => x.checked).map(x => x.id),
        metro: state.districts?.metro.map(x => {
            return {
                id: x.id,
                stations: x.metroStations.filter(x => x.checked).map(st => st.id)
            }
        }).filter(x => x.stations?.length > 0)
    }
    
    const distrctsAll = state.districts?.districts?.every(x => x.checked)
    const metroAll = state.districts?.metro?.every(x => x.metroStations.every(st => st.checked))
    if(metroAll && distrctsAll){
        delete locObj.districts
        delete locObj.metro
        console.log("Весь город выбран")
    }
    localStorage.setItem("selectedLocation", JSON.stringify(locObj))
    
    
}
const SelectUserLocationStateSlice = createSlice({
    name: "selectUserLocation",
    initialState:{
        cities: [],
        isLoadingCities: false,
        districts: null
        
    },
    reducers:{
        
        selectCityUser: (state, action)=>{
            
            const {city} = action.payload;
            if(city == -1){
                console.log('Выбрана вся беларусь')
                //setLocationToLocalStorage(0)
                state.districts = []
                return
            }
            const foundCity = state.cities.find(x => x.id == city.id)
            foundCity.selected = true
            
            
            setLocationToLocalStorage(state)
        },
        selectDistrictUser: (state, action)=>{
            const {districtId, to} = action.payload;
            
            const dist = state.districts.districts.find(x => x.id == districtId)
            dist.checked = to
            setLocationToLocalStorage(state)
        },
        selectMetroLineUser: (state, action) => {
            const {metroLineId} = action.payload;
            let to = true;
            const lineS = state.districts?.metro.find(x => x.id == metroLineId)
            if(lineS){
                if(lineS.metroStations?.some(x => x.checked)){
                    to = false
                }
                lineS.metroStations?.forEach(station => {
                    station.checked = to
                })
            }
            setLocationToLocalStorage(state)
           
        },
        selectMetroUser: (state, action)=>{
            const {stationId, lineId, to} = action.payload;
            const lineS = state.districts?.metro?.find(x => x.id == lineId)
            if(lineS){
                const st = lineS?.metroStations?.find(x => x.id == stationId)
                st.checked = to;
            }
            setLocationToLocalStorage(state)
        },
        selectAllUser: (state, action)=>{
            const {to} = action.payload
            console.log(to)
            state.districts?.districts?.forEach(x => {
                x.checked = to
                
            })
            state.districts?.metro?.forEach(x => {
                
                x.metroStations?.forEach(st => {
                    st.checked = to
                })
            })
            setLocationToLocalStorage(state)
        }
        
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchCitiesUser.pending, (state, action) => {
            state.isLoadingCities = true;
        });
        builder.addCase(fetchCitiesUser.fulfilled, (state, action) => {
            const loc = JSON.parse(localStorage.getItem('selectedLocation'))
            
            state.isLoadingCities = false;
            
            const merged = action.payload.map(city => {
                return {
                    ...city,
                    selected: loc?.cityId == city.id
                }
            })
            state.cities = merged;
            
        });
        builder.addCase(fetchCitiesUser.rejected, (state, action) => {
            state.isLoadingCities = false;
        });

        builder.addCase(fetchDistrictsUser.pending, (state, action) => {
            state.isLoadingCities = true;
        });
        builder.addCase(fetchDistrictsUser.fulfilled, (state, action) => {
            const { data, cityId } = action.payload;
            state.isLoadingCities = false;
            const loc = JSON.parse(localStorage.getItem('selectedLocation'))
            const isSelectedAll = !(loc.districts?.length > 0) && !(loc.metro?.length > 0)
            console.log('isSelectedAll', isSelectedAll)
                if(loc.cityId == cityId){
                    const mergedArray = data.districts.map(district => ({
                        ...district,
                        checked: isSelectedAll || loc?.districts?.includes(district.id)
                    }));
                    data.districts = mergedArray
                    data.metro.forEach(line => {
                        const locLine = loc?.metro?.find(x => x.id == line.id)
                        
                        line.metroStations?.forEach(station => {
                            station.checked = isSelectedAll || locLine?.stations?.some(x => x == station.id)
                        })
                    })
                    
                    state.districts = data
                }
        });
        builder.addCase(fetchDistrictsUser.rejected, (state, action) => {
            state.isLoadingCities = false;
        });


        
       
    }

})

export const fetchCitiesUser = createAsyncThunk('SelectUserLocation/fetchCities', async ()=>{
    const token = await authService.getAccessToken()
    const res = await fetch('/api/store/location/cities',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})

export const fetchDistrictsUser = createAsyncThunk('SelectUserLocation/fetchDistricts', async ({cityId})=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/store/location/cities/${cityId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return {data: await res.json(), cityId}
    }
    throw Error("Ошибка")
})



export const { selectCityUser, selectDistrictUser, selectMetroUser, selectMetroLineUser, selectAllUser } = SelectUserLocationStateSlice.actions;
export default SelectUserLocationStateSlice.reducer;