import authService from "@auth/AuthorizeService";
import React, { useState } from "react";
import { CourierMainView } from "./CourierView/CourierMainView";



export function CourierView(){
    const [data, setData] = useState(null)
    const [graph, setGraph] = useState(null)
    const [orders, setOrders] = useState({
        active: [],
        completed: []
    })
    
    async function fetchOrders(type){
        const token = await authService.getAccessToken()
        const res = await fetch('/api/mystore/...',{
            headers: {
                "Authorization":`Bearer ${token}`
            }
        })
        if(res.ok){

        }
    }
    async function fetchGraph(){

    }
    async function fetchData(){
        
    }

    return(<>
        <CourierMainView data={data} fetchGraph={fetchGraph} fetchOrders={fetchOrders} graph={graph} orders={orders}/>
    </>)
}