import authService from "@auth/AuthorizeService";
import { MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { toast } from "react-toastify";



export function RegisterAdminUser(){
    const [modal, setModal] = useState(false)
    async function registerUser(e){
        e.preventDefault();
        const token = await authService.getAccessToken()
        const fd = new FormData(e.target)
        const res = await fetch('/api/admin/createUser',{
            headers:{
                "Authorization": `Bearer ${token}`
            },
            method: 'PUT',
            body: fd
        })
        if(res.ok){
            toast.success('Создан!')
            setModal(false)
        } else{
            toast.error('Ошибка при создании')
        }
    }

    return(
        <>
        <button className="btn btn-success" onClick={()=> setModal(prev => !prev)}>Регистрация пользователя</button>
        <MDBModal setShow={setModal} show={modal}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>Регистрация</MDBModalHeader>
                    <form onSubmit={registerUser}>
                    <MDBModalBody>
                       
                            <MDBInput name="username" label="username"/>
                            <MDBInput name="passowrd" className="mt-2" label="password"/>
                        
                    </MDBModalBody>
                    <MDBModalFooter>
                    <button className="btn btn-danger">Отмена</button>
                    <button type="submit" className="btn btn-success">Добавить!</button>
                    </MDBModalFooter>
                    </form>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
        </>
    )
}