import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBCardText } from "mdb-react-ui-kit";
import React from "react";

import { RangeItemW } from "./RangeItemW";
import { RangeItem } from "components/admin/PriceList/table/RangeItem";

export function BrandCardW({brand}){

    return(
        <>
        
                    
                    <h3 className="mb-0">{brand.name}</h3>
                    <MDBRow className="gy-3">
                    {brand.ranges.map((it, index)=>{
                        return (
                            <>
                            <MDBCol key={index} size={12}>
                            <RangeItemW range={it} key={index}/>
                            </MDBCol>
                                
                            </>
                        );
                    })}
                    </MDBRow>
                
        </>
    )

}