// Modal.js
import { MDBBtn, MDBCol, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from '@auth/AuthorizeService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addDistrict } from '../LocationsStateStore';

export default function Modal ({ cityId, show, setShow }) {

    const dispatch = useDispatch()
    
    
    async function applyChanges(e){
        e.preventDefault();
        const fd = new FormData(e.target)
        fd.append('cityId', cityId)
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/admin/location/disctricts`,{
            method: 'PUT',
            headers:{
                "Authorization": `Bearer ${token}`
            },
            body: fd
        })
        if(res.ok){
            dispatch(addDistrict({cityId, district: await res.json()}))
          setShow(false)

        } else{
            toast.error('Ошибка!')
        }
    }
    return(<>
        
        <MDBModal show={show} onHide={setShow}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader><MDBModalTitle>Добавить район</MDBModalTitle></MDBModalHeader>
                    <form onSubmit={applyChanges}>
                        <MDBModalBody>
                            <MDBInput label={'Имя'} placeholder="Имя" name="name"></MDBInput>
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button type='button' className="btn btn-danger" onClick={()=> setShow(false)}>Закрыть</button>
                            <button type='submit' className="btn btn-success">Добавить</button>
                        </MDBModalFooter>
                    </form>
                    
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </>)
};

