import { TranscribeToEnglish } from "components/utils/TranslitRu";
import { useTitle } from "hooks/useTitle";
import { MDBCol, MDBRow, MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";


export default function Categories(){

    const [categories, setCategories] = useState(null);
    async function fetchCategories(){

      const res = await fetch('/api/store/categories');
      if(res.ok){
        setCategories(await res.json())
        
      }
    }
    useEffect(()=>{
      fetchCategories();
    }, [])
    
    return(
        <>
               <section>
               
                <div className="">
                <header className="mb-4">
                  <h3>Категории</h3>
                </header>
                <MDBRow className='mb-3 gy-2 text-center'>
                      {!categories && [0,0,0,0,0,0,0].map((it, index)=> <MDBCol key={index} size={6} md={4} xl={3}><Skeleton width='100%' height='30px'/></MDBCol>)}
                        {categories && categories?.map((it, index)=> <CategoryItem item={it} key={index}/>)}
                        
                      
                  </MDBRow>
                </div>
              </section>
 
            </>
    );
}


function CategoryItem({item}){
  return (
    <>
    <MDBCol size={6} md={4} xl={3}>
      <NavLink
          to={`/category/${TranscribeToEnglish(item.name)}/${item?.id}`}
          className="btn btn-warning"
          style={{textDecoration: 'none', color: 'black', fontWeight: 'bold', width: '100%'}}
          
        >
          <span color="white">{item?.name}</span>
        </NavLink>
        </MDBCol>
    </>
    
  );
}