import { MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCitiesUser, fetchDistrictsUser, selectAllUser, selectCityUser, selectDistrictUser, selectMetroLineUser, selectMetroUser } from "./SelectUserLocationStateSlice";
import useScreenSize from "hooks/ScreenSize/useScreenSize";


export function SelectUserLocation({style, className = ""}){
    const [show, setShow] = useState(false)
    const selectedCity = JSON.parse(localStorage.getItem("selectedLocation"))?.cityName ?? 'Вся беларусь'
    return(
        <>
        <div className={`selectUserLocation ${className}`} style={style} onClick={()=>{
            setShow(true)
        }}>
            <div className="d-flex align-items-center justify-content-center">
                            <p style={{fontWeight: 'normal',letterSpacing: '.05rem', fontSize: '20px'}} className="mb-0 me-2">{selectedCity}</p>
                            <MDBIcon fas icon="location-arrow" />
            </div>
        </div>
        <SelectUserLocationModal show={show} setShow={setShow}/>
            
        </>
    )
}

function SelectUserLocationModal({show, setShow}){

    const dispatch = useDispatch()
    const cities = useSelector(state => state.store.locations.cities)
    const districts = useSelector(state => state.store.locations.districts)
    useEffect(()=>{
        if(!show) {
            window.dispatchEvent(
                new CustomEvent("locationChange", {
                    bubbles: true
                })
            )
        }
        dispatch(fetchCitiesUser())
        const loc = JSON.parse(localStorage.getItem("selectedLocation"))
        
        if(loc?.cityId){
            
            dispatch(fetchDistrictsUser({cityId: loc.cityId}))
        }
    }, [show])
    function handleCitySelect(cityId){
        console.log('on lick', cityId)
        
        const city = cities.find(x => x.id == cityId)
        console.log('on lick', cityId, city)
        
        
        if(cityId == -1){
            dispatch(selectCityUser({city: -1}))
            return
        }
        dispatch(selectCityUser({city}))
        dispatch(fetchDistrictsUser({cityId: city.id}))
    }
    const screenSize = useScreenSize()
    let checkedAll = districts?.districts?.every(x => x.checked) && (districts?.metro?.length > 0 ?districts.metro.every(x => x.metroStations.every(x => x.checked)) : true)
    return(
        <>
            <MDBModal  setShow={setShow} onScroll={e => e.preventDefault()} show={show} >
                <MDBModalDialog size="lg">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                Где забирать заказ?
                            </MDBModalTitle>
                            <button className='btn-close' color='none' onClick={()=>setShow(false)}></button>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="d-flex align-items-center">
                                <label className="select" htmlFor="slct">
                                    <select id="slct" onChange={(e)=> {handleCitySelect(e.target.value)}}>
                                        <option value='-1'>Вся беларусь</option>
                                        {cities?.map((it, i) =>{
                                            return <option key={i} selected={it.selected} value={it.id}>{it.name}</option>
                                        })}
                                        
                                    </select>
                                    <svg>
                                        <use xlinkHref="#select-arrow-down"></use>
                                    </svg>
                                </label>
                                
                                <input id="setAll" className="checkmark ms-2" type="checkbox" checked={checkedAll} onClick={(e)=>{dispatch(selectAllUser({to: !checkedAll}))}}/>
                                <label htmlFor="setAll" className="ms-2">Выбрать всё</label>
                            </div>
                            
                            
                            {screenSize.width < 568 ? <MobileView data={districts}/>: <DesktopView data={districts}/>}
                            
                        </MDBModalBody>
                        <MDBModalFooter>

                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <svg className="sprites">
  <symbol id="select-arrow-down" viewBox="0 0 10 6">
    <polyline points="1 1 5 5 9 1"></polyline>
  </symbol>
</svg>
        </>
    )
}
function DesktopView({data}){
    return<>
    <MDBRow className="gx-2 mt-2">
    {data?.districts?.length > 0 && <>
        <MDBCol size={12} md={data?.metro?.length > 0 ? 6 : 12}>
        <h5>Районы</h5>
                            {data.districts?.map((district,i) => <DistrictItem key={i} district={district}/>)}
        </MDBCol></>}
        {
            data?.metro?.length > 0 && <MDBCol size={12} md={6}>
            <h5>Метро</h5>
            {data?.metro?.map((line, i) => <MetroLine key={i} line={line}/>)}
        </MDBCol>
        }
        
    </MDBRow>
        
                            
    </>
}

function MobileView({data}){
    useEffect(()=>{
        setCurrentTab(1)
    }, [data])
    const [currentTab, setCurrentTab] = useState(1)
    return<>
    {data?.metro?.length > 0 && <MDBTabs pills justify>
            <MDBTabsItem>
                <MDBTabsLink onClick={() => {setCurrentTab(1)}} active={currentTab == 1}>
                    Районы
                </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
                <MDBTabsLink onClick={() => {setCurrentTab(2)}} active={currentTab == 2}>
                    Метро
                </MDBTabsLink>
            </MDBTabsItem>
        </MDBTabs>}
        
        <MDBTabsContent>
            <MDBTabsPane show={currentTab === 1}>
                
                {data?.districts?.map((district, i) => <DistrictItem key={i} district={district}/>)}
            </MDBTabsPane>
            <MDBTabsPane show={currentTab === 2}>
                
                {data?.metro?.map((line, i) => <MetroLine key={i} line={line}/>)}
            </MDBTabsPane>
        </MDBTabsContent>
        
        
    </>
}
function DistrictItem({district}){
    const dispath = useDispatch()
    return<>
<div className="d-flex align-items-center mb-2">
                        <input className="checkmark" type="checkbox" checked={district.checked} onClick={(e)=>{dispath(selectDistrictUser({districtId: district.id, to: e.currentTarget.checked}))}}/>
                        <p className="mb-0 ms-2">{district.name}</p>
                    </div>
    </>
}
function MetroLine({line}){

    const dispatch = useDispatch()
    return(
        <>
            <div className="d-flex align-items-center">
                <div>
                

                {/* <input style={{accentColor: 'red', backgroundColor: 'blue', zIndex: 12, fill: 'black'}} className="checkmark" type="checkbox" checked={line.checked} onClick={(e)=>{dispatch(selectMetroLineUser({metroLineId: line.id, to: e.currentTarget.checked}))}}/> */}
                <div onClick={()=> {dispatch(selectMetroLineUser({metroLineId: line.id}))}} style={{width: '30px', height: '30px', backgroundColor: `#${line.color}`, cursor: 'pointer', borderRadius: '8px'}} className="d-flex align-items-center justify-content-center checkmarkLine">
                {line.metroStations.some(x => x.checked) && <MDBIcon className="my-auto" fas icon="check" />}
                </div>
                </div>
                <p style={{fontSize: '25px', fontWeight: '700'}} className="mb-0 ms-2">{line?.name}</p>
            </div>
            
            {line?.metroStations?.map((station, i) => {
                return <div className="d-flex align-items-center" key={i}>
                        <input className="checkmark" checked={station.checked} type="checkbox" onClick={(e) => {dispatch(selectMetroUser({stationId: station.id, lineId: line.id, to: e.currentTarget.checked, lineLength: line.metroStations.length}))}}/>
                        <p className="mb-0 ms-2">{station.name}</p>
                    </div>

            })}
                                
        </>
    )
}