import React, { useEffect, useState } from "react";
import 'react-date-range/dist/styles.css'; // main style file
import './DateRange.css'; // theme css file
//import "rsuite/dist/rsuite.min.css";


import { toast } from "react-toastify";
import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBProgress, MDBProgressBar, MDBRow } from "mdb-react-ui-kit";
import { DateRange } from "react-date-range";
//import { DateRangePicker } from 'rsuite';
import * as locales from 'react-date-range/dist/locale';
import { IncomeGraph, convertDate } from "./IncomeGraph";
import { getData } from "ajv/dist/compile/validate";
import { InfoCard } from "./InfoCard";
import authService from "@auth/AuthorizeService";
import { TopSales } from "../TopSales/TopSales";

  
export function IncomeCharts(){
  function setPeriodToShow(preiod){
    setSelectedPeriod(preiod)
  }
  const [data, setData] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("Today")
  async function GetData(period, CustomPeriod = null){
    if(period === 'Custom' && CustomPeriod == null){
      return
    }
    setIsLoadins(true);
      const token = await authService.getAccessToken();
      const res = await fetch(`/api/mystore/analytics/GetIncomeByPeriod`,{
        headers:{
          "Authorization": `Bearer ${token}`,
          'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          PeriodType: period,
          CustomDatePeriod: CustomPeriod
        })
      })
      if(res.ok){
          // setData
          applyData(await res.json())
          
      } else{
          toast.error('Произошла ошибка')
      }
      setIsLoadins(false);
  }
  function applyData(res){
  
    

      const temp = {
          labels: res.incomeCharts.map((it)=> (it.timeLabel)),
          datasets: [
              {
                fill: true,
                label: '',
                
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
                data: res.incomeCharts.map((it)=> it.income),
                borderColor: 'rgb(75, 192,192)',
                backgroundColor: 'rgba(75, 192,192,0.2)',
              },
            ],
      }
      console.log(temp)
      res.incomeCharts = temp;
      setData(res);
  }
  useEffect(()=>{
    GetData(selectedPeriod);
},[selectedPeriod])
    const [isLoading, setIsLoadins] = useState(false);
    const [rangePicker, setRangePicker] = useState(
      [{
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }]);
    
    function handleDateRangePicker(range){
      console.log(range)
      setRangePicker([range])
    }
    
    const [showCalendar, setShowCalendar] = useState(false);
    if(data === null){
      return <><div className="spinner-border text-primary" role="status"/></>
    }
    
    return(
        <>
        <h3>Доходы</h3>
        <MDBCard>
          <MDBCardBody>
            <div className="wrapper">
              {/* buttons: Сегондя, Вчера, Неделя, Месяц, Квартал, Год, Пероид(каленьдарь) */}
              
              <button data-mdb-ripple-duration="0" className={`btn ${selectedPeriod == 'Today' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Today')}>Сегодня</button>
              <button data-mdb-ripple-duration="0" className={`btn ${selectedPeriod == 'Yesterday' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Yesterday')}>Вчера</button>
              <button data-mdb-ripple-duration="0" className={`btn ${selectedPeriod == 'Week' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Week')}>Неделя</button>
              <button data-mdb-ripple-duration="0" className={`btn ${selectedPeriod == 'Month' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Month')}>Месяц</button>
              <button data-mdb-ripple-duration="0" className={`btn ${selectedPeriod == 'Quarter' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Quarter')}>Квартал</button>
              <button data-mdb-ripple-duration="0" className={`btn ${selectedPeriod == 'Year' ? 'btn-info' : 'btn-primary'}`} onClick={() => setPeriodToShow('Year')}>Год</button>
              <div>
                <button data-mdb-ripple-duration="0" className={`btn me-2 ${selectedPeriod == 'Custom' ? 'btn-info' : 'btn-primary'}`} onClick={()=> setShowCalendar(prev => !prev)}>{(selectedPeriod == 'Custom' && rangePicker[0]) ? `${rangePicker[0].startDate.toLocaleDateString('ru-ru')} - ${rangePicker[0].endDate.toLocaleDateString('ru-ru')}` : 'Период'} <MDBIcon fas icon="calendar" /></button>
                  
                {showCalendar && <div className="float d-flex flex-column" style={{zIndex: 1, position: 'absolute', left: '10px'}}>
                  {/* <DateRangePicker /> */}
                  <DateRange maxDate={new Date()}  editableDateInputs onChange={item => handleDateRangePicker(item.selection)}  locale={locales.ru} ranges={rangePicker}/>
                  <button className="btn btn-primary" onClick={()=>{
                      setSelectedPeriod('Custom')
                      setShowCalendar(false);
                      GetData('Custom', {From: `${rangePicker[0].startDate.toLocaleDateString('ru-ru')}`, To: `${rangePicker[0].endDate.toLocaleDateString('ru-ru')}`})
                  }}>Применить</button>
                  </div>}
              </div>
              {isLoading && 
              <div className="spinner-border text-primary" role="status"/>}
              
            </div>
            
            <div className="mt-4">
              <MDBRow className="gy-3 gx-4">
                <MDBCol size={12} md={4} className="">
                  <InfoCard number={data.income} underText={"Сумма принятых платежей"} previosNumber={data.previousPeriodTrend}/>
                </MDBCol>
                <MDBCol className="" size={12} md={4}>
                <InfoCard number={data.margin} underText={"Чистый доход"} previosNumber={data.marginPreviosPeriodTrend}/>
                
                </MDBCol>
                <MDBCol size={12} md={4}>
                <InfoCard number={`${data.totalOrders} шт.`} underText={"Кол-во заказов"} previosNumber={null}/>
                
                </MDBCol>
              </MDBRow>
              {/* 3 cards */}
              
               
            </div>
            <div className="mt-5">
              <MDBRow>
                <MDBCol xl={8}>
                <IncomeGraph data={data?.incomeCharts}/>
                </MDBCol>
                <MDBCol xl={4}>
                  <MDBRow>
                    <MDBCol size={12}>
                    <MDBCard className="mt-3" style={{backgroundColor: '#3d3d3d',border: '1px solid rgb(59, 113,202)'}}>
                    <MDBCardBody className="pt-3 pb-3">
                      <MDBRow>
                        <MDBCol size={12} md={6} lg={12} className="d-flex" >
                          
                          <b className="align-self-start" style={{flex: '0 0 25%'}}>{data.averageIncome} руб.</b>
                          <span className="align-self-start" style={{fontSize: '14px'}}>Средний чек</span>
                          
                          
                        </MDBCol>
                        <MDBCol size={12} md={6} lg={12} className="d-flex">
                        
                        <b className="align-self-start" style={{flex: '0 0 25%'}}>{data.expensesSum} руб.</b>
                          <span className="align-self-start" style={{fontSize: '14px'}}>Расходы</span>
                          
                        
                      
                        </MDBCol>
                        <hr className="my-2"/>
                        <MDBCol size={12} md={6} lg={12} className="d-flex">
                        
                        <b className="align-self-start" style={{flex: '0 0 25%'}}>{data.previousPeriod.income} руб.</b>
                          <span className="align-self-start" style={{fontSize: '14px'}}>Принято в прошлом периоде<br/>({data.previousPeriod.displayString})</span>
                          
                        
                      
                        </MDBCol>
                        
                      </MDBRow>
                      
                    </MDBCardBody>
                  </MDBCard>
                    </MDBCol>
                    <MDBCol size={12}>
                    <MDBCard className="mt-3" style={{backgroundColor: '#3d3d3d',border: '1px solid rgb(59, 113,202)'}}>
                    <MDBCardBody className="pt-3 pb-3">
                      <p className="mb-0" style={{color: '#F8F1F1'}}>Способ выдачи товара</p>

                      <div className="mt-2 d-flex justify-content-between">
                      <p className="mb-1">Самовывоз</p>
                      <p className="mb-1 pb-0" style={{fontSize: '14px', fontWeight: '200', color: '#F8F1F1'}}>49 %</p>
                      </div>
                      <MDBProgress>
                        <MDBProgressBar width={49} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <div className="mt-3 d-flex justify-content-between">
                      <p className="mb-1">Доставка</p>
                      <p className="mb-1 pb-0" style={{fontSize: '14px', fontWeight: '200', color: '#F8F1F1'}}>51 %</p>
                      </div>
                      <MDBProgress>
                        <MDBProgressBar width={51} valuemin={0} valuemax={100} />
                      </MDBProgress>
                    </MDBCardBody>
                  </MDBCard>
                    
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {/* chart  and rigth способ доставки и средний оборот + прошлый период*/}
              
              <div className="d-flex flex-row">
                  
                  

              </div>
            </div>
          
          </MDBCardBody>
        </MDBCard>
        
        <TopSales period={selectedPeriod}
          CustomPeriod={
            {
              From: rangePicker[0].startDate.toLocaleDateString('ru-ru'),
              To: rangePicker[0].endDate.toLocaleDateString('ru-ru')
            }
          }
        />
        
        </>
    )
}