import { MDBCardText, MDBIcon, MDBInput, MDBInputGroup } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { changeAmount, changeAmountMinus, changeAmountMinusBulk } from "../../state/WarehouseTalbeSlice";
import authService from "@auth/AuthorizeService";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";



export function UnloadPage({item}){
    const [flavours, setFlavours] = useState([]);
    const dispatch = useDispatch();
    const {id} = useParams();
    const whs = useSelector(state => state.mystore.info.warehouses)
    const [comment, setComment] = useState("")
    const [price, setPrice] = useState(0)
    const [toWarehouse, setToWarehouse] = useState(null)

    async function takeout(e){
      e.preventDefault();
      console.log(flavours)
        const bd = {
            warehouseId: item.whid,
            comment: comment,
            items: flavours.filter(x => x.quantity > 0).map(x => {return {flavourId: x.id, quantity: x.quantity}})
        }
        
        
        
        const token = await authService.getAccessToken();
        const res = await fetch('/api/warehouse/Takeout', {
            method: 'PUT',
            headers:{
                'Authorization': `Bearer ${token}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify(bd)
        });
        if(res.ok){
            
            toast.success('Успешно!');
            dispatch(changeAmountMinusBulk({items: bd.items}));
            
            // update table
        } else{
            toast.error('Ошибка!')
        }
    }
    async function quickSell(){
        
        const bd = {
            warehouseId: item.whid,
            comment: comment,
            price: price,
            items: flavours.filter(x => x.quantity > 0).map(x => {return {flavourId: x.id, quantity: x.quantity}})
        }
        const token = await authService.getAccessToken();
        const res = await fetch('/api/warehouse/QuickSell', {
            method: 'POST',
            headers:{
                'Authorization': `Bearer ${token}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify(bd)
        });
        if(res.ok){
            
            toast.success('Успешно!');
            dispatch(changeAmountMinusBulk({items: bd.items}));
            
            // update table
        } else{
            toast.error('Ошибка!')
        }
          
    }
    async function moveToWh(){
        const bd = {
            from: item.whid,
            comment: comment,
            to: toWarehouse,
            items: flavours.filter(x => x.quantity > 0).map(x => {return {flavourId: x.id, quantity: x.quantity}})
        }
        const token = await authService.getAccessToken();
        const res = await fetch('/api/warehouse/Move', {
            method: 'POST',
            headers:{
                'Authorization': `Bearer ${token}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify(bd)
        });
        if(res.ok){
            
           
            toast.success('Успешно!');
            dispatch(changeAmountMinusBulk({items: bd.items}));
            
            // update table
        } else{
            toast.error('Ошибка!')
        }
    }
    useEffect(()=>{
        
        setFlavours(item.range.flavours.map((x) => {console.log(x);return {quantity: 0, id: x.id, name: x.name, max: x.total}}))
    },[])
    function changeAmount(id, value){
        
        setFlavours(prev => prev.map((flavour, i)=>{
            
            if(flavour.id == id){
                return {...flavour, quantity: isNaN(value) && flavour.quantity === undefined ? 1 : value}
            }
            return flavour;
        }))
        
        
      }
    return <>
        
        <>
                                   
                                   <div className="d-flex">
                                       <img src={`/photos/ranges/${item.range.id}.jpg/0`} alt="" style={{width: '140px', height: '140px'}}/>
                                       
                                   </div>
                                       <div className="d-flex flex-column ms-1 mt-2">
                                       {flavours.map((flavour, i)=>{
                                           return(
                                               <>
                                                   {i !=0 && <hr className="mt-1 mb-1"/>}
                                                   <div className="d-flex justify-content-between align-items-center">
                                                       <div className="p-0">
                                                       <MDBCardText color="dark" style={{minWidth: '5rem'}} >{flavour?.name}</MDBCardText>
                                                       </div>
                                                       <div className="d-flex">
                                                               <button className="btn btn-danger px-3" onClick={e => {changeAmount(flavour?.id, Number(flavour?.quantity)-1)}}>
                                                                   <MDBIcon fas icon="minus"/>
                                                               </button>
                                                               
                                                               <input
                                                                   className="mx-2 amountInput"
                                                                   defaultValue={0}
                                                                   value={flavour?.quantity} step={1}
                                                                   
                                                                   style={{maxWidth: '55px',textAlign: 'center', borderRadius: '4px', border: 'none'}}
                                                                   onChange={(e)=>{changeAmount(flavour?.id, e.currentTarget.value)}}
                                                                   type="text"
                                                               />
                                                               <button className="btn btn-success px-3" onClick={e => {changeAmount(flavour?.id, Number(flavour?.quantity)+1)}}><MDBIcon fas icon="plus"/></button>
                                                           
                                                       
                                                       </div>
                                                   </div>
                                                   
                                               
                                               
                                                   
                                               </>
                                           )
                                       })}
                                       </div>
                                       
                               
                       </>
                   
               
       
         
         <MDBInput className='mt-3' label="Комментарий" value={comment} onChange={(e)=> setComment(e.currentTarget.value)} type='text' name='comment' maxLength={120}/>
         <div className='d-flex flex-column'>
           <button className='mt-3 align-end btn btn-danger' onClick={takeout}>Выгрузить</button>
           <div className='d-flex align-items-center mt-3'>
             <button className='lign-end btn btn-success text-nowrap me-3' onClick={quickSell}>Быстрая Продажа</button>
             <MDBInputGroup textAfter="руб.">
               <MDBInput name='price' min={0} step={0.1} defaultValue={4.5} onChange={(e)=> setPrice(e.currentTarget.value)}/>
             </MDBInputGroup>
             
           </div>
           
           <div className='mt-3 d-flex align-items-center justify-content-between'>
             <button className='align-end btn btn-warning me-3' onClick={moveToWh}>Переместить</button>
             <label className="select" htmlFor="slct">
                           <select id="slct" onChange={(e)=> {setToWarehouse(e.target.value)}}>
                               
                               {whs?.map((it, i) =>{
                                   return <option key={i} selected={it.selected} value={it.id}>{it.name}</option>
                               })}
                               
                           </select>
                           <svg>
                               <use xlinkHref="#select-arrow-down"></use>
                           </svg>
                       </label>
           </div>
         </div>
         
      
                <svg className="sprites">
            <symbol id="select-arrow-down" viewBox="0 0 10 6">
            <polyline points="1 1 5 5 9 1"></polyline>
            </symbol>
            </svg>
    </>
}