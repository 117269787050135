import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import React, { useContext, useState } from "react";
import { RangeItem } from "./RangeItem";
import authService from "../../../api-authorization/AuthorizeService";
import { toast } from "react-toastify";
import AddBrandModalContext from "../addBrand/AddBrandRangeModalContext";
import DeleteModalContext from "./DeleteModal/DeleteModalContext";


export function BrandCard({brand, setTable, ref}){
    const {showAddBrandModal} = useContext(AddBrandModalContext);
    const {showDeleteModal} = useContext(DeleteModalContext);
    return(
        <>
            <MDBCard ref={ref}>
                <MDBCardBody className="">
                    <div className="d-flex justify-content-between mb-3 flex-wrap">
                        <MDBCardTitle>Бренд: {brand.name}</MDBCardTitle>
                       <div>
                        <button className="btn btn-primary" onClick={()=> {showDeleteModal(brand, 'brand')}}><MDBIcon  color="white" size="1x" fas icon="pen" /></button>
                        <button className="btn btn-primary ms-2" style={{fontSize: '13px'}} onClick={()=>{showAddBrandModal({type: 'Range', parentId: brand.id})}}>Добавить линейку</button>
                       </div>
                       
                    </div>
                      
                                
                            
                        
                        
                        
                    
                    <MDBCardText>
                    {/* ranges */}
                    <MDBRow className="gy-3 d-flex ">
                    {brand?.ranges?.map((it, index)=>{
                        return (
                            <>
                            <MDBCol size={12} sm={8} md={6} xxl={4}>
                                <RangeItem range={it} key={index} setTable={setTable}/>
                            </MDBCol>
                                
                            </>
                        );
                    })}
                    </MDBRow>
                    </MDBCardText>
                    
                </MDBCardBody>
            </MDBCard>
        </>
    );
}

function AddRange({brandId}){
    const toggleShow = () =>{
        setStaticModal(!staticModal);
    }
    
    const [staticModal, setStaticModal] = useState(false);
    async function submitForm(e){
        // collect form and send to server
        e.preventDefault();
        const fd = new FormData(e.target)
        fd.append('brandId', brandId)
        const token = await authService.getAccessToken();
        const res = await fetch('/api/admin/pricelist/AddRange', {
            method: "PUT",
            headers:{
                'Authorization': `Bearer ${token}`
            },
            body: fd
        });
        if(res.ok){
            setStaticModal(false)
            toast.success('Линейка добавлена')
        } else{
            toast.error(await res.text())
        }

    }
    return (
    <>
        <button className="btn btn-primary mb-2" style={{fontSize: '13px'}} onClick={()=>{setStaticModal(true)}}>Добавить линейку</button>
        
        <MDBModal staticBackdrop tabIndex='-1' show={staticModal}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Добавить линейку</MDBModalTitle>
            </MDBModalHeader>
            <form onSubmit={submitForm}>
            <MDBModalBody>
                <MDBInput name="Name" label='Название' onChange={(e) => {}}/>
                
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn btn-danger" onClick={toggleShow}>
                Отмена
              </button>
              <button className="btn btn-success" type="submit">
                Добавить
              </button>
            </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      
        </MDBModal>
    </>
    );
    return(
        <>
            

        </>
    );
}