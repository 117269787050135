import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTabsItem } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CategoriesSP } from "./CategoriesSP";
import authService from "../../api-authorization/AuthorizeService";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { clearShopPage, fetchStorePage, switchFavouriteStorePage } from "./ShopPageStateSlice";
import { RatingStars, StoreRatingBar } from "../ProductPage/StoresTab";
import { StoreReviewsModalProvider } from "../GeneralModals/StoreReviews/StoreReviewsModalContext";
import { useTitle } from "hooks/useTitle";

export function StorePage(){
    const [searchParams, setSearchParams] = useSearchParams();
    const {id} = useParams()
    const store = useSelector(state => state.store.shopPage.info)
    const dispatch = useDispatch();
    // async function loadShop(){
    //     const res = await fetch(`/api/store?id=${searchParams.get('id')}&ref=${searchParams.get('ref')}`);
    //     setStore(await res.json());
    // }
    useTitle(`${store?.name}`)
    useEffect(()=>{
        if(id != store?.id){
            dispatch(fetchStorePage({id: id, ref: searchParams.get('ref')}));
        }
        return ()=>{
            dispatch(clearShopPage())
        }
    }, [])
    
    // if(!store){
    //     return (
    //         <>
    //             <h4 className="mt-2">Загрузка...</h4>
    //         </>
    //     );
    // };
    
    
    return (
        <>
            
            <MDBCard className="mb-3" >
                <MDBRow>
                    <MDBCol size={4} md={2}>
                        {!store?.id && <Skeleton width='100%' height='130px'/>}
                        <MDBCardImage className="" hidden={!store?.id} fluid src={`/photos/store/${store?.id}.jpg`} alt='...' />
                    </MDBCol>
                    
                    <MDBCol size={8} md={10} >
                    <MDBCardBody style={{paddingLeft: '0', paddingRight: '0'}} >
                        <div className="d-flex flex-wrap align-items-start">
                            
                            <div className="flex-grow-1">
                            <StoreReviewsModalProvider>
                                <StoreRatingBar store={store}/>
                            </StoreReviewsModalProvider>
                            <MDBCardTitle>{store?.name || <Skeleton width='150px'/>}</MDBCardTitle>
                            <p>{store?.description || <Skeleton width='350px'/>}</p>
                            </div>
                        <div className="d-flex flex-column justify-content-end align-items-end me-4">
                        
                       
                        <WriteToStore storeId={store?.id}/>
                        <button onClick={()=> dispatch(switchFavouriteStorePage({to: !store?.favourite, id: store?.id}))} className="btn btn-warning mt-2">{store?.favourite ? <MDBIcon fas icon="heart-broken" /> :<MDBIcon fas icon="heart" />}</button>
                        
                        </div>
                        
                        </div>
                        
                        <MDBCardText>
                        
                        
                        </MDBCardText>
                        
                    </MDBCardBody>
                    </MDBCol>
                    
                    
                    <MDBCol size={12}>
                        <MDBCardBody>
                        <h6>Информация о самовывозе</h6>
                        <p>{store?.selfPickup || <Skeleton width='350px'/>}</p>
                        <h6>Информация о доставке</h6>
                        <p>{store?.delivery || <Skeleton width='350px'/>}</p>
                        </MDBCardBody>
                    </MDBCol>
                </MDBRow>
            </MDBCard>
            <h5>Прайс лист</h5>
            <CategoriesSP store={store}/>
            
        </>
    )
}
function WriteToStore({storeId}){
    
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    async function WriteToStore(e){
        e.preventDefault();
        const message = e.target[0].value;
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/store/message`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({message, storeId: storeId})
        });
        if(res.ok){
            // redirect to /chat
            setShowModal(false);
            navigate('/chat')

        } else{
            toast.error("Ошибка при отправке сообщения")
        }
    }

return<>
<button className="btn btn-primary" onClick={() =>{setShowModal(true)}}>Написать <MDBIcon className="ms-2" fas icon="comment-alt" /></button>
 <MDBModal show={showModal} setShow={setShowModal}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader><MDBModalTitle>Написать в магазин</MDBModalTitle></MDBModalHeader>
                        {authService.isAuthenticated() ? <form onSubmit={WriteToStore}>
                            <MDBModalBody>
                                <MDBInput name="message" label="Сообщение" />
                            </MDBModalBody>
                            <MDBModalFooter className="d-flex justify-content-between">
                                
                                    <button className="btn btn-danger" type="button" onClick={() =>setShowModal(false)}>Отмена</button>
                                    <button className="btn btn-success" type="submit">Отправить</button>
                                
                            </MDBModalFooter>
                        </form> : <MDBModalBody>
                                <p>Войдите в аккаунт или зарегестрируйтесь</p>
                            </MDBModalBody>}
                        
                        
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
</>
   
}