import { MDBBtn, MDBIcon, MDBInputGroup, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { BrandSp } from "./BrandsSP";
import authService from "@auth/AuthorizeService";
import { useDispatch, useSelector } from "react-redux";
import { fetchStorePageTable, setCurrentCategoryShopPage, setCurrentLocationsSP, setPageShopPage, setSearchTextShopPage } from "./ShopPageStateSlice";



export function CategoriesSP({store}){
    const dispatch = useDispatch()
    const categories = useSelector(state => state.store.shopPage.categories)
    const currentCategory = useSelector(state => state.store.shopPage.currentCategory)
    const currentLocation = useSelector(state => state.store.shopPage.currentLocation)


    //const isLastPage = useSelector(state => state.store.shopPage.isLastPage);
    const searchText = useSelector(state => state.store.shopPage.searchText);
    
    const isLoadingTable = useSelector(state => state.store.shopPage.isLoadingTable);
    
    function loadTable(text, page, isLastPage){
        if(currentCategory != null && !isLastPage){
            console.log('запрашиваю страницу')
            dispatch(fetchStorePageTable({currentCategory, page, searchText: text, storeId: store.id, currentLocation}))
        }
    }
    const currentCategoryObj = useMemo(()=>categories.find(x => x.id == currentCategory), [currentCategory])
    const page = useMemo(() => {
        //loadTable(searchText, currentCategoryObj?.page, currentCategoryObj?.isLastPage);
        return currentCategoryObj?.page}, [currentCategoryObj?.page])
    const isLastPage = useMemo(() => {
        //loadTable(searchText, currentCategoryObj?.page, currentCategoryObj?.isLastPage);
        return currentCategoryObj?.isLastPage}, [currentCategoryObj?.page])
    
    let lastTimeSearch = null;
    
    useEffect(()=>{
        console.log('Вызвано обновление из-за измения параметра')
        loadTable(searchText, page, isLastPage)
        
    }, [currentCategory, page, store, currentLocation])
    const lastItemRef = useRef(null);
    useEffect(() => {
        console.log(lastItemRef.current, 'load api setup')
        if (!lastItemRef.current) return;
    
        const options = {
          root: null, // viewport
          rootMargin: '0px',
          threshold: 0.5, // 0.5 means when 50% of the element is visible
        };
    
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            
            if (entry.isIntersecting) {
                dispatch(setPageShopPage())
            }
          });
        }, options);
    
        observer.observe(lastItemRef.current);
    
        //setObserver(observer);
    
        // Clean up
        return () => observer.disconnect();
      }, [currentCategory]);
    


    let timeout;
    function handleSearchChange(text){
        
        clearTimeout(timeout)
        dispatch(setSearchTextShopPage({searchText: text}))
        if(text.length == 0){
            loadTable()
            return
        }
        if(text.length < 3){
            return;
        }
        
        timeout = setTimeout(()=>{
            console.log(text)
            loadTable(text)
        }, 300)
    }
    
    return(
        <>
        {store?.pickupLocations?.length > 1 && <><p>Выбери точку самовывоза</p>
            <MDBTabs pills>
                
                {store?.pickupLocations.map((x, i) =>  <MDBTabsItem>
                    <MDBTabsLink key={i} style={{padding: '10px'}} onClick={()=> {dispatch(setCurrentLocationsSP({id: x.id}))}} active={currentLocation == x.id}>
                        {x.name}
                    </MDBTabsLink>
                </MDBTabsItem>)}
                
                        
            </MDBTabs></>}
        
            <MDBTabs pills>
                
                {categories && categories.map((it, index)=>{
                    
                    return (
                        <>
                            <MDBTabsItem>
                                <MDBTabsLink style={{padding: '10px'}} onClick={()=> dispatch(setCurrentCategoryShopPage({id: it.id}))} active={currentCategory == it.id}>
                                    {it.name}
                                </MDBTabsLink>
                            </MDBTabsItem>
                        </>
                    );
                })}
            </MDBTabs>
            {/* <button className="btn btn-info"><MDBIcon fas icon="angle-right" /></button> */}

            <MDBInputGroup tag="form" className='d-flex w-auto mb-3'>
                <input value={searchText} onChange={e => handleSearchChange(e.currentTarget.value)} className='form-control' placeholder="Например: Rell" aria-label="Search" type='Search' />
                
            </MDBInputGroup>
            <MDBTabsContent>
                {categories.map((it, index)=>{
                    return(
                        <>
                            <MDBTabsPane className="w-100" show={currentCategory === it.id && !!it.brands}>
                                
                                <BrandSp brands={it?.brands} itemsName={it?.itemsName} storeId={store.id}/>
                                {currentCategory === it.id && <div ref={lastItemRef}></div>}
                            </MDBTabsPane>
                        </>
                    )
                })}
                {isLoadingTable && <div className=" mt-4 spinner-border text-primary" role="status"/>}
                
                
            </MDBTabsContent>
            
        </>
    );
}