
import { useEffect, useState } from "react";
import authService from "@auth/AuthorizeService";
import { ReferralItem } from "./ReferralItem";
import { AddReferralModal } from "./AddReferralModal";
import React from "react";
import EditRefModalProvider, { EditModalProvider } from "./EditModal/EditModalContext";


export function ManagersPage(){
    const [managers, setManagers] = useState(null);
    function onNewAdd(data){
        setManagers(prev => [...prev, data])
    }
    async function fetchManagers() {
        const token = await authService.getAccessToken();
        const res = await fetch('/api/mystore/workers/Referrals',{
            method: 'GET',
            headers:{
                'Authorization': `Bearer ${token}`
            }
        });

        if(!res.ok){
            return;
        }
        const obj = await res.json();
        setManagers(obj);
    }

    useEffect(()=>{
        fetchManagers();
    }, [])
    function RenderReferrals(){
        if(!managers || managers.length == 0){
            return (
                <>
                    <p>Менеджеры ещё не добавлены</p>
                </>
            );
        }
        return(
            <div className="mt-3">
                {managers.map((item)=>{
                    return <ReferralItem key={item.id} manager={item}/>
                })}
            </div>
        );
    }

    return(<>
    
        <h1>Менеджеры</h1>
        {/* add manager button */}
        <AddReferralModal onNewAdd={onNewAdd}/>
        <EditModalProvider >
            <RenderReferrals/>
        </EditModalProvider>
        

    </>);
}