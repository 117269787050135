import MyStoreInfoSignalR from "components/Notifications/NotificationMyStore";
import { toast } from "react-toastify";
import React from "react";
import authService from "@auth/AuthorizeService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");




const ProductPageStateSlice = createSlice({
    name: "productPage",
    initialState:{
        product: null,
        reviews: [],
        stores: [],
        isLoadingProduct: true,
        isLoadingStores: true,
        isLoadingReviews: true,
        isLoadingOtherRanges: true,
        otherRanges: []
    },
    reducers:{
       
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProductInfo.pending, (state, action) => {
            state.isLoadingProduct = true
        });
        builder.addCase(fetchProductInfo.fulfilled, (state, action) => {
            state.isLoadingProduct = false
            state.product = action.payload;
            
            
        });
        
        builder.addCase(fetchProductInfo.rejected, (state, action) => {
            state.isLoadingProduct = false
        });
        builder.addCase(switchFavouriteProductPage.fulfilled, (state, action) => {
            
            state.product.favourite = action.payload;
            
            
        });
        builder.addCase(fetchProductStores.pending, (state, action) => {
            state.isLoadingStores = true;
        });
        builder.addCase(fetchProductStores.fulfilled, (state, action) => {
            state.isLoadingStores = false;
            state.stores = action.payload;
            
            
        });
        builder.addCase(fetchProductStores.rejected, (state, action) => {
            state.isLoadingStores = false;
        });
        builder.addCase(fetchProductReviews.pending, (state, action) => {
            state.isLoadingReviews = true;
        });
        builder.addCase(fetchProductReviews.fulfilled, (state, action) => {
            state.isLoadingReviews = false;
            state.reviews = action.payload;
            
            
        });
        builder.addCase(fetchProductReviews.rejected, (state, action) => {
            state.isLoadingReviews = false;
        });

        builder.addCase(fetchProductOtherRanges.pending, (state, action) => {
            state.isLoadingOtherRanges = true;
        });
        builder.addCase(fetchProductOtherRanges.fulfilled, (state, action) => {
            state.isLoadingOtherRanges = false;
            state.otherRanges = action.payload;
            
            
        });
        builder.addCase(fetchProductOtherRanges.rejected, (state, action) => {
            state.isLoadingOtherRanges = false;
        });
    }
})

export const fetchProductInfo = createAsyncThunk('productPage/fetchProductInfo', async ({id})=>{
    const token = await authService.getAccessToken();
    const res = await fetch(`/api/product/${id}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})
export const fetchProductStores = createAsyncThunk('productPage/fetchProductStores', async ({id, loc})=>{
    const location = loc ?? JSON.parse(localStorage.getItem('selectedLocation'))
    const queryParams = new URLSearchParams();
    if(location?.cityId){
        queryParams.append('cityId', location?.cityId)
    }
    if (location?.districts?.length > 0) {
        location.districts.forEach(districtId => {
            queryParams.append('ds[]', districtId);
        });
    }
    location?.metro?.forEach(line=>{
        line?.stations?.forEach(st => {
            queryParams.append('ms[]', st);
        })
    })
    
    
    console.log('Загрузка магазинов...')
    
    const res = await fetch(`/api/product/${id}/stores?${queryParams?.toString()}`)
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})

export const switchFavouriteProductPage = createAsyncThunk('productPage/switchFavouriteProductPage', async ({id, to})=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/product/${id}/favourite?to=${to}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        },
        method: "PUT"
    })
    if(res.ok){
        return to
    }
    throw Error("Ошибка")
})


export const fetchProductReviews = createAsyncThunk('productPage/fetchProductReviews', async ({id})=>{
    
    const res = await fetch(`/api/product/${id}/reviews`)
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})
export const fetchProductOtherRanges = createAsyncThunk('productPage/fetchProductOtherRanges', async ({id})=>{
    
    const res = await fetch(`/api/product/OtherRanges?rangeId=${id}`)
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})
export const { } = ProductPageStateSlice.actions;
export default ProductPageStateSlice.reducer;