import useScreenSize from "hooks/ScreenSize/useScreenSize";
import { MDBContainer } from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { MyStoreHeader } from "./MyStoreHeader";
import { MyStoreMobileHeader } from "./MyStoreMobileHeader";
import { MyStoreSidebar } from "./MyStoreSidebar";
import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fillInfo } from "../InfoState/MyStoreInfoStateSlice";




export function MyStoreLayout() {
    const location = useLocation()
    const screenSize = useScreenSize()
    const dispatch = useDispatch()
    async function fetchMyStoreInfo(){
      const token = await authService.getAccessToken()
      const res = await fetch('/api/mystore/info', {
        headers: {
          "Authorization" : `Bearer ${token}`
        }
      })
      if(res.ok){
        const data = await res.json()
        dispatch(fillInfo({data}))
      } else {
        toast.error('Ошибка загрузки информации')
      }
    }

    useEffect(()=>{
      fetchMyStoreInfo()
    }, [])
      return (
        <div className='box'>
          
        {screenSize.width <= 768 && <MyStoreMobileHeader/>}
        
        <MDBContainer breakpoint='xxl' className='mt-3' style={{flex: '1 1 auto', paddingBottom: screenSize.width <=768 && location.pathname != '/mystore/chat/' ? '80px' : 0}}>
            {screenSize.width > 768 ? 
            <div className="d-flex h-100">
                <MyStoreSidebar/>
                <div className="flex-grow-1 ms-3"><Outlet/></div>
            </div> : <Outlet/>}
            
        </MDBContainer>
        
        {/* <div style={{flex: '0 1 40px'}}></div> */}
        </div>
      );
    
  }