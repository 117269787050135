import React, { useEffect, useState } from "react";
import authService from "@auth/AuthorizeService";
import { MDBBadge, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTabs } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import './Order.css'
import { ChangeOrderStatus } from "../ChangeStatusModal";
import { OrderMarginDiv } from "./OrdreMarginComponent";
import useScreenSize from "hooks/ScreenSize/useScreenSize";
export function getColorByOrderStatus(status){
    switch (status) {
        case 'Создан':
           return 'info'
        case 'Принят':
            return 'warning'
        case 'Собран':
            return 'warning'
        case 'Завершён':
            return 'success'
        case 'Отменён':
            return 'danger'
        default:
            return 'info';
    }
}


export function OrderPage({setShowOrderPage}){
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();

    const [order, setOrder] = useState(null);
    async function getOrder(id){
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/mystore/orders/${id}`,{
            headers:{
                "Authorization": `Bearer ${token}`
            }
        });

        setOrder(await res.json())
    }
    useEffect(()=>{
        getOrder(search.get('order'))
    }, [])
    
    
    async function openChat(){
        navigate('/mystore/chat')
    }
    
    const [inEdit, setInEdit] = useState(false);
    const [timer, setTimer] = useState(null);
    
    async function changeAmount(value, itemId){
            if (timer) {
            clearTimeout(timer);
          }
        
          // Set the timer to 1 second.
          setOrder(prev => {
            const ranges = prev.ranges.map(r => {
                const flavours = r.flavours.map(f => {
                    if(f.id === itemId){
                        f.quantity = value
                    }
                    return f
                })
                return {...r, flavours}
            })
            return {...prev, ranges}
        })
          const tempTimer = setTimeout(async () => {
            const token = await authService.getAccessToken();

            const res = await fetch(`/api/mystore/orders/${order.id}/changeAmount`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    itemId: itemId,
                    amount: value
                })
            })
            if(res.ok){
                //applyTotalPrice(await res.text())
                const sum = await res.text();
                setOrder(prev => { return {...prev, sum: sum}})
                
            }
          }, 300);
          setTimer(tempTimer)
        
        
    }

    function applyTotalPrice(price){
        // set to order
        setShowOrderPage(prev => {prev.sum = price; return prev})

    }
    const [unitTimer, setUnitTimer] = useState(null);

    
    async function changeUnitPrice(price, rangeId){
        
        setOrder(prev => {
            const newArr = {...prev};
            newArr.ranges.forEach((r) => {
                if(r.id === rangeId){
                    r.flavours.forEach((f)=>{
                        f.unitPrice = Number(price).toFixed(1)
                    })
                }
            })
            return {...newArr}
        })
        if (unitTimer) {
            clearTimeout(unitTimer);
        }

        const tempTimer = setTimeout(async () => {
            price = Number.parseFloat(price).toFixed(2)
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/mystore/orders/${order.id}/changeUnitPrice`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                rangeId: rangeId,
                unitPrice: price
            })
        })
        
        if(res.ok){

            const sum = await res.text();
                setOrder(prev => { return {...prev, sum: sum}})
        }
        }, 300)
        setUnitTimer(tempTimer);
        
    }
  
    const screenSize = useScreenSize()
    if(order == null){
        return(
            <>
            <button className="btn btn-info mb-3" onClick={e => setShowOrderPage()}><MDBIcon color="white" fas icon="arrow-left" /></button>
            <div className="spinner-border text-primary" role="status"/>
            </>
        )
    }
    
    return(
        <>
        <div className="d-flex justify-content-between">
            <h4>Заказ № {order.fId}</h4>
        </div>
        <MDBTabs fill className="orderControl d-flex mb-3">
            
        <div className="flex-grow-1 flex-shrink-1">
        <button className="btn btn-info" onClick={e => setShowOrderPage()}><MDBIcon color="white" fas icon="arrow-left" /></button>
                <button className="btn btn-secondary" onClick={e => {openChat()}}>Открыть чат<MDBIcon className="ms-2" fas icon="comment-alt" /></button>
                {order.status !== 'Завершён' && (!inEdit ? <button className="btn btn-primary ms-3" onClick={e => {setInEdit(true)}}><MDBIcon className="ms-1" fas icon="pen" /></button> : 
                    <>
                        <button className="btn btn-success ms-3" onClick={e => {setInEdit(false)}}><MDBIcon fas icon="check" /></button>
                        
                    </>
                )}
        </div>
        
                {screenSize.width > 768 && <div className="flex-grow-1"></div>}
                <div className="flex-grow-1 flex-shrink-1">   
        <ChangeOrderStatus order={order} onApply={() => getOrder(order.id)}/>
        </div>
        
        </MDBTabs>
            
            <MDBCard>
                <MDBCardHeader>
                    <div className="d-flex">
                        <MDBCardTitle className="flex-grow-1">{order.user.name}</MDBCardTitle>
                        <MDBBadge color={getColorByOrderStatus(order.status)}  style={{height: '20px'}}>{order.status}</MDBBadge>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="">{order.date}</span>
                        {false ? <>
                            <MDBInputGroup style={{width: 'auto'}}>
                                <button disabled className="btn btn-danger p-2" onClick={e => {}}><MDBIcon fas icon="minus" /></button>
                                
                                <input className="form-control" placeholder="шт." style={{width: '5rem', backgroundColor:'#424242'}}  type="number" step={0.1} value={order.sum} min={0.1}/>
                                <span className="input-group-text">руб.</span>
                                
                                <button disabled className="btn btn-success p-2" onClick={e => {}}><MDBIcon fas icon="plus" /></button>
                            </MDBInputGroup>
                        
                            
                        </> : <MDBBadge style={{height: '20px'}}>{order.sum} руб.</MDBBadge>}
                        
                    </div>
                    
                    
                </MDBCardHeader>
                
                <MDBCardBody>
                    <OrderMarginDiv order={order} setOrder={setOrder} fetchOrder={()=> getOrder(search.get('order'))}/>
                    <hr/>
                    {order?.ranges.map((range, index)=>{
                        return(
                            <>
                            <div className="d-flex align-items-end" key={range.id}>
                            <p className={`${index == 0 ? "mt-0" : "mt-4"} mb-0 flex-grow-1`}>{range.name}</p>
                                
                                {inEdit ? <>
                                    <div className="d-flex ms-2">
                                        <button className="btn btn-danger px-3" onClick={e => {changeUnitPrice(+range?.flavours[0]?.unitPrice-0.1,range.id)}}><MDBIcon fas icon="minus"/></button>
                                        <input
                                            className="mx-2 amountInput"
                                            placeholder="шт."
                                            
                                            value={range?.flavours[0]?.unitPrice} step={0.1} min={0.1}
                                            style={{maxWidth: '55px',textAlign: 'center', borderRadius: '4px', border: 'none'}}
                                            onChange={e => {changeUnitPrice(Number(e.target.value),range.id)}}
                                            type="number"
                                        />
                                        <button className="btn btn-success px-3" onClick={e => changeUnitPrice(+range?.flavours[0]?.unitPrice+0.1,range.id)}><MDBIcon fas icon="plus"/></button>
                                    </div>
                        
                            
                        </> : <MDBBadge className="mb-1">{range?.flavours[0]?.unitPrice} руб/шт.</MDBBadge>}
                            </div>
                                
                                <MDBListGroup>
                                    
                                        {range?.flavours?.map((flavour)=>{
                                            return(
                                                <>
                                                    <MDBListGroupItem color="dark" className="d-flex" key={flavour.id}>
                                                        
                                                        <p style={{fontSize: '1.1rem', margin: 'auto'}} className="flex-grow-1">{flavour.flavourName}</p>
                                                        <div className="d-flex flex-wrap align-items-center">
                                                            
                                                        {inEdit ? <>
                                                            <div className="d-flex ms-2">
                                                                    <button className="btn btn-danger px-3" onClick={e => {changeAmount(flavour.quantity-1, flavour.id)}}><MDBIcon fas icon="minus"/></button>
                                                                    <input
                                                                        className="mx-2 amountInput"
                                                                        value={flavour.quantity} step={1}
                                                                        style={{maxWidth: '55px',textAlign: 'center', borderRadius: '4px', border: 'none'}}
                                                                        onChange={e => changeAmount(Number(e.target.value), flavour.id)}
                                                                        type="number"
                                                                    />
                                                                    <button className="btn btn-success px-3" onClick={e => changeAmount(flavour.quantity+1, flavour.id)}><MDBIcon fas icon="plus"/></button>
                                                                </div>
                                                            
                                                            
                                                            
                                                            </> : <MDBBadge>{flavour.quantity} шт.</MDBBadge>}
                                                                

                                                               
                                                            
                                                            
                                                            
                                                        </div>
                                                        
                                                    </MDBListGroupItem>
                                                </>
                                            )
                                        })}
                                </MDBListGroup>
                            </>
                        )
                    })}
                </MDBCardBody>
            </MDBCard>
        </>
    )
}