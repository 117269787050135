// Modal.js
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBProgress, MDBProgressBar, MDBRow, MDBTextArea } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from '@auth/AuthorizeService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import StarRating from 'components/StarRating/StarRating';
import { AutoGrowTextArea, AutoTextArea } from 'css/Utils';
import { RatingStars } from 'components/store/ProductPage/StoresTab';
import { AvatarBox } from 'components/Global/AvatarBox';
import { deleteExpenseItemR, editExpenseItemR } from '../ExpensesStateSlice';

const Modal = ({ item, show, setShow }) => {

    const dispatch = useDispatch()
    const [reviews, setReviews] = useState([])
    async function EditExpenseApply(e){
        e.preventDefault();
        const token = await authService.getAccessToken()
        const fd = new FormData(e.target)
        const res = await fetch(`/api/mystore/analytics/expenses/${item.id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            method: 'PATCH',
            body: fd
        })
        if(res.ok){
            
            dispatch(editExpenseItemR(await res.json()))
            setShow()
        } else{
            toast.error('Ошибка редактирования')
        }
    }
    async function DeleteExpense(){
        const token = await authService.getAccessToken()
        const res = await fetch(`/api/mystore/analytics/expenses/${item?.id}`,{
            headers: {
                "Authorization": `Bearer ${token}`
            },
            method: "DELETE"
        })
        if(res.ok){
            dispatch(deleteExpenseItemR(item))
            setShow()
        } else{
            toast.error('Ошибка удаления')
        }
    }
    
    
    
    // function setStoreStars(val){
    //     setStore(prev => {
    //         if(prev){
    //             prev.ratings = val
    //         }
    //     })
    // }
    
    
  return (
    
    <MDBModal show={show} onHide={()=> {setShow()}} >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader className='d-flex justify-content-between'><MDBModalTitle>Редактировать запись</MDBModalTitle><p className='text-muted'>{item?.date}</p></MDBModalHeader>
                    {item && <form onSubmit={EditExpenseApply}>
                        <MDBModalBody>
                            <MDBInput name="title" defaultValue={item.title} className="mt-2" label="Название" placeholder="Название"/>
                            <MDBInput name="description" defaultValue={item.description} className="mt-2" label="Описание" placeholder="Описание"/>
                            <MDBInput name="amount" defaultValue={item.amount} className="mt-2" label="Сумма" type="number" step={0.01} placeholder="Сумма"/>
                            {/* <MDBInput name="date" defaultValue={item.date} className="mt-2" label="Дата" type="date"/> */}

                            <button type='button' className='btn btn-danger mt-5' onClick={()=> DeleteExpense()}>Удалить расход</button>
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button className="btn btn-danger" type="button" onClick={()=> setShow()}>Отмена</button>
                            <button className="btn btn-success" type="submit">Применить</button>
                        </MDBModalFooter>
                    </form>}
                    
                    
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
  );
};


export default Modal;