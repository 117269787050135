import { MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader } from "mdb-react-ui-kit"
import React, { useEffect, useState } from "react"
import authService from "@auth/AuthorizeService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { decrementOrdersCount } from "../InfoState/MyStoreInfoStateSlice";
import { updateStatus } from "./OrdersState/OrdersStateSlice";

export function ChangeOrderStatus({order, onApply}){
   
    const dispath = useDispatch();
    const [error, setError] = useState(null);
    async function ChangeStatus(toStatus){
        
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/mystore/orders/${order.id}/${toStatus}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            method: 'PATCH'
        });
        if(res.ok){
            //dispath({type: 'ordersState/updateStatus', payload: {orderId: order.id, status: await res.text()}})
            
            dispath(updateStatus({orderId: order.id, status: await res.text()}))
            if(order.status == 'Создан'){
                dispath(decrementOrdersCount())
            }
            onApply();
            setShow(false);
        } else{
            toast.error('Ошибка при изменении статуса заказа')
            setError(await res.text());
        }
    }
    
    
    function getName(name){
        switch(name){
            case 'Collected':
                return 'подтвердить статус собран'
            case 'Accept':
                return 'Принять'
            case 'Complete':
                return 'Завершить'
            case 'Cancel':
                return 'Отменить'
            case 'Dispatched':
                return 'подтвердить статус передано курьеру'
            case 'Continue':
                return 'Возобновить'
            default:
                return 'Неизвестно'
        }
    }
    const [show, setShow] = useState(false);
    useEffect(()=>{
        if(order && show && localStorage.getItem('autoConfirmMyStore')) ChangeStatus(show)
    },[order, show])
    return (
        <>
        {order.status === 'Принят' && <button className="btn btn-warning  me-3" onClick={e => {setShow('Collected')}}>Собран</button>}

        {order.status === 'Создан' && <button className="btn btn-success  me-3" onClick={e => {setShow('Accept')}}>Принять заказ</button>}
        {order.orderType == 3 && order.status === 'Собран' && <button className="btn btn-warning  me-3" onClick={e => {setShow('Dispatched')}}>Передан курьеру</button>}
        {((order.status === 'Собран' && order.orderType == 1) || order.statusCode == 3 && order.orderType >= 2) && <button className="btn btn-success  me-3" onClick={e => {setShow('Complete')}}>Завершить заказ</button>}
        {(order.status !== 'Отменён' && order.status !== 'Завершён') &&  <button className="btn btn-danger " onClick={()=>{setShow('Cancel')}}>Отменить заказ</button>}
        {order.status === 'Отменён' &&  <button className="btn btn-warning " onClick={e => {setShow('Continue')}}>Возобновить заказ</button>}
        
            <MDBModal show={show} onHide={()=> setShow(false)}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <h5>Ты точно хочешь <b className="m-0 p-0">{getName(show)}</b> заказ?</h5>
                        </MDBModalHeader>
                        
                        
                        {error &&<MDBModalBody>
                            
                            <p style={{whiteSpace: 'pre-line'}}>{error}</p>
                            </MDBModalBody>}
                        
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button className="btn btn-danger" onClick={()=> setShow(false)}>Назад</button>
                            <button className="btn btn-success" onClick={() => ChangeStatus(show)}>{getName(show)}</button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}