import { TelegramLoginButton } from "@auth/Pages/TelegramLoginButton";
import React from "react";
import { render } from "react-dom";
import { NavLink } from "react-router-dom";



export default function IntroStore() {
    

    

    return (
            <>
            
                <section className="my-4">
                  
                    <div className="card p-4 bg-primary">
                      <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <div>
                          <h4 className="mb-0 text-white">Купи в 1 клик!</h4>
                          <p className="mb-0 text-white-50">
                            Наша платформа облегчает процесс покупки, встречи
                          </p>
                        </div>
                        <div>
                          <TelegramLoginButton className="mt-2 mt-md-0"/>
                          {/* <NavLink to='/auth/login'>
                            <button type="button" className="btn btn-light" data-mdb-ripple-color="dark">Регистрация</button>
                          </NavLink> */}
                        
                        </div>
                      </div>
                  </div>
                </section>

            
            </>
    );
    
}