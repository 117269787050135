import StarRating from "components/StarRating/StarRating";
import { MDBCard, MDBCardBody, MDBCardImage, MDBCarousel, MDBCarouselItem, MDBCol } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import ProductReviewModalContext from "./ReviewModal/ProductReviewModalContext";
import Carousel from "react-multi-carousel";
import authService from "@auth/AuthorizeService";




export function ItemsForReview(){
    const {showReviewModal} = useContext(ProductReviewModalContext)
    const [items, setItemsForReview] = useState([])
    
    async function fetchReviewItems(){
        const token = await authService.getAccessToken()
        const res = await fetch('/api/profile/ItemsForReview',{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if(res.ok){
            setItemsForReview(await res.json())
        }
    }
    useEffect(()=>{
        fetchReviewItems()
    },[])
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1200, min: 480 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 480, min: 0 },
          items: 1,
          slidesToSlide: 1, // optional, default to 1.
          partialVisibilityGutter: 60,
        }
      };
    return<>
    {/* <Carousel items={items}/> */}
    

    
    
    <div className="d-grid">
    
        <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        keyBoardControl={true}
        partialVisbile={true}
        removeArrowOnDeviceType={['mobile', 'tablet']}
        containerClass="carousel-container"
        itemClass="px-4"
        >
            {items?.map((item, index)=>{
                    return <MDBCard>
                    <MDBCardBody className="text-center">
                        <MDBCardImage src={`/photos/ranges/${item?.rangeId}.jpg`} height='140px' className="rounded"/>
                        <p style={{lineHeight: '1.5em', height: '4.5em',  textOverflow: 'ellipsis'}}>{item?.name}</p>
                        <StarRating defaultValue={0} onSet={(value)=> {item.stars = value;showReviewModal(item)}}/>
                    </MDBCardBody>
                </MDBCard>
                })}
        </Carousel>
    </div>
    
                {/* <div className="d-flex flex-wrap">
                
                    
                
                {items?.map((item, index)=>{
                    return <MDBCol><MDBCard style={{width: '300px', marginRight: '20px'}}>
                    <MDBCardBody className="text-center">
                        <MDBCardImage src={`/photos/ranges/${item?.rangeId}.jpg`} height='140px'/>
                        <p>{item?.name}</p>
                        <StarRating defaultValue={0} onSet={(value)=> {item.stars = value;showReviewModal(item)}}/>
                    </MDBCardBody>
                </MDBCard></MDBCol>
                })}
                </div> */}
        
    </>
}