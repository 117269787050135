import React, {useEffect, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderWidth: 3,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export function StyledDropzone({onDrop1}) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone(
    {
      accept: {'image/*': []},
      onDrop: e => onDrop1(e),
      onDragEnter: e => console.log(e),
      onDragOver: e => console.log('sdf', e)

});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.kind === 'file') {
    const blob = item.getAsFile();
    console.log('Pasted file:', blob);
      onDrop1([blob])
    // Do something with the file, like upload or process it
    }
    }
    };
    
    
    
    useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
    document.removeEventListener('paste', handlePaste);
    };
    }, []);
  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Перетащи сюда картинку, или нажми для открытия диалога</p>
      </div>
    </div>
  );
}


export function ChatDropzone({onDrop1}) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: {'image/*': []}, onDrop: e => onDrop1(e), });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.kind === 'file') {
    const blob = item.getAsFile();
    console.log('Pasted file:', blob);
   
    // Do something with the file, like upload or process it
    }
    }
    };
    
    
    
    useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
    document.removeEventListener('paste', handlePaste);
    };
    }, []);
  return (
    <div className="container" >
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Перетащи сюда картинку, или нажми для открытия диалога2</p>
      </div>
    </div>
  );
}

