
import IntroStore from "./Intro";
import Categories from "./Categories";
import Products from "./Stores";
import Features from "./Features";
import Recomended from "./Popular";

import React, { Component } from 'react';
import authService from "@auth/AuthorizeService";
import { MDBContainer } from "mdb-react-ui-kit";

export function MainStore() {
  

  
    return (
      <MDBContainer breakpoint="xxl" className=" my-5">
                
                <Categories/>
                {!authService.isAuthenticated() && <IntroStore/>}
                <Products/>
                <Features/>
                <Recomended/>
        </MDBContainer>
    );
  
}