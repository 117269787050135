// Modal.js
import { MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import authService from '../../../api-authorization/AuthorizeService';

const FlavourStoresModal = ({ flavour, show, setShow }) => {

    const [stores, setStores] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    async function fetchStores(){
        setIsLoading(true)
        const location = JSON.parse(localStorage.getItem('selectedLocation'))
        const queryParams = new URLSearchParams({
            cityId: location.cityId,
            'ds[]': location.districts.join('&ds[]='),
            'ms[]': location.metro?.flatMap(m => m.stations).join('&ms[]=')
          });
          
        const res = await fetch(`/api/search/getShopsByFlavour/${flavour.id}?${queryParams.toString()}`);
        setIsLoading(false)
        if(res.ok){
           
        setStores(await res.json());
        }
        
    }

    useEffect(()=>{
        if(flavour){
            fetchStores();
        }
        
    }, [flavour])
    async function addToCart(storeId){
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/store/cart?storeId=${storeId}&flavourId=${flavour.id}`,{
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if(res.ok){
            toast.success('Добавлено в корзину')
        } else{
            toast.error(await res.text());
        }
    }
  return (
    <MDBModal show={show} onHide={()=> setShow()} >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{flavour?.name}</MDBModalTitle>
              <button className='btn btn-close' color='none' onClick={()=>setShow()}></button>
            </MDBModalHeader>
            <MDBModalBody>
                <div className='d-flex justify-content-between'>
                    <h5>Магазины</h5>
                    <select className='select'>
                        <option>По цене</option>
                        <option>По названию</option>
                    </select>
                </div>
                {isLoading && <div className="spinner-border text-primary" role="status"/>}
                {!isLoading && stores?.length == 0 && <p>Магазины не найдены</p>}
                {stores?.map((store, index)=>{
                    return (
                        <>

                            <div className='mt-2 d-flex justify-content-between align-items-center'>
                                <h5 className='m-0'>{store.store?.name}</h5>
                                <button className='btn btn-primary' onClick={()=>{
                                    addToCart(store?.store.id);
                                }}>В корзину</button>
                            </div>
                        <MDBRow>
                                <MDBCol>
                                <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th>Количество</th>
                                        <th>Цена</th>
                                        
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {store?.prices?.map((price, index)=>{
                                        return (
                                            <>
                                                <tr>
                                                    <td>От {price.from} шт.</td>
                                                    <td>{price.price} руб.</td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                                </MDBCol>
                                <MDBCol className=''>
                                    <p><span style={{fontWeight: 'bold'}}>Доставка:</span> {store?.delivery}</p>
                                    <p><span style={{fontWeight: 'bold'}}>Самовывоз:</span> {store?.pickup}</p>
                                </MDBCol>
                        </MDBRow>    
                            
                        </>
                    );
                })}
            </MDBModalBody>

            <MDBModalFooter>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
  );
};

export default FlavourStoresModal;