import { MDBCol, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardBody, MDBCardFooter, MDBListGroup, MDBListGroupItem, MDBRow, MDBBadge, MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { OrderPage, getColorByOrderStatus } from "./OrderPage/OrderPage";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePage, cleanOrders, fetchCategories, fetchOrders, setActiveCategory } from "./OrdersState/OrdersStateSlice";
import { getOrderColorByType, getOrderTypeStringByValue } from "./StatusCode";


export function OrdersLayoutStore(){

    const [showOrderPage, setShowOrderPage] = useState(null);
    const [search, setSearch] = useSearchParams();
    function ClosePage(){
        search.delete('order');
        setSearch(search);
        setShowOrderPage(false);
    }
    const orders = useSelector(state => state.mystore.orders.orders)
    useEffect(()=>{
        console.log();
        if(search.get('order') == null) return;
        
        
        setShowOrderPage(true);
    }, [search])
    const activeTab = useSelector(state => state.mystore.orders.activeCategory)
    const categories = useSelector(state => state.mystore.orders.categories)
    const loading = useSelector(state => state.mystore.orders.isLoading)

    const currentPage = useSelector(state => state.mystore.orders.page)
    const lastPage = useSelector(state => state.mystore.orders.lastPage)

    const dispatch = useDispatch();
    
    useEffect(()=>{
        if(categories.length == 0){
            dispatch(fetchCategories());
        }
        
    }, [])
    useEffect(()=>{
        console.log('Запрашиваю заказы')
        
            dispatch(fetchOrders({status: categories[activeTab]?.statusCode, page: currentPage, pageSize: 10}));
        return ()=>{
            dispatch(cleanOrders())
        }
    }, [categories, activeTab, currentPage])
    

    useEffect(()=>{
        const handleScroll = (e) =>{
        
            
            if (
                ((window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
              ) {
                console.log('dispatching...', currentPage+1, 'lastPage: ', lastPage)
                dispatch(changePage())
                
              }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll);
          };
    },[])
    if(showOrderPage == true){
        
        
        return(
            <>
                <OrderPage showOrderPage={showOrderPage} setShowOrderPage={ClosePage} />
            </>
        )
    }
    
    return(
        <>
        {/* <MDBTabs pills fill>
            
            <MDBTabsItem key={1}>
                <MDBTabsLink onClick={() => dispatch(setActiveCategory(1))} active={activeTab == 1}>
                    Сегодня
                </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem key={1}>
                <MDBTabsLink onClick={() => dispatch(setActiveCategory(1))} active={activeTab == 1}>
                    Завтра
                </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem key={1}>
                <MDBTabsLink onClick={() => dispatch(setActiveCategory(1))} active={activeTab == 1}>
                    Остальное
                </MDBTabsLink>
            </MDBTabsItem>
                        
        </MDBTabs> */}
        <div className="d-flex justify-content-between">
            <h4>Заказы</h4>
            <div className="d-flex align-items-center">
                <input id="setAll" className="checkmark ms-2" onChange={(e)=> {e.currentTarget.checked ? localStorage.setItem('autoConfirmMyStore', '1') : localStorage.removeItem('autoConfirmMyStore')}} type="checkbox" defaultChecked={localStorage.getItem('autoConfirmMyStore')}></input>
                <label htmlFor="setAll" className="ms-2">Отключить подтверждение смена статуса</label>
            </div>
        </div>
        <MDBTabs pills fill>
            
                <MDBTabsItem key={1}>
                    <MDBTabsLink onClick={() => dispatch(setActiveCategory(0))} active={activeTab == 0}>
                        Новые
                        </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem key={1}>
                    <MDBTabsLink onClick={() => dispatch(setActiveCategory(4))} active={activeTab == 4}>
                        Завершён
                        </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem key={1}>
                    <MDBTabsLink onClick={() => dispatch(setActiveCategory(5))} active={activeTab == 5}>
                        Отменён
                        </MDBTabsLink>
                </MDBTabsItem>
                        
        </MDBTabs>
        <MDBRow className="mb-4">

            
            
            {orders?.length === 0 ? <h4>Нет заказов с таким статусом</h4> : orders?.map((order, index) => {
                
                return(
                    <>
                        <MDBCol key={order.id} size={12} className={`${index == 0 ? "" : "mt-2"}`}>
                            <MDBCard style={{cursor: 'pointer'}} onClick={()=>{search.set('order', order.id); setSearch(search)}}>
                                <MDBCardBody className="">
                                    <div className="d-flex align-items-start">
                                        <MDBCardTitle className="flex-grow-1">№ {order.fId} @{order?.user?.name} </MDBCardTitle>
                                        {activeTab < 4 && 
                                        <MDBBadge color={getColorByOrderStatus(order.status)} className="text-center me-3" style={{fontSize: '1rem', paddingTop: 10}}>
                                        {order.status}
                                        </MDBBadge>}
                                        <MDBBadge color={getOrderColorByType(order.orderType)} className="text-center" style={{fontSize: '1rem', paddingTop: 10}}>
                                            {getOrderTypeStringByValue(order.orderType)}
                                        </MDBBadge>
                                    </div>
                                    <div className="d-flex mt-2">
                                        <span className="flex-grow-1" style={{fontSize: '0.9rem'}}>{order?.date}</span>
                                        <MDBBadge style={{fontSize: '0.9rem', paddingTop: 6}}>
                                            {order.sum} руб.
                                        </MDBBadge>
                                    </div>
                                    
                                    
                                </MDBCardBody>
                                
                            </MDBCard>
                        </MDBCol>
                    </>
                )
            })}
            {lastPage && orders.length > 0 && <p>Больше нет заказов</p>}
            {loading && <div className="spinner-border text-primary" role="status"/>}
            </MDBRow>
        </>
    )
}