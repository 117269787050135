import authService from "@auth/AuthorizeService";
import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { AdminCreateNewPaymentToWorker } from "./AdminCreateNewPayment";
import { IncomeCharts } from './../../Analytics/Income/IncomeCharts';
import { toast } from "react-toastify";


export function WorkerPaymentInfo({type, workerId = null}){
    const isAdmin = authService.getRoles().includes('StoreAdmin')
    const [data, setData] = useState(null)
    const [payments, setPaymets] = useState([])
    async function fetchSalaryInfo(){
        const token = await authService.getAccessToken() 
        const res = await fetch(`/api/mystore/my${type}/SalaryInfo?${type}Id=${workerId}`, {
            headers: {
                "Authorization":`Bearer ${token}`
            }
        })
        if(res.ok){
            setData(await res.json())
        }
    }
    async function fetchPayments(){
        const token = await authService.getAccessToken() 
        const res = await fetch(`/api/mystore/my${type}/Payments?${type}Id=${workerId}`, {
            headers: {
                "Authorization":`Bearer ${token}`
            }
        })
        if(res.ok){
            setPaymets(await res.json())
        }
    }
    useEffect(()=>{fetchSalaryInfo(); fetchPayments()},[])
    function renderPaymentInfo(wrk){
        
            switch(wrk?.paymentType){
                case 0:
                    return <>
                    <MDBRow>
                        {wrk?.fees.map((it, index)=>{
                            return(
                                <>
                                    <MDBCol size={12} sm={6}>
                                        <p className='mb-0'>{it.categoryName} {it.isOpt ? '(Опт)' : ''}</p>
                                        <MDBInputGroup className='mb-3' textAfter='руб/шт.'>
                                            <input className='form-control' type='number' step={0.1} value={it.fee} disabled/>
                                        </MDBInputGroup>
                                        {/* <MDBInput type='number' step={0.1} value={it.fee} onChange={(e) => onInputFee(Number(e.currentTarget.value), it.categoryId, it.isOpt)}/> руб. */}
                                    </MDBCol>
                                </>
                            )
                        })}
                    </MDBRow>
                    </>
                case 1:
                    return <>
                    <MDBInputGroup className='' textAfter='руб/заказ'>
                        <input className='form-control' value={wrk?.fees} readOnly name='value' type='number' max={100} min={0} step={0.1}/>
                    </MDBInputGroup>
                    </>
                case 2:
                    return <>
                    <MDBInputGroup  style={{width: 'fit-content'}} textAfter='%'>
                        <input className='form-control' value={wrk?.fees} readOnly name='value' type='number' max={100} min={0} step={0.1}/>
                    </MDBInputGroup>
                    </>
                default: return <>Непонятно</>
            }
        
    }
    const [editModal, setEditModal] = useState(null)

    return <>
        <h4 className="mt-3">Выплаты</h4>
        <MDBCard>
            <MDBCardBody>
                <p>Тип оплаты: {data?.paymentType == 0 ? <span className="fw-bold">За каждую позиции в заказе</span> : (data?.paymentType == 1 ? <span className="fw-bold">Фиксированное ставка за заказ</span> : <span className="fw-bold">Процент от суммы заказа</span>)}</p>
                {renderPaymentInfo(data)}
                <p>Не выплачено: <span className="fw-bold">{data?.remainSum} руб.</span></p>
                {isAdmin && <AdminCreateNewPaymentToWorker onAdd={(i)=> setPaymets(prev => [i, ...prev])} type={type} workerId={workerId} unpaid={data?.remainSum}/>}
                
            </MDBCardBody>
        </MDBCard>

        <MDBRow className="my-3 gy-2">
            {payments?.map((it, index)=> {
                return<>
                    <MDBCol size={12}>
                        <MDBCard style={{backgroundColor: `${it.paidAmount < it.totalAmount ? '#de556c' : ''}`}} onClick={()=>{}}>
                            <MDBCardBody>
                                <div className="d-flex justify-content-between">
                                <h5>{it?.paidAmount} руб. / {it?.totalAmount} руб.</h5>
                                <p>{it?.date}</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-start">
                                    <div className='d-flex align-items-center mt-2'>
                                        <MDBIcon color='muted' fas icon="comment-alt" />
                                        
                                        <p className='ms-2'>{it?.comment}</p>
                                        
                                    </div>
                                    {isAdmin && it.paidAmount < it.totalAmount && <button className="btn btn-primary" onClick={()=> setEditModal(it)}>Закрыть долг</button>}
                                </div>
                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </>
            })}
        </MDBRow>
        <EditPaymentModal item={editModal} type={type} onClose={()=> setEditModal(false)} onAdd={(d)=> {setPaymets(prev => 
            prev?.map((t)=> {
                if(t.id == d.id){
                    return d
                }
                return t
            })
            
        ); setEditModal(false)}}/>
    </>
}
function EditPaymentModal({item, onClose, onAdd, type}){
    async function apply(e){
        e.preventDefault()
        const fd = new FormData(e.target)

        
        const obj = {};
        fd.forEach((value, key) => (obj[key] = value));
        obj.paymentId = item.id;
        obj.isCourier = (type == 'courier')
        const token = await authService.getAccessToken()
        const res = await fetch('/api/mystore/workers/CloseDebt',
        {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(obj)
        })
        if(res.ok){
            
            onAdd(await res.json())
            toast.success('Успешно')
        } else{
            toast.error('Неизсвестная ошибка')
        }

    }
    return<>
        <MDBModal show={!!item} onHide={onClose}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader><MDBModalTitle>Закрыть долг</MDBModalTitle></MDBModalHeader>
                    <form onSubmit={apply}>

                    {item && <> <MDBModalBody>
                            <p>Долг: <span className="fw-bold">{(item?.totalAmount-item?.paidAmount).toFixed(2)} руб.</span></p>
                            <MDBInputGroup className="my-2" textAfter="руб.">
                                <MDBInput required placeholder="23.5" step={0.01} min={0} defaultValue={(item?.totalAmount - item?.paidAmount).toFixed(2)} label="Сумма" name="amount"></MDBInput>
                                {/* <button className="btn btn-warning">Всю сумму</button> */}
                            </MDBInputGroup>
                            <MDBInput placeholder="Комментарий" label="Комментарий" defaultValue={item?.comment} type="text" name="comment"/>
                            
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button className="btn btn-danger" onClick={()=> onClose(false)} type="button">Отмена</button>
                            <button className="btn btn-success" type="submit">Добавить</button>
                        </MDBModalFooter></>}
                        
                        </form>
                </MDBModalContent>
            </MDBModalDialog>
            
        </MDBModal>
    </>
}