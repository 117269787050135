import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from "mdb-react-ui-kit";
import React from "react";
import { NavLink } from "react-router-dom";
import { FavouriteProductsPage } from './../../store/Profile/Favourites/FavouritesProducts';
import { useSelector } from "react-redux";
import { AddWarehouse } from "./Warehouse";



export function WarehousesView(){

    const warehouses = useSelector(state => state.mystore.info.warehouses)

    return <>
    <div className="d-flex align-items-center justify-content-between mb-3">
        <h4>Твои склады</h4>
        <AddWarehouse/>
    </div>
        
        {warehouses?.map((it, key)=>{
            
            return <NavLink end
            // to={`warehouse/${warehouse.id}`}
            to={{pathname:`/mystore/warehouse/${it.id}`}}
            caseSensitive
            state={{warehouse: it}} style={{color: 'inherit'}}><MDBCard  className="mt-3">
            <MDBCardBody >
                <div className="d-flex justify-content-between align-items-start"><MDBCardTitle>{it.name}</MDBCardTitle> <MDBBadge color="success">Основной</MDBBadge></div>
                
                <MDBCardText>
                Баланс {it.balance} руб.
                </MDBCardText>
                {it.courierId && <NavLink to={`/mystore/couriers/${it.courierId}`}>Курьер {it.courierName}</NavLink>}
                
            </MDBCardBody>
        </MDBCard></NavLink>
        })}
        
    </>
}