import { MDBBadge, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import React from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export function MobileChatContatcs({chats, setSelectedChat, selectedChat}){
  
    // const [search, setSearch] = useSearchParams();
   
    // useEffect(()=>{
    //   setSelectedChat(search.get('id'))
      
    // },[search])
          return(
            
              <>
                    
                      <div className="">
                        
                        <h4>Сообщения</h4>
                        <MDBListGroup className="" dark style={{ overflowX: 'clip',overflowY: 'auto'}} >
                          {console.log(chats, 'chats')}
                        {chats.map((chat, index) => {
                            return (
                              <>
                                <MDBListGroupItem
                                key={index}
                                className="px-3 rounded mb-2 d-flex justify-content-between align-items-center"
                                active={chat.to == selectedChat} action color="dark" 
                                style={{cursor: 'pointer', paddingLeft: '15px', height: '100px', paddingRight: '5px', }} 
                                onClick={()=> {setSelectedChat(chat.to)}}
                                >
                                    <div className='d-flex align-items-center'>
                                    <img
                                        src={`/photos/store/${chat.to}.jpg`}
                                        alt=''
                                        style={{ width: '45px', height: '45px' }}
                                        className='rounded-circle'
                                    />
                                    <div className='ms-3' style={{maxWidth: '200px'}}>
                                        <p className='fw-bold mb-1'>{chat.chatName}</p>
                                        <p
                                        className='text-muted mb-0'
                                        style={{overflow: 'hidden',display: '-webkit-box',WebkitBoxOrient: 'vertical',
                                          WebkitLineClamp: 2, lineClamp: 2,textOverflow: 'clip'}}
                                        >
                                          {chat?.isWriting ? <p className="mb-0" style={{color: 'green'}}>Печатает...</p> :
                                          
                                          (chat?.messages?.length > 0 ? chat.messages[chat.messages.length-1].text : chat?.lastMessage.text)
                                        }
                                        </p>
                                    </div>
                                    </div>
                                    {chat.unreadCount !== 0 && <MDBBadge pill color='danger'>
                                    {chat.unreadCount}
                                    </MDBBadge>}
                                    
                                </MDBListGroupItem>
                              </>
                            )
                          })}
                          
                          </MDBListGroup>
                      </div>
                    
                    
      
              </>
          );
  }