import authService from "@auth/AuthorizeService";
import { SignalRConnectionInstance } from "components/MyStore/InfoState/signalrHub";
import MyStoreInfoSignalR from "components/Notifications/NotificationMyStore";
import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export function ToastStoreMessageContainer({message, imgId, chatType, chatName, chatId}){
    return <>
        <NavLink to={`/mystore/chat?id=${chatId}`} className="d-flex" style={{color: 'initial'}}>
            {/* <img src={`/api/Store/${chatType}/${imgId}.jpg`} style={{maxWidth: '30%', borderRadius: '16px'}}/> */}
            <div className="d-flex flex-column ms-2">
                <p className="m-0" style={{fontWeight: 'bold'}}>{chatName}</p>
                <p className=""><span className="fw-bold">Новое сообщение:</span>{message.text}</p>
            </div>
        </NavLink>
    </>
}
// {
//     "id": "465b36a5-462d-49e2-8a88-c724e72aa9af",
//     "chatName": "Облака Минск",
//     "to": "611b2e5c-62f0-41bb-bad0-b90c6d7584cf",
//     "lastMessage": "",
//     "unreadCount": 0,
//     "isWriting": false,
//     "messages": []
// },

// message:
// [
//     {
//         "id": "f2d5055a-307b-419f-8491-6d0a2e84cb10",
//         "text": "Привет!\nЯ хочу заказать:\nhuskyОблака МинскЯгодный микс",
//         "isFromStore": false,
//         "date": "14:32 | июл. 31"
//     },
// ]

const MyStoreStateSlice = createSlice({
    name: "mystoreChat",
    initialState:{
        selectedChat: null,
        chats: [],
        isLoading: false,
        unreadChats: 0
    },
    reducers:{
        userWriting: (state, action) =>{
            const {userId, bool} = action.payload;
            state.chats.forEach(it =>{
                if(it.to === userId){
                    it.isWriting = bool;
                }
            })
        },
        messageFromUser: (state, action)=>{
            const {chatId, message, unreadCount, unreadChats, storeId, chatName} = action.payload.dto;
            console.log('state 123chats', action.payload)
            state.chats.forEach(it =>{
                console.log(it)
                if(it.id === chatId){
                    it.messages = [...it.messages, message]
                    it.isWriting = false;
                    if(it.id !== state.selectedChat){
                        it.unreadCount = unreadCount;
                        state.unreadChats = unreadChats
                    } else{
                        state.unreadChats = unreadChats-1;
                    }
                    
                }
            })
            console.log('state chats', state.chats.length)
            console.log()
            if(window.location.pathname != '/mystore/chat'){
                toast.info(<ToastStoreMessageContainer message={message} chatType={'store'} imgId={storeId} chatName={chatName} chatId={chatId}/>, {
                    icon: false,
                    autoClose: false,
                    
                })
                state.unreadChats = unreadChats
            }
            if(state.chats?.length === 0){
                
                state.unreadChats = unreadChats
            } 
        return state
        },
        setSetlectedStoreChat: (state, action)=>{
            console.log('Устанавливаю чат магазина', action.payload.id)
            state.selectedChat = action.payload.id
            return state
        },
        markAsReadFromUser: (state, action)=>{
            const {chatId, messageId} = action.payload;
            state.chats.forEach(chat =>{
                if(chat.id == chatId){
                    chat.lastMessageReadId = messageId;
                }
            })

        },
        setMyStoreUnreadChats: (state, action)=>{
            state.unreadChats = action.payload.unreadChats;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchChatsStore.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchChatsStore.fulfilled, (state, action) => {
            const { chats } = action.payload;
            state.isLoading = false;
            const selectedIndex = state.selectedChat !== null ? chats.findIndex(chat => chat.to === state.selectedChat) : -1;
            console.log('selectedIndex', selectedIndex)
            if (selectedIndex !== -1) {
                // Если выбран чат, обновляем только его свойство messages
                state.chats = chats.map(chat => {
                    if (chat.to === state.selectedChat) {
                        return {
                            ...chats[selectedIndex],
                            messages: state.chats.find(c => c.to === chat.to)?.messages
                        };
                    }
                    return chat;
                });
                
            } else {
                // Если чат не выбран, просто добавляем все чаты из action.payload
                state.chats= chats;
            }
        });
        builder.addCase(fetchChatsStore.rejected, (state, action) => {
            state.isLoading = false;
        });


        builder.addCase(fetchDialogStore.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDialogStore.fulfilled, (state, action) => {
            const { data, id } = action.payload;
            state.isLoading = false;
            console.log(id)
            const chat = state.chats.find(x => x.to == id)
            if(chat){
                if(chat.unreadCount > 0){
                    state.unreadChats =state.unreadChats - 1;
                }
                chat.unreadCount = 0;
                chat.messages = data.messages;
                chat.lastMessageReadId = data.lastMessageReadId;
            } else {
                state.chats.push({
                    unreadCount: 0,
                    messages: data.messages,
                    lastMessageReadId: data.lastMessageReadId,
                    to: id,
                    chatName: 'Загрузка...'
                })
            }
            
            
            // useImmer
        });
        builder.addCase(fetchDialogStore.rejected, (state, action) => {
            state.isLoading = false;
        });


        builder.addCase(sendMessageStore.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(sendMessageStore.fulfilled, (state, action) => {
            if(action.payload == null){
                return;
            }
            const {message, chatId} = action.payload;

            state.isLoading = false;
            state.chats.forEach(it => {
                if(it.to === chatId){
                    it.messages = [...it.messages, message]
                    
                }
            })
            
        });
        builder.addCase(sendMessageStore.rejected, (state, action) => {
            state.isLoading = false;
        });
    }
    
    // async thunk fetch chats, fetch dialog, but 

})
export const fetchDialogStore = createAsyncThunk('chat/fetchStoreDialog', async ({id}, {dispatch})=>{
    const token = await authService.getAccessToken();
    const res = await fetch(`/api/chat/StoreDialog/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await res.json();
    
    return {data, id}
})
export const fetchChatsStore = createAsyncThunk('chat/fetchStoreChats', async ()=>{
    console.log('fetching chat...')
    const token = await authService.getAccessToken();
    const res = await fetch(`/api/chat/StoreDialogs`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    const chats = await res.json();
    return {chats};
})

export const sendMessageStore = createAsyncThunk('chat/sendMessageFromStore', async ({to, text})=>{
    // use hub
    console.log('sendMEssage...', to)
    if(SignalRConnectionInstance){
        const res = await SignalRConnectionInstance.invoke(`SendToUser`, to, text);
        
        return {
            message: res,
            chatId: to,
        };
    }
    return null;
});

export const sendWritingEventStore = createAsyncThunk('chat/writingEventFromStore', async ({to})=>{
    if(SignalRConnectionInstance){
        const res = await SignalRConnectionInstance.invoke(`isWritingToUser`, to)
    }
})
export const sendMessageReadEventStore = createAsyncThunk('chat/sendMessageReadEventStore', async ({id, chatId})=>{
    if(SignalRConnectionInstance){
        const res = await SignalRConnectionInstance.invoke(`messageFromUserRead`, id, chatId)
    }
})

export const {messageFromUser, userWriting, setSetlectedStoreChat, markAsReadFromUser, setMyStoreUnreadChats} = MyStoreStateSlice.actions;
export default MyStoreStateSlice.reducer;