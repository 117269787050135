import React, { useEffect, useRef, useState } from "react";
import '../Client/chat.css'
import { MDBBadge, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import * as signalR from "@microsoft/signalr";
import authService from "../../../api-authorization/AuthorizeService";
import { ChatGlobal } from './../../../Global/Chat/ChatGlobal';
import { useDispatch, useSelector } from "react-redux";
import { fetchChatsStore, fetchDialogStore, sendMessageReadEventStore, sendMessageStore, sendWritingEventStore, setSetlectedStoreChat } from "components/Global/Chat/MyStore/MyStoreChatStateSlice";


export function StoreChat(){
  
  const chats = useSelector(state => state.mystore.chat.chats)
  const dispatch = useDispatch();
  const selectedChat = useSelector(state => state.mystore.chat.selectedChat)
  useEffect(()=>{
    dispatch(fetchChatsStore())
  },[])
  async function sendMessage(text, storeId){
    
    if(text.trim() === '') {
      return;
    }
    dispatch(sendMessageStore({to: storeId, text: text}))
  }

  async function fetchChat(chatId, fromCache = false){
    dispatch(fetchDialogStore({id: chatId}))
  }
  let isWritingTimeout;
  function isWriting(to){
    if(isWritingTimeout + 3000 > Date.now()) return;
    isWritingTimeout = Date.now();
    // @ts-ignore
    dispatch(sendWritingEventStore({to}))
  }
    return(
        <>
        <ChatGlobal onMessageRead={(id, chatId) => dispatch(sendMessageReadEventStore({id, chatId}))} type={'Store'} fetchChat={fetchChat} chats={chats} isWriting={isWriting} sendMessage={sendMessage} selectedChat={selectedChat} setSelectedChat={id => dispatch(setSetlectedStoreChat({id: id}))}/>
        </>
    )
  
  }