import authService from "@auth/AuthorizeService"
import { MDBCard, MDBCardHeader, MDBCardTitle, MDBBadge, MDBCardBody, MDBRow, MDBCol, MDBCardFooter } from "mdb-react-ui-kit"
import React, { useContext } from "react"
import { toast } from "react-toastify"



export function ManagerOrderItem({order, openChat}){
    
      return(
          <>
                      <MDBCard className="mt-3">
                          <MDBCardHeader className="d-flex justify-content-between align-items-center">
                            
                            <MDBCardTitle className="mb-0">
                                  Заказ № {order?.fId} {order?.username} от {order?.date}
                              </MDBCardTitle>
                              
                              <div className="d-flex align-items-center">
                              
                                {/* {order.status >= 3 && 
                                <MDBCardTitle className="mb-0 me-3">
                                    {order?.lastEdit}
                                </MDBCardTitle>
                                } */}
                                  <MDBBadge>
                                    + {order?.income} руб.
                                  </MDBBadge>
                              </div>
                              
                          </MDBCardHeader>
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol size={12} md={6}>
                                <p>Время встречи: {order?.time ?? 'jsdf'}</p>
                                <p>Место встречи: {order?.address}</p>
                                <p>Комментарий: {order?.comment}</p>
                                
                                <p>Сумма: {order?.sum} руб.</p>
                                <p>Кол-во позиций: {order?.totalQuantity} шт.</p>
                                {/* <button className="btn btn-info">Показать товары 🔽</button> */}
                              </MDBCol>
                              <MDBCol size={12} md={6}>
                                {order?.ranges.map((it, index)=>{
                                  return(
                                    <>
                                      <p>{it.name}</p>
                                      {it.flavours.map((fl, i)=>{
                                        return<>
                                          <p className="ms-2">{fl.flavourName} {fl.quantity} шт.</p>
                                        </>
                                      })}
                                    </>
                                  )
                                })}
                              </MDBCol>
                            </MDBRow>
                              
                          </MDBCardBody>
                          <MDBCardFooter className="d-flex justify-content-between">
                              <button className="btn btn-info" onClick={()=> openChat()}>Открыть чат {order?.unreadMessages > 0 && <MDBBadge color="danger">{order.unreadMessages}</MDBBadge>}</button>
                          </MDBCardFooter>
                      </MDBCard>
          </>
      )
  }