import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import { NavLink } from "react-router-dom";


export default function Features(){



    return(
        <>
            <section>
  <div className="my-5">
    <div className="card p-4 bg-primary">
      <div className="row align-items-center">
        <div className="col">
          <h4 className="mb-0 text-white">Ты продавец?</h4>
          <p className="mb-0 text-white-50">
            Присоеднийся к нам!
          </p>
        </div>
        <div className="col-auto">
          <NavLink to='/JoinUs'>
            <button type="button" className="btn btn-light" data-mdb-ripple-color="dark">Узнать подробнее</button>
          </NavLink>
        
        </div>
      </div>
    </div>
  </div>
</section>
        </>
    );
}