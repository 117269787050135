import React, { createContext, useState } from 'react';
import Modal from './DeleteModal';

const DeleteModalContext = createContext();

export const DeleteModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [type, setType] = useState(null);
  const [item, setItem] = useState(null);
  const showDeleteModal = (item, type) => {
    setItem(item);
    setType(type);
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setItem(null);
    setType(null);
  };

  return (
    <DeleteModalContext.Provider value={{ showDeleteModal, hideModal }}>
      {children}
      {<Modal show={!!item} item={item} type={type} setShow={hideModal}></Modal>}
    </DeleteModalContext.Provider>
  );
};

export default DeleteModalContext;
