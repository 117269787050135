
import React, { Component, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { SearchDiv } from "./SearchDiv";
import { MDBBadge, MDBBtn, MDBCol, MDBContainer, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import authService  from '@auth/AuthorizeService';
import useScreenSize from "hooks/ScreenSize/useScreenSize";
import { SelectUserLocation } from "./SelectUserLocation";


export function Header(){
    
    const [user, setUser] = useState(null)
    const [userDropdown, setUserDropdown] = useState(false)
    const toogleDropdownUser = () => setUserDropdown(prev => !prev)
    const screenSize = useScreenSize()
    useEffect(()=>{
        const user = authService.getUser()
        
        setUser(user)
          
          fetchCategories();
    },[])
    const [categories, setCategories] = useState([])
    
    async function fetchCategories(){
        const res = await fetch('/api/store/categories');
        setCategories(await res.json())
    }
    
    const newOrders = useSelector(state => state.mystore.info.newOrders)
    const newStoreMessages = useSelector(state => state.mystore.info.newMessages)


    const newMessages = useSelector(state => state.store.chat.unreadChats)
    const activeOrders = useSelector(state => state.store.orders.count)
    const cartItems = useSelector(state => state.store.cart.count)


        return (
            
            <header className="" style={{flex: '0 1 auto'}}>
                <div className="border-bottom">
                    <MDBContainer breakpoint="xxl" className="custom-container">
                    <div className="d-flex align-items-center justify-content-between mx-3">
                        <SelectUserLocation />
                        <div>
                            {user?.roles?.some(x => x == 'Admin') && <NavLink className='me-2' to={'/admin/'}>Админка</NavLink>}
                            {user?.roles?.some(x => x.includes('Store')) && <NavLink className='me-2' to={'/mystore/'}>Мой магазин</NavLink>}
                            
                            <NavLink to={'/news'} className="me-2">Новости</NavLink>
                            <NavLink to={'/help'}>Помощь</NavLink>

                        </div>
                        
                    </div>
                    <div className="gy-3 gx-2  d-flex">
                        <div className="d-flex align-items-center" order={1} md={5} center lg={4} xl={3} size={4}>
                            <NavLink
                                to="/"
                                
                                
                            >
                                
                                <img
                                className="logoImg"
                                src="/logo.png"
                                height={45}
                                />
                            </NavLink>
                            {/* {screenSize.width > 576 && <SelectUserLocation className="w-100 ms-4"/>} */}
                            
                        
                        </div>
                        <div className="flex-grow-1 px-3 d-flex align-items-center" size={12} lg={3} xl={4}>
                            <SearchDiv/>
                        </div>
                        
                        
                        <div className="d-flex float-end">
                        {screenSize.width > 576 && <>
                            {/* <NavbarItem icon={'store'} link={'/mystore'} name={'Магазин'} indicator={null}/> */}
                            <NavbarItem icon={'user-alt'} name={user ? user.username:"Вход"} link={user? "/profile":"/auth/login"} indicator={null}/>
                            </>}
                            
                            <NavbarItem name="Чат" badgeColor="danger" indicator={newMessages} icon={'comment'} link="/chat"/>
                            <NavbarItem name="Корзина" badgeColor="success" indicator={cartItems} icon={'shopping-cart'} link="/mycart"/>
                            <NavbarItem name="Избранное" badgeColor="success" indicator={11} icon={'heart'} link="/profile/favorites"/>
                            <NavbarItem name="Заказы" badgeColor="success" indicator={activeOrders} icon={'truck'} link="/profile/orders"/>
                            
                        </div>
                        
                    </div>
                    </MDBContainer>
                </div>
                <nav
                    className="navbar navbar-expand-lg navbar-dark bg-dark"
                    style={{ backgroundColor: "#f5f5f5", color: '#fff', display: 'none' }}
                >
                    <div className="container justify-content-center justify-content-md-between">
                    <button
                        className="navbar-toggler border  py-2"
                        type="button"
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbarLeftAlignExample"
                        aria-controls="navbarLeftAlignExample"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-bars" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarLeftAlignExample">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link " aria-current="page" to="/">
                            Домашняя страница
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/">
                            Магазины
                            </NavLink>
                        </li>
                        
                        {false && categories?.map((category, index) => {
                            return (
                                <>
                                    <li className="nav-item dropdown">
                                        <a
                                        className="nav-link dropdown-toggle mb-0"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        >
                                        {category.name}
                                        </a>
                                        
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <a className="dropdown-item" href="#">
                                            Action
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                            Another action
                                            </a>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                            Something else here
                                            </a>
                                        </li>
                                        </ul>
                                    </li>
                                </>
                            );
                        })}
                        
                        </ul> 
                        
                    </div>
                    </div>
                    
                </nav>
                
                </header>

        )
    
}

export function NavbarItem({name,icon, badgeColor = "info", link, indicator}){
    const screenSize = useScreenSize()
   return <NavLink 
                            to={link}
                            className="me-1  rounded py-1 px-3 nav-link d-flex flex-column align-items-center justify-content-center"
                            >
                            <div>
                                <i style={{color: 'white'}} className={`fas fa-${icon} m-1 me-md-2`} />
                                {indicator > 0 ? <MDBBadge color={badgeColor} notification pill>
                                {indicator}
                                </MDBBadge> : ''}
                            </div>
                            
                            
                            {screenSize.width >= 800 && <p className="d-md-block mb-0">{name}</p>}
                            </NavLink>
}