import { TranscribeToEnglish } from "components/utils/TranslitRu";
import { useTitle } from "hooks/useTitle";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { NavLink, useParams, useSearchParams } from "react-router-dom";


export function CategoryPage(){
    const [searchParams, setSearchParams] = useSearchParams()
    const {id, name} = useParams()
    const [data, setData] = useState(null)
    async function loadCategoryItems(){

        const res = await fetch(`/api/category/category?id=${id}`)
        if(res.ok){

            setData(await res.json())
        }
    }
    useEffect(()=>{
        loadCategoryItems()
    },[id])
    useTitle(`${data?.name}`)
    return(
        <>
            <h3>{data?.name}</h3>
            <MDBRow className='mb-3 gy-2 text-center'>
                    
                        {data?.items?.map((it, index)=>{
                          return (
                            <>
                            <MDBCol size={6} md={4} xl={3}>
                              <NavLink
                                  to={`/brand/${TranscribeToEnglish(it.name)}/${it.id}`}
                                  className="btn btn-warning"
                                  style={{textDecoration: 'none', color: 'black', fontWeight: 'bold', width: '100%'}}
                                  
                                >
                                  <span color="white">{it.name}</span>
                                </NavLink>
                                </MDBCol>
                            </>
                            
                          );
                        })}
                        
                      
                  </MDBRow>
            
        </>
    )
}