import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from "@auth/AuthorizeService";
import produce from 'immer'

export const WhTableSlice = createSlice({
  name: 'warehouseTable',
  initialState: {
    categories: [],
    currentCategory: null,
    isLoading: false,
    error: null,
    totalBalance: 0,
    curWarehouseId: "",
    searchText: "",
    page: 0,
    pageSize: 10,
    lastPage: false
  },
  reducers: {
    changePrices: (state, action) => {
        const {rangeId, prices} = action.payload;
        // deep search by rangeid categoies->brands->ranges
        state.categories.forEach((category)=>{
            category.brands.forEach((brand)=>{
                brand.ranges.forEach((range)=>{
                    if(range.id === rangeId){
                      range.prices = prices;
                    }
                })
            })
        }) 
    },
    resetLastPage: (state, action)=>{
        state.lastPage = false
    },
    setCurrentWhCategory: (state, action)=>{
        const {id} = action.payload;
        console.log(id)
        state.currentCategory = id;
    },
    updateSearch: (state, action)=>{
        //state.page = 0;
        state.searchText = action.payload.searchText;
        
    },
    changeAmount: (state, action) => {
        const {flavourId, amount} = action.payload;
        console.log('flavourId: ',flavourId)
        console.log('amount: ',amount)
        state.categories.forEach((category)=>{
          category.brands.forEach((brand)=>{
              brand.ranges.forEach((range)=>{
                  range.flavours.forEach((flavour)=>{
                      if(flavour.id === flavourId){
                        flavour.total = amount;
                      }
                  })
              })
          })
      }) 
    },
    changePage: (state, action) => {
        const { page } = action.payload;
        console.log('page: ',page)
        if(state.lastPage == false){
            state.page += 1;
        }
        
      },
      changeAmountMinusBulk: (state, action) => {
        const {items, rangeId} = action.payload;
        
        for (const category of state.categories) {
            for (const brand of category.brands) {
                for (const range of brand.ranges) {
                    for (const flavour of range.flavours) {
                        const it = items.find(x => x.flavourId == flavour.id)
                        console.log('it',it)
                        if (it) {
                            flavour.total -= it.quantity;
                            
                        }
                    }
                }
            }
        }
    },
    changeAmountMinus: (state, action) => {
        const {flavourId, amount} = action.payload;
        console.log('flavourId: ',flavourId)
        console.log('amount: ',amount)
        outerLoop:
        for (const category of state.categories) {
            for (const brand of category.brands) {
                for (const range of brand.ranges) {
                    for (const flavour of range.flavours) {
                        if (flavour.id === flavourId) {
                            flavour.total -= amount;
                            break outerLoop;
                        }
                    }
                }
            }
        }
    },
    addFlavours: (state, action) => {},
    
  },
  extraReducers: (builder) => {

    // categories
    builder.addCase(fetchCategories.pending, (state, action) => {
        state.isLoading = true;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
        const {categories,whid} = action.payload;
        state.isLoading = false;
        
        state.categories = categories.items;
        if(categories.items.length > 0){
            console.log(categories.items[0].id)
            state.currentCategory = categories.items[0].id;
        }
        state.page = 0;
        state.curWarehouseId = whid;
        
        state.totalBalance = categories.totalBalance;
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
    });



    builder.addCase(fetchTableData.pending, (state, action) => {
        state.isLoading = true;
    });
    builder.addCase(fetchTableData.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
            draftState.isLoading = false;
            const { brands, wh, categoryId } = action.payload;
            console.log(brands)
            if(brands.brands?.length === 0){
                draftState.lastPage = true;
                
            } else{
                draftState.categories = draftState.categories.map((it) => {
                    if (it.id === categoryId) {
                      if(draftState.page > 0){
                          console.log(draftState)
                          return {
                              ...it, // Copy the original category object
                              brands: [...it.brands, ...brands.brands] // Update the brands property
                            };
                      }
                      return {
                        ...it, // Copy the original category object
                        brands: brands.brands, // Update the brands property
                      };
                    }
                    return it; // Return other category objects unchanged
                  });
            }
            
            
            draftState.curWarehouseId = wh;
            return draftState;
          });
    });
    builder.addCase(fetchTableData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
    });




  }
})
export const fetchCategories = createAsyncThunk('warehouseTable/fetchCategories', async (whid) => {
    
    const token = await authService.getAccessToken();
        const res = await fetch(`/api/warehouse/Categories?wh=${whid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        
        if(!res.ok){
            // error
            console.log(await res.text())
            return;
        }
        const obj = await res.json();
        return {categories: obj, whid};
});

export const fetchTableData = createAsyncThunk('warehouseTable/fetchTableData', async (prms, {getState}) => {
    
    const {currentCategory, search, whid, page} = prms;
    console.log('curCat', currentCategory)
   
    const token = await authService.getAccessToken();
        const res = await fetch(`/api/warehouse/GetTable?id=${whid}&categoryId=${currentCategory}&searchText=${search}&page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
    
        });
        
        if(!res.ok){
            // error
            console.log(await res.text())
            return;
        }
        const obj = await res.json();
        return {brands: obj, wh: whid, categoryId: currentCategory};
});
// Action creators are generated for each case reducer function
export const { changePrices, addFlavours, changeAmount, changeAmountMinus, changeAmountMinusBulk, setCurrentWhCategory, updateSearch, changePage, resetLastPage } = WhTableSlice.actions

export default WhTableSlice.reducer
