import { MDBTextArea } from "mdb-react-ui-kit";
import React, { useRef, useState } from "react";

export function AutoGrowTextArea(element){
    console.log(element.scrollHeight,element.style.height)
    element.style.height = "35px";
    element.style.height = (element.scrollHeight) + "px";
}

export const AutoTextArea = () => {
	const textAreaRef = useRef(null);
	const [text, setText] = useState("");
	const [textAreaHeight, setTextAreaHeight] = useState("auto");
	const [parentHeight, setParentHeight] = useState("auto");

	const parentStyle = {
		minHeight: parentHeight,
	};

	const textAreaStyle = {
		height: textAreaHeight,
	};

	return (
		<div style={parentStyle}>
			<MDBTextArea inputRef={textAreaRef}
				style={textAreaStyle}
				rows={1}
			/>
		</div>
	);
};
export function getNounPluralForm(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  }
export function autoDeclensionTimes(num){
	const root = 'раз'
	return getNounPluralForm(num, 'раз', "раза", "раз")
}
export function autoDeclensionReviews(num) {
	return getNounPluralForm(num, 'отзыв', "отзыва", "отзывов")
}
export function AvailabilityStatusColor(amount){
    if(amount==0){
        return 'danger'
    }
    if(amount <=5)
        return 'warning'
    return ''
}
export function AvailabilityStatusText(amount){
    if(amount==0){
        return '(Недоступен)'
    }
    if(amount <=5)
        return `${amount} шт`
    return ''
}