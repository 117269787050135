import { AvatarBox } from "components/Global/AvatarBox";
import { MDBCard, MDBCardBody, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { useSelector } from "react-redux";
import { RatingStars } from "./StoresTab";
import Skeleton from "react-loading-skeleton";



export function ReviewsTab({}){
    const reviews = useSelector(state => state.store.productPage.reviews)
    const isLoadingReviews = useSelector(state => state.store.productPage.isLoadingReviews)
    return(
        <>
        {isLoadingReviews && [1].map((it, i)=> {
                return <Skeleton className="mt-3" height='160px'/>
            })}
        {!isLoadingReviews && reviews?.map((it, key)=>{
            return <>
                <ReviewItem review={it} key={key}/>
            </>
        })}
        
        {!isLoadingReviews && reviews?.length == 0 && 
                <div className="text-center w-100">
                    <p>Ещё нет отзывов на данный товар</p>
                    <p className="text-muted">Вы можете оставить отзыв первым</p>
                </div>
                    }
            
        </>
    )
}
function ReviewItem({review}){
    return <>
    
    <MDBCard style={{backgroundColor: '#363636'}} className="mt-3">
            <MDBCardBody>
                <div className="d-flex justify-content-between align-items-start">
                    <div className="">
                    
                    <div className="d-flex">
                        <AvatarBox letter={review?.username?.[0] ?? ')'}/>
                        
                        <div>
                            <RatingStars rating={review?.rating}/>
                            <div  className="d-flex">
                                <p className='text-muted'>@{review?.username}</p>
                                <p className="text-muted ms-2">{review?.date}</p>

                            </div>
                        </div>
                    </div>
                    <div style={{lineHeight: '2rem'}}>
                                    {review?.benefits && <div className="d-flex align-items-center">
                                        <MDBIcon color="muted" fas icon="plus-circle" /> <p className="ms-2"> {review?.benefits}</p>
                                    </div>}
                                    {review?.disadvantages && <div className="d-flex align-items-center">
                                        <MDBIcon color="muted" fas icon="minus-circle" /> <p className="ms-2"> {review?.disadvantages}</p>
                                    </div>}
                                    {review?.commnet && <div className="d-flex align-items-center">
                                        <MDBIcon color="muted" fas icon="comment-dots" /> <p className="ms-2"> {review?.commnet}</p>
                                    </div>}
                    </div>
                    
                    </div>
               </div>
                    
               

            </MDBCardBody>
        </MDBCard>
    </>
}