import { configureStore } from "@reduxjs/toolkit";
import ClientChatStateSlice from "components/Global/Chat/Client/ClientChatStateSlice";
import ChatStateSlice from "components/Global/Chat/Client/ClientChatStateSlice";
import MyStoreChatStateSlice from "components/Global/Chat/MyStore/MyStoreChatStateSlice";
import MyCourierStateSlice from "components/MyStore/CourierPage/CourierView/CourierStateSlice";

import InfoStateSlice from "components/MyStore/InfoState/MyStoreInfoStateSlice";
import UserInfoStateSlice from "components/MyStore/InfoState/UserInfoStateSlice";
import OrdersStateSlice from "components/MyStore/Orders/OrdersState/OrdersStateSlice";
import WhTableReducer from "components/MyStore/warehouse/state/WarehouseTalbeSlice";
import CartStateSlice from "components/store/Cart/MyCartState/CartStateSlice";
import MyManagerStateSlice from './components/MyStore/ReferralPage/ManagerView/ManagerStateSlice';
import ShopPageStateSlice from "components/store/ShopPage/ShopPageStateSlice";
import LocationsStateStore from "components/admin/LocationsPage/LocationsStateStore";
import MyStoreEditStateSlice from "components/MyStore/Edit/MyStoreEditStateSlice";
import SelectUserLocationStateSlice from "components/layouts/Header/SelectUserLocationStateSlice";
import ProductPageStateSlice from "components/store/ProductPage/ProductPageStateSlice";
import UserOrdersPageStateSlice from "components/store/Profile/Orders/UserOrdersPageStateSlice";
import ExpensesStateSlice from "components/MyStore/Analytics/Expenses/ExpensesStateSlice";
import TableStateSlice from "components/admin/PriceList/table/TableStateSlice";



export const store = configureStore({
    reducer: {
        //warehouseTable: WhTableReducer,
        mystore: mystoreReducers,
        store: storeReducers,
        admin: adminReducers
    }
})
function storeReducers(state = {}, action){
    
    return{
        cart: CartStateSlice(state.cart, action),
        chat: ClientChatStateSlice(state.chat, action),
        info: UserInfoStateSlice(state.info, action),
        shopPage: ShopPageStateSlice(state.shopPage, action),
        productPage: ProductPageStateSlice(state.productPage, action),
        locations: SelectUserLocationStateSlice(state.locations, action),
        orders: UserOrdersPageStateSlice(state.orders, action)
    }
} 

function mystoreReducers(state = {}, action){
    
    return{
        warehouseTable: WhTableReducer(state.warehouseTable, action),
        orders: OrdersStateSlice(state.orders, action),
        info: InfoStateSlice(state.info, action),
        chat: MyStoreChatStateSlice(state.chat, action),
        myCourier: MyCourierStateSlice(state.myCourier, action),
        myManager: MyManagerStateSlice(state.myManager, action),
        locations: MyStoreEditStateSlice(state.locations, action),
        expenses: ExpensesStateSlice(state.expenses, action)
    }
} 
function adminReducers(state = {}, action){
    return{
        locations: LocationsStateStore(state.locations, action),
        priceList: TableStateSlice(state.priceList, action)
    }
}