import React from "react";



class MyStoreInfoSignalR{
    constructor(url){
        this.url = url;
        this.sokcet = null;
        this.listeners = []
    }
    connect(){

    }
    disconnect(){

    }
    addMessageListener(listener) {
        this.listeners.push(listener);
    }
    
    removeMessageListener(listener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
    }
}
export default MyStoreInfoSignalR;