import { MDBCol, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardBody, MDBCardFooter, MDBListGroup, MDBListGroupItem, MDBRow, MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { OrderPage } from "./OrderPage";

import { NavLink, useSearchParams } from "react-router-dom";
import { getColorByOrderStatus } from "components/MyStore/Orders/OrderPage/OrderPage";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserOrders } from "./UserOrdersPageStateSlice";
import Skeleton from "react-loading-skeleton";
import { useTitle } from "hooks/useTitle";


export function OrdersLayoutProfile(){
    const dispatch = useDispatch()
    const orders = useSelector(state => state.store.orders.orders)
    const isLoadingOrders = useSelector(state => state.store.orders.isLoadingOrders)
    const [showOrderPage, setShowOrderPage] = useState(null);
    const [search, setSearch] = useSearchParams();
    function ClosePage(){
        search.delete('order');
        setSearch(search);
        setShowOrderPage(null);
    }
    useEffect(()=>{
        dispatch(fetchUserOrders())
    },[])
    function setSearchOrder(orderId){
        search.set('order', orderId); 
        setSearch(search)
    }
    useEffect(()=>{
        console.log(search.get('order'));
        const order = orders.find((it)=>it.id == search.get('order'));
        if(order == null) return;
        setShowOrderPage(order);
    }, [search, orders])

    useTitle('Заказы')
    // if(showOrderPage != null){
        
        
    //     return(
    //         <>
    //             <OrderPage showOrderPage={showOrderPage} setShowOrderPage={ClosePage} />
    //         </>
    //     )
    // }


    return(
        <>
         <NavLink to={'/profile/'} className="collapse backToProfileBtn btn btn-info me-2" ><MDBIcon color="white" fas icon="arrow-left" /></NavLink>
        <h4 className="d-inline-block m-0">Твои заказы</h4>
        <MDBRow className="mt-2">

            {isLoadingOrders && [1,2,3,4,5,6,7,8,9,10].map((it, index) => <MDBCol size={12} className={`${index == 0 ? "" : "mt-2"}`}><OrderItem order={null} setSearch={()=>{}}/></MDBCol>)}
            {orders.map((order, index) => {
                
                return(
                    <>
                        <MDBCol size={12} className={`${index == 0 ? "" : "mt-2"}`}>
                            <OrderItem order={order} setSearch={setSearchOrder}/>
                        </MDBCol>
                    </>
                )
            })}
            {!isLoadingOrders && orders?.length == 0 && <div>
            <p>У вас пока нет заказов</p>
            <NavLink to={'/'} className="btn btn-primary">Перейти к покупкам</NavLink>
            </div>}
            </MDBRow>
        </>
    )
}

function OrderItem({order, setSearch}){

    return<>
    <NavLink to={order?.id ? order.id : '#'} className='text-white'>
        <MDBCard style={{cursor: 'pointer'}}>
                                <MDBCardBody className="">
                                    <div className="d-flex  align-items-center">
                                    <MDBCardTitle className="flex-grow-1">№ {order?.fId || <Skeleton width='200px'/>} {order?.store?.name}</MDBCardTitle>
                                    {order?.status ?  <MDBBadge color={getColorByOrderStatus(order?.status)} className="text-center" style={{fontSize: '1rem', paddingTop: 10}}>
                                        {order?.status}
                                    </MDBBadge> : <Skeleton width={120}/>}
                                    </div>
                                    <div className="d-flex mt-2">
                                        <span className="flex-grow-1" style={{fontSize: '0.9rem'}}>{order?.date || <Skeleton width={100}/>}</span>
                                        {order?.sum ? <MDBBadge style={{fontSize: '0.9rem', paddingTop: 6}}>
                                            {order?.sum } руб.
                                        </MDBBadge> : <Skeleton width='75px'/>}
                                    </div>
                                    
                                    
                                </MDBCardBody>
                                
                            </MDBCard>
    </NavLink>
        
    </>
}