import React, { useEffect, useRef, useState } from "react";
import { StyledDropzone } from "../Dropzone";
import AvatarEditor from "react-avatar-editor";
import { MDBContainer, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRange } from "mdb-react-ui-kit";
import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";
import useMeasure from "react-use-measure";
import SortableList, { SortableItem } from "react-easy-sort";
import { useDispatch } from "react-redux";
import { arrayMoveImmutable } from "array-move";



export function EditPickupPhotos(){
    const [ids, setIds] = useState([])
    
    async function OnAddPhoto(fd){
        const token = await authService.getAccessToken()
        const res = await fetch('/api/mystore/PickupPhotos', {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: fd
        })
        if(res.ok){
            // close modal
            const image = await res.json()
            setIds(prev => [...prev, image.id])
        } else {
            toast.error('Ошибка загрузки фото')
        }
    }
    async function getPhotosIds(){
        const token = await authService.getAccessToken()
        const res = await fetch('/api/mystore/PickupPhotos', {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        })
        if(res.ok){
            setIds(await res.json())
        } else {
            toast.error('Ошибка загрузки фото')
        }
    }
    useEffect(()=>{
        getPhotosIds()
    }, [])
    const onSortEnd = async (oldIndex, newIndex) => {
        const newArr = arrayMoveImmutable(ids, oldIndex, newIndex)
        setIds((array) => newArr)
        const token = await authService.getAccessToken()
        const res = await fetch('/api/mystore/updateImageOrder', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(newArr)
        })
        if(res.ok){
            
        }
      }
    const [toDeleteImg, setToDeleteImg] = useState(null)
    async function deleteImg(id){
        const token = await authService.getAccessToken();

        const res = await fetch(`/api/mystore/PickupPhotos/${id}`,{
            headers: {
                "Authorization": `Bearer ${token}`
            },
            method: 'DELETE'
        })
        if(res.ok){
            setIds(prev => prev.filter(x => x != id))
        } else {
            toast.error('Ошибка удаления')
        }
    }
    return <>
        {/* <div className="mt-3">
            <h5>Добавь часы работы, часы доставки, дни работы</h5>
            
        </div> */}
        
        <div className="mt-3 d-flex justify-content-between flex-wrap">
        <h5>Добавь фото самовывоза</h5>
            
            <LoadPhotoModal onAdd={OnAddPhoto}/>
        </div>
        <div className="mt-2" style={{maxHeight: '400px', overflowX:'auto'}}>
            <SortableList onSortEnd={onSortEnd} className="d-flex flex-wrap">
                {ids.map(it => <SortableItem key={it}>
                    <div className="p-1" style={{position: 'relative'}}>
                        <img  style={{objectFit: 'fill', aspectRatio: '16/9'}} width={'300px'} src={`/photos/pickup/${it}.jpg`}/>
                        <div style={{position: 'absolute', top: 0, right:0}}>
                        {it == toDeleteImg && <button onClick={()=>setToDeleteImg(null)} className="btn btn-sm btn-primary btn-floating me-2"> <MDBIcon size="2x"  fas icon="times" /> </button>}
                        <button onClick={()=> {if(it == toDeleteImg) deleteImg(it)}}  className="btn btn-danger btn-floating btn-sm">
                            {toDeleteImg == it ? <MDBIcon size="2x"  fas icon="check" /> : <MDBIcon size="2x"  fas icon="times-circle" onClick={()=>{setToDeleteImg(it)}}/>}
                        </button>
                        </div>
                        
                    </div>
                </SortableItem>)}
            </SortableList>
           
        </div>
        
        
    </>
}


function LoadPhotoModal({onAdd}){
    const editor = useRef()
    const [image, setImage] = useState(null)
    const [zoomValue, setZoomValue] = useState(1.0)
    const [modal, setModal] = useState(false)
    function onAddInner(fd){
        onAdd(fd).then(()=>{
            setModal(false)
        })
    }
    async function uploadAvatar(){
       
        if(editor.current){
          const canvas = editor.current.getImage();
          const dataUrl = canvas.toDataURL('image/jpeg', 0.8)
          const dataURItoBlob = (dataURI)=> {
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
          }
          var blob = dataURItoBlob(dataUrl);
          var fd = new FormData();
          const file = new File( [blob], 'canvasImage.jpg', { type: 'image/jpeg' } );
          fd.append("file", file);
      
          onAddInner(fd)
      
        }
      }
    
    
    const [ref, bounds] = useMeasure({debounce: 1})
    useEffect(()=>{console.log('new width...', bounds)}, [bounds])
    return <>
    <button className="btn btn-success" onClick={()=>setModal(true)}>Загрузить фото</button>
    <MDBModal show={modal} setShow={setModal}>
        <MDBModalDialog>
            <MDBModalContent>

            
       
                <MDBModalHeader><MDBModalTitle>Загрузить фото</MDBModalTitle></MDBModalHeader>
                <MDBModalBody>
                    {image ? <div ref={ref} style={{width: '100%', aspectRatio: '16/9', position: 'relative'}}>
                        <AvatarEditor className='mt-1' ref={editor} width={bounds.width} height={bounds.height} style={{height: '100%'}}  border={0} image={image} scale={zoomValue}/>
                        <button onClick={()=> setImage(null)} className="btn btn-danger btn-floating btn-sm" style={{position: 'absolute', top: -10, right:-10}}> <MDBIcon fas icon="trash-alt" /></button>
                        </div> : 
                    <StyledDropzone onDrop1={(dropped) => setImage(dropped[0])}/> }
                    
                    
                    <MDBRange style={{maxWidth: '250px'}} label="Увеличение" labelId='range' id='range' defaultValue={1} max={5} min={1} step={0.1.toString()} onChange={(x) => setZoomValue(Number(x.target.value))}  />
                    
                </MDBModalBody>
                <MDBModalFooter className="d-flex justify-space-between">
                <button className="btn btn-danger" onClick={()=> setModal(false)}>Отмена</button>
                <button className="btn btn-success" onClick={uploadAvatar}>Загрузить</button>
                </MDBModalFooter>
        </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
        
    </>
}