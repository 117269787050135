
import { MDBInputGroup, MDBInput, MDBBtn, MDBIcon, } from "mdb-react-ui-kit";
import React from "react";
import { Component, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

export function SearchDiv() {
    
    
    const navigate = useNavigate();
    const [searchString, setSearchString] = useState("");
    const [url, setUrl] = useSearchParams();
    async function search(){
        //console.log(searchString)
        const searchString1 = searchString.toLowerCase().trim();
        if(searchString1 == ""){
            return;
        }
        //this.props.navigate('/search?str=123a')
        //this.setState({navi: true})
        navigate({pathname: '/search',
        search: `?s=${searchString1}`});

    }
    function onKeyDown(event){
        if(event.key === 'Enter'){
            event.preventDefault();
            event.stopPropagation();
            search();
        }
    }
    
        
        return (
            <>
                <MDBInputGroup tag="form" className='d-flex rounded-2 align-self-center' style={{backgroundColor: '#5e5e5e', height: '35px'}}>
                    {/* <MDBInput className='h-100' label="Поиск"  placeholder="Искать на vape market" style={{padding: '0 30px'}} type='text' defaultValue={url.get('s')} onKeyDown={onKeyDown} onChange={(x)=> setSearchString(x.target.value)} autoComplete="off"/> */}
                    {/* <div className="form-outline" style={{height: '35px'}}> */}
                        <input className='form-control h-100' style={{padding: '0 0 0 30px', borderColor: 'transparent'}} placeholder="Искать на vape market" type='text' defaultValue={url.get('s')} onKeyDown={onKeyDown} onChange={(x)=> setSearchString(x.target.value)} autoComplete="off"/>
                        <MDBIcon style={{position: 'absolute', top: '9px', left: '10px'}} fas icon="search" />
                    {/* </div> */}
                    <MDBIcon style={{position: 'absolute', top: '9px', left: '10px'}} fas icon="search" />
                    <button onClick={() => search()} type="button" className="flex-shrink-1 btn btn-primary" >
                    <MDBIcon icon='search' />
                    </button>
                </MDBInputGroup>
                {/* <MDBInputGroup className="d-flex flex-nowrap">
                <div className="form-outline">
                    <input type="search" id="form1" defaultValue={url.get('s')} onKeyDown={onKeyDown} className="form-control" onChange={(x)=> setSearchString(x.target.value)} autoComplete="off"/>
                    <label className="form-label" for="form1" >Поиск</label>
                </div>
                    <button onClick={() => search()} className="flex-shrink-1 btn btn-primary" rippleColor='dark'>
                    <MDBIcon icon='search' />
                    </button>
                </MDBInputGroup> */}

                
            </>
            // <div className="input-group float-center" style={{flexWrap: "nowrap"}}>
            //     <div className="form-outline">
            //     <input onKeyDown={onKeyDown} type="search" id="form1" onChange={(x)=> setSearchString(x.target.value)} className="form-control" />
            //     <label className="form-label" htmlFor="form1">
            //         Поиск
            //     </label>
            //     </div>
            //     <button type="button" onClick={search} className="btn btn-primary shadow-0">
            //         <i className="fas fa-search" />
            //     </button>
                
            // </div>
           
        );
    
}