import authService from "@auth/AuthorizeService";
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";




export function FireWorkerModal({worker, setShowParent, type}){
    async function applyFire(){
        const token = await authService.getAccessToken()
        const res=  await fetch(`/api/mystore/workers/delete${type}?id=${worker?.id}`,{
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${token}`
            },

        })
        if(res.ok){
            setModal(false)
        }
        else {
            toast.error("Ошибка удаления")
        }
    }
    const [modal, setModal] = useState(false)
    
    return <>
        <button type='button' className='btn btn-danger mt-3' onClick={() => setModal(true)}>
                    Уволить 
                </button>
        <MDBModal show={modal} setShow={setModal} >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Вы точно хотите уволить {worker?.username}</MDBModalTitle>
              <button className='btn btn-close' color='none' onClick={()=>setModal(false)}></button>
            </MDBModalHeader>
            <MDBModalBody>
                <p>Курьер будет заморожен</p>
                <p>Проверьте, что весь товар у вас и вы полностью расчитаны перед ним</p>
                <p>Не должно быть активных заказов</p>
                <p>Склад должен быть пустым</p>
                
            </MDBModalBody>

            <MDBModalFooter className='d-flex justify-content-between'>
              <button type='button' className='btn btn-primary' onClick={() => setModal(false)}>
                Помилую {type}
              </button>
              <button className='btn btn-danger' onClick={() => {applyFire()}}>
                Я хочу его уволить!
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    </>
}