import React, { useEffect, useState } from "react";

import { CategoriesTabs } from "./table/Categories";
import { DeleteModalProvider } from "./table/DeleteModal/DeleteModalContext";
import { AddBrandRangeModalProvider } from "./addBrand/AddBrandRangeModalContext";
import { useDispatch } from "react-redux";
import { fetchCategoriesAdmin } from "./table/TableStateSlice";


export function PriceList(){
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(fetchCategoriesAdmin())
    },[])
    return (<>
        <h2>Прайс лист</h2>
        <AddBrandRangeModalProvider>
            <DeleteModalProvider>
                
                    <CategoriesTabs />
                
                
            </DeleteModalProvider>
        
        </AddBrandRangeModalProvider>
        
        </>);
}