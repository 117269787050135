
import { ActiveOrdersModal } from "components/MyStore/Chat/ActiveOrdersModal"
import useScreenSize from "hooks/ScreenSize/useScreenSize";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBListGroup, MDBListGroupItem, MDBBadge } from "mdb-react-ui-kit"
import React, { useCallback, useEffect, useRef, useState } from "react"
import './ChatGlobal.css'
import { useSelector } from "react-redux";
import { ChatDropzone } from "components/MyStore/Dropzone";
import {DragAndDropFull} from "./DragAndDropFull";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MobileChatContatcs } from "./Mobile/MobileChatContacts";
import { useTitle } from "hooks/useTitle";

export function ChatGlobal({fetchChat, chats, isWriting, sendMessage, type, selectedChat, setSelectedChat, onMessageRead}){

  
  //const navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const screen = useScreenSize()
  const mobWidth = 992;
  function setCurChat(id){
    console.log('!!! я тут sdfsdf', id)
    
    if(id){
      setSearch({id: id})
      
        fetchChat(id)
      
      
    } else {
      setSearch({})
    }
    
    setSelectedChat(id)
  }
  useEffect(()=>{
    console.log()
    setCurChat(selectedChat || search.get('id'))
  },[])
  useTitle(`Чат`)
  if(screen.width <= mobWidth){
    return (
      <>
        {selectedChat == null ?
            <MDBCol md={12} lg={5} xl={5} className="mb-4">
              <MobileChatContatcs chats={chats} selectedChat={selectedChat} setSelectedChat={setCurChat} />
            </MDBCol>
          :
            <MDBCol md={12} lg={7} xl={7} style={{height: 'inherit'}} className="h-100 px-0">
              <ChatMessages onMessageRead={onMessageRead} type={type} currentChat={chats.find(chat => chat.to === selectedChat)} isWriting={isWriting} sendMessage={sendMessage} backBtn={screen.width < mobWidth} setSelectedChat={setCurChat}/>
            </MDBCol>
        }
      </>
    )
  }
  return(<>
  
    <MDBCard className="h-100" style={{ borderRadius: 15}}>
      <MDBCardBody className="h-100" >
        <MDBRow className="h-100">
        
        
        <MDBCol md={12} lg={5} xl={5} style={{borderRight: '1px #7f7f7f solid'}} className="h-100 px-0 py-0">
          <ChatContatcs chats={chats} selectedChat={selectedChat} setSelectedChat={setCurChat}/>
        </MDBCol>
        
        
        {selectedChat !== null &&
          <MDBCol md={12} lg={7} xl={7} className="h-100 py-0 px-0">
            <ChatMessages onMessageRead={onMessageRead} type={type} currentChat={chats.find(chat => chat.to === selectedChat)} isWriting={isWriting} sendMessage={sendMessage} backBtn={screen.width < mobWidth} setSelectedChat={setCurChat}/>
          </MDBCol>
        }
        </MDBRow>
        
        
        
      </MDBCardBody>
    </MDBCard>
  
    
  
  </>)
}

function ChatContatcs({chats, setSelectedChat, selectedChat}){
  
  
        return(
          
            <>
                  
                    <div className="p-3 d-flex flex-column flex-grow-1" >
                      
                      <h4>Сообщения</h4>
                      <MDBListGroup className="h-100 " dark style={{ overflowX: 'clip',overflowY: 'auto'}}>
                        
                      {chats.map((chat, index) => {
                          return (
                            <>
                              <MDBListGroupItem
                              key={index}
                              className="px-3 rounded mb-2 d-flex justify-content-between align-items-center"
                              active={chat.to == selectedChat} action color="dark" 
                              style={{cursor: 'pointer', paddingLeft: '15px', height: '100px', paddingRight: '5px', }} 
                              onClick={()=> {console.log(chat.id, 'Из карточки');setSelectedChat(chat.to)}}
                              >
                                  <div className='d-flex align-items-center'>
                                  <img
                                      src={`/photos/store/${chat.to}.jpg`}
                                      alt=''
                                      style={{ width: '45px', height: '45px' }}
                                      className='rounded-circle'
                                  />
                                  <div className='ms-3' style={{maxWidth: '200px'}}>
                                      <p className='fw-bold mb-1'>{chat.chatName}</p>
                                      <p
                                      className='text-muted mb-0'
                                      style={{overflow: 'hidden',display: '-webkit-box',WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2, lineClamp: 2,textOverflow: 'clip'}}
                                      >
                                        {chat?.isWriting ? <p className="mb-0" style={{color: 'green'}}>Печатает...</p> :
                                        
                                        (chat?.messages?.length > 0 ? chat.messages[chat.messages.length-1].text : chat?.lastMessage.text)
                                      }
                                      </p>
                                  </div>
                                  </div>
                                  {chat.unreadCount !== 0 && <MDBBadge pill color='danger'>
                                  {chat.unreadCount}
                                  </MDBBadge>}
                                  
                              </MDBListGroupItem>
                            </>
                          )
                        })}
                        
                        </MDBListGroup>
                        {/* <div className="d-flex flex-column flex-1 bg-light h-auto" onClick={()=> {setSelectedChat(null)}}>

                        </div> */}
                    </div>
                  
                  
    
            </>
        );
}
function ChatMessages({currentChat, sendMessage, isWriting, backBtn = false, setSelectedChat, type, onMessageRead}){
  
  function handleKeyDown(e) {
    if(e.keyCode == 13){
      form.current.click()
      e.preventDefault()
      return
    }
    e.target.style.height = 'inherit';
    e.target.style.height = `${Math.min(e.target.scrollHeight, 120)}px`;
  }
  
  const form = useRef()
  const bottomRef = useRef(null);
  const markAsRead = useRef(true);
  useEffect(()=>{
    if(currentChat?.lastMessageReadId == null) {
      markAsRead.current = false
    } else {
      markAsRead.current = true
    }
    bottomRef?.current?.scrollIntoView({behavior: 'auto'});
  }, [currentChat])
  async function sendMessageLocal(e) {
    e.preventDefault();
    // markAsRead.current = true
    
    const text = e.target.elements[0].value;
    await sendMessage(text, currentChat?.to)
    e.target.reset();
  }


  const [isVisible, setIsVisible] = useState(false);
  const onDragEnter = useCallback((e) => {
    //e.stopPropagation()
    e.preventDefault();
    if (e.currentTarget === document) {
      count.current += 1
    }
    console.log(e.target)
    setIsVisible(true);
    console.log('is drag enter');
    return false;
}, []);
let count = useRef(0)
const onDragLeave = useCallback((e) => {
  //e.stopPropagation()
    e.preventDefault();
    if (e.currentTarget === document) {
      count.current = 0
   }
 
   if (count.current === 0) {
    setIsVisible(false)
     // Handle drag leave.
   }
    
    
    return false;
}, []);

const onDragOver = useCallback((e) => {
  e.stopPropagation();
    e.preventDefault();
    
    return false;
}, []);

const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log('Files dropped: ', files);
    setIsVisible(false);
    return false;
}, []);

useEffect(() => {
    const handleDragEnter = (e) => onDragEnter(e);
    const handleDragLeave = (e) => onDragLeave(e);
    const handleDragOver = (e) => onDragOver(e);
    const handleDrop = (e) => onDrop(e);
    const hz = document
    hz.addEventListener('dragleave', handleDragLeave);
    hz.addEventListener('dragenter', handleDragEnter);
    hz.addEventListener('dragover', handleDragOver);
    hz.addEventListener('drop', handleDrop);

    return () => {
      hz.removeEventListener('dragenter', handleDragEnter);
      hz.removeEventListener('dragleave', handleDragLeave);
      hz.removeEventListener('dragover', handleDragOver);
      hz.removeEventListener('drop', handleDrop);
    };
}, [onDragEnter, onDragLeave, onDragOver, onDrop]);

  //const [search, setSearch] = useSearchParams()


return <>
      <div className="d-flex flex-column h-100">
      <div color="wheat" style={{width: '100%'}} className="block-content d-flex pb-3 align-items-center border-bottom">
              
              {backBtn && <button className="btn btn-info me-3" onClick={e => setSelectedChat(null)}><MDBIcon color="white" fas icon="arrow-left" /></button>}
              <p className="mb-0 ps-3" > {currentChat?.chatName}</p>
              {currentChat?.isWriting && <p className="mb-0 ms-2" style={{color: 'green'}}>Печатает...</p>}
              {/* <p className="mb-0"  style={{color: 'gray'}}>В сети 12:45 | авг. 12</p>
              <p className="mb-0"  style={{color: 'green'}}>Онлайн</p> */}
              <ActiveOrdersModal to={currentChat?.to} type={type}/>
              
      </div>
      <div className="flex-grow-1" style={{overflowY: 'scroll', height: 0}}>
               
                {/* <DragAndDropFull/> */}
                {/* {isVisible ? <>
                <ChatDropzone onDrop1={()=>{}}/>
                </> : <p>sdf</p> } */}
                
                <div className="d-flex flex-column ">
                  {console.log('curChat',currentChat)}
                  
                  {currentChat?.messages?.map((message, index) => {
                    
                  
                    // if(index == 0){
                    //   markAsRead.current = true
                    // }
                    
                    if(currentChat.lastMessageReadId === message.id){
                      console.log('unread message', message)
                      markAsRead.current = false;
                      if(type === 'User'){
                        return <ClientMessage markAsRead={true} message={message} onView={(id)=> onMessageRead(id, currentChat.id)}/>
                      } else{
                        
                        return <StoreMessage key={message.id} message={message} markAsRead={true} onView={(id)=>{onMessageRead(id, currentChat.id)}}/>
                      }
                    }
                    if(type === 'User'){
                      return <ClientMessage markAsRead={markAsRead.current} message={message} onView={(id)=> onMessageRead(id, currentChat.id)}/>
                    } else{
                      
                      return <StoreMessage key={message.id} message={message} markAsRead={markAsRead.current} onView={(id)=>{onMessageRead(id, currentChat.id)}}/>
                    }
                    
                      
                    
                  })}
                </div>
                <div ref={bottomRef}/>
                
                </div>
                



                <form className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3" onSubmit={sendMessageLocal}
                  style={{}}
                 >
                  
                    
                    <div className="w-100 divOfTextarea">
                      <textarea
                        
                        className=" messageArea form-control"
                        id="exampleFormControlInput2"
                        name="text"
                        onInput={(e)=> {isWriting(currentChat?.to); e.currentTarget.style.height = 'auto';
                          
                            e.currentTarget.style.height = (e.currentTarget.scrollHeight < 200 ? e.currentTarget.scrollHeight : 200) + 'px';
                          
                        }}
                        onKeyDown={handleKeyDown}
                        placeholder="Сообщение"
                      />
                    </div>
                    
                    
                    <button disabled={isVisible} className="ms-1 text-muted btn m-0 p-2">
                      <i className="fas fa-paperclip" />
                    </button>
                    
                    <button ref={form} type="submit" disabled={1 == null} className="btn btn-success ms-3">
                      <i className="fas fa-paper-plane" />
                    </button>
                </form>
</div>
      
                
                
                  
                  
                  
</>
    return(<>
    
                  <div color="wheat" style={{width: '100%'}} className="d-flex pb-3 align-items-center border-bottom">
                  
                    {backBtn && <button className="btn btn-info me-3" onClick={e => setSelectedChat(null)}><MDBIcon color="white" fas icon="arrow-left" /></button>}
                    <p className="mb-0 ps-3" > {currentChat?.chatName}</p>
                    {currentChat?.isWriting && <p className="mb-0 ms-2" style={{color: 'green'}}>Печатает...</p>}
                    {/* <p className="mb-0"  style={{color: 'gray'}}>В сети 12:45 | авг. 12</p>
                    <p className="mb-0"  style={{color: 'green'}}>Онлайн</p> */}
                    <ActiveOrdersModal to={currentChat?.to} type={type}/>
                    
                  </div>
                  <div
                  
                    className="pt-3 pe-3 flex-grow-1"
                    data-mdb-perfect-scrollbar="true"
                    style={{ position: "relative",  overflowY: 'scroll', height: 'calc(100vh - 330px)' }}
                    
                  >
                    <DragAndDropFull/>
                    {isVisible ? <>
                    <ChatDropzone onDrop1={()=>{}}/>
                    </> : currentChat?.messages?.map((message, index) => {
                      
                      if(isVisible){
                        return <p>sdfli</p>
                      }
                      if(index == 0){
                        markAsRead.current = true
                      }
                      if(currentChat.lastMessageReadId === message.id){
                        markAsRead.current = false;
                        if(type === 'User'){
                          return <ClientMessage markAsRead={true} message={message} onView={(id)=> onMessageRead(id, currentChat.id)}/>
                        } else{
                          
                          return <StoreMessage key={message.id} message={message} markAsRead={true} onView={(id)=>{onMessageRead(id, currentChat.id)}}/>
                        }
                      }
                      if(type === 'User'){
                        return <ClientMessage markAsRead={markAsRead.current} message={message} onView={(id)=> onMessageRead(id, currentChat.id)}/>
                      } else{
                        
                        return <StoreMessage key={message.id} message={message} markAsRead={markAsRead.current} onView={(id)=>{onMessageRead(id, currentChat.id)}}/>
                      }
                      
                        
                      
                    })}
                    
                    
                    <div ref={bottomRef}/>
                    </div>
                   <form onSubmit={sendMessageLocal}>
                    <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                      {/* <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                        alt="avatar 3"
                        style={{ width: 40, height: "100%" }}
                      /> */}
                      <div className="divOfTextarea w-100">
                        <textarea
                          
                          className="messageArea form-control form-control-lg"
                          id="exampleFormControlInput2"
                          name="text"
                          onInput={(e)=> {isWriting(currentChat?.to); e.currentTarget.style.height = 'auto';
                            
                              e.currentTarget.style.height = (e.currentTarget.scrollHeight < 200 ? e.currentTarget.scrollHeight : 200) + 'px';
                            
                          }}
                          onKeyDown={handleKeyDown}
                          placeholder="Чиркани тут"
                        />
                      </div>
                      
                      
                      <button disabled={isVisible} className="ms-1 text-muted btn m-0 p-2">
                        <i className="fas fa-paperclip" />
                      </button>
                      
                      <button ref={form} type="submit" disabled={1 == null} className="btn btn-success ms-3">
                        <i className="fas fa-paper-plane" />
                      </button>
                    </div>
                  </form>
    
    
    </>)
}
function StoreMessage({message, markAsRead, onView}){
  const reff = useRef(null);
  useEffect(() => {
    if (!reff.current) return;
    if(message.isFromStore) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // The element is now visible
            onView(message.id);
          }
        });
      },
      { threshold: 0.5 } // You can adjust the threshold as needed
    );
  
    observer.observe(reff.current);
  
    return () => {
      observer.disconnect();
    };
  }, [reff, message.id]);
  if(!message.isFromStore){
    return<>
    <div className={`d-flex flex-row justify-content-start`}  ref={reff}>
                              
                              <div>
                                <p
                                  className="small p-2 ms-3 mb-1 rounded-3 bg-info chat-message"
                                  style={{ backgroundColor: "#f5f6f7"}}
                                >
                                  {message.text}
                                </p>
                                <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                                  {message.date}
                                </p>
                              </div>
                            </div>
    </>
  }
  return <>
  <div className="d-flex flex-row justify-content-end">
                      <div>
                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-dark chat-message" >
                          {message.text}
                        </p>
                        <p className="small me-3 mb-3 rounded-3 text-muted float-start">
                          {message.storeUserName} {message.date} {markAsRead ? <MDBIcon fas icon="check-double" /> : <MDBIcon fas icon="check" />}
                        </p>
                      </div>
                      {/* <img
                        src={`/photos/store/${0}`}
                        alt="avatar 1"
                        style={{ width: 45, height: "100%" }}
                      /> */}
                    </div>
  </>
}

function ClientMessage({message, onView, markAsRead}){
  const reff = useRef(null);
  useEffect(() => {
    if (!reff.current) return;
    if(!message.isFromStore) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // The element is now visible
            onView(message.id);
          }
        });
      },
      { threshold: 0.5 } // You can adjust the threshold as needed
    );
  
    observer.observe(reff.current);
  
    return () => {
      observer.disconnect();
    };
  }, [reff, message.id]);
  if(message.isFromStore){
    return<>
    <div className={`d-flex justify-content-start`} ref={reff}>
                              
                              <div>
                                <p
                                  className="small p-2 ms-3 mb-1 rounded-3 bg-info chat-message"
                                  style={{ backgroundColor: "#f5f6f7"}}
                                >
                                  {message.text}
                                </p>
                                <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                                  {message.date}
                                </p>
                              </div>
                            </div>
    </>
  }
  return <>
  <div className="d-flex justify-content-end" >
                      <div>
                        <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-dark chat-message" >
                          {message.text}
                        </p>
                        <p className="small me-3 mb-3 rounded-3 text-muted float-end">
                          {message.storeUserName} {message.date} {markAsRead ? <MDBIcon fas icon="check-double" /> : <MDBIcon fas icon="check" />}
                        </p>
                      </div>
                      {/* <img
                        src={`/photos/store/${0}`}
                        alt="avatar 1"
                        style={{ width: 45, height: "100%" }}
                      /> */}
                    </div>
  </>
}