import authService from "@auth/AuthorizeService";
import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ItemsForReview } from "./ItemsForReview";
import { ProductReviewModalProvider } from "./ReviewModal/ProductReviewModalContext";
import { AvatarBox } from "components/Global/AvatarBox";
import 'css/Profile.css'

export function ProfileMainMobile(){
    
    const [user, setUser] = useState(authService.getUser())
    
    return (
        <>
            
            <div className="topProfileName d-flex mb-2 align-items-center">
                <AvatarBox letter={user?.username[0]}/>
                <h4>@{user?.username}</h4>
            </div>

            
            <MDBRow className="topProfileCards gx-2">
                
                {/* <NavLink to='/profile/itemreviews'>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBIcon fas icon="heart" />
                            <p>Избранное</p>
                            <p>32 товара</p>
                        </MDBCardBody>
                    </MDBCard>
                </NavLink> */}
                <MDBCol size={4}>
                    <NavLink to='favorites' className='topProfileLink flex-fill'>
                        <MDBCard className="topProfileCard">
                            <MDBCardBody className="topProfileCardBody">
                            <MDBIcon fas icon="heart" color="danger"/>
                                <p className="title">Избранное</p>
                                <p className="text-muted desc">32 товара</p>
                            </MDBCardBody>
                        </MDBCard>
                    </NavLink>
                </MDBCol>
                <MDBCol size={4}>
                    <NavLink to='/profile/points/' className='topProfileLink flex-fill'>
                        <MDBCard className="topProfileCard">
                            <MDBCardBody className="topProfileCardBody">
                            <MDBIcon far icon="money-bill-alt" color="success"/>
                                <p className="title">Баллы</p>
                                <p className="text-muted desc">231</p>
                            </MDBCardBody>
                        </MDBCard>
                    </NavLink>
                </MDBCol>
                <MDBCol size={4}>
                    <NavLink to='/profile/points/' className='topProfileLink flex-fill'>
                        <MDBCard className="topProfileCard">
                            <MDBCardBody className="topProfileCardBody">
                                <MDBIcon color="warning" fas icon="star" />
                                <p className="title">Ждут отзыва</p>
                                <p className="text-muted desc">32 товара</p>
                            </MDBCardBody>
                        </MDBCard>
                    </NavLink>
                </MDBCol>
                
                
                {/* <NavLink to='/profile/points/'>
                    <MDBCard className="ms-3">
                        <MDBCardBody>
                        <MDBIcon color="warning" fas icon="star" />
                            <p>Ждут отзыва</p>
                            <p>4 товара</p>
                        </MDBCardBody>
                    </MDBCard>
                </NavLink> */}
                
            </MDBRow>
            <h4 className="my-3">Оцени недавние заказы</h4>
            <ProductReviewModalProvider>
                <ItemsForReview/>
            </ProductReviewModalProvider>
            
            
            <p>Используйте промокод</p>
            <MDBRow className="mt-2 px-3 gy-2">
                {user?.roles?.includes("Admin") && <InlineLinkItem url={'/admin/'} name={'Админка'}/>}
                {user?.roles?.some(x => x.includes("Store")) && <InlineLinkItem url={'/mystore'} name={'Мой магазин'}/>}
                <InlineLinkItem url={'orders'} name={'Мои заказы'}/>
                <InlineLinkItem url={'reviews'} name={'Отзывы о товарах'}/>
                <InlineLinkItem url={'storesReviews'} name={'Отзывы о магазинах'}/>
                <InlineLinkItem url={'favoritesStores'} name={'Избранные магазины'}/>
                <InlineLinkItem url={'favorites'} name={'Избранные товары'}/>
                <InlineLinkItem url={'boughtitems'} name={'Купленные товары'}/>
                <InlineLinkItem url={'points'} name={'Баллы и бонусы'}/>
                <InlineLinkItem url={'points'} name={'Активировать промокод'}/>
                <InlineLinkItem url={'edit'} name={'Настройки'}/>
                
                <NavLink onClick={() => authService.logout()} className={`inlineLinkItem rounded-4 d-flex justify-content-between align-items-center col-12 `} to="/auth/login">
                    Выход из аккаунта
                    
                </NavLink>
                
            </MDBRow>
            
        </>
    );
}
export function InlineLinkItem({url, name, className = null}){
    return <>
        <NavLink className={`inlineLinkItem rounded-4 d-flex justify-content-between align-items-center col-12 ${className}`} to={url}>
            {name}
            <MDBIcon fas icon="chevron-right" />
        </NavLink>
    </>
}