import authService from "@auth/AuthorizeService";
import { FlavourStoresModalProvider } from "components/store/search/Modal/FlavourStoresModalContext";
import { RangeBoxItem } from "components/store/search/RangeBoxItem";
import { useTitle } from "hooks/useTitle";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";


export function BrandPage(){
    
    const {id, name} = useParams()
    const [data, setData] = useState(null)
    async function loadCategoryItems(){

        const res = await fetch(`/api/category/brand?id=${id}`)
        if(res.ok){
            
            const dat = await res.json();
            console.log('setting...', dat)
            setData(dat)
        }
    }
    useEffect(()=>{
        loadCategoryItems()
    },[id])
    useTitle(`${data?.name}`)
    return(
        <>
            <h3>{data?.name}</h3>
            <MDBRow className='mb-3 gy-2 text-center'>
            <MDBCol size={12}>
            <FlavourStoresModalProvider>
            {data?.items?.map((it, index)=>{
                                    return <RangeBoxItem range={it}/>
                                })}

            </FlavourStoresModalProvider>
                        
                               
                                
                            
                        </MDBCol>
                        
                        
                      
                  </MDBRow>
            
        </>
    )
}

function RangesSp({ranges, brandName}){


    return(
        <>
            {ranges?.map((it, index)=>{
                
            })}
        </>
    );
}

function FlavoursSp({flavours}){

    
    async function addToCart(flavourId){
        const token = await authService.getAccessToken();
        if(!token){
            toast.error('Войдите в аккаунт')
        }
        const res = await fetch(`/api/store/cart?storeId=${null}&flavourId=${flavourId}`,{
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if(res.ok){
            toast.success('Добавлено в корзину')
        } else{
            toast.error(await res.text());
        }
    }
    return(
        <>
       
            
            
        </>
    );
}