import React from "react";


export function JoinUs(){



    return (
        <>
        <h3>Присоединяйся к нам</h3>
            <p>Для связи: https://t.me/</p>
        </>
    )
}