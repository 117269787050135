import authService from "@auth/AuthorizeService";
import { MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import { AddressSuggestions } from "react-dadata";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectContactCart } from "./MyCartState/CartStateSlice";



export function SelectCartContact(){
    const [modal, setModal] = useState(false)
    const [contacts, setContacts] = useState([])
    const dispatch = useDispatch()
    const selectedContact = useSelector(state => state.store.cart.selectedContact)
    async function fetchContacts(){
        const token = await authService.getAccessToken()
        const res = await fetch('/api/cart/contacts', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if(res.ok){
            const items = await res.json()
            setContacts(items)
            dispatch(selectContactCart(items?.at(0)))
        } else {
            toast.error('Ошибка загрузки контактов')
        }
    }
    useEffect(()=>{
        fetchContacts()
    },[])
    function onAddContact(contact){
        setModal(true)
        setContacts(prev => [...prev, contact])
    }
    function onSelectContact(contact){
        dispatch(selectContactCart({contact}))
        setModal(false)
    }
    return <>
        
        <div className="rounded-4 border p-4">
            <div className="d-flex justify-content-between"><h5>Доставка по адресу</h5><p className="text-info" style={{cursor: 'pointer'}} onClick={()=>{setModal(true)}}>Поменять</p></div>
            <p>{selectedContact?.address}</p>
            <p>{selectedContact?.fio}</p>
            <p>{selectedContact?.phoneNumber}</p>
        </div>
        <MDBModal setShow={setModal} show={modal}>
            <MDBModalDialog size="xl">
                <MDBModalContent>
                        <MDBModalHeader className="d-flex justify-content-between">
                            <MDBModalTitle>Выбрать адрес для доставки</MDBModalTitle>
                            <AddContactModal onAddContact={onAddContact}/>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                {contacts.map(it => <MDBCol size={12} md={6}>
                                    <div className="rounded-4 border p-4 mb-2" style={{cursor: 'pointer'}} onClick={()=>{onSelectContact(it) }}>
                                        <h5>Доставка по адресу</h5>
                                        <p>{it.address}</p>
                                        <p>{it.fio}</p> <p>{it.phoneNumber}</p>
                                    </div>
                                </MDBCol>)}
                                {/* <MDBCol size={12} md={6}>
                                    <div className="rounded-4 border p-4" style={{cursor: 'pointer'}} onClick={()=>{ onSelectContact()}}>
                                        <h5>Доставка по адресу</h5>
                                        <p>Санкт-Петербург, Гражданский проспект, 128к.2 кв. 90</p>
                                        <p>Новиков Андрей Андреевич</p> <p>+7 911 998 11 60</p>
                                    </div>
                                </MDBCol> */}
                                <MDBCol size={12} md={6}></MDBCol>
                            </MDBRow>
                            
                        </MDBModalBody>
                        </MDBModalContent>
                    </MDBModalDialog>
            
        </MDBModal>
        
    </>
}


function AddContactModal({onAddContact}){
    
    const [modal, setModal] = useState(false)
    async function AddContact(e){
        console.log('Я тут')
        e.preventDefault()
        e.stopPropagation()
        const fd = new FormData(e.target)
        const token = await authService.getAccessToken()
        const res = await fetch('/api/cart/contacts', {
            headers: {
                "Authorization" : `Bearer ${token}`,
            },
            method: 'PUT',
            body: fd
        })
        if(res.ok){
            const contact = await res.json()
            setModal(false)
            onAddContact(contact)
            toast.success('Адрес добавлен')
        } else {
            toast.error('Ошибка!')
        }
    }
    
    const [addressT, setAddressT] = useState()
    useEffect(()=>{
        //setModal(true)
        console.log(addressT)
    },[addressT])
    return <>
       <button className="btn btn-success" type="button" onClick={()=> setModal(true)}>Добавить адрес</button>
        <MDBModal setShow={setModal} show={modal}>
            <MDBModalDialog >
                <MDBModalContent>
                        <MDBModalHeader>
                        <MDBModalTitle>Новый адрес доставки</MDBModalTitle>
                        </MDBModalHeader>
                        <form onSubmit={AddContact}>
                        <MDBModalBody>

                        <AutocompleteInput/>
                        {/* <label htmlFor="adressField">Адрес</label>
            <AddressSuggestions customInput={"input"} selectOnBlur delay={300} minChars={3}  inputProps={{className: 'form-control', id:'adressField', style: {border: '1px solid #bdbdbd'}, placeholder: 'Адрес', label: 'Адрес 1', name: 'address'}} containerClassName="form-outline mt-2"   token="3f2f2beb7c2858d70e65c2a4d4d1958fe32f7221"  filterLocations={[{'country': 'Беларусь'}]} value={addressT} onChange={setAddressT} />
               <input disabled value={addressT?.data?.postal_code}/> */}
                            <MDBInput
                    
                    label='Email (Отправка уведомления)'
                    className="mt-2"
                    placeholder="vape@vapeby.market"
                    name='email'
                    type='email'
            />
            
            <MDBInput
                    
                    label='ФИО'
                    placeholder={'Лукашенко Александр Григорьевич'}
                    name='fio'
                    className="mt-2"
                    type='text'
            />
            <MDBInput
                
                label='Телефон для связи'
                placeholder="+375(17)280-60-88"
                name='phoneNumber'
                className="mt-2"
                type='tel'
            />
                            
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between">
                            <button className="btn btn-danger" onClick={()=> setModal(false)} type="button">Отмена</button>
                            <button className="btn btn-success" >Добавить</button>
                        </MDBModalFooter>
                        </form>
                        </MDBModalContent>
                    </MDBModalDialog>
            
        </MDBModal>
        
    </>
}


const AutocompleteInput = () => {
    const [inputValue, setInputValue] = useState('');
    const [addressVariants, setAddressVariants] = useState([]);
    let lastRequest = useRef();
    const fetchAddressVariants = async () => {
        
        try {

            const token = await authService.getAccessToken()
            const tmpDate = new Date()
            lastRequest.current = tmpDate
            
          const response = await fetch(`/api/cart/Suggestion?address=${inputValue}`, {headers: {'Authorization': `Bearer ${token}`}})
          const data = await response.json()
          console.log(inputValue,data)
          if(lastRequest.current <= tmpDate){
            setAddressVariants(data);
          }
          
        } catch (error) {
          console.error('Error fetching address variants:', error);
        }
      };
      let timeout = useRef();
    useEffect(() => {
        clearTimeout(timeout.current)
        timeout.current = setTimeout(()=>{
            if (inputValue.trim() !== '') {
                fetchAddressVariants();
              } else {
                setAddressVariants([]); // Reset address variants if input is empty
              }
        }, 300)
      
    }, [inputValue]);
    
    const handleInputChange = (e) => {
        
        
            setInputValue(e.target.value);
        
      
    };
   
    const handleAddressSelect = (address) => {
      
      setInputValue(address);
      setAddressVariants([]); // Close dropdown after selection
    };
  
    return (
      <div>
        <MDBInput
            list="adresses"
            label="Адрес"
          type="text"
          name="address"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Введи адрес"
        />
        <datalist id="adresses">
          {addressVariants.map((address, index) => (
            <option  key={index} onClick={() => handleAddressSelect(address.unrestricted_value)}>
              {address.unrestricted_value}
            </option>
          ))}
        </datalist>
      </div>
    );
  };