import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useContext } from "react";
import EditRefModalContext from "./EditModal/EditModalContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";




export function ReferralItem({manager}){

  const {showEditModal} = useContext(EditRefModalContext);
  const navigate = useNavigate()
    return(
        <>
          
            <MDBCard style={{ maxWidth: '540px', borderRadius: '10px' }}  className='mt-1'> 
                          <MDBRow className='g-0'>
                            <MDBCol md='3' className="p-1">
                              <MDBCardImage src='/img/avaDefault.png' alt='Карточка товара' fluid />
                            </MDBCol>
                            <MDBCol md='9'>
                              <MDBCardBody>
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex">
                                    <MDBCardTitle>{manager.username}</MDBCardTitle>
                                    
                                  </div>
                                  
                                  <p style={{color: '#8babe0', cursor: 'pointer'}} onClick={()=>{navigator.clipboard.writeText(window.location.protocol +'//'+ window.location.hostname + manager.link); toast.success('Скопировано!', {autoClose: 1000})}}>реф. ссылка<MDBIcon className="ms-2" fas icon="copy" /></p>
                                </div>
                                
                                
                                <div className="d-flex justify-content-between align-items-end">
                                  <div>
                                    <p>{manager.createdAt}</p>
                                    <p className="fw-bold">{manager.invitedCount} <MDBIcon fas icon="users" /></p>
                                  </div>
                                  
                                  <button className="btn btn-success" onClick={()=> {showEditModal(manager, 'Referral')}}>Ред.</button>
                                  <button className="btn btn-info" onClick={()=>{
                                  navigate(`${manager.id}`)
                                }}>Инфо</button>
                                </div>
                              </MDBCardBody>
                            </MDBCol>
                          </MDBRow>
                        </MDBCard>
        </>
    );
}