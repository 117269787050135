import { MDBBtnGroup, MDBInput, MDBRadio, MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  setOrderComment, setOrderType, setPostType } from "./MyCartState/CartStateSlice";
import { ImageGallery } from "./ImageGallery";
import { AddressSuggestions } from "react-dadata";
import 'react-dadata/dist/react-dadata.css';
import { SelectCartContact } from "./SelectContact";


export function OrderTypeSelector({}){
    const dispatch = useDispatch();
    const orderType = useSelector(state => state.store.cart.orderType)
    const cartItems = useSelector(state => state.store.cart.items)
    
    function setMode(value){
        dispatch(setOrderType({orderType: value}))
    }
    const comment = useSelector(state => state.store.cart.comment)
    
    
    
    //const [mode, setMode] = useState(0)
    
    //const [comment, setComment] = useState('');
    useEffect(()=>{console.log(orderType)}, [orderType])
    return(<>
        <p className="m-0">Выберите способ получения</p>
        <MDBTabs pills fill>
            <MDBTabsItem>
                <MDBTabsLink onClick={() => setMode(1)} active={orderType == 1}>
                    Самовывоз
                </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
                <MDBTabsLink onClick={() => setMode(2)} active={orderType == 2}>
                    Почта
                </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
                <MDBTabsLink onClick={() => setMode(3)} active={orderType == 3}>
                    Курьер
                </MDBTabsLink>
            </MDBTabsItem>
        </MDBTabs>
        {/* <div className="d-flex flex-wrap g-2">
            <button type="button" className={`btn ${orderType == 1 ? 'btn-info' : 'btn-light'} me-2`} onClick={() => setMode(1)}>Самовывоз</button>
            <button type="button" className={`btn ${orderType == 2 ? 'btn-info' : 'btn-light'} me-2`} onClick={()=> setMode(2)}>Почта</button>
            <button type="button" className={`btn ${orderType == 3 ? 'btn-info' : 'btn-light'}`} onClick={()=> setMode(3)}>Курьер</button>
        </div> */}

        {orderType == 1 && <>
        
            {/* {cartItems[0]?.store.pickupPhotos?.map(it => <img height={'50px'} src={`/photos/pickup/${it}.jpg`} alt="Фото самовывоза" key={it}/>)} */}
            <p>Фото самовывоза</p>

        </>}
        
        {orderType >1 &&<>
            <SelectCartContact/>
            
            
                
                
                
                

            
            
            

        </>}
        {orderType == 2 && <>
            <div className="mt-2">
                {cartItems.every(x => x.store.belPost) && 
                <MDBRadio name='selectedPost' value='belPost' onChange={()=> dispatch(setPostType({post:'belPost'}))} label='Белпочта'/>}
                
                {cartItems.every(x => x.store.euroPost) && 
                <MDBRadio name='selectedPost' value='euroPost'  label='Европочта' disabled={cartItems.every(x => x.store.euroPost != true)} onChange={()=>{dispatch(setPostType({post:'euroPost'}))}}  />}
            </div>
            {cartItems.map((it, key)=> <>
                {!it.store.belPost && <p className="text-danger mt-1">Магазин {it.store.name} не поддерживает доставку через Белпочту</p>}
                {!it.store.euroPost && <p className="text-danger mt-1">Магазин {it.store.name} не поддерживает доставку через Европочту</p>}
            </>)}
        
        </>}
        <MDBInput
                    value={comment}
                    onChange={(e) => dispatch(setOrderComment({comment: e.currentTarget.value}))}
                    label='Комментарий'
                    className="mt-2"
                    name='comment'
                    type='text'
            />
         
            
    </>)
}