// Modal.js
import { MDBBtn, MDBCardBody, MDBCardImage, MDBCol, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTextArea } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from '@auth/AuthorizeService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import StarRating from 'components/StarRating/StarRating';
import { AutoGrowTextArea, AutoTextArea } from 'css/Utils';

const Modal = ({ product, show, setShow }) => {

    const dispatch = useDispatch()
    const [stars, setStars] = useState(0)
    const [storeText, setStoreText] = useState(null)

    const [storeStars, setStoreStars] = useState(0)
    useEffect(()=>{
        console.log(product)
        setStars(product?.stars)
        isNeedToReviewStore()
    }, [product])
    async function SendReview(e){
        e.preventDefault();
        
        const fd = new FormData(e.target)
        fd.append("storeId", product?.storeId)
        fd.append("flavourId", product?.flavourId)
        if(storeStars == 0 && stars == 0){
            toast.error('Установите рейтинг')
            return
        }
        fd.append("stars", stars)
        fd.append("storeStars", storeStars)
        const token =  await authService.getAccessToken();
        const res = await fetch('/api/product/AddReview',
        {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            method: 'PUT',
            body: fd
        })
        if(res.ok){
            setShow()
        }
    }
    async function isNeedToReviewStore(){
        const token =  await authService.getAccessToken();
        const res = await fetch(`/api/product/IsNeedToReviewStore?storeId=${product?.storeId}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if(res.status === 200){
            const st = await res.json()
            setStoreText(st?.text)
            setStoreStars(prev => st?.rating || prev)
        }
    }
    // function setStoreStars(val){
    //     setStore(prev => {
    //         if(prev){
    //             prev.ratings = val
    //         }
    //     })
    // }
    function renderTextSwitch(value){
        switch (value) {
            case 1:
                return 'Ужасно'
            case 2:
                return 'Плохо'
            case 3:
                return 'Нормально'
            case 4:
                return 'Хорошо'
            case 5:
                return 'Отлично'
            default:
                break;
        }
    }

  return (
    <MDBModal show={show} onHide={()=> {setShow()}} >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
                
              <MDBModalTitle>Напишите отзыв о покупке</MDBModalTitle>
              <button className='btn btn-close' color='none' onClick={()=>setShow()}></button>
            </MDBModalHeader>
            <form onSubmit={SendReview}>
            <MDBModalBody>

            
                
                <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column justify-content-between me-2'>
                        <h5>{product?.name}</h5>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <StarRating defaultValue={product?.stars} onSet={(value)=> {setStars(value)}}/>
                                <p className='ms-2'>{renderTextSwitch(stars)}</p>
                            </div>
                            {/* <p className='text-muted'>{product?.storeName}</p> */}
                        </div>
                       
                        
                    </div>
                    <MDBCardImage className='rounded-1' src={`/photos/ranges/${product?.rangeId}.jpg`} width='100px' height='100px'/>
                </div>
                {/* <AutoTextArea/> */}
                <MDBInput onInput={(e)=>AutoGrowTextArea(e.currentTarget)}  wrapperClass='reviewTextArea' className='mt-2' name='benefits' type='text' label='Достоинства' placeholder='Насыщенный вкус'/>
                <MDBInput className='mt-2' name='disadvantages' type='text' label='Недостатки' placeholder='Слабый никотин'/>
                <MDBInput className='mt-2' name='commnet' type='text' label='Комментарий' placeholder='Хотелось бы новый вкус в этой линейки'/>
                <div className='mt-4'>
                    <div className='d-flex justify-content-between align-items-start'>
                        <div>
                            <h5>Оцените магазин</h5>
                            <p>{product?.storeName}</p>
                            <StarRating defaultValue={storeStars} onSet={(value)=> {setStoreStars(value)}}/>
                        </div>
                        <MDBCardImage className='rounded-1' src={`/photos/store/${product?.storeId}.jpg`} width='100px' height='100px'/>
                        </div>
                    
                    
                    <MDBInput value={storeText} onChange={(e)=>{setStoreText(e.currentTarget.value)}} className='mt-2' name='storeText' type='text' label='Отзыв' placeholder='Долгая доставка'/>
                </div>
            </MDBModalBody>
            <MDBModalFooter className='d-flex justify-content-between'>
              <button className='btn btn-danger' type='button' onClick={() => setShow()}>
                Закрыть
              </button>
              <button type='submit' className='btn btn-success'>
                Отправить отзыв
              </button>
            </MDBModalFooter>
            </form>
            
            
            
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
  );
};

export default Modal;