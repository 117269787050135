import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple,
    MDBIcon,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
  } from 'mdb-react-ui-kit';
import React, { useContext } from 'react';
import { Component, useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { CategoryFilter } from './CategoryFilter';
import { BrandFilter } from './BrandFilter';
import { RangeFilter } from './RangeFilter';
import { FlavourFilter } from './FlavourFilter';
import FlavourStoresModal from './Modal/FlavourStoresModal';
import FlavourStoresModalContext, { FlavourStoresModalProvider } from './Modal/FlavourStoresModalContext';
import { RangeBoxItem } from './RangeBoxItem';
import { useTitle } from 'hooks/useTitle';



export function SearchPage(){

    const [search, setSearch] = useSearchParams();
    const [items, setItems] = useState(null);
    console.log(search.get("s"))
    useEffect(()=> {searchFromServer()}, [search]);
    
    async function searchFromServer(){
        const res = await fetch(`/api/Search/v2?s=${search.get("s")}&page=${0}&pageSize=${40}`);
        console.log(res.status)
        if(!res.ok) return;
        const productsTemp = await res.json()
        setItems(productsTemp)
        console.log(productsTemp)

    }
    useTitle(`Поиск - ${search.get('s')}`)
        return(
            <>
                <section className="">
                <div className="container">
                    <div className="">
                    {/* <div className="col-lg-3">
                        <button
                        className="btn btn-outline-secondary mb-3 w-100 d-lg-none"
                        type="button"
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        >
                        <span>Показать фильтр</span>
                        </button>
                        <div
                        className="collapse card d-lg-block mb-5"
                        id="navbarSupportedContent"
                        >
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            
                            
                            <CategoryFilter selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>
                            <BrandFilter selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands} selectedCategory={selectedCategory}/>
                            <RangeFilter selectedBrands={selectedBrands} selectedRanges={selectedRanges} setSelectedRanges={setSelectedRanges} selectedCategory={selectedCategory}/>
                            <FlavourFilter selectedRanges={selectedRanges} selectedFlavours={selectedFlavours} setSelectedFlavours={setSelectedFlavours}/>
                            <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button
                                className="accordion-button text-dark bg-light"
                                type="button"
                                data-mdb-toggle="collapse"
                                data-mdb-target="#panelsStayOpen-collapseThree"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseThree"
                                >
                                Цена
                                </button>
                            </h2>
                            <div
                                id="panelsStayOpen-collapseThree"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingThree"
                            >
                                <div className="accordion-body">
                                <div className="range">
                                    <input
                                    type="range"
                                    className="form-range"
                                    id="customRange1"
                                    />
                                </div>
                                <div className="row mb-3">
                                    <div className="col-6">
                                    <p className="mb-0">Min</p>
                                    <div className="form-outline">
                                        <input
                                        type="number"
                                        id="typeNumber"
                                        className="form-control"
                                        />
                                        <label className="form-label" htmlFor="typeNumber">
                                        $0
                                        </label>
                                    </div>
                                    </div>
                                    <div className="col-6">
                                    <p className="mb-0">Max</p>
                                    <div className="form-outline">
                                        <input
                                        type="number"
                                        id="typeNumber"
                                        className="form-control"
                                        />
                                        <label className="form-label" htmlFor="typeNumber">
                                        $1,0000
                                        </label>
                                    </div>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-white w-100 border border-secondary"
                                >
                                    apply
                                </button>
                                </div>
                            </div>
                            </div>
                            
                            <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button
                                className="accordion-button text-dark bg-light"
                                type="button"
                                data-mdb-toggle="collapse"
                                data-mdb-target="#panelsStayOpen-collapseFive"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFive"
                                >
                                Оценка
                                </button>
                            </h2>
                            <div
                                id="panelsStayOpen-collapseFive"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingThree"
                            >
                                <div className="accordion-body">
                                <div className="form-check">
                                    <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue=""
                                    id="flexCheckDefault"
                                    defaultChecked=""
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                    >
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue=""
                                    id="flexCheckDefault"
                                    defaultChecked=""
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                    >
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-secondary" />
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue=""
                                    id="flexCheckDefault"
                                    defaultChecked=""
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                    >
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-secondary" />
                                    <i className="fas fa-star text-secondary" />
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue=""
                                    id="flexCheckDefault"
                                    defaultChecked=""
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                    >
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-warning" />
                                    <i className="fas fa-star text-secondary" />
                                    <i className="fas fa-star text-secondary" />
                                    <i className="fas fa-star text-secondary" />
                                    </label>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                    <div className="">
                        <header className="d-sm-flex align-items-center border-bottom mb-4 pb-3">
                        <strong className="d-block py-2">{items?.length} найдено</strong>
                        <div className="ms-auto">
                            <select className="form-select d-inline-block w-auto border pt-1">
                            <option value={0}>Лучшее совпадение</option>
                            <option value={1}>Цены по убыванию</option>
                            <option value={2}>Цены по возрастнанию</option>
                            </select>
                            <div className="btn-group shadow-0 border">
                            <a href="#" className="btn btn-light" title="List view">
                                <i className="fa fa-bars fa-lg" />
                            </a>
                            <a href="#" className="btn btn-light active" title="Grid view">
                                <i className="fa fa-th fa-lg" />
                            </a>
                            </div>
                        </div>
                        </header>
                        <div className="row gy-2">
                            <FlavourStoresModalProvider>
                                {false && items?.map((it, index)=>{
                                return <ProductCard product={it} key={index}/>
                                })}
                                {
                                    items?.map((range, index)=>{
                                        return <RangeBoxItem range={range}/>
                                    })
                                }
                            </FlavourStoresModalProvider>
                        
                        
                        
                        </div>
                        <hr />
                        <nav
                        aria-label="Page navigation example"
                        className="d-flex justify-content-center mt-3"
                        >
                        <ul className="pagination">
                            <li className="page-item disabled">
                            <a className="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">«</span>
                            </a>
                            </li>
                            {/* {items && Array.apply(0, Array(page.totalPages)).map((x, i)=>{
                                console.log(i)
                                let classNameT = "page-item"
                                if(i+1 == page.pageNumber){
                                    classNameT += " active"
                                }
                                return (
                                    <li className={classNameT}>
                                        <a className="page-link" href="#">
                                            {i+1}
                                        </a>
                                    </li>
                                );
                            })} */}
                            
                            <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">»</span>
                            </a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                    </div>
                </div>
                </section>

            
            </>
        );
    
}


function ProductCard({product}) {

    //const productUrl = '/product?id='+product.id;
    const {showFlavourModal} = useContext(FlavourStoresModalContext)
    return (
        <>
        {/* <NavLink to={``}  style={{textDecorationLine: 'none', color: 'black'}} className='col-lg-4 col-md-6 col-sm-6 d-flex'> */}
        <MDBCol sm={6} md={6} lg={4} className='d-flex'>

        
        <MDBCard onClick={()=>{
            console.log(product)
            showFlavourModal(product)
        }} style={{cursor: 'pointer'}}>
            {/* <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'> */}
                <MDBCardImage width='100%' src={`/photos/ranges/${product.rangeId}.jpg/0`} fluid alt='Картинка линейки'/>
                {/* <a>
                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                </a> */}
            {/* </MDBRipple> */}
            <MDBCardBody>
                <MDBCardTitle>{product.name}</MDBCardTitle>
                <MDBCardText>
                    Самая низкая цена: {product.firstPrice} руб. <br/>
                    Кол-во: {product.inStock} шт.
                    <MDBRow>
                        {product.shops?.map((it, index)=>{
                            return (
                                <>
                                    <MDBCol size={12}>
                                        <h6>Магазин: {it.name}</h6>
                                        <p>В наличие: {it.amount} шт.</p>

                                        <MDBTable>
                                            <MDBTableHead>
                                                <tr>
                                                    <th scope='col'>Кол-во</th>
                                                    <th scope='col'>Цена</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                
                                                    {it.prices?.map((price, indexPrice)=>{
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>От {price.from} шт.</td>
                                                                    <td>{price.price} руб.</td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                                
                                            </MDBTableBody>
                                            
                                        </MDBTable>
                                    </MDBCol>
                                </>
                            );
                        })}
                    </MDBRow>
                    
                </MDBCardText>
{/*                 
                <MDBBtn href='#'>В корзину</MDBBtn>
                <MDBBtn href='#'><MDBIcon icon='heart'/></MDBBtn> */}
            </MDBCardBody>
        </MDBCard>
        </MDBCol>                  
        
        {/* </NavLink> */}
       
        
        </>
    )
    
}