import authService from "@auth/AuthorizeService";
import { RatingStars } from "components/store/ProductPage/StoresTab";
import { MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";



export function MyStoresReviewsPage(){
    const [reviews, setReviews] = useState([])
    const [loading, setLoading] = useState(true)
    async function getReviews(){
        setLoading(true)
        const token = await authService.getAccessToken()
        const res = await fetch('/api/profile/MyStoresReviews', {
            headers: {
                "Authorization":`Bearer ${token}`
            }
        })
        if(res.ok){
            setReviews(await res.json())
        }
        setLoading(false)
    }
    useEffect(()=>{
        getReviews()
    },[])

    return <>
        <h5>Мои отзывы о магазинах</h5>
        {loading ? <div className="spinner-border text-primary" role="status"/> : (reviews.length > 0 ?
        <MDBRow className="gy-3 mb-2">
            {reviews?.map((it, i)=>{
                return <MDBCol size={12}> <ReviewCard review={it} key={i}/></MDBCol>
            })}
        </MDBRow>: <p className="fw-bold">У вас ещё нет отзывов</p>)}
        
    </>
}

function ReviewCard({review}){

    return<>
        <MDBCard>
            <MDBCardBody>
               
                    <div className="d-flex align-items-start">
                        <MDBCardImage className="rounded-4" src={`/photos/store/${review?.storeId}.jpg`} width='56px'/>
                        <div className="ms-2 w-100">
                            <div  className="d-flex justify-content-between">
                            <RatingStars rating={review?.rating}/> <p className="text-muted ms-2">{review?.date}</p>

                            </div>
                            <NavLink to={`/store/${review?.storeId}`}><h4 className="text-white">{review?.storeName}</h4></NavLink>
               
                        </div>
                    
                    </div>
                    
                {review?.text && <div className="d-flex align-items-center">
                        <MDBIcon color="muted" fas icon="comment" /> <p className="ms-2"> {review?.text}</p>
                        </div>}
               <button className="btn btn-primary">Редактировать</button>

            </MDBCardBody>
        </MDBCard>
    </>
}