import authService from "@auth/AuthorizeService";
import { MDBBadge, MDBCollapse, MDBIcon, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getWhBadgeColor, getWhBadgeText } from "../WarehouseTypes";



export function MyStoreSidebar(){
    
    const unreadChats = useSelector(state => state.mystore.chat.unreadChats)
    const newOrders = useSelector(state => state.mystore.info.newOrders)
    const storeId = useSelector(state => state.mystore.info.storeId)
    const warehouses = useSelector(state => state.mystore.info.warehouses)
    const roles = authService.getRoles();
    
  const [collapse1, setCollapse1] = useState(true);
    return <>
        <div  
    className="d-block sidebar"
  >
    <div className="position-sticky">
        <NavLink to="/"><img src="/logo.png"  className="px-3 w-100"/></NavLink>
    
      <div className="list-group mt-2 mx-3">
      
        {roles.includes('StoreAdmin') && <>
        <NavLink
          to="/mystore/"
          className="list-group-item list-group-item-action py-2"
          aria-current="true"
        >
          <i className="fas fa-tachometer-alt fa-fw me-3" />
          <span>Мой магазин</span>
        </NavLink>
        <NavLink
          to="/mystore/orders"
          className="list-group-item list-group-item-action py-2 d-flex align-items-center"
        >
          
         
          <div>
          <i className="fas fa-chart-bar fa-fw me-3" />
          <span>Заказы</span>
          </div>
          {newOrders > 0 && <MDBBadge color={'info'} className="ms-auto">{newOrders}</MDBBadge>}
          
        </NavLink></>
        }
        {(roles.includes('StoreAdmin')||roles.includes('StoreManager')||roles.includes('StoreCourier')) &&
        <NavLink
          to="/mystore/chat"
          className="list-group-item list-group-item-action py-2 d-flex align-items-center"
        >
          <MDBIcon fas icon="comment-alt" className="me-3 fa-fw"/>
          
          <span>Чат</span>
          {unreadChats > 0 && <MDBBadge color={'info'} className="ms-auto">{unreadChats}</MDBBadge>}
        </NavLink>
        }
        {/* <NavLink
          to="/mystore/warehouse"
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-globe fa-fw me-3" />
          <span>Мой склад</span>
        </NavLink> */}
        {roles?.includes('StoreAdmin') && <>
        
          <NavLink
          to="/mystore/warehouse/"
          className="list-group-item list-group-item-action py-2"
            >
          <i className="fas fa-globe fa-fw me-3" />
          
          <span>Мой склад</span>
        </NavLink>
        
        
           
              <MDBListGroup flush>
              
              {warehouses?.map((warehouse, index) => {
                return(
                  <>
                    <NavLink
                      end
                      // to={`warehouse/${warehouse.id}`}
                      to={{pathname:`warehouse/${warehouse.id}`}}
                      caseSensitive
                      state={{warehouse: warehouse}}
                      className={`py-2 list-group-item list-group-item-action `}
                      // className={`${search.get('id') === warehouse.id ? 'active' : ''} list-group-item list-group-item-action py-2`}
                    >
                      {/* <i className="fas fa-globe fa-fw me-3" /> */}
                      <MDBBadge color={getWhBadgeColor(warehouse.type)} className="mt-1 ms-auto d-block">{getWhBadgeText(warehouse.type)}</MDBBadge>
                      <span>{warehouse.name}</span>
                    </NavLink>
                  </>
                )
              })}
                
              </MDBListGroup>
            
              
        
        <NavLink
          to="/mystore/edit"
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-chart-area fa-fw me-3" />
          <span>Редактировать</span>
        </NavLink>
        
        <NavLink
          to="/mystore/managers"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-users fa-fw me-3" />
          <span>Менеджеры</span>
        </NavLink>
        <NavLink
          to="/mystore/couriers"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-truck fa-fw me-3" />
          <span>Курьеры</span>
        </NavLink>
        
        <NavLink
          to="/mystore/payments"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-chart-pie fa-fw me-3" />
          <span>Выплаты</span>
        </NavLink>
        <NavLink
          to="/mystore/income"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-chart-line fa-fw me-3" />
          <span>Доходы</span>
        </NavLink>
        <NavLink
          to="/mystore/expenses"  
          className="list-group-item list-group-item-action py-2"
        >
          <MDBIcon fas icon="file-invoice-dollar" className="me-3 fa-fw" />
          {/* <i className="fas file-invoice-dollar fa-fw me-3" /> */}
          <span>Расходы</span>
        </NavLink>
        <NavLink
          to="/mystore/analytics"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-chart-line fa-fw me-3" />
          <span>Аналитика</span>
        </NavLink>
        <NavLink
          to={`/store/${storeId}`}
          target="_blank"  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-book fa-fw me-3" />
          <span>Страница магазина</span>
        </NavLink>
        </>}
        {roles?.includes('StoreCourier') && <>
        <NavLink
          to={`/mystore/myCourier`}  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-book fa-fw me-3" />
          <span>Я курьер</span>
        </NavLink>
        
        </>}
        {roles?.includes('StoreManager') && <>
        <NavLink
          to={`/mystore/myManager`}  
          className="list-group-item list-group-item-action py-2"
        >
          <i className="fas fa-book fa-fw me-3" />
          <span>Я менеджер!</span>
        </NavLink>
        
        </>}
        
        
        
        
      </div>
    </div>
  </div>
    </>
}