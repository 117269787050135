import authService from "@auth/AuthorizeService";
import { SignalRConnectionInstance } from "components/MyStore/InfoState/signalrHub";
import MyStoreInfoSignalR from "components/Notifications/NotificationMyStore";
import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export function ToastMessageContainer({message, imgId, chatType, chatName, chatId}){
    return <>
        <NavLink onDragStart={(e) => e.preventDefault()} to={`/chat?id=${chatId}`} className="d-flex" style={{color: 'initial'}}>
            <img src={`/api/Store/${chatType}/${imgId}.jpg`} style={{maxWidth: '30%', borderRadius: '16px'}}/>
            <div className="d-flex flex-column ms-2">
                <p className="m-0" style={{fontWeight: 'bold'}}>{chatName}</p>
                <p className=""><span className="fw-bold">Новое сообщение:</span>{message.text}</p>
            </div>
        </NavLink>
    </>
}
// {
//     "id": "465b36a5-462d-49e2-8a88-c724e72aa9af",
//     "chatName": "Облака Минск",
//     "to": "611b2e5c-62f0-41bb-bad0-b90c6d7584cf",
//     "lastMessage": "",
//     "unreadCount": 0,
//     "isWriting": false,
//     "messages": []
// },

// message:
// [
//     {
//         "id": "f2d5055a-307b-419f-8491-6d0a2e84cb10",
//         "text": "Привет!\nЯ хочу заказать:\nhuskyОблака МинскЯгодный микс",
//         "isFromStore": false,
//         "date": "14:32 | июл. 31"
//     },
// ]

const ClientChatStateSlice = createSlice({
    name: "clientChat",
    initialState:{
        selectedChat: null,
        chats: [],
        isLoading: false,
        unreadChats: 0
    },
    reducers:{
       storeWriting: (state, action) =>{
        const {storeId, bool} = action.payload;
        state.chats.forEach(it =>{
            if(it.to == storeId){
                it.isWriting = bool;
            }
        })
        
       },
       messageFromStore: (state, action)=>{
        console.log('payload: ', action.payload)
        const {chatId, message, unreadCount, unreadChats, storeId, chatName} = action.payload.dto;

        state.chats.forEach(it =>{
            if(it.id == chatId){
                it.messages = [...it.messages, message]
                it.isWriting = false;
                if(it.to != state.selectedChat){
                    it.unreadCount = unreadCount;
                    state.unreadChats = unreadChats
                } else{
                    state.unreadChats = unreadChats-1;
                }
                
            }
        })
        console.log('state chats')
        if(state.chats?.length === 0){
            toast.info(<ToastMessageContainer  message={message} chatType={'store'} chatId={chatId} imgId={storeId} chatName={chatName}/>, {
                icon: false,
                autoClose: 5000,
                hideProgressBar: true,

                closeOnClick:true,
                
            })
            state.unreadChats = unreadChats
        }
        return state
       },
       setSetlectedClientChat: (state, action)=>{
            state.selectedChat = action.payload.id
            // state
        },
        setClientUnreadChats: (state, action) => {
            state.unreadChats = action.payload.unreadChats;
            return state
        },
        markAsReadFromStore: (state, action)=>{
            const {chatId, messageId} = action.payload;
            state.chats.forEach(chat =>{
                if(chat.id == chatId){
                    chat.lastMessageReadId = messageId;
                    console.log('message marked as readed from store in user chat')
                }
            })

        }
        
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchChatsClient.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchChatsClient.fulfilled, (state, action) => {
            const { chats } = action.payload;
            state.isLoading = false;
            const selectedIndex = state.selectedChat !== null ? chats.findIndex(chat => chat.to === state.selectedChat) : -1;

            if (selectedIndex !== -1) {
                // Если выбран чат, обновляем только его свойство messages
                state.chats = chats.map(chat => {
                    if (chat.to === state.selectedChat) {
                        return {
                            ...chats[selectedIndex],
                            messages: state.chats.find(c => c.to === chat.to)?.messages
                        };
                    }
                    return chat;
                });
                
            } else {
                // Если чат не выбран, просто добавляем все чаты из action.payload
                state.chats= chats;
            }
        });
        builder.addCase(fetchChatsClient.rejected, (state, action) => {
            state.isLoading = false;
        });


        builder.addCase(fetchDialogClient.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDialogClient.fulfilled, (state, action) => {
            
            const { data, id } = action.payload;
            state.isLoading = false;
            const chat = state.chats.find(x => x.to == id)
            if(chat){
                if(chat.unreadCount > 0){
                    state.unreadChats =state.unreadChats - 1;
                }
                chat.unreadCount = 0;
                chat.messages = data.messages;
                chat.lastMessageReadId = data.lastMessageReadId;
            } else {
                state.chats.push({
                    unreadCount: 0,
                    messages: data.messages,
                    lastMessageReadId: data.lastMessageReadId,
                    to: id,
                    chatName: 'Загрузка...'
                })
            }
        });
        builder.addCase(fetchDialogClient.rejected, (state, action) => {
            state.isLoading = false;
        });


        builder.addCase(sendMessageClient.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(sendMessageClient.fulfilled, (state, action) => {
            console.log('message...1231223')
            if(action.payload == null){
                return state;
            }
            const {message, chatId} = action.payload;

            state.isLoading = false;
            console.log('on sent message', message, chatId)
            state.chats.forEach(it => {
                if(it.to === chatId){
                    it.messages = [...it.messages, message]
                }
            })
        });
        builder.addCase(sendMessageClient.rejected, (state, action) => {
            state.isLoading = false;
        });
    }
    
    // async thunk fetch chats, fetch dialog, but 

})
export const fetchDialogClient = createAsyncThunk('clientChat/fetchDialog', async ({id})=>{
    const token = await authService.getAccessToken();
    const res = await fetch(`/api/chat/UserDialog/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await res.json();
    return {data, id}
})
export const fetchChatsClient = createAsyncThunk('clientChat/fetchChats', async ()=>{
    console.log('fetching chat...')
    const token = await authService.getAccessToken();
    const res = await fetch(`/api/chat/UserDialogs`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    const chats = await res.json();
    return {chats};
})
export const sendMessageClient = createAsyncThunk('clientChat/sendMessage', async ({to, text})=>{
    // use hub
    
    if(SignalRConnectionInstance){
        console.log('sendMessage from user to store')
        const res = await SignalRConnectionInstance.invoke(`SendToStore`, to, text);
        console.log('resp on sendMEssage',res)
        return {
            message: res,
            chatId: to,

        };
    }
    return null;
});
export const sendWritingEventClient = createAsyncThunk('clientChat/writingEvent', async ({to})=>{
    if(SignalRConnectionInstance){
        const res = await SignalRConnectionInstance.invoke(`isWritingToStore`, to)
        
    }
})
export const sendMessageReadEventClient = createAsyncThunk('chat/sendMessageReadEventClient', async ({id, chatId})=>{
    if(SignalRConnectionInstance){
        const res = await SignalRConnectionInstance.invoke(`messageFromStoreRead`, id, chatId)
    }
})
export const {storeWriting, messageFromStore, setSetlectedClientChat, setClientUnreadChats, markAsReadFromStore} = ClientChatStateSlice.actions;
export default ClientChatStateSlice.reducer;