import { MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import { useState } from "react";
import authService from "@auth/AuthorizeService";
import React from "react";
import { toast } from "react-toastify";


export function AddCourierModal({onNewAdded}){
    const [showModal, setShowModal] = useState(false);
    async function addCourier(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const token = await authService.getAccessToken();
        
        const res = await fetch('/api/mystore/workers/addCourier',{
            method: "PUT",
            headers:{
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        if(!res.ok){
            // error!
            toast.error(await res.text())

            return;
        }
        setShowModal(false);
        // reload
        onNewAdded(await res.json())
        

    }
    return(
        <>
        <button className="btn btn-primary" onClick={()=>setShowModal(true)}>Добавить курьера</button>
        <MDBModal show={showModal}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Добавить курьера</MDBModalTitle>
                    </MDBModalHeader>
                    <form onSubmit={addCourier}>
                    <MDBModalBody>
                        
                        <MDBInput label="Username" placeholder="Username" name="Username"/>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn btn-success">Добавить</button>
                        <button className="btn btn-danger" type="button" onClick={()=>setShowModal(false)}>Отмена</button>
                    </MDBModalFooter>
                    </form>
                </MDBModalContent>
            </MDBModalDialog>

        </MDBModal>
        </>
    );
}