import React, { createContext, useState } from 'react';
import Modal from './AddMetroStationModal';

const AddMetroStationModalContext = createContext(null);

export const AddMetroStationModalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [id, setid] = useState(1);

  const showAddMSTModal = (metroLineId) => {
    console.log(metroLineId)
    setid(metroLineId);
    
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setid(null);
  };

  return (
    <AddMetroStationModalContext.Provider value={{ showAddMSTModal, hideModal }}>
      {children}
      
      {<Modal show={modal} metroLineId={id} setShow={hideModal}/>}
    </AddMetroStationModalContext.Provider>
  );
};

export default AddMetroStationModalContext;