import React, { Component } from 'react';
import { Header } from './Header/Header';
import { Footer } from './Footer';
import { Outlet, useLocation } from 'react-router-dom';
import { MDBContainer } from 'mdb-react-ui-kit';
import useScreenSize from 'hooks/ScreenSize/useScreenSize';
import { MobileHeader } from './Header/MobileHeader';

export function HomeLayout() {
  const location = useLocation()
  const screenSize = useScreenSize()
    return (
      <div className='box'>
        {screenSize.width<=768 ? <MobileHeader/> : <Header/>}
        
      
      <MDBContainer breakpoint='xxl' className='py-2' style={{flex: '1 1 auto'}}>
          <Outlet/>
      </MDBContainer>
      {location.pathname !== '/chat' && <Footer/>}
      {screenSize.width<=768 && <div style={{minHeight: '100px', width:'100%'}}></div>}
      </div>
    );
  
}