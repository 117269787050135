import React from 'react';
import { MainStore } from './components/MainStore/MainStore';
import { ProductPage } from './components/store/ProductPage/ProductPage';
import { SearchPage } from './components/store/search/SearchPage';
import { StorePage } from './components/store/ShopPage/ShopPage';
import { JoinUs } from './components/MainStore/About.jsx/JoisUs';
import { MyCart } from './components/store/Cart/MyCart';
import { ClientChat } from './components/store/Chat/Client/ClientChat';
import { Provider } from 'react-redux';
import { CategoryPage } from 'components/MainStore/Categories/CategoryPage';
import { BrandPage } from 'components/MainStore/Categories/BrandPage';
import AuthorizeRoute from '@auth/AuthorizeRoute';
import { ProfilePage } from 'components/store/Profile/ProfileLayout';

const AppRoutes = [
  {
    index: true,
    element: <MainStore />
  },
  {
    path: 'search',
    element: <SearchPage/>
  },
  {
    path: 'product/:id/*',
    element: <ProductPage/>
  },
  {
    path: 'store/:id',
    element: <StorePage/>
  },
  {
    path: 'category/:name/:id',
    element: <CategoryPage/>
  },
  {
    path: 'brand/:name/:id',
    element: <BrandPage/>
  },
  {
    path: 'JoinUs',
    element: <JoinUs/>
  },
  {
    path: 'chat',
    element: <ClientChat/>,
    requireAuth: true
  },
  {
    path: 'mycart',
    element: <MyCart/>
  },
  {
    path: 'profile/*',
    element: <ProfilePage/>,
    requireAuth: true
  }
  //...ApiAuthorzationRoutes,
  
];

export default AppRoutes;
