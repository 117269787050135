import React, {useState, createContext, useEffect} from "react";
import PropTypes from "prop-types";



export default function StarRating({onSet, defaultValue}) {
  const [hover, setHover] = useState(0);
  const [selected, setSelected] = useState(0)

  useEffect(()=>{
    setHover(defaultValue)
  }, [defaultValue])
  function sel(value){
    setSelected(value)
    onSet(value)
  }
  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" style={{display: 'none'}} viewBox="0 0 24 24">
        <defs>
            <symbol id="star-svg">
            <path fill="currentColor"
        d="M9.358 6.136C10.53 4.046 11.117 3 12 3s1.47 1.045 2.643 3.136c.259.462.484 1.038.925 1.354.42.302 1.006.332 1.502.422 2.356.429 3.534.643 3.842 1.457.023.06.042.12.057.182.208.847-.632 1.581-2.316 3.313-.527.541-.766.798-.872 1.149-.097.325-.05.677.044 1.381.323 2.42.482 3.762-.21 4.31-1.24.98-3.24-.742-4.359-1.259C12.638 18.16 12.33 18 12 18c-.329 0-.638.16-1.256.445-1.12.517-3.119 2.24-4.358 1.258-.693-.547-.528-1.889-.205-4.309.094-.704.14-1.056.043-1.381-.105-.351-.345-.607-.872-1.15-1.684-1.73-2.529-2.465-2.32-3.312.014-.062.033-.123.056-.182.308-.814 1.486-1.028 3.842-1.457.496-.09 1.083-.12 1.502-.422.441-.316.666-.893.926-1.354Z"></path>
        
            </symbol>
        </defs>
        <use href="#star-svg"/>
      </svg>
      <div className="">
        {[1,2,3,4,5].map(it => <OneStar active={hover != 0 ? hover >= it : selected >=it} onHover={()=>setHover(it)} onClick={()=>{sel(it)}} onExit={()=>{setHover(0)}}/>)}
        
        
        
      </div>
      
    </>
  );
}
function OneStar({active, onHover, onClick, onExit}){
    return<>
        <svg width='24' height='24' viewBox="0 0 24 24" color={active ? 'yellow' :'gray'} onMouseEnter={onHover} onClick={onClick} onMouseLeave={onExit}>
            <use xlinkHref="#star-svg"/>
        </svg>
    </>
    
}
