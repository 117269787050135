
const transliterationMap = {
    ' ': "-", 'а': "a", 'б': "b", 'в': "v", 'г': "g", 'д': "d", 'е': "e",
    'ё': "yo", 'ж': "zh", 'з': "z", 'и': "i", 'й': "y", 'к': "k",
    'л': "l", 'м': "m", 'н': "n", 'о': "o", 'п': "p", 'р': "r",
    'с': "s", 'т': "t", 'у': "u", 'ф': "f", 'х': "kh", 'ц': "ts",
    'ч': "ch", 'ш': "sh", 'щ': "sch", 'ъ': "", 'ы': "y", 'ь': "",
    'э': "e", 'ю': "yu", 'я': "ya", ',': "-"
};
export function TranscribeToEnglish(text) {
    

    text = text.toLowerCase(); // Convert text to lowercase

    let englishText = "";
    for (let i = 0; i < text.length; i++) {
        const russianChar = text[i];
        const englishChar = transliterationMap[russianChar] || russianChar;
        englishText += englishChar;
    }

    return englishText;
}