import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import './css/custom.css';
import { HomeLayout } from './components/layouts/homeLayout';
import { MyStore } from './components/MyStore/myStore';
import { AdminLayout } from './components/admin/layout/AdminLayout';
import { ToastContainer, Flip } from 'react-toastify';


import 'react-toastify/dist/ReactToastify.css';
import { LoginLayout } from './components/api-authorization/Pages/Layout';
import authService from '@auth/AuthorizeService';
import { SignalRConnection } from 'components/MyStore/InfoState/signalrHub';
// import './scss/mdb.dark.free.scss';
import 'css/customMdb.scss'
import 'mdb-react-ui-kit/dist/css/mdb.dark.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { SkeletonTheme } from 'react-loading-skeleton';
import "react-multi-carousel/lib/styles.css";
export default class App extends Component {
  static displayName = App.name;
  
  
  
  render() {
    return (
      <>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Routes>
          <Route path="/" element={<HomeLayout/>}>
            {AppRoutes.map((route, index) => {
              const { element, requireAuth, ...rest } = route;
              return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} children={element} /> : element} />;
            })}
          </Route>
        
            
            <Route path='myStore/*' element={<MyStore/>}/>
            <Route path='admin/*' element={<AuthorizeRoute requierRoles={['Admin']} children={<AdminLayout/>}/>}/>
            <Route path='auth/*' element={<LoginLayout/>}/>
        </Routes>
        {/* {authService.getRoles().includes('StoreAdmin') && 
          <NotificationComponent/>
          
        } */}
        <ToastContainer limit={3} transition={Flip} newestOnTop style={{marginTop: '60px'}} autoClose={1300}/>
        <SignalRConnection/>
        </SkeletonTheme>
      </>
    );
  }
}
