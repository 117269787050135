import authService from "@auth/AuthorizeService";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ManagerMainView } from "./ManagerMainView";
import { setManagerId } from "./ManagerStateSlice";



export function AdminManagerView(){
    const {id} = useParams()
    const dispatch = useDispatch()
    
    useEffect(()=>{
        dispatch(setManagerId({managerId: id}))
        //fetchOrders()
    },[])
    console.log(id)
    return(<>
        <ManagerMainView courierId={id} viewFrom="Admin"/>
    </>)
}