import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import 'css/Profile.css'
import authService from "@auth/AuthorizeService";

export function ProfileSideBarV2(){
    const roles = authService.getRoles()
    const [user, setUser] = useState(authService.getUser())
    return<>
    <div>
    <span>Личный кабинет</span>
        <div className="d-flex flex-column avatar-zone mb-4">
            <span>Привет!</span>
            <span>@{user.username}</span>
        </div>
        <div>
            <div className="menu-list">
                <span className="menu-label">Личная информация</span>
                <div className="submenu-items">
                    <NavLink to={'/profile/'}>Главная</NavLink>
                    <NavLink to={'points'}>Баллы и бонусы</NavLink>
                </div>
            </div>


            <div  className="menu-list">
                <span className="menu-label">Заказы</span>
                <div className="submenu-items">
                    <NavLink to={'orders'} className='menu-link'>Мои заказы</NavLink>

                    <NavLink to={'boughtitems'}>Купленные товары</NavLink>
                    <NavLink to={'boughtstores'}>Магазины</NavLink>
                    <NavLink to={'storesReviews'}>Отзывы о магазинах</NavLink>
                    <NavLink to={'reviews'}>Отзывы о товарах</NavLink>
                </div>
                
            </div>


            <div className="menu-list">
                <span className="menu-label">Подписки</span>
                <div className="submenu-items">
                    <NavLink to={'favoritesStores'}>Избранные магазины</NavLink>
                    <NavLink to={'favorites'}>Избранные товары</NavLink>
                    <NavLink to={'notifications'}>Настройки уведомлений</NavLink>
                </div>
                
            </div>

            <div className="menu-list">
                <span className="menu-label">Настройки аккаунта</span>
                <div className="submenu-items">
                    <NavLink to={'edit'}>Моя учётная запись</NavLink>
                    <NavLink to="/auth/login" onClick={() => authService.logout()}>Выход из аккаунта</NavLink>
                    
                    {roles.includes('Admin') && 
                <NavLink
                    to="/admin/"
                    
                    >
          
                    <span>Админка</span>
                </NavLink>}
                </div>
                
            </div>
        </div>

    </div>
        
    </>
}

