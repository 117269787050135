// Modal.js
import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import authService from '../../../../api-authorization/AuthorizeService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { deleteBrandAdmin, deleteCategoryAdmin, deleteProductAdmin, deleteRangeAdmin } from '../TableStateSlice';

const DeleteModal = ({ item, type, show, setShow }) => {

    const dispatch = useDispatch()
    const [name, setName] = useState("");
    const [order, setOrder] = useState(1);
    async function deleteSubmit(e){
        e.preventDefault();
        const fd = new FormData(e.target);
        fd.append(`${type}Id`, item.id);
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/admin/pricelist/Delete${type}`,{
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: fd
        });
        e.target.reset();
        if(res.ok){
            
            toast('Удалено! ' + item.name);
            setShow();
            switch(type){
                case 'category':
                    dispatch(deleteCategoryAdmin({id: item.id}))
                    break;
                case 'brand':
                    dispatch(deleteBrandAdmin({id: item.id}))
                    break;
                case 'range':
                    dispatch(deleteRangeAdmin({id: item.id}))
                    break;
                case 'flavour':
                    dispatch(deleteProductAdmin({id: item.id}))
                    break;
                default:
                    console.log('Не найдена категория')

            }
        } else{
            toast('Ошибка удаления ' + res.status)
        }
        
    }
    async function changeName(e){
        e.preventDefault();
        const fd = new FormData(e.target);
        fd.append(`${type}Id`, item.id);
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/admin/pricelist/Change${type}Name`,{
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: fd
        });
        e.target.reset();
        if(res.ok){
            toast('Изменено!')
            setShow();
        } else{
            toast('Ошибка! ' + res.status)
        }
        
    }
    async function changeOrder(e){
        e.preventDefault();
        const fd = new FormData(e.target);
        fd.append(`Id`, item.id);
        fd.append("type", type)
        const token = await authService.getAccessToken();
        const res = await fetch(`/api/admin/pricelist/ChangeOrder`,{
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: fd
        });
        e.target.reset();
        if(res.ok){
            toast('Изменено!')
            setShow();
        } else{
            toast('Ошибка! ' + res.status)
        }
    }
    useEffect(()=>{
        setName(item?.name)
        
        setOrder(item?.order)
    }, [item?.name])
    
  return (
    <MDBModal show={show} onHide={()=> setShow()} >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{item?.name} {type}</MDBModalTitle>
              <button className='btn btn-close' color='none' onClick={()=>setShow()}></button>
            </MDBModalHeader>
            <MDBModalBody>
                <form onSubmit={(e)=> {changeName(e)}}>
                    <h5>Изменить название</h5>
                    <MDBInput name='name' autoComplete='off' label='Новое имя' value={name} onChange={(e) => setName(e.target.value)}/>
                    {type == 'category' && <MDBInput className='mt-3' name='itemsName' autoComplete='off' label='Подпись' defaultValue={item?.itemsName}/>}
                    <button type='submit' className='btn btn-warning mt-2'>Сохранить</button>
                </form>
                <form onSubmit={(e)=> {changeOrder(e)}} className='mt-3'>
                    <h5>Изменить порядок</h5>
                    <MDBInput type='number' min={1} max={1000} step={1} name='order' autoComplete='off' label='Номер' value={order} onChange={(e) => setOrder(e.target.value)}/>
                    <button type='submit' className='btn btn-warning mt-2'>Сохранить</button>
                </form>
                <form onSubmit={(e)=> {deleteSubmit(e)}} className='mt-3'>
                    <h5>Удалить</h5>
                    <MDBInput name='key' autoComplete='off' label='Код' placeholder='12345'/>
                    <button type='submit' className='btn btn-danger mt-2'>Удалить</button>
                </form>
            </MDBModalBody>

            <MDBModalFooter>
              {/* <MDBBtn color='secondary' onClick={null}>
                Close
              </MDBBtn>
              <MDBBtn>Save changes</MDBBtn> */}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
  );
};

export default DeleteModal;