import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBListGroupItem, MDBRipple, MDBRow } from "mdb-react-ui-kit";
import React, { useContext, useState } from "react";


import DeleteModalContext from "./DeleteModal/DeleteModalContext";
import { EditProductModal } from "components/MyStore/warehouse/EditModal";


export function FlavourItem({flavour}){

    const {showDeleteModal} = useContext(DeleteModalContext);
    
    return(
        <>
        
        <MDBListGroupItem action className='px-3 d-flex' color="dark" onClick={()=>{
            // open edit modal
            showDeleteModal(flavour, 'flavour')
        }}>
                <p>{flavour.name}</p>
            </MDBListGroupItem>
        
            
            
        </>
    );
}