import MyStoreInfoSignalR from "components/Notifications/NotificationMyStore";
import { toast } from "react-toastify";
import { addOrder } from "../Orders/OrdersState/OrdersStateSlice";
import React from "react";
import { NavLink } from "react-router-dom";

const { createSlice } = require("@reduxjs/toolkit");




const MyStoreInfoStateSlice = createSlice({
    name: "mystoreInfo",
    initialState:{
        newOrders: 0,
        newMessages: 0,
        warehouses:[],
        storeId: null
    },
    reducers:{
       newOrderToStore: (state, action)=>{
        const order = action.payload.data;
        toast.success(<div><NavLink style={{color: 'black'}} to={`/mystore/orders?order=${order.id}`}>Новый заказ!<br/>Id: {order.fId}<br/>Сумма: {order.sum}</NavLink></div>, {
            autoClose: false,
            

        })
        
        
        state.newOrders+=1;
       },
       fillInfo: (state, action)=>{
        
        
        state = {
            ...state,
            ...action.payload.data
        }
        return state
       },
       decrementOrdersCount: (state, action) =>{
            state.newOrders -= 1;
       },
       newMessageToStore: (state, action) =>{
        const messages = action.payload.data;
        toast.success(<div>
            <NavLink style={{color: 'black'}} to={`/mystore/orders?order=`}>Новое сообщение от пользователя</NavLink>
        </div>)
       },
       decrementNewMessagesCount: (state, action) =>{
        state.newMessages -=1;
       }
    },
    extraReducers:{

    }
})
export const {newOrderToStore, fillInfo, decrementOrdersCount} = MyStoreInfoStateSlice.actions;
export default MyStoreInfoStateSlice.reducer;