import React, { Component, useState, useRef } from 'react';
import authService from "@auth/AuthorizeService";
import { MDBCol, MDBContainer, MDBInput, MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBCardFooter, MDBRange, MDBInputGroup, MDBCheckbox } from 'mdb-react-ui-kit';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { StyledDropzone } from '../Dropzone';
import { toast } from 'react-toastify';
import { SetLocationsMyStore } from './SetLocationsMyStore';
import { EditPickupPhotos } from './EditPickupPhotos';
import AutoResizeTextarea from 'hooks/TextArea/AutoResuzeTextArea';
import { EditPickupInfo } from './EditPickupInfo';

export class MyStoreEdit extends Component {
  static displayName = MyStoreEdit.name;
  
  
  constructor(props) {
    super(props);
    this.state = { store: null, loading: true, image: null, imageUrl: null, zoomValue: 1.0 };
    this.editor = React.createRef();
    this.loadStore = this.loadStore.bind(this);
    this.renderStore = this.renderStore.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);
  }
  componentDidMount(){
    this.loadStore();
  }
  async loadStore(){
    
      const token = await authService.getAccessToken();
      const res = await fetch('/api/mystore', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }
  
      });
      if(res.status === 404){
        console.log('shop not found');
        this.setState({ store: null, loading: false });
        return;
      }
      const data = await res.json();
      this.setState({ store: data, loading: false, imageUrl: '/api/store/'+data.id+'.jpg', image: '/photos/store/'+data.id+'.jpg'});
      
  }

  
  async saveNameAndDesc(e) {
    

    e.preventDefault();
    const formData = new FormData(e.target);
    const token = await authService.getAccessToken();
    formData.set('euroPost', formData.get('euroPost') == 'on' ? true : false)
    formData.set('belPost', formData.get('belPost') == 'on' ? true : false)
    const res = await fetch('/api/mystore/edit', {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`
      },
       body: formData
    })
    if(res.ok){
      toast.success('Успешно')
    } else{
      toast.error('Ошибка. Обратитесь в поддержку')
    }
    
  }
  async setStoreAvatar(e){
    e.preventDefault();
    const formData = new FormData(e.target);

    const token = await authService.getAccessToken();
    const res = await fetch('/api/mystore/UploadImage', {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        },
        body: formData

    });
    if(res.ok){
      toast.success("Успешно!")
    } else{
      toast.error('Ошибка. Обратитесь в поддержку')
    }
    
  }
  

  renderStore(store){
    if(!store){
      return (
        <>
          <p>Магазин не создан</p>
        </>
      )
    }


    
    console.log(this.state.zoomValue)
    return (
      <>
      
      
        <MDBRow evenly >
          
        <MDBCol size={12}  md={5} className='d-flex justify-content-center'>
        <MDBCard className='text-center'>
            <MDBCardBody>
            <MDBCardTitle>Изменить аватарку</MDBCardTitle>
            <MDBCardText>
              
            <StyledDropzone onDrop1={(dropped) => this.setState({image: dropped[0]})}/>
            <AvatarEditor className='mt-1' ref={this.editor} border={0} width={250} height={250} image={this.state.image} scale={this.state.zoomValue}/>
           
            <MDBRange style={{maxWidth: '250px'}} label="Увеличение" labelId='range' id='range' defaultValue={1} max={5} min={1} step={0.1.toString()} onChange={(x) => this.setState({zoomValue: x.target.value})}  />
            
            
            
            
            
            </MDBCardText>
            
          </MDBCardBody>
          <MDBCardFooter className='d-flex justify-content-evenly'>
          <button className='btn btn-danger'>Отмена</button>
            <button className='btn btn-success' onClick={this.uploadAvatar}>Сохранить</button>
          </MDBCardFooter>
          
            
            
            </MDBCard>
        </MDBCol>
        
        <MDBCol size={12} md={7} className=' mt-4 mt-md-0'>
          <MDBCard >
          <form onSubmit={this.saveNameAndDesc}>
          <MDBCardBody>
            
            
            
            
            
            <MDBInput name="Name" wrapperClass='mb-4' label='Название' defaultValue={this.state.store.name}/>
            <MDBInputGroup textBefore="@">
            <MDBInput name="username" wrapperClass='mb-4' label='Username' placeholder='@bestVape' defaultValue={this.state.store.username}/>
            </MDBInputGroup>
            
            <AutoResizeTextarea required name="Description" className='mb-4' label='Описание' defaultValue={this.state.store.description}/>
            <AutoResizeTextarea required name="SelfPickup" className='mb-4' label='Самовывоз' defaultValue={this.state.store.selfPickup}/>
            <AutoResizeTextarea required name="Delivery" className='mb-4' label='Доставка' defaultValue={this.state.store.delivery}/>
            <MDBCheckbox toggleSwitch inline name='belPost' defaultChecked={this.state.store.belPost}  label='Белпочта' />
            <MDBCheckbox toggleSwitch inline name='euroPost' defaultChecked={this.state.store.euroPost}  label='Европочта' />
            
            
          </MDBCardBody>
            <MDBCardFooter className='d-flex justify-content-between'>
              <button className='btn btn-danger'>Отмена</button>
            <button type='submit' className='btn btn-success'>Сохранить</button>
          </MDBCardFooter>
          </form>
          
          </MDBCard>
        
        </MDBCol>
        <MDBCol size={12}>
          <EditPickupInfo/>
        </MDBCol>
        <MDBCol size={12}>
          <EditPickupPhotos/>
        </MDBCol>
        {/* <MDBCol size={12}>
          <SetLocationsMyStore storeId={store.id}/>
        </MDBCol> */}
        </MDBRow>
        

        
        
      </>
    )
  }

  
  
  
  
  render() {
    let contents  = this.state.loading
    
    ? <p><em>Loading...</em></p>
    : this.renderStore(this.state.store);
    
    if(this.state)
    return (
      <>
        <h1>Редактирование</h1>
        {contents}
      </>
    );
  }

  async uploadAvatar(){
    console.log(this.editor)
    if(this.editor){
      const canvas = this.editor.current.getImage();
      const dataUrl = canvas.toDataURL('image/jpeg', 0.8)
      const dataURItoBlob = (dataURI)=> {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for(var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
      }
      var blob = dataURItoBlob(dataUrl);
      var fd = new FormData();
      const file = new File( [blob], 'canvasImage.jpg', { type: 'image/jpeg' } );
      fd.append("file", file);
  
      const token = await authService.getAccessToken();
      const res = await fetch('/api/mystore/UploadImage', {
        method: 'POST',
        headers: {
            
            
            'Authorization': `Bearer ${token}`
        },
        body: fd
      });
      console.log(res)
  
    }
  }

}


