import React, { createContext, useState } from 'react';
import Modal from './AddDistrictModal';

const AddDistrictProviderModalContext = createContext(null);

export const AddDistrictProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [id, setid] = useState(1);

  const showAddModal = (cityId) => {
    console.log(cityId)
    setid(cityId);
    
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
    setid(null);
  };

  return (
    <AddDistrictProviderModalContext.Provider value={{ showAddModal, hideModal }}>
      {children}
      
      {<Modal show={modal} cityId={id} setShow={hideModal}/>}
    </AddDistrictProviderModalContext.Provider>
  );
};

export default AddDistrictProviderModalContext;