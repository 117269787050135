import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductStores } from "./ProductPageStateSlice";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";
import ReactStars from "react-rating-stars-component";
import StarRating from "components/StarRating/StarRating";
import './product.css'
import { NavLink } from "react-router-dom";
import StoreReviewsModalContext, { StoreReviewsModalProvider } from "../GeneralModals/StoreReviews/StoreReviewsModalContext";
import Skeleton from "react-loading-skeleton";
import { autoDeclensionReviews } from "css/Utils";

  
  
  
  

export function StoresTab({productId}){
    const dispatch = useDispatch();
    const stores = useSelector(state => state.store.productPage.stores)
    const isLoadingStores = useSelector(state => state.store.productPage.isLoadingStores)
    function onChangeLocation(){
        console.log('Диспатчинг евента')
        dispatch(fetchProductStores({id:productId}))
        
    }
    useEffect(()=>{
        console.log('setting listener')
        
        window.addEventListener('locationChange', onChangeLocation)
        return () => {
            window.removeEventListener('locationChange', onChangeLocation)
        }
        
    },[])
    useEffect(()=>{
        dispatch(fetchProductStores({id:productId}))
    },[productId])
    const {showReviewsModal} = useContext(StoreReviewsModalContext)
    return(
        <>
            {isLoadingStores && [1].map((it, i)=> {
                return <Skeleton className="mt-3" height='160px'/>
            })}
            {!isLoadingStores && stores?.map((store, i)=>{
                    return <>
                        <StoreItem store={store} key={i} openReviews={()=>showReviewsModal(store)}/>
                    </>
                })}
            {!isLoadingStores && stores?.length == 0 && 
                <div className="text-center w-100">
                    <p>Магазины не найдены</p>
                    <p className="text-muted">Попробуйте изменить свою локацию</p>
                </div>}
            
        </>
    )
}

function StoreItem({store, openReviews}){

    return<>
    

    
        <MDBCard className="mt-3">
      <div className='d-flex flex-wrap'>
        
      <img height='150px' width='150px' className="rounded-5" src={`/photos/store/${store.id}.jpg/0`} alt='...' />
        
        
          
            <div style={{height: 'fit-content'}} className="pt-3 px-3 d-flex align-items-start justify-content-between flex-wrap">
              <div>
                <NavLink style={{wordBreak: 'break-word'}} to={`/store/@${store?.username}`}>
                  <MDBCardTitle className="mb-0">{store.name}</MDBCardTitle>
                </NavLink>
                <StoreRatingBar store={store}/>
                <div className="d-table">

                                
                                        {store?.prices?.map((item, index)=>{
                                            return (
                                                <>
                                                <div className="d-table-row">
                                                    <p className="pe-5 d-table-cell" style={{fontWeight: '600'}}>от {item.from}  шт.</p><p style={{fontWeight: '600'}} className="d-table-cell">{item.price} руб.</p>
                                                </div>
                                                
                                            
                                        
                                                </>
                                            );
                                        })}
                  </div>
              </div>
              
            </div>
            
              
                
                
                        
            
            
            
{/*             
          </MDBCardBody> */}
      </div>
    </MDBCard>
    
    </>
}

export function StoreRatingBar({store,className = ''}){
    const {showReviewsModal} = useContext(StoreReviewsModalContext)
    return <div onClick={()=>{showReviewsModal(store)}} className="reviewLink d-flex align-items-center">
    <p>{store?.rating?.toFixed(1)}</p>
    <RatingStars rating={store?.rating} className="mx-2"/>
    <p className="text-muted">{store?.reviewsCount} {autoDeclensionReviews(store?.reviewsCount)}</p>
</div>
}
export function RatingStars({rating = 0, className = ''}){
    return<div className={"d-flex align-items-center " + className}>
        
        <div className="ratingBar">
            <div className="grayStars stars"></div>
            <div className="yellowStars stars" style={{width: `${rating*20}%`}}></div>
        </div>
    </div>
}