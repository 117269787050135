import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// {
//     "id": "465b36a5-462d-49e2-8a88-c724e72aa9af",
//     "chatName": "Облака Минск",
//     "to": "611b2e5c-62f0-41bb-bad0-b90c6d7584cf",
//     "lastMessage": "",
//     "unreadCount": 0,
//     "isWriting": false,
//     "messages": []
// },

// message:
// [
//     {
//         "id": "f2d5055a-307b-419f-8491-6d0a2e84cb10",
//         "text": "Привет!\nЯ хочу заказать:\nhuskyОблака МинскЯгодный микс",
//         "isFromStore": false,
//         "date": "14:32 | июл. 31"
//     },
// ]

const LocationsPageStateSlice = createSlice({
    name: "MyStoreLocationsPage",
    initialState:{
        cities: [],
        isLoadingCities: false,
        points: []
        
    },
    reducers:{
        checkCityMyStore: (state, action)=>{
            const {cityId, to} = action.payload
            state.cities.forEach(city=>{
                if(city.id === cityId){
                    city.checked = to
                    city.districts.forEach(district =>{
                        district.checked = to
                    })
                    city.metro.forEach(line =>{
                        line.checked = to
                        line.metroStations.forEach(station => {
                            station.checked = to
                        })
                    })
                }
            })
            
        },
        checkMetroLineMyStore: (state, action)=>{
            const {cityId, metroLineId, to} = action.payload
            const city = state.cities.find(city=> city.id == cityId)
            if(city){
                const line = city.metro.find(line => line.id == metroLineId)
                if(line){
                    line.checked = to
                    line.metroStations.forEach(station => {
                        station.checked = to
                    })
                }
            }
            
        },
        
        selectCityMyStore: (state, action)=>{
            state.cities.push(action.payload)
        },
        selectDistrictMyStore: (state, action)=>{
            const {cityId, district} = action.payload;
            state.cities.forEach((it)=>{
                if(it.id == cityId){
                    if(it.districts?.length >= 0){
                        it.districts.push(district)
                    } else {
                        it.districts = [district]
                    }
                }
            })
        },
        selectMetroStationMyStore: (state, action)=>{
            const {metroLineId, station} = action.payload;
            state.cities.forEach((city)=>{
                city.metro.forEach(metroLine =>{
                    if (metroLine.id == metroLineId){
                        if(metroLine.metroStations.length >= 0){
                            metroLine.metroStations.push(station)
                        } else{
                            metroLine.metroStations = [station]
                        }
                       
                    }
                })
            })
        },
        selectMetroLineMyStore: (state, action)=>{
            const {metroLineId, station} = action.payload;
            state.cities.forEach((city)=>{
                city.metro.forEach(metroLine =>{
                    if (metroLine.id == metroLineId){
                        if(metroLine.metroStations.length >= 0){
                            metroLine.metroStations.push(station)
                        } else{
                            metroLine.metroStations = [station]
                        }
                       
                    }
                })
            })
        }
        
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchCitiesMyStore.pending, (state, action) => {
            state.isLoadingCities = true;
        });
        builder.addCase(fetchCitiesMyStore.fulfilled, (state, action) => {
            
            state.isLoadingCities = false;
            state.cities = action.payload;
        });
        builder.addCase(fetchCitiesMyStore.rejected, (state, action) => {
            state.isLoadingCities = false;
        });

        builder.addCase(fetchDistrictsMyStore.pending, (state, action) => {
            state.isLoadingCities = true;
        });
        builder.addCase(fetchDistrictsMyStore.fulfilled, (state, action) => {
            const { data, cityId } = action.payload;
            state.isLoadingCities = false;
            state.cities.forEach((it)=>{
                if(it.id == cityId){
                    it.districts = data.districts
                    it.metro = data.metro
                }
            })
        });
        // builder.addCase(fetchDistrictsMyStore.rejected, (state, action) => {
        //     state.isLoadingCities = false;
        // });
        builder.addCase(setCityMyStore.fulfilled, (state, action) => {
           
            state.points = action.payload;
        });
        builder.addCase(setDistrictMyStore.fulfilled, (state, action) => {
            const { districtId, warehouseId, to } = action.payload;
            const point = state.points.find(point => point.warehouseId == warehouseId)
            const district = point.districts.find(x => x.id == districtId)
            if(district){
                district.checked = to
            }
        });

        builder.addCase(setMetroStationMyStore.fulfilled, (state, action) => {
            const { stationId, warehouseId, to } = action.payload;
            const point = state.points.find(point => point.warehouseId == warehouseId)

            point.metro.forEach(line => {
                const station = line.stations.find(x => x.id== stationId)
                if(station){
                    station.checked = to
                }
            })
        });

        builder.addCase(fetchPickupPointsMyStore.fulfilled, (state, action) => {
            
            state.points = action.payload.points;
            state.cities = action.payload.cities
        });


        
       
    }
    
    // async thunk fetch chats, fetch dialog, but 

})

export const fetchCitiesMyStore = createAsyncThunk('MyStoreLocationsPage/fetchCities', async ()=>{
    const token = await authService.getAccessToken()
    const res = await fetch('/api/mystore/location/cities',{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})

export const fetchDistrictsMyStore = createAsyncThunk('MyStoreLocationsPage/fetchDistricts', async ({cityId})=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/mystore/location/cities/${cityId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return {data: await res.json(), cityId: cityId}
    }
    throw Error("Ошибка")
})
export const setCityMyStore = createAsyncThunk('MyStoreLocationsPage/setCityMyStore', async ({cityId, warehouseId})=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/mystore/edit/location/setCity?cityId=${cityId}&warehouseId=${warehouseId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        },
        method: "PATCH"
    })
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})
export const setDistrictMyStore = createAsyncThunk('MyStoreLocationsPage/setDistrictMyStore', async ({districtId,warehouseId, to})=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/mystore/edit/location/districts?districtId=${districtId}&warehouseId=${warehouseId}&to=${to}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        },
        method: "PATCH"
    })
    if(res.ok){
        return {
            districtId,
            to,
            warehouseId
        }
    }
    throw Error("Ошибка")
})

export const setMetroStationMyStore = createAsyncThunk('MyStoreLocationsPage/setMetroStationMyStore', async ({stationId,warehouseId, to})=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/mystore/edit/location/metroStations?stationId=${stationId}&warehouseId=${warehouseId}&to=${to}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        },
        method: "PATCH"
    })
    if(res.ok){
        return {
            stationId,
            to,
            warehouseId
        }
    }
    throw Error("Ошибка")
})
export const fetchPickupPointsMyStore = createAsyncThunk('MyStoreLocationsPage/fetchPickupPointsMyStore', async ()=>{
    const token = await authService.getAccessToken()
    const res = await fetch(`/api/mystore/edit/pickupPoints`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    if(res.ok){
        return await res.json()
    }
    throw Error("Ошибка")
})
export const { selectCityMyStore, selectDistrictMyStore, selectMetroLineMyStore, selectMetroStationMyStore, checkCityMyStore, checkMetroLineMyStore } = LocationsPageStateSlice.actions;
export default LocationsPageStateSlice.reducer;