


import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk, current, isRejected } = require("@reduxjs/toolkit")

const ordersHz = {
    category: 'active',
    orders: []
}

const MyManagerStateSlice = createSlice({
    name: 'myManagerState',
    initialState: {
        orders: [],
        completedOrders: [],
        isLoading: false,
        completeError: null,
        activeTab: 0,
        page: 0,
        lastPage: false,
        managerId: null
    },
    reducers: {
        
        setManagerId: (state, action) =>{
            const {managerId} = action.payload;
            state.managerId = managerId;
            console.log(state.managerId, 'cur id', state)
        },
        setManagerActiveTab: (state, action)=>{
            const {status} = action.payload;
            state.page = 0;
            state.lastPage = false;
            state.activeTab = status;
            state.orders = []
            return state
        },
        cleanCompleteError: (state, action)=>{
            
            state.completeError = null;
            
            return state
        },
        changeManagerPage: (state, action) =>{
            if(state.lastPage == false){
                state.page += 1;
            }
        }
        

    },
    extraReducers: (builder) => {
        builder.addCase(fetchMyManagerOrders.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMyManagerOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            if(!action.payload){
                return state
            }
            const {orders, status} = action.payload
            if(!orders){
                return state
            }
            if(orders.length === 0){
                state.lastPage = true
            }
            state.orders = [...state.orders, ...orders];
            
        });
        builder.addCase(fetchMyManagerOrders.rejected, (state, action) => {
            state.isLoading = false;
            
        });

        
      }
})

export const fetchMyManagerOrders = createAsyncThunk('myManagerState/fetchOrders', async ({status, page}, {getState, rejectWithValue}) => {
    
    if(status == undefined) return;
    console.log('fetching orders...')
    const state = getState()
    console.log('curState',state)
    const token = await authService.getAccessToken()
      const res = await fetch(`/api/mystore/mymanager/orders?managerId=${state?.mystore.myManager?.managerId}&status=${status}&page=${page}`,{
        headers:{
          "Authorization": `Bearer ${token}`
        }
      })
      if(res.ok){
        return {orders: await res.json(), status: status}
      }
      return rejectWithValue(null)
});

export const { cleanCompleteError, changeManagerPage, setManagerActiveTab, setManagerId } = MyManagerStateSlice.actions
export default MyManagerStateSlice.reducer