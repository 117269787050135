import { toast } from "react-toastify";
import authService from "../../../api-authorization/AuthorizeService";



const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit")



const CartStateSlice = createSlice({
    name: 'cartState',
    initialState: {
        items: [],
        totalSum: 0,
        isLoading: false,
        error: null,
        orderType: 1,
        comment: "",
        count: 0,
        selectedContact: null,
        postType: null
        
    },
    reducers: {
        changeAmount: (state, action) => {
            
            const {cartItemId, amount} = action.payload;

            state?.items?.forEach(store => {
                store?.ranges?.forEach(range => {
                  range?.flavours?.forEach((flavour, index) => {
                    if (flavour.id === cartItemId) {
                        if(amount === 0){
                            flavour.amount = 1;
                        } else{
                            flavour.amount = amount;
                        }
                      
                      
                     
                    }
                  });
                });
              });
              
              
              
        },
        selectContactCart: (state, action)=>{
            const {contact} = action.payload;
            state.selectedContact = contact;
        },
        switchSelectedShop: (state, action)=>{
            const {to} = action.payload;
            state.items.forEach(it => it.store.selected = to)
        },
        deleteFromCart: (state, action) =>{
            const {cartItemId} = action.payload;
            state.items = state?.items?.filter(store => {
                store.ranges = store?.ranges?.filter(range => {
                  range.flavours = range?.flavours?.filter((flavour, index) => {
                    if (flavour.id === cartItemId) {
                        return false;
                    }
                    return true;
                  });
                  if(range?.flavours?.length == 0){
                    return false;
                  }
                  return true;
                });
                if(store?.ranges?.length == 0){
                    return false
                }
                return true;
              });
        },
        setPostType: (state, action)=>{
            const {post} = action.payload
            state.postType = post
        },
        setOrderType: (state, action)=>{
            const {orderType} = action.payload;
            state.orderType = orderType;
        },
        setOrderComment: (state, action)=>{
            const {comment} = action.payload;
            state.comment = comment;
        },
        
        setClientCartItemsCount: (state, action)=>{
            const {count} = action.payload;
            state.count = count;
        },
        

    },
    extraReducers: (builder) => {
        builder.addCase(fetchCart.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCart.fulfilled, (state, action) => {
            state.isLoading = false;
            state.items = action.payload.items;
            state.items.forEach(it => {it.store.selected = true})
            state.totalSum = action.payload.totalSum;
        });
        builder.addCase(fetchCart.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });


        builder.addCase(postChangeAmount.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(postChangeAmount.fulfilled, (state, action) => {
            state.isLoading = false;
            const newData = action.payload;
              mergeAndUpdateTotalSums(state.items, newData.stores)
              state.totalSum = newData.totalSum;
        });
        builder.addCase(postChangeAmount.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });

        builder.addCase(postDeleteItem.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(postDeleteItem.fulfilled, (state, action) => {
            state.isLoading = false;
            const newData = action.payload;
              mergeAndUpdateTotalSums(state.items, newData.stores)
              state.totalSum = newData.totalSum;
        });
        builder.addCase(postDeleteItem.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });
        
      }
})
function mergeAndUpdateTotalSums(oldData, newData) {
    for (let i = 0; i < newData.length; i++) {
      const newItem = newData[i];
      const oldItem = oldData.find(item => item.store.id === newItem.id);
  
      if (oldItem) {
        for (let j = 0; j < newItem.ranges.length; j++) {
          const newRange = newItem.ranges[j];
          const oldRange = oldItem.ranges.find(range => range.id === newRange.id);
  
            oldRange.rangeTotalSum = newRange.rangeTotalSum;
            
        }
        oldItem.storeTotalSum = newItem.storeTotalSum;
      }
    }
    
  }
export const fetchCart = createAsyncThunk('cartState/fetchOrders', async () => {
    
    const token = await authService.getAccessToken();
    const res = await fetch('/api/cart', {
        method: 'GET',
        headers:{
            'Authorization': `Bearer ${token}`
        }
    });
    if(!res.ok){
        // error
        console.log(await res.text())
        return;
    }
    return await res.json();
    
});
export const postChangeAmount = createAsyncThunk('cartState/changeAmount', async ({cartId, amount})=>{
    const token = await authService.getAccessToken();
    
        const res = await fetch(`/api/cart?itemId=${cartId}&amount=${amount}`, {
            method: 'PATCH',
            headers:{
                "Authorization": `Bearer ${token}`   
            }
        });
        if(!res.ok){
            toast.error('Ошибка при изменении количества');
            return
        }
        return await res.json();
})
export const postDeleteItem = createAsyncThunk('cartState/deleteItem', async ({cartItemId})=>{
    const token = await authService.getAccessToken();
        const res = await fetch(`/api/cart?cartItemId=${cartItemId}`,{
            headers:{
                "Authorization": `Bearer ${token}`
            },
            method: 'DELETE'
        })
        if(!res.ok){
            toast.error('Ошибка при удаление');
            return
        }
        return await res.json();
})
export const { changeAmount, deleteFromCart, setOrderType, setPostType,selectContactCart, switchSelectedShop, setOrderComment, setClientCartItemsCount } = CartStateSlice.actions
export default CartStateSlice.reducer

























