import useScreenSize from "hooks/ScreenSize/useScreenSize";
import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { AdminMobileHeader } from "./AdminMobileHeader";
import { AdminSidebar } from "./AdminSidebar";
import { AdminIndex } from "../Index/AdminIndex";
import { LocationPage } from "../LocationsPage/LocationsPage";
import { PriceList } from "../PriceList/PriceList";
import { AdminStoresView } from "../Stores/AdminStoresView";
import { AdminUsersView } from "../Users/AdminUsersView";





export function AdminLayout() {
    const location = useLocation()
    const screenSize = useScreenSize()
    
      return (
        <div className='box'>
          
        {screenSize.width <= 768 && <AdminMobileHeader/>}
        
        <MDBContainer breakpoint='xxl' className='mt-3' style={{flex: '1 1 auto', paddingBottom: screenSize.width <=768 && location.pathname != '/mystore/chat/' ? '80px' : 0}}>
            {screenSize.width > 768 ? 
            <div className="d-flex h-100">
                <AdminSidebar/>
                <div className="flex-grow-1 ms-3">
                  <AdminRoutes/>
                </div>
            </div> : <AdminRoutes/>}
            
        </MDBContainer>
        
        {/* <div style={{flex: '0 1 40px'}}></div> */}
        </div>
      );
    
  }
  function AdminRoutes(){
    return <>
      <Routes>
                  
                  <Route index element={<AdminIndex/>}/>
                  <Route path='pricelist' element={<PriceList/>}/>
                  <Route path='stores' element={<AdminStoresView/>}/>
                  <Route path='users' element={<AdminUsersView/>}/>
                  <Route path='locations' element={<LocationPage/>}/>
                </Routes>
    </>
  }