import { MDBRow, MDBCol, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCard, MDBInput, MDBTable, MDBTableBody, MDBTableHead, MDBCardHeader, MDBCardFooter, MDBListGroup, MDBListGroupItem, MDBInputGroup, MDBIcon, MDBCheckbox } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import authService from "../../api-authorization/AuthorizeService";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import './mycart.css'
import { useDispatch, useSelector } from "react-redux";
import { changeAmount, deleteFromCart, fetchCart, postChangeAmount, postDeleteItem, switchSelectedShop } from "./MyCartState/CartStateSlice";
import { OrderTypeSelector } from "./OrderType";
import { AvailabilityStatusColor, AvailabilityStatusText } from "css/Utils";
import { ImageGallery } from "./ImageGallery";


export function MyCart(){
    const navigate = useNavigate();
    const cartItems = useSelector(state => state.store.cart.items)
    const comment = useSelector(state => state.store.cart.comment)
    const totalSum = useSelector(state => state.store.cart.totalSum)
    const orderType = useSelector(state => state.store.cart.orderType)
    const dispatch = useDispatch()
    const selectedContact = useSelector(state => state.store.cart.selectedContact)
    const postType = useSelector(state => state.store.cart.postType)
    
    const [timer, setTimer] = useState(null);
    function changeOnInputAmount(flavourId,value){
        

        // Clear the timer if it exists.
        if (timer) {
          clearTimeout(timer);
        }
      
        // Set the timer to 1 second.
        const tempTimer = setTimeout(() => {
            changeAmount(flavourId, value, false, false)
        }, 500);
        setTimer(tempTimer)
    }
    
    function changeAm(cartItemId, amount){
        dispatch(changeAmount({ cartItemId, amount }));
        if (timer) {
            clearTimeout(timer);
          }
        
          // Set the timer to 1 second.
          const tempTimer = setTimeout(() => {
                dispatch(postChangeAmount({ cartId: cartItemId, amount }));
          }, 300);
          setTimer(tempTimer)
        
    }
    async function deleteFromCartL(cartItemId){

        dispatch(deleteFromCart({ cartItemId: cartItemId }))
        dispatch(postDeleteItem({ cartItemId: cartItemId }));
    }
    useEffect(()=>{
        //fetchCart()
        dispatch(fetchCart())
    },[]);
    async function Order(e){
        
        const body = {
            stores: cartItems.filter(x => x.store.selected).map(x => x.store.id),
            comment: comment,
            contactId: selectedContact?.id,
            orderType: orderType
        }
        const token = await authService.getAccessToken();
        const res = await fetch('/api/orders', {
            method: 'PUT',
            headers:{
                "Authorization": `Bearer ${token}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if(res.ok){
            navigate(`/profile/orders/${await res.text()}`)
        } else{
            toast.error('Ошибка при оформление заказа');
        }
    }
    if(cartItems && cartItems.length == 0){
        return(<>
            <h4 className="mt-4">Корзина</h4>
            <p>Корзина пуста</p>
        </>)
    }
    return(
        <>
            <h4 className="mt-4">Корзина</h4>
            
            <div className="d-flex flex-wrap">
                
                <div className="flex-grow-1">
                {cartItems && cartItems?.map((item, index)=>{
                    return(
                        <>
                        
                            <MDBCard className="mb-5">
                                
                                <MDBCardHeader className="d-flex justify-content-between align-items-center">
                                    <MDBCardTitle className="mb-0 d-flex align-items-center">
                                        <MDBCheckbox checked={item.store.selected}  onChange={(e)=> dispatch(switchSelectedShop({to: e.currentTarget.checked}))} className="mt-0"/>
                                        <NavLink to={`/store/${item.store.id}`}>{item?.store?.name}</NavLink>
                                    </MDBCardTitle>
                                    <ImageGallery images={item.store?.pickupPhotos} store={item.store}/></MDBCardHeader>
                                <MDBCardBody>
                                    {item?.ranges?.map((range, index)=>{
                                        return (
                                            <>

                                                <h4 className="mb-2">{range?.name}</h4>
                                                {/* <p>Итого за линейку: {range.rangeTotalSum} руб.</p> */}
                                                
                                                <div className='d-flex flex-wrap align-items-start'>
                                                    <div>
                                                        <img style={{height: '150px', borderRadius: '16px'}} src={`/photos/ranges/${range?.id}.jpg/0`}/>
                                                    </div>
                                                    <div className="mx-3">
                                                        <MDBTable className="cartTable">
                                                            <MDBTableHead>
                                                            <tr >
                                                                <th style={{paddingTop: 0}}>Количество</th>
                                                                <th style={{paddingTop: 0}}>Цена</th>
                                                            </tr>
                                                            </MDBTableHead>
                                                            <MDBTableBody>
                                                                {range?.prices?.map((price, index)=>{
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>От {price.from} шт.</td>
                                                                                <td>{price.price} руб.</td>
                                                                                
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                            </MDBTableBody>
                                                        </MDBTable>
                                                    </div >
                                                    
                                                    <MDBListGroup light small className="" style={{flex: '1 1 auto'}}>
                                                        {range?.flavours?.map((flavour, index)=>{
                                                            return (
                                                                <>
                                                                <MDBListGroupItem color="dark" className={`d-flex justify-content-between align-items-center flex-grow-1 flex-shrink-1 ${index == 0 ? 'pt-0' : ''}`}>
                                                                    
                                                                    <p className="m-0 fw-bold me-2">{flavour.name}</p>
                                                                    
                                                                    
                                                                    <div className="d-flex ms-2">
                                                                    {flavour.amountOnWarehouse < 6 && <span className={`me-2 text-${AvailabilityStatusColor(flavour.amountOnWarehouse)}`}>{AvailabilityStatusText(flavour.amountOnWarehouse)}</span>}
                                                                    {flavour.amount <= range?.prices[0]?.from
                                                                    ?   <button className="btn btn-danger px-3" onClick={e => deleteFromCartL(flavour.id)}>
                                                                            <MDBIcon far icon="trash-alt" />
                                                                        </button>
                                                                        :
                                                                        <button className="btn btn-danger px-3" onClick={e => changeAm(flavour.id, flavour.amount-1)}>
                                                                            <MDBIcon fas icon="minus"/>
                                                                        </button>}
                                                                        
                                                                        <input
                                                                            className="mx-2 amountInput"
                                                                            value={flavour.amount} step={1}
                                                                            style={{maxWidth: '55px',textAlign: 'center', borderRadius: '4px', border: 'none'}}
                                                                            onChange={e => changeAm(flavour.id,  Number(e.target.value))}
                                                                            type="number"
                                                                        />
                                                                        <button className="btn btn-success px-3" onClick={e => changeAm(flavour.id, flavour.amount+1)}><MDBIcon fas icon="plus"/></button>
                                                                    </div>
                                                                        
                                                                    
                                                                
                                                                </MDBListGroupItem>
                                                                    
                                                                </>
                                                            )
                                                        })}
                                                    </MDBListGroup>
                                                </div>
                                                
                                                
                                            </>
                                        )
                                    })}
                                </MDBCardBody>
                                <MDBCardFooter>
                                    <p className="mb-0">Итого в магазине: {item.storeTotalSum} руб.</p>
                                </MDBCardFooter>
                            </MDBCard>
                            
                        
                        
                        </>
                    )
                    
                })}
                </div>
                
                <div className="mt-2 ms-4 w-sm-25">
                
                
                <OrderTypeSelector/>
                        
                       
                            <h3>Общая сумма: {totalSum} руб.</h3>
                            <button onClick={Order} className="btn btn-success" disabled={orderType > 1 ? (((orderType == 2 ? !postType : false) || !selectedContact) ?? true) : false}>Оформить заказ</button>
                            </div>
                     
            </div>
            
            
            
            
        </>
    );
}