import { Component } from "react";

import authService from "@auth/AuthorizeService";
import CreateShopForm from "./createShopForm";
import React from "react";
import { toast } from "react-toastify";
import { StoreRatingBar } from "components/store/ProductPage/StoresTab";
import { StoreReviewsModalProvider } from "components/store/GeneralModals/StoreReviews/StoreReviewsModalContext";


export class MyStoreIndex extends Component{
    static displayName = MyStoreIndex.name;

  constructor(props) {
    super(props);
    this.state = { store: null, loading: true };
    this.createShop = this.createShop.bind(this);
  }
  componentDidMount(){
    this.getShop();
  }

  async getShop() {
    const token = await authService.getAccessToken();
    console.log(token, 'token')
    const res = await fetch('/api/mystore', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

    });
    if(res.status === 404){
      console.log('shop not found');
      this.setState({ store: null, loading: false });
      return;
    }
    const data = await res.json();
    this.setState({ store: data, loading: false });
    
  }
  async createShop(body) {
    
    this.setState({ loading: true });
    
    const token = await authService.getAccessToken();
    const res = await fetch('/api/mystore/Create', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: body

    });
    if(res.ok){
      await authService.silentRenew()
      const data = await res.json();
    
      this.setState({ store: data, loading: false });
      console.log(data);
    } else {
      toast.error('Ошибка создания!')
    }
    

    
  }
  
  renderStore(store) {
    if(store === null){
      return (
        <div>
          <p>У вас ещё нет магазина.</p>
          <CreateShopForm callback={this.createShop}/>
          
        </div>
      )
    }
    return (
      <div>
        <div className="d-flex">
          <img src={`/photos/store/${store.id}.jpg`} alt="store img" height="150px"/>
          <div className="ms-3">
            <StoreReviewsModalProvider>
              <StoreRatingBar store={store}/>
            </StoreReviewsModalProvider>
            
            <h5>{store.name}</h5>
            <h6 className="mb-0">Описание</h6>
            <p>{store.description}</p>
            
          </div>
          
        </div>
        
        <h6 className="mb-0">Самовывоз</h6>
        <p>{store.selfPickup}</p>
        <h6 className="mb-0">Доставка</h6>
        <p>{store.delivery}</p>
        
        <h3>Основная статистика за сегодня:</h3>
        <p>Продажи: 10 шт.</p>
        <p>Прибыль: 1000 руб.</p>
        <p>Просмотры: 8596</p>


        

      </div>
    )
  }

  render() {
    let contents = this.state.loading
    ? <p><em>Loading...</em></p>
    : this.renderStore(this.state.store)
    return (
      <>
        
        <h4>Твой магазин: </h4>
        {contents}
                
        
            
          
          
        
      </>
    );
  }
}