import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import authService from "@auth/AuthorizeService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchTableData, resetLastPage } from "./state/WarehouseTalbeSlice";


export function AddProductToWarehouse({warehouseId}) {
    const [modal, setModal] = useState(false);
    const [search, setSearch] = useState('');
    const [ranges, setRanges] = useState([]);
    let isAdded = false;
    const currentCategory = useSelector(state => state.mystore.warehouseTable.currentCategory);
    const currentWarehouse = useSelector(state => state.mystore.warehouseTable.curWarehouseId);

    const currentPage = useSelector(state => state.mystore.warehouseTable.page)
    const dispatch = useDispatch();
    async function toggle(){
        setModal(false);
        if(isAdded){
            console.log(isAdded, warehouseId, 'update///')
            dispatch(fetchCategories(currentWarehouse))
            dispatch(fetchTableData({currentCategory: currentCategory, search: '', whid: warehouseId, page: 0}));
            dispatch(resetLastPage())
        }
        
    }
    async function getRanges(str){
        if(str == ''){
            setRanges([]);
            return;
        }
        if(str.length < 3) return;
        const token = authService.getAccessToken();
        const res = await fetch(`api/warehouse/search?S=${str}&page=1&pageSize=10`, {
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        })
        if(res.ok){
            const data = await res.json();
            setRanges(data);
        }
        
    }
    useEffect(()=>{
        
        getRanges(search);
    }, [search])
    function changeAmount(amount, flavourId, rangeIndex){
        amount = Number(amount);
        if(amount < 0) return;
        
        setRanges(prev=>{
            return prev.map((it, i)=>{
                if(i == rangeIndex){
                    return {...it, flavours: it.flavours.map((flavour, i)=>{
                        if(flavour.id == flavourId){
                            return {...flavour, amount: amount}
                        }
                        return flavour;
                    })}
                }
                return it;
            })
        })
    }
    function setPriceToRange(price, rangeIndex){
        setRanges(prev=>{
            return prev.map((it, i)=>{
                if(i == rangeIndex){
                    return {...it, price: price}
                }
                return it;
            })
        })
    }
    async function AddToWh(rangeIndex){
        // delete where amount == 0
        // delete property name in flavours
        // delete property checked
        // add property flavourId
        const x = ranges[rangeIndex];
        const obj = {
                items: x.flavours.filter(it=>it.amount > 0).map(it=>{
                    return {amount: it.amount, flavourId: it.id}
                }),
                price : x.price,
                warehouseId: warehouseId
            }
            
        const token = await authService.getAccessToken();
        const res = await fetch('/api/warehouse/AddToWh', {
            method: 'PUT',
            headers:{
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        });
        if(res.ok){
            isAdded = true
            //setIsAdded(true);
            toast.success('Товар добавлен')
        } else{
            toast.error('Ошибка')
        }
    }
    return(
        <>
            <button className="btn btn-success mb-2" onClick={()=>setModal(true)}>Загрузить товар</button>
            <MDBModal show={modal} onHide={toggle}>
                <MDBModalDialog >
                    <MDBModalContent >
                        <MDBModalHeader>
                            <MDBModalTitle>Добавить товары на склад</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                        <MDBInputGroup>
                            <MDBInput label='Поиск' onInput={(e)=>setSearch(e.currentTarget.value)}/>
                            <button className="btn btn-primary" onClick={()=> getRanges(search)}>
                                <MDBIcon icon='search' />
                            </button>
                        </MDBInputGroup>
                        {ranges?.map((it, index)=>{
                            return(
                                <>
                                    <MDBCard className="mt-3">
                                        <MDBCardHeader>
                                            <div className="d-flex align-items-center">
                                                
                                            
                                            <MDBCardTitle className="flex-grow-1">{it.name}</MDBCardTitle>
                                            
                                            
                                            <button className="btn btn-dark align-self-start" onClick={()=> {
                                            // set in ranges by index property "checked"
                                            setRanges(prev=>{
                                                return prev.map((it, i)=>{
                                                    if(i == index){
                                                        return {...it, checked: !it.checked}
                                                    }
                                                    return it;
                                                })
                                            })}}>{it.checked ? <MDBIcon size="xl" fas icon="close" /> : <MDBIcon size="xl" color="white" fas icon="plus" />}</button>
                                            

                                            
                                            
                                            
                                        </div>
                                        </MDBCardHeader>
                                        <MDBCardBody>
                                            <div className="d-flex">
                                                <img src={`/photos/ranges/${it.id}.jpg/0`} alt="" style={{width: '140px', height: '140px'}}/>
                                                
                                            </div>
                                                <div className="d-flex flex-column ms-1 mt-2">
                                                {it?.flavours.map((flavour, i)=>{
                                                    return(
                                                        <>
                                                            {i !=0 && <hr className="mt-1 mb-1"/>}
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="p-0">
                                                                <MDBCardText color="dark" style={{minWidth: '5rem'}} >{flavour?.name}</MDBCardText>
                                                                </div>
                                                                {it?.checked && <div className="d-flex">
                                                                        <button className="btn btn-danger px-3" onClick={e => {changeAmount(flavour?.amount-1, flavour?.id, index)}}>
                                                                            <MDBIcon fas icon="minus"/>
                                                                        </button>
                                                                        
                                                                        <input
                                                                            className="mx-2 amountInput"
                                                                            value={flavour.amount} step={1}
                                                                            style={{maxWidth: '55px',textAlign: 'center', borderRadius: '4px', border: 'none'}}
                                                                            onChange={(e)=>{changeAmount(e.currentTarget.value, flavour?.id, index)}}
                                                                            type="number"
                                                                        />
                                                                        <button className="btn btn-success px-3" onClick={e => {changeAmount(flavour?.amount+1, flavour?.id, index)}}><MDBIcon fas icon="plus"/></button>
                                                                    {/* <MDBInputGroup>
                                                                        <button className="btn btn-danger p-2" onClick={e => {changeAmount(flavour?.amount-1, flavour?.id, index)}}><MDBIcon fas icon="minus" /></button>
                                                                        
                                                                        <input onInput={(e)=>{changeAmount(e.currentTarget.value, flavour?.id, index)}} value={flavour?.amount} className="form-control" placeholder="шт." style={{width: '5rem', backgroundColor:'#424242'}}  type="number" step={1}  min={0}/>
                                                                        <span className="input-group-text">шт.</span>
                                                                        
                                                                        <button className="btn btn-success p-2" onClick={e => {changeAmount(flavour?.amount+1, flavour?.id, index)}}><MDBIcon fas icon="plus" /></button>
                                                                    </MDBInputGroup> */}
                                                                
                                                                </div>}
                                                            </div>
                                                            
                                                        
                                                        
                                                            
                                                        </>
                                                    )
                                                })}
                                                </div>
                                                {it?.checked && 
                                                <div className="d-flex justify-content-between mt-2">
                                                    <MDBInput onInput={(e) => {setPriceToRange(Number.parseFloat(e.currentTarget.value), index)}} value={it.price} name="price" type="number" min={0.1} label='Закупочная цена' placeholder="12.50"/>
                                                    <button className="btn btn-success ms-1" color="success" onClick={()=>{AddToWh(index)}}>Добавить</button>
                                                </div>}
                                                
                                        
                                        </MDBCardBody>
                                        
                                        
                                    </MDBCard>
                                </>
                            )
                        })}
                        </MDBModalBody>
                        
                        
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}